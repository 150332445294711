import { useSelector } from 'react-redux';
import { useUtils } from 'src/hooks/useUtils';
import { AppUserSelf } from 'src/interfaces/AppUserSelf';
import { Organization } from 'src/interfaces/Organization';
import { Subtitle } from '../Subtitle';
import { IonText } from '@ionic/react';

export const SuperInfo: React.FC = () => {
  const utils = useUtils();
  const authUser: AppUserSelf | null = useSelector(
    (state: any) => state.auth.user
  );
  const organization: Organization | null = useSelector(
    (state: any) => state.app.organization
  );
  return (
    <>
      {/* SuperAdmin-only */}
      {utils.isSuperAdmin(authUser) && (
        <div
          slot="primary"
          style={{ alignSelf: 'center' }}
          className="ion-padding-horizontal"
        >
          <Subtitle className="text-color-light font-size-xs">
            Selected Organization:
          </Subtitle>
          <p className="ion-no-margin text-color-tertiary">
            {organization?.name}
          </p>
          <p className="ion-no-margin font-size-xs">
            Type:{' '}
            <IonText color="primary" style={{ textTransform: 'capitalize' }}>
              {organization?.organization_type_name}
            </IonText>
          </p>
        </div>
      )}
    </>
  );
};
