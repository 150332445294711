import { configureStore } from '@reduxjs/toolkit';
import { appReducer } from 'src/redux/appReducer';
import { authReducer } from 'src/redux/authReducer';
import { supplierReducer } from 'src/redux/supplierReducer';
import { adminReducer } from './adminReducer';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    app: appReducer,
    supplier: supplierReducer,
    admin: adminReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
