import { IonButton, useIonRouter } from '@ionic/react';
import { useId, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumb, Breadcrumbs } from 'src/components/shared/Breadcrumbs';
import {
  FormManageUser,
  FormUserSchema,
} from 'src/components/shared/FormManageUser';
import { LoadingInline } from 'src/components/shared/LoadingInline';
import { useApi } from 'src/hooks/useApi';
import { useEndpoints } from 'src/hooks/useEndpoints';
import { AppUser } from 'src/interfaces/AppUser';
import { Organization } from 'src/interfaces/Organization';
import { Supplier } from 'src/interfaces/Supplier';
import { Page } from 'src/layouts/Page';
import { setToast } from 'src/redux/appReducer';

export const UserAddPage: React.FC = () => {
  const api = useApi();
  const { createB2cUser } = useEndpoints();
  const userFormId = useId();
  const organization: Organization | null = useSelector(
    (state: any) => state.app.organization
  );
  const supplier: Supplier | null = useSelector(
    (state: any) => state.supplier.supplier
  );
  const dispatch = useDispatch();
  const router = useIonRouter();

  const [loading, setLoading] = useState(false);

  const breadcrumbs: Breadcrumb[] = [
    { label: 'Home', path: '/', direction: 'back' },
    {
      label: 'Settings',
      path: `${supplier ? '/supplier' : ''}/settings`,
      direction: 'back',
    },
    {
      label: 'Users',
      path: `${supplier ? '/supplier' : ''}/settings/users`,
      direction: 'back',
    },
    { label: 'Add User' },
  ];

  const createUser = async (data: FormUserSchema) => {
    if (!organization) {
      return;
    }
    setLoading(true);
    const userData = [
      {
        app_role_id: data.roleId,
        organization_id: organization.organization_id,
        first_name: data.firstName,
        last_name: data.lastName,
        email_address: data.email,
        location_ids: data.locationIds,
      },
    ];
    api
      .post('app_user', userData)
      .then(async (response) => {
        if (response.status === 201) {
          const user: AppUser = response.data[0];
          await createB2cUser(user, true, onSuccess, onError);
        } else {
          dispatch(
            setToast({
              header: 'Error creating user',
              color: 'danger',
              message: `This user may already exist.`,
              duration: -1,
              buttons: [
                {
                  text: 'Close',
                  role: 'cancel',
                },
              ],
            })
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSuccess = (response: any) => {
    router.push(`${supplier ? '/supplier' : ''}/settings/users`, 'back');
    console.log('User created successfully');
  };
  const onError = (response: any) => {
    console.log('User not created');
  };

  const Footer = () => {
    return (
      <div className="ion-text-right">
        <IonButton
          color="dark"
          fill="clear"
          routerLink={`${supplier ? '/supplier' : ''}/settings/users`}
          routerDirection="back"
          disabled={loading}
        >
          Cancel
        </IonButton>
        <IonButton
          color="primary"
          type="submit"
          form={userFormId}
          disabled={loading}
        >
          {loading ? (
            <span slot="start">
              <LoadingInline color="light" />
            </span>
          ) : null}
          Create User
        </IonButton>
      </div>
    );
  };

  return (
    <Page title="Add User" footer={<Footer />}>
      <Breadcrumbs breadcrumbs={breadcrumbs}></Breadcrumbs>
      <FormManageUser
        formId={userFormId}
        onSubmit={(data: FormUserSchema) => {
          createUser(data);
        }}
        loading={loading}
      />
    </Page>
  );
};
