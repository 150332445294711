import { zodResolver } from '@hookform/resolvers/zod';
import {
  IonCol,
  IonItemDivider,
  IonList,
  IonProgressBar,
  IonRow,
} from '@ionic/react';
import { Divider } from 'src/components/shared/Divider';
import { FileInputLegacy } from 'src/components/shared/FileInputLegacy';
import { FormError } from 'src/components/shared/FormError';
import { FormInput } from 'src/components/shared/FormInput';
import { SelectLocation } from 'src/components/shared/SelectLocation';
import { SelectState } from 'src/components/shared/SelectState';
import { useApi } from 'src/hooks/useApi';
import { Location } from 'src/interfaces/Location';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { ShipToFormData } from './ApplicationStep3';

import { v } from 'src/utils/validators';
import { z } from 'zod';
import { SelectClassOfTrade } from './SelectClassOfTrade';
import { Address } from 'src/interfaces/Address';
import { License } from 'src/interfaces/License';
import { FormLicenseInput } from 'src/components/buyer/FormLicenseInput';
import { LicenseFileDataSchema } from 'src/components/buyer/FormLicenseInput/FormLicenseInput';

const shipToSchema = z
  .object({
    showClassOfTrade: z.boolean(),
    classOfTrade: z.string(),
    address1: v.address1(),
    address2: v.address2(),
    city: v.city(),
    state: v.state(),
    zip: v.zip(),
    deaLicenseNum: v.deaLicenseNum(),
    deaLicenseExpiration: v.date('DEA Expiration', true),
    deaLicenseFilesize: z
      .number()
      .max(5000000, { message: 'File too large. Must be less than 5MB' }),
    deaLicenseFilename: z.string().min(1, { message: 'Missing DEA filename' }),
    deaLicenseFileBase64: v.deaLicenseFileBase64(),
    stateLicenseNum: v.stateLicenseNum(),
    stateLicenseExpiration: v.date('State Expiration', true),
    stateLicenseFilesize: z
      .number()
      .max(5000000, { message: 'File too large. Must be less than 5MB' }),
    stateLicenseFilename: z
      .string()
      .min(1, { message: 'Missing DEA filename' }),
    stateLicenseFileBase64: v.stateLicenseFileBase64(),
    gln: v.gln(),
    gcp: v.gcp(),
    hin: v.hin(),
  })
  // Class of Trade is required only if it applies to this location type.
  .refine(
    (schema) =>
      schema.showClassOfTrade === true &&
      (schema.classOfTrade === undefined || schema.classOfTrade === '')
        ? false
        : true,
    {
      message: 'Class of Trade is required',
      path: ['classOfTrade'],
    }
  );

export type ShipToSchema = z.infer<typeof shipToSchema>;

export const FormManageShipTo: React.FC<{
  shipTo?: ShipToFormData | null;
  formId: string;
  setIsValid: (isValid: boolean) => void;
  setIsSubmitted: (isSubmitted: boolean) => void;
  onSubmitted: (data: ShipToFormData) => void;
}> = ({ shipTo, formId, setIsValid, setIsSubmitted, onSubmitted }) => {
  const api = useApi();
  const selectedLocation: Location | null = useSelector(
    (state: any) => state.app.selectedLocation
  );
  const [locationId, setLocationId] = useState<number | null>(
    selectedLocation?.location_id || null
  );
  const [location, setLocation] = useState<Location | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  // addresses
  const [address, setAddress] = useState<Address | null>(null);
  const [addressLoading, setAddressLoading] = useState(false);

  // licenses
  const [deaLicense, setDeaLicense] = useState<License | null>(null);
  const [deaShipToLicense, setDeaShipToLicense] =
    useState<LicenseFileDataSchema | null>(null);
  const [deaLicenseLoading, setDeaLicenseLoading] = useState(false);
  const [stateLicense, setStateLicense] = useState<License | null>(null);
  const [stateShipToLicense, setStateShipToLicense] =
    useState<LicenseFileDataSchema | null>(null);
  const [stateLicenseLoading, setStateLicenseLoading] = useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    register,
    getValues,
    watch,
    formState: { errors, isSubmitted, isValid },
  } = useForm<ShipToSchema>({
    resolver: zodResolver(shipToSchema),
    defaultValues: {
      showClassOfTrade: shipTo?.showClassOfTrade || false,
      classOfTrade: shipTo?.classOfTrade || '',
      address1: shipTo?.address1 || '',
      address2: shipTo?.address2 || '',
      city: shipTo?.city || '',
      state: shipTo?.state || '',
      zip: shipTo?.zip || '',
      deaLicenseNum: shipTo?.deaLicenseNum || '',
      deaLicenseExpiration: shipTo?.deaLicenseExpiration || '',
      deaLicenseFileBase64: shipTo?.deaLicenseFileBase64 || '',
      deaLicenseFilename: shipTo?.deaLicenseFilename || '',
      deaLicenseFilesize: shipTo?.deaLicenseFilesize || 0,
      stateLicenseNum: shipTo?.stateLicenseNum || '',
      stateLicenseExpiration: shipTo?.stateLicenseExpiration || '',
      stateLicenseFileBase64: shipTo?.stateLicenseFileBase64 || '',
      stateLicenseFilename: shipTo?.stateLicenseFilename || '',
      stateLicenseFilesize: shipTo?.stateLicenseFilesize || 0,
      gcp: shipTo?.gcp || '',
      gln: shipTo?.gln || '',
      hin: shipTo?.hin || '',
    },
  });

  watch('showClassOfTrade');

  /**
   * When a "shipTo" object is passed in,
   * populate from shipTo data
   */
  useEffect(() => {
    if (shipTo) {
      populateFromShipTo();
    }
  }, [shipTo]);

  /**
   * When a "shipTo" object is NOT passed in,
   * populate from the selected location.
   */
  useEffect(() => {
    if (!shipTo) {
      populateFromSelectedLocation();
    }
  }, [location, address, deaLicense, stateLicense]);

  /**
   * When locationId changes, get the location data over api.
   */
  useEffect(() => {
    if (locationId) {
      getLocation(locationId);
    } else {
      setLocation(null);
    }
  }, [locationId]);

  /**
   * WHen the location changes
   * AND we don't have the shipTo data,
   * get the address and licenses.
   */
  useEffect(() => {
    if (location && !shipTo) {
      getAddress();
      getLicenses();
    }
  }, [location]);

  const getLocation = (locationId: number) => {
    setLoading(true);
    api
      .get(`/location/${locationId}`)
      .then((response) => {
        if (response.status === 200) {
          const data: Location = response.data;
          setLocation(data);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getAddress = () => {
    if (!location) return;
    setAddressLoading(true);
    api
      .get(`address/${location.address_id}`)
      .then((response) => {
        if (response.status === 200) {
          const address: Address = response.data;
          setAddress(address);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setAddressLoading(false);
      });
  };

  const getLicenses = () => {
    if (!location) return;
    setDeaLicenseLoading(true);
    setStateLicenseLoading(true);
    api
      .get(`location/${location.location_id}/licenses`)
      .then((response) => {
        const { data } = response;
        if (response.status === 200) {
          const licenses: License[] = data;
          licenses.forEach((license) => {
            if (license.type === 'dea') {
              setDeaLicense(license);
            } else if (license.type === 'state') {
              setStateLicense(license);
            } else {
              console.log('Unknown license type', license);
            }
          });
          setDeaLicenseLoading(false);
          setStateLicenseLoading(false);
        } else {
          setDeaLicenseLoading(false);
          setStateLicenseLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setDeaLicenseLoading(false);
        setStateLicenseLoading(false);
      });
  };

  const prepopulateLocation = () => {
    const showClassOfTrade =
      location?.location_type_name === 'Physician Office/Clinic' ||
      location?.location_type_name === 'Specialty Pharmacy';
    setValue('showClassOfTrade', showClassOfTrade);
    setValue('classOfTrade', '');
    setValue('gcp', location?.gcp || '');
    setValue('gln', location?.gln || '');
    setValue('hin', location?.hin || '');
  };
  const prepopulateAddress = () => {
    setValue('address1', address?.address1 || '');
    setValue('address2', address?.address2 || '');
    setValue('city', address?.city || '');
    setValue('state', address?.state || '');
    setValue('zip', address?.postal_code || '');
  };
  const prepopulateDeaLicense = () => {
    setValue('deaLicenseNum', deaLicense?.number || '');
    setValue('deaLicenseExpiration', deaLicense?.expiration || '');
    setValue('deaLicenseFilesize', deaLicense?.filesize || 0);
    setValue('deaLicenseFilename', deaLicense?.filename || '');
    setValue('deaLicenseFileBase64', deaLicense?.file || '');
  };
  const prepopulateStateLicense = () => {
    setValue('stateLicenseNum', stateLicense?.number || '');
    setValue('stateLicenseExpiration', stateLicense?.expiration || '');
    setValue('stateLicenseFilesize', stateLicense?.filesize || 0);
    setValue('stateLicenseFilename', stateLicense?.filename || '');
    setValue('stateLicenseFileBase64', stateLicense?.file || '');
  };

  const populateFromSelectedLocation = () => {
    if (!shipTo) {
      prepopulateLocation();
      prepopulateAddress();
      prepopulateDeaLicense();
      prepopulateStateLicense();
    }
  };

  const populateFromShipTo = () => {
    if (!shipTo) {
      return;
    }
    setDeaShipToLicense({
      name: shipTo.deaLicenseFilename || '',
      filename: shipTo.deaLicenseFilename || '',
      filesize: shipTo.deaLicenseFilesize || 0,
      file: shipTo.deaLicenseFileBase64, // base64 encoded string
    });
    setStateShipToLicense({
      name: shipTo.stateLicenseFilename || '',
      filename: shipTo.stateLicenseFilename || '',
      filesize: shipTo.stateLicenseFilesize || 0,
      file: shipTo.stateLicenseFileBase64, // base64 encoded string
    });

    setLocationId(shipTo.location_id);
    setValue('showClassOfTrade', shipTo.showClassOfTrade || false);
    setValue('classOfTrade', shipTo.classOfTrade || '');
    setValue('address1', shipTo.address1 || '');
    setValue('address2', shipTo.address2 || '');
    setValue('city', shipTo.city || '');
    setValue('state', shipTo.state || '');
    setValue('zip', shipTo.zip || '');
    setValue('deaLicenseNum', shipTo.deaLicenseNum || '');
    setValue('deaLicenseExpiration', shipTo.deaLicenseExpiration || '');
    setValue('deaLicenseFilesize', shipTo.deaLicenseFilesize || 0);
    setValue('deaLicenseFilename', shipTo.deaLicenseFilename || '');
    setValue('deaLicenseFileBase64', shipTo.deaLicenseFileBase64 || '');
    setValue('stateLicenseExpiration', shipTo.stateLicenseExpiration || '');
    setValue('stateLicenseFilesize', shipTo.stateLicenseFilesize || 0);
    setValue('stateLicenseFilename', shipTo.stateLicenseFilename || '');
    setValue('stateLicenseFileBase64', shipTo.stateLicenseFileBase64 || '');
    setValue('gcp', shipTo.gcp || '');
    setValue('gln', shipTo.gln || '');
    setValue('hin', shipTo.hin || '');
  };

  const onSubmit = (data: ShipToSchema) => {
    if (!locationId || !location?.name) {
      return;
    }
    const formData: ShipToFormData = {
      ...data,
      location_id: locationId,
      location_name: location.name,
    };
    onSubmitted(formData);
  };

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <IonList lines="none">
        <IonItemDivider>Select Location</IonItemDivider>
        <IonRow className="ion-padding-horizontal ion-padding-bottom">
          <IonCol size="12">
            <SelectLocation
              locationId={locationId}
              setLocationId={setLocationId}
              disabled={!!shipTo || loading}
            />
            {loading && <IonProgressBar type="indeterminate" color="primary" />}
          </IonCol>
          {getValues('showClassOfTrade') ? (
            <IonCol size="12">
              {/* NOTE: This "SelectClassOfTrade" component is the only one specific to Sandoz in this form. */}
              <Controller
                name="classOfTrade"
                control={control}
                render={({ field }) => {
                  return (
                    <SelectClassOfTrade
                      errorMessage={errors.classOfTrade?.message}
                      value={field.value}
                      onIonChange={(e) => {
                        setValue('classOfTrade', e.detail.value as string);
                      }}
                      disabled={loading}
                    />
                  );
                }}
              />
            </IonCol>
          ) : null}
        </IonRow>
        <IonItemDivider>Address</IonItemDivider>
        <IonRow className="ion-padding-horizontal">
          <IonCol size="12">
            <FormInput
              {...register('address1')}
              label="Ship To Street Address"
              disabled={loading}
              onIonChange={(e) => {
                setValue('address1', e.detail.value as string);
              }}
              placeholder="1234 Example Blvd."
              errorMessage={errors.address1?.message}
            />
          </IonCol>
          <IonCol size="12">
            <FormInput
              {...register('address2')}
              label="Suite, Unit, etc. (optional)"
              disabled={loading}
              onIonChange={(e) => {
                setValue('address2', e.detail.value as string);
              }}
              errorMessage={errors.address2?.message}
            />
          </IonCol>
          <IonCol size="12">
            <FormInput
              {...register('city')}
              label="City"
              disabled={loading}
              onIonChange={(e) => {
                setValue('city', e.detail.value as string);
              }}
              errorMessage={errors.city?.message}
            />
          </IonCol>
        </IonRow>
        <IonRow className="ion-padding-horizontal ion-padding-bottom">
          <IonCol size="12" sizeMd="6">
            <Controller
              name="state"
              control={control}
              render={({ field }) => {
                return (
                  <SelectState
                    errorMessage={errors.state?.message}
                    rounded={true}
                    disabled={loading}
                    value={field.value || null}
                    onIonChange={(e) => {
                      setValue('state', e.detail.value as string);
                    }}
                  />
                );
              }}
            />
          </IonCol>
          <IonCol size="12" sizeMd="6">
            <FormInput
              {...register('zip')}
              label="Zip"
              disabled={loading}
              onIonChange={(e) => {
                setValue('zip', e.detail.value as string);
              }}
              errorMessage={errors.zip?.message}
            />
          </IonCol>
        </IonRow>
        <IonItemDivider>Licensing</IonItemDivider>
        <IonRow className="ion-padding-horizontal ion-padding-bottom">
          <IonCol size="12">
            <IonRow>
              <IonCol>
                <Divider text="DEA" className="ion-no-margin" />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="7">
                <FormInput
                  {...register('deaLicenseNum')}
                  label="DEA License #"
                  disabled={loading}
                  onIonChange={(e) => {
                    setValue('deaLicenseNum', e.detail.value as string);
                  }}
                  errorMessage={errors.deaLicenseNum?.message}
                />
              </IonCol>
              <IonCol size="5">
                <FormInput
                  {...register('deaLicenseExpiration')}
                  label="DEA Expiration"
                  disabled={loading}
                  onIonChange={(e) => {
                    setValue('deaLicenseExpiration', e.detail.value);
                  }}
                  placeholder="yyyy-mm-dd"
                  errorMessage={errors.deaLicenseExpiration?.message}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12">
                <Controller
                  name="deaLicenseFileBase64"
                  control={control}
                  render={({ field }) => {
                    return (
                      <>
                        <FormLicenseInput
                          license={deaLicense || deaShipToLicense}
                          label="DEA License"
                          placeholder="Supported types: pdf, png, jpeg. File size: 5MB or smaller."
                          accept="application/pdf, image/png, image/jpeg"
                          value={field.value}
                          setValue={(filesize, filename, base64Content) => {
                            setValue('deaLicenseFilesize', filesize || 0);
                            setValue('deaLicenseFilename', filename || '');
                            setValue(
                              'deaLicenseFileBase64',
                              base64Content || ''
                            );
                          }}
                          showThumbnail={true}
                          errorMessage={errors.deaLicenseFileBase64?.message}
                          disabled={loading || deaLicenseLoading}
                        />
                        {errors?.deaLicenseFileBase64 && (
                          <FormError>
                            {errors?.deaLicenseFileBase64.message}
                          </FormError>
                        )}
                      </>
                    );
                  }}
                />
              </IonCol>
            </IonRow>
            <IonRow className="ion-margin-top">
              <IonCol>
                <Divider text="State" className="ion-no-margin" />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="7">
                <FormInput
                  {...register('stateLicenseNum')}
                  label="State License #"
                  disabled={loading}
                  onIonChange={(e) => {
                    setValue('stateLicenseNum', e.detail.value as string);
                  }}
                  errorMessage={errors.stateLicenseNum?.message}
                />
              </IonCol>
              <IonCol size="5">
                <FormInput
                  {...register('stateLicenseExpiration')}
                  label="State Expiration"
                  disabled={loading}
                  onIonChange={(e) => {
                    setValue('stateLicenseExpiration', e.detail.value);
                  }}
                  placeholder="yyyy-mm-dd"
                  errorMessage={errors.stateLicenseExpiration?.message}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12">
                <Controller
                  name="stateLicenseFileBase64"
                  control={control}
                  render={({ field }) => {
                    return (
                      <>
                        <FormLicenseInput
                          license={stateLicense || stateShipToLicense}
                          label="State License"
                          placeholder="Supported types: pdf, png, jpeg. File size: 5MB or smaller."
                          accept="application/pdf, image/png, image/jpeg"
                          value={field.value}
                          setValue={(filesize, filename, base64Content) => {
                            setValue('stateLicenseFilesize', filesize || 0);
                            setValue('stateLicenseFilename', filename || '');
                            setValue(
                              'stateLicenseFileBase64',
                              base64Content || ''
                            );
                          }}
                          showThumbnail={true}
                          errorMessage={errors.stateLicenseFileBase64?.message}
                          disabled={loading || stateLicenseLoading}
                        />
                        {errors?.stateLicenseFileBase64 && (
                          <FormError>
                            {errors?.stateLicenseFileBase64.message}
                          </FormError>
                        )}
                      </>
                    );
                  }}
                />
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
        <IonItemDivider>Additional Info</IonItemDivider>
        <IonRow className="ion-padding-horizontal ion-padding-bottom">
          <IonCol size="12">
            <FormInput
              {...register('gln')}
              label="GLN"
              disabled={loading}
              onIonChange={(e) => {
                setValue('gln', e.detail.value as string);
              }}
              helperText="Global Locator Number"
              errorMessage={errors.gln?.message}
            />
            <FormInput
              {...register('gcp')}
              label="GCP (optional)"
              disabled={loading}
              onIonChange={(e) => {
                setValue('gcp', e.detail.value as string);
              }}
              helperText="Facility Global Company Prefix"
              errorMessage={errors.gcp?.message}
            />
            <FormInput
              {...register('hin')}
              label="HIN (optional)"
              disabled={loading}
              onIonChange={(e) => {
                setValue('hin', e.detail.value as string);
              }}
              helperText="Hospital Identifier Number"
              errorMessage={errors.hin?.message}
            />
          </IonCol>
        </IonRow>
      </IonList>
    </form>
  );
};
