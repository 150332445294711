import { IonButton, IonCard, IonCol, IonIcon, IonRow } from '@ionic/react';
import { addOutline } from 'ionicons/icons';
import { useState } from 'react';
import { Breadcrumb, Breadcrumbs } from 'src/components/shared/Breadcrumbs';
import { Page } from 'src/layouts/Page';
import { ModalAddSupplierTerm } from './ModalAddSupplierTerm';
import { TableSupplierTerms } from './TableSupplierTerms';

export const TermsPage: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Settings', path: '/supplier/settings', direction: 'back' },
    { label: 'Terms Documents' },
  ];
  return (
    <Page title="Supplier Terms">
      <Breadcrumbs breadcrumbs={breadcrumbs}>
        <IonButton size="small" onClick={() => setIsOpen(true)}>
          Add Terms Document
          <IonIcon slot="end" icon={addOutline} />
        </IonButton>
      </Breadcrumbs>
      <IonRow>
        <IonCol>
          <IonCard>
            <TableSupplierTerms refresh={refresh} />
          </IonCard>
        </IonCol>
      </IonRow>
      <ModalAddSupplierTerm
        isOpen={isOpen}
        onDismiss={(created?: any) => {
          setIsOpen(false);
          if (created && created.supplier_term_id) {
            setRefresh(!refresh);
          }
        }}
      />
    </Page>
  );
};
