import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import './ModalTerms.css';
import { Divider } from 'src/components/shared/Divider';

export const ModalTerms: React.FC<{
  isOpen: boolean;
  onDismiss: () => void;
}> = ({ isOpen, onDismiss }) => {
  return (
    <IonModal
      isOpen={isOpen}
      onIonModalWillDismiss={onDismiss}
      className="modalTerms"
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>Trulla Direct Terms of Use</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onDismiss} fill="clear">
              <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent class="ion-padding">
        <p>Last Updated: May 22, 2023</p>
        <Divider />
        <p>
          Thank you for choosing to use Trulla Direct by SpendMend LLC. By
          signing up and placing an order on our platform, you are agreeing to
          the following terms and conditions:
        </p>
        <ol>
          <li>
            Trulla Direct is a platform that enables buyers to place orders
            directly to manufacturers. We do not manufacture or distribute any
            pharmaceutical products ourselves, and we are not responsible for
            the quality or safety of any products purchased through our website.
          </li>
          <li>
            All orders placed through Trulla Direct are subject to acceptance by
            the manufacturer. We reserve the right to reject any orders that we
            believe are not genuine or that may be in breach of any laws or
            regulations.
          </li>
          <li>
            We make no guarantees or warranties with respect to the availability
            of products, the accuracy of product descriptions, or the timeliness
            of deliveries. We do not accept any liability for any damages or
            losses that may result from the use of our website, including but
            not limited to any direct, indirect, incidental, special, or
            consequential damages.
          </li>
          <li>
            All orders are subject to the manufacturer's terms and conditions of
            sale, which are available upon request. By placing an order, you
            agree to be bound by the manufacturer's terms and conditions of
            sale.
          </li>
          <li>
            The prices of products listed on our website are subject to change
            without notice. We are not responsible for any errors or omissions
            in the pricing information displayed on our website. The prices
            displayed on our website do not include taxes or shipping fees,
            which will be added to your invoice from the manufacturer, if
            applicable.
          </li>
          <li>
            We reserve the right to suspend or terminate your access to our
            website at any time, without notice, if we believe that you have
            breached any of these terms and conditions or if we believe that
            your use of our website may harm our business or reputation.
          </li>
          <li>
            You agree to indemnify and hold harmless SpendMend, its affiliates,
            directors, officers, employees, agents, and licensors, from and
            against any and all claims, damages, liabilities, costs, and
            expenses, including reasonable attorneys' fees, arising out of or in
            connection with your use of our website or your breach of these
            terms and conditions.
          </li>
          <li>
            These terms and conditions shall be governed by and construed in
            accordance with the laws of the jurisdiction in the state of
            Michigan. Any disputes arising out of or in connection with these
            terms and conditions shall be resolved by arbitration in accordance
            with the rules of the applicable arbitration association.
          </li>
          <li>
            These terms and conditions constitute the entire agreement between
            you and SpendMend with respect to your use of the Trulla Direct
            platform. If any provision of these terms and conditions is held to
            be invalid or unenforceable, such provision shall be struck out and
            the remaining provisions shall be enforced.
          </li>
        </ol>
      </IonContent>
      <IonFooter>
        <IonToolbar color="transparent">
          <IonButtons slot="end">
            <IonButton onClick={onDismiss} color="primary">
              Ok
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};
