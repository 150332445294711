import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from '@ionic/react';
import { LoadingInline } from 'src/components/shared/LoadingInline';
import { useApi } from 'src/hooks/useApi';
import { Contact } from 'src/interfaces/Contact';
import { ContactAttribute } from 'src/interfaces/ContactAttribute';
import { Organization } from 'src/interfaces/Organization';
import { closeOutline, trashOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setToast } from 'src/redux/appReducer';

export const ModalSupplierContact: React.FC<{
  isOpen: boolean;
  onDismiss: (data?: any) => void;
  supplierContact?: Contact | null;
}> = ({ isOpen, onDismiss, supplierContact }) => {
  const api = useApi();
  const dispatch = useDispatch();
  const [presentAlert] = useIonAlert();

  const organization: Organization | null = useSelector(
    (state: any) => state.app.organization
  );
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState<string | null>('New Accounts');
  const [email, setEmail] = useState<string | null>(
    'account-support@sandoz.com'
  );
  const [phone, setPhone] = useState<string | null>('8015554321');
  const [phoneSecondary, setPhoneSecondary] = useState<string | null>(
    '8015551234'
  );
  const [supportHours, setSupportHours] = useState<string | null>(
    '9AM - 5PM Eastern'
  );
  const [supportDays, setSupportDays] = useState<string | null>(
    'Monday - Friday'
  );

  const valid =
    !!name &&
    !!email &&
    !!phone &&
    !!phoneSecondary &&
    !!supportHours &&
    !!supportDays;

  useEffect(() => {
    if (supplierContact) {
      setName(supplierContact.name);
      setEmail(supplierContact.email_address);
      setPhone(supplierContact.phone1);
      setPhoneSecondary(supplierContact.phone2);
      supplierContact.attributes.forEach((attribute: ContactAttribute) => {
        if (attribute.name === 'Support Hours') {
          setSupportHours(attribute.value);
        } else if (attribute.name === 'Support Days') {
          setSupportDays(attribute.value);
        }
      });
    }
  }, [supplierContact]);

  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen]);

  const resetForm = () => {
    setLoading(false);
    setName(null);
    setEmail(null);
    setPhone(null);
    setPhoneSecondary(null);
    setSupportHours(null);
    setSupportDays(null);
  };

  const onSubmit = (e?: any) => {
    if (e) {
      e.preventDefault();
    }
    if (!supplierContact) {
      createContact();
    } else {
      updateContact();
    }
  };

  const createContact = () => {
    setLoading(true);
    const formData = [
      {
        organization_id: organization?.organization_id,
        name: name,
        email_address: email,
        phone1: phone,
        phone2: phoneSecondary,
        support_hours: supportHours,
        support_days: supportDays,
      },
    ];
    api
      .post(`contact`, formData)
      .then((response) => {
        if (response.status === 201) {
          dispatch(
            setToast({
              message: 'Successfully Created',
            })
          );
          onDismiss(response.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const updateContact = () => {
    if (!supplierContact) {
      return;
    }
    setLoading(true);
    const formData = {
      organization_id: organization?.organization_id,
      name: name,
      email_address: email,
      phone1: phone,
      phone2: phoneSecondary,
      support_hours: supportHours,
      support_days: supportDays,
    };
    api
      .put(`contact/${supplierContact.contact_id}`, formData)
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            setToast({
              message: 'Successfully Updated',
            })
          );
          onDismiss();
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const onClickDelete = () => {
    if (!supplierContact) {
      return;
    }
    presentAlert({
      header: 'Delete Contact',
      subHeader: `"${supplierContact.name}"`,
      message: 'Are you sure you want to delete this contact?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Delete',
          role: 'confirm',
          cssClass: 'text-color-danger',
        },
      ],
      onWillDismiss: (e: any) => {
        if (e.detail.role === 'confirm') {
          deleteContact();
        }
      },
    });
  };

  const deleteContact = () => {
    if (!supplierContact) {
      return;
    }
    setLoading(true);
    api
      .delete(`contact/${supplierContact.contact_id}`)
      .then((response) => {
        console.log(response);
        if (response.status === 204) {
          dispatch(
            setToast({
              message: 'Successfully Deleted',
            })
          );
        }
        setLoading(false);
        onDismiss();
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  return (
    <IonModal isOpen={isOpen} onIonModalWillDismiss={onDismiss}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            {supplierContact ? 'Edit Contact' : 'Add Contact'}
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onDismiss} fill="clear">
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <form action="" onSubmit={onSubmit}>
          <IonList>
            <IonItem>
              <IonLabel position="floating">Name</IonLabel>
              <IonInput
                type="text"
                name="contactName"
                placeholder="ex: Customer Support"
                value={name}
                disabled={loading}
                onIonChange={(e: any) => setName(e.detail.value as string)}
              />
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Email Address</IonLabel>
              <IonInput
                type="email"
                name="email"
                placeholder="ex: support@example.com"
                value={email}
                disabled={loading}
                onIonChange={(e: any) => setEmail(e.detail.value as string)}
              />
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Phone Number</IonLabel>
              <IonInput
                type="tel"
                name="phone"
                placeholder="ex: 8005551111"
                value={phone}
                disabled={loading}
                onIonChange={(e: any) => setPhone(e.detail.value as string)}
              />
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Secondary Phone</IonLabel>
              <IonInput
                type="tel"
                name="phoneSecondary"
                placeholder="ex: 8005551111"
                value={phoneSecondary}
                disabled={loading}
                onIonChange={(e: any) =>
                  setPhoneSecondary(e.detail.value as string)
                }
              />
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Support Hours</IonLabel>
              <IonInput
                type="text"
                name="hours"
                placeholder="ex: 9AM - 5PM Eastern"
                value={supportHours}
                disabled={loading}
                onIonChange={(e: any) =>
                  setSupportHours(e.detail.value as string)
                }
              />
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Support Days</IonLabel>
              <IonInput
                type="text"
                name="days"
                placeholder="ex: Monday - Friday"
                value={supportDays}
                disabled={loading}
                onIonChange={(e: any) =>
                  setSupportDays(e.detail.value as string)
                }
              />
            </IonItem>
          </IonList>
        </form>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          {supplierContact ? (
            <IonButtons slot="start">
              <IonButton
                color="danger"
                size="small"
                data-tooltip-id="global-tooltip"
                data-tooltip-content="Delete Contact"
                data-tooltip-place="bottom"
                onClick={onClickDelete}
              >
                <IonIcon slot="icon-only" icon={trashOutline} />
              </IonButton>
            </IonButtons>
          ) : null}
          <IonButtons slot="end">
            <IonButton color="medium" onClick={onDismiss}>
              Cancel
            </IonButton>
            <IonButton disabled={loading || !valid} onClick={onSubmit}>
              {supplierContact ? 'Save' : 'Create'}
              {loading && (
                <span className="margin-left-sm">
                  <LoadingInline />
                </span>
              )}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};
