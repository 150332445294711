import { IonButton, IonCol, IonIcon, IonRow, IonSearchbar } from '@ionic/react';
import { ChipAccount } from 'src/components/buyer/ChipAccount';
import { ModalManageAccounts } from 'src/components/buyer/ModalManageAccounts';
import { DataTable } from 'src/components/shared/DataTable';
import { DataTableHeader } from 'src/components/shared/DataTable/interfaces/DataTableHeader';
import { DataTablePagination } from 'src/components/shared/DataTable/interfaces/DataTablePagination';
import { DataTableRow } from 'src/components/shared/DataTable/interfaces/DataTableRow';
import { LogoSupplier } from 'src/components/shared/LogoSupplier';
import { Subtitle } from 'src/components/shared/Subtitle';
import { useApi } from 'src/hooks/useApi';
import { Account } from 'src/interfaces/Account';
import { Location } from 'src/interfaces/Location';
import { Organization } from 'src/interfaces/Organization';
import { Supplier } from 'src/interfaces/Supplier';
import { pencilOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LocationCol } from './LocationCol';

export const TableSupplierLocations: React.FC<{
  supplier: Supplier;
  hideLogo?: boolean;
}> = ({ supplier, hideLogo }) => {
  const api = useApi();
  const organization: Organization | null = useSelector(
    (state: any) => state.app.organization
  );

  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState<Location[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<Location>();
  const [accountsModalOpen, setAccountsModalOpen] = useState(false);
  const [pagination, setPagination] = useState<DataTablePagination | null>(
    null
  );
  const [totalCount, setTotalCount] = useState<number>(0);
  const [orderBy, setOrderBy] = useState<string | null>('created');
  const [orderByDesc, setOrderByDesc] = useState<boolean>(false);
  const [search, setSearch] = useState<string | null>(null);

  useEffect(() => {
    getLocations();
  }, []);

  useEffect(() => {
    if (selectedLocation) {
      setAccountsModalOpen(true);
    } else {
      setAccountsModalOpen(false);
    }
  }, [selectedLocation]);

  useEffect(() => {
    getLocations();
  }, [search, pagination, selectedLocation]);

  const headers: DataTableHeader[] = [
    { text: 'Location', key: 'location', width: '200px' },
    { text: 'Accounts', key: 'accounts' },
  ];

  const getLocations = () => {
    if (!organization || !pagination) {
      return;
    }
    setLoading(true);
    api
      .get(`organization/${organization.organization_id}/locations`, {
        limit: pagination.limit,
        offset: pagination.offset,
        order_by: orderBy ? orderBy : 'name',
        order_by_desc: orderByDesc,
        search: search ? search : undefined,
      })
      .then((response) => {
        if (response.status === 200) {
          setLocations(response.data.result || []);
          setTotalCount(response.data.total);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const LocationAccountChips: React.FC<{ location: Location }> = ({
    location,
  }) => {
    const [accounts, setAccounts] = useState<Account[]>([]);

    useEffect(() => {
      api
        .get(
          `location/${location.location_id}/accounts?supplier_id=${supplier.supplier_id}`
        )
        .then((response) => {
          if (response.status === 200) {
            const data: Account[] = response.data;
            setAccounts(data);
          }
        });
    }, []);
    return (
      <>
        <div className="d-flex ion-justify-content-between ion-align-items-center">
          <div className="d-flex flex-wrap">
            {accounts.map((account) => (
              <ChipAccount account={account} key={account.account_id} />
            ))}
          </div>
          <IonButton
            size="small"
            fill="clear"
            onClick={() => setSelectedLocation(location)}
          >
            <IonIcon icon={pencilOutline} slot="icon-only" />
          </IonButton>
        </div>
      </>
    );
  };

  return (
    <>
      <DataTable
        title="Location Accounts"
        loading={loading}
        headers={headers}
        search={false}
        onPaginationChange={(pagination: DataTablePagination) =>
          setPagination(pagination)
        }
        orderBy={orderBy}
        orderByDesc={orderByDesc}
        setOrderBy={setOrderBy}
        setOrderByDesc={setOrderByDesc}
        totalCount={totalCount}
        itemsPerPageOptions={[5, 10, 15]}
        rows={locations.map((location: Location): DataTableRow => {
          const row = {
            item: location,
            key: location.location_id,
            columns: [
              {
                header: 'location',
                content: <LocationCol location={location} />,
              },
              {
                header: 'accounts',
                content: <LocationAccountChips location={location} />,
              },
            ],
          };
          return row;
        })}
      >
        {!hideLogo && <LogoSupplier src={supplier.logo} />}
        <IonRow>
          <IonCol size="12" sizeSm="8" offsetSm="4">
            <IonSearchbar
              value={search}
              onIonChange={(e: any) => setSearch(e.detail.value as string)}
              debounce={350}
              className="ion-text-left"
            />
          </IonCol>
        </IonRow>
      </DataTable>
      <ModalManageAccounts
        isOpen={accountsModalOpen}
        onDismiss={() => {
          setSelectedLocation(undefined);
          setAccountsModalOpen(false);
        }}
        supplier={supplier}
        location={selectedLocation}
      />
    </>
  );
};
