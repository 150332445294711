import { IonNote } from '@ionic/react';
import styles from './formStyles.module.css';

export const FormError: React.FC<{
  children: any;
  className?: string;
  style?: any;
}> = ({ children, className, style }) => {
  return (
    <IonNote
      className={`${styles.formError} ${className ? className : ''}`}
      color="danger"
      style={style ? style : undefined}
    >
      {children}
    </IonNote>
  );
};
