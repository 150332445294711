import { zodResolver } from '@hookform/resolvers/zod';
import { IonButton, IonCol, IonIcon, IonList, IonRow } from '@ionic/react';
import { FormInput } from 'src/components/shared/FormInput';
import { chevronBackOutline, chevronForwardOutline } from 'ionicons/icons';
import { SubmitHandler, useForm } from 'react-hook-form';
import { v } from 'src/utils/validators';
import { z } from 'zod';

const step1Schema = z.object({
  firstName: v.firstName(),
  lastName: v.lastName(),
  email: v.email(),
  phone: v.phone('Phone', false),
});

export type Step1Schema = z.infer<typeof step1Schema>;

export const Step1Form: React.FC<{
  loading: boolean;
  onSubmit: SubmitHandler<Step1Schema>;
  firstName: Step1Schema['firstName'];
  lastName: Step1Schema['lastName'];
  email: Step1Schema['email'];
  phone: Step1Schema['phone'];
  setFirstName: (value: Step1Schema['firstName']) => void;
  setLastName: (value: Step1Schema['lastName']) => void;
  setEmail: (value: Step1Schema['email']) => void;
  setPhone: (value: Step1Schema['phone']) => void;
}> = ({
  loading,
  onSubmit,
  firstName,
  lastName,
  email,
  phone,
  setFirstName,
  setLastName,
  setEmail,
  setPhone,
}) => {
  const {
    handleSubmit,
    setValue,
    register,
    getValues,
    watch,
    formState: { errors, isDirty, isValid },
  } = useForm<Step1Schema>({
    resolver: zodResolver(step1Schema),
    defaultValues: {
      firstName: firstName || '',
      lastName: lastName || '',
      email: email || '',
      phone: phone || '',
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <IonList lines="none">
        <IonRow>
          <IonCol size="12">
            {/* <IonInput {...register('firstName')}></IonInput> */}
            <FormInput
              label="First Name"
              onIonChange={(e) => {
                setValue('firstName', e.detail.value as string);
                setFirstName(e.detail.value as string);
              }}
              {...register('firstName')}
              errorMessage={errors.firstName?.message}
            />
          </IonCol>
          <IonCol size="12">
            <FormInput
              label="Last Name"
              onIonChange={(e) => {
                setValue('lastName', e.detail.value as string);
                setLastName(e.detail.value as string);
              }}
              {...register('lastName')}
              errorMessage={errors.lastName?.message}
            />
          </IonCol>
          <IonCol size="12">
            <FormInput
              label="Email"
              type="email"
              onIonChange={(e) => {
                setValue('email', e.detail.value as string);
                setEmail(e.detail.value as string);
              }}
              {...register('email')}
              errorMessage={errors.email?.message}
            />
          </IonCol>
          <IonCol size="12">
            <FormInput
              label="Phone (optional)"
              type="tel"
              onIonChange={(e) => {
                setValue('phone', e.detail.value as string);
                setPhone(e.detail.value as string);
              }}
              {...register('phone')}
              errorMessage={errors.phone?.message}
            />
          </IonCol>
        </IonRow>
      </IonList>
      <IonRow className="ion-justify-content-between ion-margin-top">
        <IonButton
          fill="clear"
          routerLink="/"
          routerDirection="back"
          disabled={loading}
        >
          <IonIcon slot="start" icon={chevronBackOutline}></IonIcon>
          Back
        </IonButton>
        <IonButton type="submit">
          Next
          <IonIcon slot="end" icon={chevronForwardOutline} />
        </IonButton>
      </IonRow>
    </form>
  );
};
