import {
  IonBadge,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonChip,
  IonCol,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonRow,
  IonText,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Breadcrumb, Breadcrumbs } from 'src/components/shared/Breadcrumbs';
import { Divider } from 'src/components/shared/Divider';
import { useApi } from 'src/hooks/useApi';
import { useUtils } from 'src/hooks/useUtils';
import { Organization } from 'src/interfaces/Organization';
import { Page } from 'src/layouts/Page';
import { TableUsersSuper } from '../UsersPage/TableUsersSuper';
import { CardActions } from 'src/components/shared/CardActions';
import { chevronForwardOutline } from 'ionicons/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHospital,
  faRotate,
  faUsers,
} from '@fortawesome/pro-light-svg-icons';
import { Subtitle } from 'src/components/shared/Subtitle';
import { useAlert } from 'src/hooks/useAlert';
import { useEndpoints } from 'src/hooks/useEndpoints';
import { AppUserSelf } from 'src/interfaces/AppUserSelf';
import { useSelector } from 'react-redux';

export const OrganizationPage: React.FC = () => {
  const { isBuyerOrganization } = useUtils();
  const { switchOrganizations } = useEndpoints();
  const api = useApi();
  const { showAlert } = useAlert();
  const { organization_id } = useParams<{ organization_id: string }>();

  const authUser: AppUserSelf | null = useSelector(
    (state: any) => state.auth.user
  );

  const [loading, setLoading] = useState(false);
  const [organization, setOrganization] = useState<Organization | null>(null);

  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Trulla Admin' },
    { label: 'Organizations', path: '/admin/organizations', direction: 'back' },
    { label: organization ? organization.name : organization_id },
  ];

  useEffect(() => {
    getOrganization();
  }, [organization_id]);

  const getOrganization = () => {
    setLoading(true);
    api
      .get(`organization/${organization_id}`)
      .then((response) => {
        if (response.status === 200) {
          setOrganization(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onClickSwitchOrgs = () => {
    if (!organization) {
      return;
    }
    const options = {
      header: organization.name,
      message: 'Would you like to switch your user to this organization?',
    };
    showAlert(options, (confirm: boolean) => {
      if (confirm) {
        switchOrganizations(organization);
      } else {
        console.log('Cancelled');
      }
    });
  };

  return (
    <Page title="OrganizationPage">
      <Breadcrumbs
        breadcrumbs={breadcrumbs}
        title="Organization Details"
      ></Breadcrumbs>
      <IonRow>
        <IonCol size="12" sizeMd="6" offsetMd="3">
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>{organization?.name}</IonCardTitle>
              <IonCardSubtitle className="">
                <Subtitle className="d-flex ion-align-items-center ion-justify-content-start">
                  Type:
                  <IonBadge
                    className="margin-left-xs"
                    style={{ cursor: 'default', textTransform: 'capitalize' }}
                    data-tooltip-id="global-tooltip"
                    data-tooltip-place="bottom"
                    data-tooltip-content="Organization Type"
                  >
                    {organization?.organization_type_name}
                  </IonBadge>
                </Subtitle>
                <Subtitle>
                  {organization?.organization_id ===
                    authUser?.organization_id && (
                    <IonText color="tertiary" className="d-block">
                      Currently Selected
                    </IonText>
                  )}
                </Subtitle>
              </IonCardSubtitle>
            </IonCardHeader>
            <Divider className="ion-no-margin" />
            <IonItemDivider>
              <IonLabel>Actions</IonLabel>
            </IonItemDivider>
            <IonList>
              <IonItem button onClick={onClickSwitchOrgs}>
                <span slot="start" className="text-color-primary">
                  <FontAwesomeIcon icon={faRotate} />
                </span>
                Switch to this Organization
              </IonItem>
              <IonItem
                button
                routerLink={`/admin/organizations/${organization_id}/users`}
              >
                <span slot="start" className="text-color-primary">
                  <FontAwesomeIcon icon={faUsers} />
                </span>
                Manage Users
              </IonItem>
              {isBuyerOrganization(organization) && (
                <IonItem
                  button
                  routerLink={`/admin/organizations/${organization_id}/locations`}
                >
                  <span slot="start" className="text-color-primary">
                    <FontAwesomeIcon icon={faHospital} />
                  </span>
                  Manage Locations
                </IonItem>
              )}
            </IonList>
          </IonCard>
        </IonCol>
      </IonRow>
    </Page>
  );
};
