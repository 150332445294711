import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonChip,
  IonCol,
  IonIcon,
  IonLabel,
  IonList,
  IonRow,
  IonSearchbar,
  IonText,
  useIonAlert,
  useIonModal,
} from '@ionic/react';
import { Breadcrumb, Breadcrumbs } from 'src/components/shared/Breadcrumbs';
import { Page } from 'src/layouts/Page';
import { addOutline, closeCircleOutline, saveOutline } from 'ionicons/icons';
import { Fragment, useEffect, useState } from 'react';

import { ModalAssignUsers } from 'src/components/buyer/ModalAssignUsers';
import { CardActions } from 'src/components/shared/CardActions';
import { LoadingInline } from 'src/components/shared/LoadingInline';
import { Subtitle } from 'src/components/shared/Subtitle';
import { useApi } from 'src/hooks/useApi';
import { useUtils } from 'src/hooks/useUtils';
import { AppUser } from 'src/interfaces/AppUser';
import { AppUserMapLocation } from 'src/interfaces/AppUserMapLocation';
import { AssignedAppUser } from 'src/interfaces/AssignedAppUser';
import { Location } from 'src/interfaces/Location';
import { Organization } from 'src/interfaces/Organization';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Tooltip } from 'react-tooltip';
import { setToast } from 'src/redux/appReducer';

const flattenAssignedUsers = (assignedUsers: AssignedAppUser[]) => {
  const flattened: number[] = [];
  assignedUsers.forEach((assignedLocation) => {
    flattened.push(assignedLocation.app_user_id);
  });
  return flattened;
};

export const LocationUsersPage: React.FC = () => {
  const organization: Organization | null = useSelector(
    (state: any) => state.app.organization
  );
  const { id } = useParams<{ id: string }>();
  const api = useApi();
  const dispatch = useDispatch();
  const utils = useUtils();

  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState<Location>();
  const [locationUsers, setLocationUsers] = useState<AppUserMapLocation[]>([]);
  const [locationUsersLoading, setLocationUsersLoading] =
    useState<boolean>(false);

  const [allUsers, setAllUsers] = useState<AppUser[]>([]);
  const [allUsersLoading, setAllUsersLoading] = useState<boolean>(false);
  const [assignedUsers, setAssignedUsers] = useState<AssignedAppUser[]>([]);
  const [assignedUsersFiltered, setAssignedUsersFiltered] = useState<
    AssignedAppUser[]
  >([]);
  const [search, setSearch] = useState<string>('');

  const changed = flattenAssignedUsers(assignedUsers).toString();

  const [presentAlert] = useIonAlert();
  const [presentUsers, dismissUsers] = useIonModal(ModalAssignUsers, {
    // props
    location: location,
    users: allUsers.filter((user) => {
      const exists = !!assignedUsers.find(
        (assignedUser) => assignedUser.app_user_id === user.app_user_id
      );
      return !exists;
    }),
    onDismiss: (data?: AssignedAppUser[], role?: string) => {
      dismissUsers(data, role);
      if (data) {
        setAssignedUsers([...assignedUsers, ...data]);
      }
    },
  });

  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Settings', path: '/settings', direction: 'back' },
    { label: 'Locations', path: '/settings/locations', direction: 'back' },
    {
      label: location && location.name ? location.name : id,
      path: `/settings/locations/${id}`,
      direction: 'back',
    },
    { label: 'Manage Users' },
  ];

  useEffect(() => {
    getLocation();
  }, [id]);

  /**
   * When the locationUsers change, update the assigned locations
   */
  useEffect(() => {
    if (!locationUsers) {
      setAssignedUsers([]);
    } else {
      resetAssignedUsers();
    }
  }, [locationUsers]);

  /**
   * When location changes, get locationUsers
   */
  useEffect(() => {
    if (location) {
      getLocationUsers();
    }
  }, [location]);

  /**
   * get all users for the current organization
   */
  useEffect(() => {
    getAllUsers();
  }, [organization]);

  //
  useEffect(() => {
    filterAssignedUsers();
  }, [search, allUsers, assignedUsers]);

  const showUsers = () => {
    presentUsers({
      onWillDismiss: (e: any) => {},
    });
  };

  const getLocation = () => {
    setLoading(true);
    api
      .get(`location/${id}`)
      .then(({ data }) => {
        setLocation(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const resetAssignedUsers = () => {
    if (!locationUsers) {
      return;
    }
    let assigned: AssignedAppUser[] = [];
    locationUsers.forEach((locationUser: AppUserMapLocation) => {
      assigned.push({
        app_user_id: locationUser.app_user_id,
        first_name: locationUser.app_user_first_name,
        last_name: locationUser.app_user_last_name || '',
        email_address: locationUser.app_user_email,
      });
    });
    setAssignedUsers(assigned);
  };

  const getAllUsers = () => {
    if (!organization) {
      return;
    }
    setAllUsersLoading(true);
    api
      .get(`organization/${organization.organization_id}/app_users`)
      .then((response) => {
        if (response.status === 200) {
          setAllUsers(response.data.result || []);
        }
        setAllUsersLoading(false);
      })
      .catch((error) => {
        setAllUsersLoading(false);
      });
  };

  const getLocationUsers = () => {
    if (!location) {
      return;
    }
    setLocationUsersLoading(true);
    api
      .get(`location/${location.location_id}/app_user_map_locations`)
      .then((response) => {
        if (response.status === 200) {
          const data: AppUserMapLocation[] = response.data;
          setLocationUsers(data);
        }
        setLocationUsersLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLocationUsersLoading(false);
      });
  };

  const filterAssignedUsers = () => {
    setAssignedUsersFiltered(
      assignedUsers.filter(
        (assignedUser: AssignedAppUser) =>
          assignedUser.first_name
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          assignedUser.last_name.toLowerCase().includes(search.toLowerCase())
      )
    );
  };

  const removeLocation = (assignedUser: AssignedAppUser) => {
    const newAssignedAppUsers = assignedUsers.filter(
      (user) => user.app_user_id !== assignedUser.app_user_id
    );
    setAssignedUsers(newAssignedAppUsers);
  };

  const onClickRemoveAllUsers = () => {
    presentAlert({
      header: 'Remove all assigned Users?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Remove All',
          role: 'confirm',
        },
      ],
      onWillDismiss: (e: any) => {
        if (e.detail.role === 'confirm') {
          removeAllUsers();
        }
      },
    });
  };

  const removeAllUsers = () => {
    setAssignedUsers([]);
  };

  /**
   * Determine if app_user_map_locations should be updated, or deleted.
   */
  const onClickSave = () => {
    const userIds = flattenAssignedUsers(assignedUsers);
    if (userIds.length === 0) {
      deleteLocationUsers();
    } else {
      updateLocationUsers(userIds);
    }
  };

  /**
   * Delete all app_user_map_locations for the current location
   */
  const deleteLocationUsers = () => {
    if (!location) {
      return;
    }
    setLoading(true);
    api
      .delete(`location/${location.location_id}/app_user_map_locations`)
      .then((response) => {
        if (response.status === 204) {
          dispatch(
            setToast({
              message: 'Successfully updated!',
            })
          );
          getLocation();
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  /**
   * Update all app_user_map_locations for the current location
   */
  const updateLocationUsers = (userIds: number[]) => {
    if (!location) {
      return;
    }

    const formData = {
      app_user_ids: userIds,
    };
    setLoading(true);
    api
      .put(`location/${location.location_id}/app_user_map_locations`, formData)
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            setToast({
              message: 'Successfully updated!',
            })
          );
          getLocation();
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <Page title="Manage Location Users" className="pageLocationUsers">
      <Breadcrumbs
        breadcrumbs={breadcrumbs}
        title="Manage Location Users"
      ></Breadcrumbs>
      <IonRow>
        <IonCol size="12" sizeMd="8" offsetMd="2">
          <IonCard>
            <IonCardHeader style={{ paddingBottom: 0 }}>
              <div className="fullWidth d-flex ion-justify-content-between ion-align-items-end">
                <IonCardTitle>
                  Assigned Users{' '}
                  <Subtitle>
                    For location:{' '}
                    <span className="text-color-black">{location?.name}</span>
                  </Subtitle>
                </IonCardTitle>
                {location ? (
                  <div>
                    <IonButton
                      className="ion-no-margin"
                      size="small"
                      color="danger"
                      fill="clear"
                      onClick={onClickRemoveAllUsers}
                      disabled={loading || assignedUsers.length === 0}
                    >
                      Remove All
                    </IonButton>
                    <IonButton
                      className="ion-no-margin"
                      size="small"
                      onClick={showUsers}
                      disabled={loading}
                    >
                      Add Users
                      <IonIcon icon={addOutline} slot="end"></IonIcon>
                    </IonButton>
                  </div>
                ) : null}
              </div>
            </IonCardHeader>
            <IonCardContent className="ion-no-padding ion-padding-horizontal ion-padding-bottom">
              <IonSearchbar
                className="ion-no-padding"
                placeholder="Search Assigned Users..."
                value={search}
                onIonChange={(e) => setSearch(e.detail.value as string)}
              />
              {locationUsersLoading ? (
                <LoadingInline />
              ) : (
                <IonList>
                  {assignedUsersFiltered &&
                    assignedUsersFiltered.map(
                      (assignedUser: AssignedAppUser, index: any) => (
                        <Fragment key={assignedUser.app_user_id}>
                          <IonChip
                            style={{ height: 'unset' }}
                            disabled={loading}
                            data-tooltip-id="locationUsersTooltip"
                            data-tooltip-content={`${assignedUser.email_address}`}
                            data-tooltip-place="bottom"
                          >
                            <IonLabel>
                              {utils.getFullName(assignedUser)}
                            </IonLabel>
                            {location ? (
                              <IonIcon
                                icon={closeCircleOutline}
                                color="danger"
                                onClick={() => removeLocation(assignedUser)}
                              ></IonIcon>
                            ) : null}
                          </IonChip>
                        </Fragment>
                      )
                    )}
                </IonList>
              )}
            </IonCardContent>
            <CardActions>
              <div className="d-flex ion-justify-content-end ion-align-items-center">
                {changed ? (
                  <IonText color="danger">
                    <p className="ion-no-margin ion-margin-end font-style-italic">
                      Unsaved Changes
                    </p>
                  </IonText>
                ) : null}
                <IonButton
                  disabled={loading || !changed}
                  onClick={resetAssignedUsers}
                  color="medium"
                  fill="clear"
                  className="ion-margin-end"
                >
                  Cancel
                </IonButton>
                <IonButton disabled={loading || !changed} onClick={onClickSave}>
                  Save
                  <IonIcon slot="end" icon={saveOutline} />
                </IonButton>
              </div>
            </CardActions>
          </IonCard>
        </IonCol>
      </IonRow>
      <Tooltip
        id="locationUsersTooltip"
        style={{
          zIndex: 9999,
          maxWidth: '500px',
        }}
      ></Tooltip>
    </Page>
  );
};
