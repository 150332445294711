import { IonButton, IonContent, IonIcon, IonPopover } from '@ionic/react';
import { Supplier } from 'src/interfaces/Supplier';
import { informationCircleOutline } from 'ionicons/icons';
import { useId } from 'react';
import { ListSupplierContacts } from './ListSupplierContacts';
import './PopoverSupplierMeta.css';

export const PopoverSupplierMeta: React.FC<{
  supplier?: Supplier;
  trigger?: string;
  alignment?: 'start' | 'center' | 'end';
  side?: 'top' | 'bottom' | 'left' | 'right' | 'start' | 'end';
}> = ({ supplier, trigger, alignment = 'center', side = 'bottom' }) => {
  const triggerId = useId();

  return (
    <>
      {!trigger ? (
        <IonButton id={triggerId} fill="outline" size="small">
          Contact Info
          <IonIcon slot="end" icon={informationCircleOutline} />
        </IonButton>
      ) : null}
      <IonPopover
        className="popoverSupplierMeta"
        trigger={trigger || triggerId}
        triggerAction="click"
        alignment={alignment}
        side={side}
      >
        <IonContent>
          <ListSupplierContacts supplier={supplier} />
        </IonContent>
      </IonPopover>
    </>
  );
};
