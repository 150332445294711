import {
  IonButton,
  IonCard,
  IonCardContent,
  IonChip,
  IonCol,
  IonProgressBar,
  IonRow,
  IonSearchbar,
  IonSkeletonText,
} from '@ionic/react';
import { ButtonAddToCart } from 'src/components/buyer/ButtonAddToCart';
import { CardStatOrderedItems } from 'src/components/buyer/CardStatOrderedItems';
import { CardStatSpend } from 'src/components/buyer/CardStatSpend';
import { ListSupplierContacts } from 'src/components/buyer/ListSupplierContacts';
import { ModalManageAccounts } from 'src/components/buyer/ModalManageAccounts';
import { ModalSupplierAdd } from 'src/components/buyer/ModalSupplierAdd';
import { PopoverSupplierMeta } from 'src/components/buyer/PopoverSupplierMeta';
import { Breadcrumb, Breadcrumbs } from 'src/components/shared/Breadcrumbs';
import { DataTable } from 'src/components/shared/DataTable';
import { DataTableHeader } from 'src/components/shared/DataTable/interfaces/DataTableHeader';
import { DataTableRow } from 'src/components/shared/DataTable/interfaces/DataTableRow';
import { Divider } from 'src/components/shared/Divider';
import { ItemDescriptionCol } from 'src/components/shared/ItemDescriptionCol';
import { LoadingInline } from 'src/components/shared/LoadingInline';
import { LogoSupplier } from 'src/components/shared/LogoSupplier';
import {
  SelectTimeFrame,
  TimeFrame,
} from 'src/components/shared/SelectTimeFrame';
import { useApi } from 'src/hooks/useApi';
import { useUtils } from 'src/hooks/useUtils';
import { Account } from 'src/interfaces/Account';
import { AppUserSelf } from 'src/interfaces/AppUserSelf';
import { ItemCatalog } from 'src/interfaces/ItemCatalog';
import { Location } from 'src/interfaces/Location';
import { Supplier } from 'src/interfaces/Supplier';
import { Page } from 'src/layouts/Page';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { SelectAccount } from './SelectAccount';

import { DataTablePagination } from 'src/components/shared/DataTable/interfaces/DataTablePagination';
import styles from '../buyerStyles.module.css';

export const SupplierPage: React.FC = () => {
  const api = useApi();
  const utils = useUtils();
  const { id } = useParams<{ id: string }>();
  const authUser: AppUserSelf | null = useSelector(
    (state: any) => state.auth.user
  );

  const selectedLocation: Location | null = useSelector(
    (state: any) => state.app.selectedLocation
  );
  const [search, setSearch] = useState<string>('');

  const [loading, setLoading] = useState(false);
  const [supplier, setSupplier] = useState<Supplier>();
  const [itemsLoading, setItemsLoading] = useState(false);
  const [items, setItems] = useState<ItemCatalog[]>([]);
  const [showAccounts, setShowAccounts] = useState(false);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [accountsLoading, setAccountsLoading] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<Account>();

  const [timeFrameLabel, setTimeFrameLabel] = useState<string>();
  const [filterMinDate, setFilterMinDate] = useState<string | null>(null);
  const [pagination, setPagination] = useState<DataTablePagination | null>(
    null
  );
  const [totalCount, setTotalCount] = useState<number>(0);

  const [supplierAddModalOpen, setSupplierAddModalOpen] = useState(false);

  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Suppliers', path: '/suppliers', direction: 'back' },
    { label: supplier ? supplier.name : id },
  ];

  const headers: Array<DataTableHeader> = [
    { text: 'Item', key: 'item' },
    { text: 'Price', key: 'packPrice', align: 'right' },
    { text: 'Quantity', key: 'actions', align: 'left', width: '325px' },
  ];

  useEffect(() => {
    getSupplier();
  }, [id]);

  useEffect(() => {
    getAccounts();
  }, [id, selectedLocation]);

  useEffect(() => {
    getItems();
  }, [selectedAccount, search, pagination]);

  useEffect(() => {
    if (accounts.length === 0) {
      setItems([]);
    }
  }, [accounts]);

  const getSupplier = () => {
    setLoading(true);
    api
      .get(`supplier/${id}`)
      .then(({ data }) => {
        setSupplier(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getAccounts = () => {
    if (!selectedLocation) return;
    setAccountsLoading(true);
    api
      .get(
        `location/${selectedLocation.location_id}/accounts?supplier_id=${id}`
      )
      .then((response) => {
        const data: Account[] = response.data;
        setAccounts(data);
        setAccountsLoading(false);
        if (data.length > 0) {
          setSelectedAccount(data[0]);
        }
      })
      .catch((error) => {
        console.log(error);
        setAccountsLoading(false);
      });
  };

  const getItems = () => {
    if (!selectedAccount || !pagination) return;
    setItemsLoading(true);
    api
      .get(`account/${selectedAccount.account_id}/catalog`, {
        limit: pagination.limit,
        offset: pagination.offset,
        search: search || undefined,
      })
      .then((response) => {
        if (response.status === 200) {
          setItems(response.data.result || []);
          setTotalCount(response.data.total);
        }
        setItemsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setItemsLoading(false);
      });
  };
  const onClickAddAccount = () => {
    setSupplierAddModalOpen(true);
  };

  const onCloseSupplierModal = (data?: any) => {
    setSupplierAddModalOpen(false);
    getAccounts();
  };

  return (
    <Page title="Supplier Detail" className="supplierPage">
      <Breadcrumbs breadcrumbs={breadcrumbs} title="Supplier Detail">
        {authUser && !utils.isAnalyst(authUser) ? (
          <>
            {accounts.length > 0 ? (
              <IonButton size="small" onClick={() => setShowAccounts(true)}>
                Manage Accounts
              </IonButton>
            ) : (
              <IonButton size="small" onClick={onClickAddAccount}>
                Add Existing Account
              </IonButton>
            )}
          </>
        ) : null}
      </Breadcrumbs>
      {!accountsLoading ? (
        <>
          {accounts.length < 1 ? (
            <IonRow>
              {/* "NO ACCOUNTS" MESSAGING */}
              <IonCol size="12">
                <IonCard className={styles.cardSupplierInfo}>
                  <div className="ion-padding ion-text-center">
                    {(supplier && <LogoSupplier src={supplier.logo} />) || (
                      <IonSkeletonText
                        animated={true}
                        className="supplierLogo"
                      ></IonSkeletonText>
                    )}
                  </div>
                  <IonCardContent className="ion-no-padding">
                    <IonRow className="ion-padding-horizontal">
                      <IonCol size="12" className="ion-text-center">
                        <h2>
                          A {supplier?.name} account was not found for this
                          location
                        </h2>
                      </IonCol>
                    </IonRow>
                    {supplier?.application_enabled ? (
                      <IonRow className="ion-padding">
                        <IonCol size="12" className="ion-text-center">
                          <p>Already have a {supplier?.name} account?</p>
                          <IonButton
                            size="small"
                            color="primary"
                            shape="round"
                            onClick={onClickAddAccount}
                          >
                            Add Existing Account
                          </IonButton>
                          <Divider text="OR" />
                          <IonButton
                            shape="round"
                            size="small"
                            routerLink={`/suppliers/${supplier?.supplier_id}/apply`}
                            routerDirection="forward"
                          >
                            Apply Today
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    ) : (
                      <>
                        <IonRow className="ion-no-padding ion-padding-horizontal ion-padding-bottom">
                          <IonCol size="12" className="ion-text-center">
                            <p>Already have a {supplier?.name} account?</p>
                            <IonButton
                              shape="round"
                              size="small"
                              onClick={onClickAddAccount}
                            >
                              Add Existing Account
                            </IonButton>
                          </IonCol>
                          <Divider text="OR" />
                          <IonCol size="12" className="ion-text-center">
                            <IonChip color="danger">
                              Account Application Not Supported
                            </IonChip>
                          </IonCol>
                          <IonCol size="12">
                            <p className="margin-bottom-large">
                              {supplier?.name} does not currently support
                              applications for direct accounts from within
                              Trulla Direct.
                            </p>
                          </IonCol>
                          <IonCol size="12">
                            <p>
                              Use the information below to contact{' '}
                              {supplier?.name} if you wish to apply for a direct
                              account.
                            </p>
                          </IonCol>
                        </IonRow>

                        <div>
                          {supplier ? (
                            <ListSupplierContacts supplier={supplier} />
                          ) : (
                            <IonProgressBar
                              type="indeterminate"
                              color="primary"
                            />
                          )}
                        </div>
                      </>
                    )}
                  </IonCardContent>
                </IonCard>
              </IonCol>

              <ModalSupplierAdd
                isOpen={supplierAddModalOpen}
                supplier={supplier}
                onClose={(data?: any) => onCloseSupplierModal(data)}
                onDismiss={(data?: any) => onCloseSupplierModal(data)}
              />
            </IonRow>
          ) : (
            <>
              {/* "STANDARD" MESSAGING */}

              <IonRow>
                <IonCol size="12" sizeMd="6" sizeLg="3">
                  <IonCard
                    className={`
                      d-flex
                      ion-align-items-center
                      ion-justify-content-center
                      fullHeight
                    `}
                  >
                    <IonCardContent>
                      <IonRow>
                        <IonCol size="12" className="ion-text-center">
                          {loading && (
                            <IonSkeletonText
                              animated={true}
                              className="supplierLogo"
                            />
                          )}
                          {!loading && supplier && (
                            <LogoSupplier
                              src={supplier.logo}
                              style={{
                                height: '70px',
                                maxWidth: '200px',
                              }}
                            />
                          )}
                        </IonCol>
                        <IonCol className="ion-text-center">
                          <PopoverSupplierMeta supplier={supplier} />
                        </IonCol>
                      </IonRow>
                    </IonCardContent>
                  </IonCard>
                </IonCol>
                <IonCol
                  size="12"
                  sizeMd="6"
                  sizeLg="9"
                  className="ion-no-padding"
                >
                  <IonRow>
                    <IonCol size="12" className="ion-no-padding">
                      <IonRow>
                        <IonCol
                          size="12"
                          sizeLg="8"
                          className="ion-padding-horizontal"
                        >
                          <SelectTimeFrame
                            onUpdate={(timeFrame: TimeFrame) => {
                              setFilterMinDate(timeFrame.value);
                              setTimeFrameLabel(timeFrame.label);
                            }}
                          />
                        </IonCol>
                      </IonRow>
                    </IonCol>
                    <IonCol size="12" sizeMd="6" sizeLg="4">
                      <CardStatSpend
                        minDate={filterMinDate}
                        label={timeFrameLabel}
                        supplier={supplier}
                      />
                    </IonCol>
                    {/* TODO: Realized Savings (from buying featured deals) */}
                    {/* <IonCol size="12" sizeMd="6" sizeLg="4">
                <CardStat
                  color="success"
                  title="Realized Savings"
                  stat={toUsd(0, 0)}
                  statTitle={filterTimeFrame}
                  icon={cashOutline}
                  loading={loading}
                />
              </IonCol> */}
                    <IonCol size="12" sizeMd="6" sizeLg="4">
                      <CardStatOrderedItems
                        minDate={filterMinDate}
                        label={timeFrameLabel}
                        supplier={supplier}
                      />
                    </IonCol>
                  </IonRow>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="12">
                  <IonCard>
                    <DataTable
                      headers={headers}
                      loading={itemsLoading}
                      title={`${supplier?.name} Items`}
                      search={false}
                      onPaginationChange={(pagination: DataTablePagination) =>
                        setPagination(pagination)
                      }
                      itemsPerPage={50}
                      totalCount={totalCount}
                      rows={items.map((item): DataTableRow => {
                        const row: DataTableRow = {
                          item: item,
                          key: `${item.supplier_item_number}-${item.account_number}`,
                          columns: [
                            {
                              header: 'item',
                              content: (
                                <>
                                  {supplier && selectedAccount && (
                                    <ItemDescriptionCol
                                      item={item}
                                      account={selectedAccount}
                                    />
                                  )}
                                </>
                              ),
                            },
                            {
                              header: 'packPrice',
                              content: (
                                <>
                                  <p className="font-weight-500 text-color-black">
                                    {item.item_unit_price
                                      ? utils.toUsd(
                                          parseFloat(
                                            item.item_unit_price || '0'
                                          )
                                        )
                                      : 'N/A'}
                                  </p>
                                </>
                              ),
                            },
                            {
                              header: 'actions',
                              content: (
                                <>
                                  {selectedAccount &&
                                  authUser &&
                                  !utils.isAnalyst(authUser) ? (
                                    <ButtonAddToCart
                                      item={item}
                                      account={selectedAccount}
                                    />
                                  ) : null}
                                </>
                              ),
                            },
                          ],
                        };
                        return row;
                      })}
                    >
                      <IonRow className="ion-justify-content-end ion-align-items-end">
                        <IonCol size="12" sizeXl="6" className="">
                          <p className="margin-left-xs ion-text-left">
                            Select Account:
                          </p>
                          {supplier ? (
                            <SelectAccount
                              accounts={accounts}
                              supplier={supplier}
                              value={selectedAccount}
                              disabled={accountsLoading}
                              onIonChange={(e: any) =>
                                setSelectedAccount(e.detail.value)
                              }
                            />
                          ) : null}
                        </IonCol>
                        <IonCol size="12" sizeXl="6">
                          <IonSearchbar
                            className="ion-text-left"
                            value={search}
                            onIonChange={(e: any) =>
                              setSearch(e.detail.value as string)
                            }
                            debounce={500}
                          />
                        </IonCol>
                      </IonRow>
                    </DataTable>
                  </IonCard>
                </IonCol>
              </IonRow>
              {supplier && selectedLocation ? (
                <ModalManageAccounts
                  isOpen={showAccounts}
                  onDismiss={() => {
                    setShowAccounts(false);
                    getAccounts();
                  }}
                  supplier={supplier}
                  location={selectedLocation}
                />
              ) : null}
            </>
          )}
        </>
      ) : (
        <LoadingInline />
      )}
    </Page>
  );
};
