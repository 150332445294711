import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
} from '@ionic/react';
import './StepCard.css';
import { checkmarkOutline, closeOutline } from 'ionicons/icons';

export const StepCard: React.FC<{
  label: string;
  index: number;
  clickable?: boolean;
  setActiveIndex: (index: number) => void;
  isActive: boolean;
  isComplete: boolean;
  isInvalid: boolean;
  disabled?: boolean;
}> = ({
  label,
  index,
  clickable = true,
  setActiveIndex,
  isActive,
  isComplete,
  isInvalid,
  disabled = false,
}) => {
  const cardStatus = () => {
    let status = '';
    if (isInvalid) {
      status = 'invalid';
    } else if (isComplete) {
      status = 'complete';
    }
    if (isActive) {
      status += ' active';
    }
    return status;
  };
  return (
    <IonCard
      button={clickable}
      className={`stepCard ${cardStatus()} ${clickable ? 'clickable' : ''}`}
      onClick={() => (clickable ? setActiveIndex(index) : undefined)}
      disabled={disabled}
    >
      <IonCardHeader className="ion-text-center">
        <IonCardTitle>
          <span className="number">{index + 1}</span>
        </IonCardTitle>
        <IonCardSubtitle>{label}</IonCardSubtitle>
      </IonCardHeader>
    </IonCard>
  );
};
