import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import { FormError } from 'src/components/shared/FormError';
import { useApi } from 'src/hooks/useApi';
import { Location340bType } from 'src/interfaces/Location340bType';
import { useEffect, useState } from 'react';

export const SelectLocation340bType: React.FC<{
  value?: number | null;
  onIonChange?: (e: any) => void;
  disabled?: boolean;
  errorMessage?: string;
}> = ({ value, onIonChange, disabled = false, errorMessage }) => {
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [location340bTypes, setLocation340bTypes] = useState<
    Location340bType[]
  >([]);

  useEffect(() => {
    getLocationTypes();
  }, []);

  const getLocationTypes = () => {
    setLoading(true);
    api
      .get('location_340b_types')
      .then(({ data }) => {
        setLocation340bTypes(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  return (
    <IonItem
      className={`no-padding ${errorMessage ? 'ion-invalid' : 'ion-valid'}`}
      detail={false}
    >
      <IonLabel position="stacked">340B Type</IonLabel>

      <IonSelect
        value={value}
        onIonChange={onIonChange}
        interface="popover"
        interfaceOptions={{
          alignment: 'end',
          showBackdrop: true,
          dismissOnSelect: true,
        }}
        disabled={disabled || loading}
        className="rounded"
      >
        {location340bTypes.map((locationType: Location340bType) => {
          return (
            <IonSelectOption
              key={locationType.location_340b_type_id}
              value={locationType.location_340b_type_id}
            >
              {locationType.name}
            </IonSelectOption>
          );
        })}
      </IonSelect>

      {errorMessage && <FormError>{errorMessage}</FormError>}
    </IonItem>
  );
};
