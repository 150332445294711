import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { AssignedLocation } from 'src/interfaces/AssignedLocation';
import { addOutline, closeOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { Subtitle } from '../shared/Subtitle';
import { LocationWithAddress } from 'src/interfaces/LocationWithAddress';

export const ModalAssignLocations: React.FC<{
  locations: LocationWithAddress[];
  onDismiss: (
    data?: AssignedLocation[] | null | undefined,
    role?: string
  ) => void;
}> = ({ locations, onDismiss }) => {
  const [search, setSearch] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [locationsFiltered, setLocationsFiltered] =
    useState<LocationWithAddress[]>(locations);

  const [selectedLocations, setSelectedLocations] = useState<
    AssignedLocation[]
  >([]);

  useEffect(() => {
    if (locations) {
      filterLocations();
    } else {
      setLocationsFiltered([]);
    }
  }, [locations, search]);

  const filterLocations = () => {
    setLocationsFiltered(
      locations.filter(
        (location: LocationWithAddress) =>
          // Filter by search query
          location.name?.toLowerCase().includes(search.toLowerCase()) ||
          location.address1?.toLowerCase().includes(search.toLowerCase()) ||
          location.address2?.toLowerCase().includes(search.toLowerCase()) ||
          location.city?.toLowerCase().includes(search.toLowerCase()) ||
          location.state?.toLowerCase().includes(search.toLowerCase()) ||
          location.postal_code?.toLowerCase().includes(search.toLowerCase())
      )
    );
  };

  const onClickAdd = () => {
    onDismiss(selectedLocations);
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Assign Locations</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => onDismiss(null, 'cancel')}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <div>
            <IonSearchbar
              value={search}
              onIonChange={(e: any) => setSearch(e.detail.value as string)}
              placeholder="Search for a Location"
            />
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {locationsFiltered.length > 0 ? (
          <IonList>
            {locationsFiltered.map((location) => (
              <IonItem key={location.location_id} disabled={loading}>
                <IonCheckbox
                  slot="start"
                  checked={
                    !!selectedLocations.find(
                      (loc) => loc.location_id === location.location_id
                    )
                  }
                  onIonChange={(e) => {
                    const checked = e.detail.checked;
                    if (checked) {
                      setSelectedLocations([
                        ...selectedLocations,
                        {
                          location_id: location.location_id,
                          name: location.name || '',
                        },
                      ]);
                    } else {
                      setSelectedLocations(
                        selectedLocations.filter(
                          (loc) => loc.location_id !== location.location_id
                        )
                      );
                    }
                  }}
                />
                <IonLabel>
                  <div>
                    <p className="font-size-default">{location.name}</p>

                    <p className="font-size-xs ion-no-margin">
                      {location.address1}
                      {location.address2 && (
                        <span>,&nbsp;{location.address2}</span>
                      )}
                    </p>
                    <p className="font-size-xs ion-no-margin">
                      {location.city} {location.state}, {location.postal_code}
                    </p>
                  </div>
                </IonLabel>
                <div>
                  {location.facility_id && (
                    <Subtitle>
                      <span className="font-style-italic font-size-xs">
                        Facility Id:
                      </span>{' '}
                      {location.facility_id}
                    </Subtitle>
                  )}
                </div>
              </IonItem>
            ))}
          </IonList>
        ) : (
          <div className="ion-padding ion-text-center">
            <p className="font-style-italic">No locations available</p>
          </div>
        )}
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <div slot="end">
            <IonButton
              fill="clear"
              color="dark"
              size="small"
              onClick={() => onDismiss()}
            >
              Cancel
            </IonButton>
            <IonButton
              size="small"
              onClick={onClickAdd}
              disabled={selectedLocations.length === 0}
            >
              Add
              <IonIcon slot="end" icon={addOutline} />
            </IonButton>
          </div>
        </IonToolbar>
      </IonFooter>
    </>
  );
};
