import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { ApplicationOrganization } from 'src/interfaces/ApplicationOrganization';
import { SupplierAccountApplication } from 'src/interfaces/SupplierAccountApplication';
import { closeOutline } from 'ionicons/icons';
import { TableAccountApplications } from './TableAccountApplications';

export const ModalAccountApplications: React.FC<{
  organization: ApplicationOrganization | null;
  onDismiss: (
    application?: SupplierAccountApplication | null | undefined,
    role?: string
  ) => void;
}> = ({ organization, onDismiss }) => {
  const onClickRow = (application: SupplierAccountApplication) => {
    onDismiss(application);
  };
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Account Applications</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => onDismiss()}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {organization ? (
          <TableAccountApplications
            organization={organization}
            onClickRow={onClickRow}
          />
        ) : null}
      </IonContent>
    </>
  );
};
