import { Breadcrumbs, Breadcrumb } from 'src/components/shared/Breadcrumbs';
import { Page } from 'src/layouts/Page';

export const AnalyticsPage: React.FC = () => {
  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Analytics' },
  ];

  return (
    <Page title="Analytics">
      <Breadcrumbs breadcrumbs={breadcrumbs} />
    </Page>
  );
};
