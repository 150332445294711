import { IonButton, IonIcon, IonSpinner } from '@ionic/react';
import { downloadOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useApi } from 'src/hooks/useApi';
import { SupplierTerm } from 'src/interfaces/SupplierTerm';
import { SupplierTermContent } from 'src/interfaces/SupplierTermContent';
import { setToast } from 'src/redux/appReducer';
export const ButtonDownloadSupplierTerm: React.FC<{
  supplierTerm: SupplierTerm;
  disabled?: boolean;
}> = ({ supplierTerm, disabled = false }) => {
  const api = useApi();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState<string | null>(null);

  useEffect(() => {
    if (content) {
      download(content);
    }
  }, [content]);

  const onClickDownload = async () => {
    setLoading(true);
    if (content) {
      download(content);
    } else {
      if (supplierTerm) {
        const response = await api.get(
          `supplier_term/${supplierTerm.supplier_term_id}/content`
        );
        if (response.status === 200) {
          const data: SupplierTermContent = response.data;
          setContent(data.content);
        } else {
          dispatch(
            setToast({
              color: 'danger',
              message: 'There was a problem downloading the document.',
            })
          );
        }
      }
    }

    setLoading(false);
  };
  const download = (content: string) => {
    const link = document.createElement('a');
    link.href = content;
    link.download = supplierTerm.name || 'document';
    link.click();
  };

  return (
    <IonButton
      size="small"
      fill="clear"
      slot="end"
      onClick={onClickDownload}
      disabled={loading || disabled}
      data-tooltip-id="global-tooltip"
      data-tooltip-html={`
        <span>Download</span><br/>
        <span>${supplierTerm.name || 'document'}</span>
      `}
      data-tooltip-place="bottom"
    >
      {loading ? (
        <IonSpinner name="crescent" color="primary" />
      ) : (
        <IonIcon icon={downloadOutline} slot="icon-only" />
      )}
    </IonButton>
  );
};
