import { zodResolver } from '@hookform/resolvers/zod';
import { IonCol, IonList, IonRow } from '@ionic/react';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Divider } from 'src/components/shared/Divider';
import { FormError } from 'src/components/shared/FormError';
import { FormInput } from 'src/components/shared/FormInput';
import { License } from 'src/interfaces/License';
import { v } from 'src/utils/validators';
import { z } from 'zod';
import { FormLicenseInput } from '../FormLicenseInput';

const licenseSchema = z.object({
  deaLicenseNum: v.deaLicenseNum(),
  deaLicenseExpiration: v.date('DEA Expiration', true),
  deaLicenseFilesize: z
    .number()
    .max(5000000, { message: 'File too large. Must be less than 5MB' }),
  deaLicenseFilename: z.string().min(1, { message: 'Missing DEA filename' }),
  deaLicenseFileBase64: v.deaLicenseFileBase64(),
  stateLicenseNum: v.stateLicenseNum(),
  stateLicenseExpiration: v.date('State Expiration', true),
  stateLicenseFilesize: z
    .number()
    .max(5000000, { message: 'File too large. Must be less than 5MB' }),
  stateLicenseFilename: z.string().min(1, { message: 'Missing DEA filename' }),
  stateLicenseFileBase64: v.stateLicenseFileBase64(),
});

export type EditLicensesSchema = z.infer<typeof licenseSchema>;

export const FormLicenses: React.FC<{
  formId: string;
  deaLicense?: License;
  deaLoading?: boolean;
  stateLicense?: License;
  stateLoading?: boolean;
  onSubmit: (location: EditLicensesSchema) => void;
  setChanged?: (changed: boolean) => void;
  revertToggle?: boolean;
}> = ({
  formId,
  deaLicense,
  deaLoading,
  stateLicense,
  stateLoading,
  onSubmit,
  setChanged,
  revertToggle,
}) => {
  const {
    handleSubmit,
    control,
    setValue,
    register,
    watch,
    formState: { errors },
  } = useForm<EditLicensesSchema>({
    resolver: zodResolver(licenseSchema),
    defaultValues: {
      deaLicenseNum: deaLicense?.number,
      deaLicenseExpiration: deaLicense?.expiration,
      deaLicenseFileBase64: deaLicense?.file,
      deaLicenseFilename: deaLicense?.filename,
      deaLicenseFilesize: deaLicense?.filesize,
      stateLicenseNum: stateLicense?.number,
      stateLicenseExpiration: stateLicense?.expiration,
      stateLicenseFileBase64: stateLicense?.file,
      stateLicenseFilename: stateLicense?.filename,
      stateLicenseFilesize: stateLicense?.filesize,
    },
  });

  const deaLicenseNum = watch('deaLicenseNum');
  const deaLicenseExpiration = watch('deaLicenseExpiration');
  const deaLicenseFilesize = watch('deaLicenseFilesize');
  const stateLicenseNum = watch('stateLicenseNum');
  const stateLicenseExpiration = watch('stateLicenseExpiration');
  const stateLicenseFilesize = watch('stateLicenseFilesize');

  const changed =
    deaLicenseNum !== (deaLicense?.number || '') ||
    deaLicenseExpiration !== (deaLicense?.expiration || '') ||
    deaLicenseFilesize !== (deaLicense?.filesize || '') ||
    stateLicenseNum !== (stateLicense?.number || '') ||
    stateLicenseExpiration !== (stateLicense?.expiration || '') ||
    stateLicenseFilesize !== (stateLicense?.filesize || 0);

  /**
   * When licenses change, populate form data.
   */
  useEffect(() => {
    if (deaLicense || stateLicense) {
      populateData();
    }
  }, [deaLicense, stateLicense]);

  /**
   * When "revertToggle" changes AND the component has already been populated with data,
   * revert the form to the original data
   */
  useEffect(() => {
    if (changed) {
      populateData();
    }
  }, [revertToggle]);

  /**
   * Tell the parent component that the data has changed
   */
  useEffect(() => {
    if (setChanged) {
      setChanged(changed);
    }
  }, [changed]);

  const populateData = () => {
    if (deaLicense) {
      setValue('deaLicenseNum', deaLicense.number);
      setValue('deaLicenseExpiration', deaLicense.expiration);
      setValue('deaLicenseFileBase64', deaLicense.file);
      setValue('deaLicenseFilename', deaLicense.filename);
      setValue('deaLicenseFilesize', deaLicense.filesize);
    }
    if (stateLicense) {
      setValue('stateLicenseNum', stateLicense.number);
      setValue('stateLicenseExpiration', stateLicense.expiration);
      setValue('stateLicenseFileBase64', stateLicense.file);
      setValue('stateLicenseFilename', stateLicense.filename);
      setValue('stateLicenseFilesize', stateLicense.filesize);
    }
  };

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <IonList lines="none">
        <IonRow>
          <IonCol>
            <Divider className="ion-no-margin" text="DEA" />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="7">
            <FormInput
              {...register('deaLicenseNum')}
              label="DEA License #"
              disabled={deaLoading}
              onIonChange={(e) => {
                setValue('deaLicenseNum', e.detail.value);
              }}
              errorMessage={errors.deaLicenseNum?.message}
            />
          </IonCol>
          <IonCol size="5">
            <FormInput
              {...register('deaLicenseExpiration')}
              label="DEA Expiration"
              disabled={deaLoading}
              onIonChange={(e) => {
                setValue('deaLicenseExpiration', e.detail.value);
              }}
              placeholder="yyyy-mm-dd"
              errorMessage={errors.deaLicenseExpiration?.message}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="12">
            <Controller
              name="deaLicenseFileBase64"
              control={control}
              render={({ field }) => {
                return (
                  <>
                    <FormLicenseInput
                      license={deaLicense}
                      label="DEA License"
                      placeholder="Supported types: pdf, png, jpeg. File size: 5MB or smaller."
                      accept="application/pdf, image/png, image/jpeg"
                      value={field.value}
                      setValue={(filesize, filename, base64Content) => {
                        setValue('deaLicenseFilesize', filesize || 0);
                        setValue('deaLicenseFilename', filename || '');
                        setValue('deaLicenseFileBase64', base64Content || '');
                      }}
                      showThumbnail={true}
                      errorMessage={errors.deaLicenseFileBase64?.message}
                      disabled={deaLoading}
                    />
                    {errors?.deaLicenseFileBase64 && (
                      <FormError>
                        {errors?.deaLicenseFileBase64.message}
                      </FormError>
                    )}
                  </>
                );
              }}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="ion-margin-top">
            <Divider className="ion-no-margin" text="State" />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="7">
            <FormInput
              {...register('stateLicenseNum')}
              label="State License #"
              disabled={stateLoading}
              onIonChange={(e) => {
                setValue('stateLicenseNum', e.detail.value);
              }}
              errorMessage={errors.stateLicenseNum?.message}
            />
          </IonCol>
          <IonCol size="5">
            <FormInput
              {...register('stateLicenseExpiration')}
              label="State Expiration"
              disabled={stateLoading}
              onIonChange={(e) => {
                setValue('stateLicenseExpiration', e.detail.value);
              }}
              placeholder="yyyy-mm-dd"
              errorMessage={errors.stateLicenseExpiration?.message}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="12">
            <Controller
              name="stateLicenseFileBase64"
              control={control}
              render={({ field }) => {
                return (
                  <>
                    <FormLicenseInput
                      license={stateLicense}
                      label="State License"
                      placeholder="Supported types: pdf, png, jpeg. File size: 5MB or smaller."
                      accept="application/pdf, image/png, image/jpeg"
                      value={field.value}
                      setValue={(filesize, filename, base64Content) => {
                        setValue('stateLicenseFilesize', filesize || 0);
                        setValue('stateLicenseFilename', filename || '');
                        setValue('stateLicenseFileBase64', base64Content || '');
                      }}
                      showThumbnail={true}
                      errorMessage={errors.stateLicenseFileBase64?.message}
                      disabled={stateLoading}
                    />
                    {errors?.stateLicenseFileBase64 && (
                      <FormError>
                        {errors?.stateLicenseFileBase64.message}
                      </FormError>
                    )}
                  </>
                );
              }}
            />
          </IonCol>
        </IonRow>
      </IonList>
    </form>
  );
};
