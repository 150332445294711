import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonItemDivider,
  IonLabel,
  IonList,
  IonRow,
  IonText,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Breadcrumb, Breadcrumbs } from 'src/components/shared/Breadcrumbs';
import { Divider } from 'src/components/shared/Divider';
import { ApplicationField } from 'src/features/accountApplication/interfaces/ApplicationField';
import { useApi } from 'src/hooks/useApi';
import { SupplierAccountApplication } from 'src/interfaces/SupplierAccountApplication';
import { Page } from 'src/layouts/Page';
import { ListApplicationDocuments } from 'src/pages/supplier/AccountApplicationPage/ListApplicationDocuments';
import { AccountApplicationItem } from '../../AccountApplicationItem';
import { SandozTermsInputForm } from './SandozTermsInputForm';

export const AccountApplicationPageSandoz: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const api = useApi();

  const [loading, setLoading] = useState(false);

  const [application, setApplication] =
    useState<SupplierAccountApplication | null>(null);

  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Accounts', path: '/supplier/accounts', direction: 'back' },
    {
      label: 'Account Applications',
      path: '/supplier/accounts/applications',
      direction: 'back',
    },
    { label: 'Application Detail' },
  ];

  useEffect(() => {
    if (id) {
      getApplication();
    }
  }, [id]);

  const getApplication = () => {
    setLoading(true);
    api
      .get(`account_application/${id}`)
      .then((response) => {
        if (response.status === 200) {
          const data: SupplierAccountApplication = response.data;
          setApplication(data);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getField = (key: string): ApplicationField | null => {
    if (!application) {
      return null;
    }
    const field = application.fields.find((f) => f.key === key);
    return field || null;
  };

  return (
    <Page title="Application Detail">
      <Breadcrumbs breadcrumbs={breadcrumbs} title="Application Detail">
        {application?.application_status === 'pending' && (
          <>
            Status:{' '}
            <IonText className="font-size-large font-weight-600">
              Pending
            </IonText>
          </>
        )}
        {application?.application_status === 'approved' && (
          <>
            Status:{' '}
            <IonText
              color="success"
              className="font-size-large font-weight-600"
            >
              Approved
            </IonText>
          </>
        )}
        {application?.application_status === 'rejected' && (
          <>
            Status:{' '}
            <IonText color="danger" className="font-size-large font-weight-600">
              Rejected
            </IonText>
          </>
        )}
      </Breadcrumbs>
      <IonRow>
        <IonCol size="12" sizeLg="6">
          <IonCard className="fullHeight">
            <IonCardHeader>
              <IonCardTitle>Organization</IonCardTitle>
            </IonCardHeader>
            <IonCardContent className="ion-no-padding">
              <IonList className="ion-padding-horizontal">
                <AccountApplicationItem field={getField('orgName')} />
                <AccountApplicationItem field={getField('orgContactName')} />
                <AccountApplicationItem field={getField('orgContactEmail')} />
                <AccountApplicationItem field={getField('primaryGpo')} />
              </IonList>
              <IonItemDivider>
                <IonLabel>DSCSA</IonLabel>
              </IonItemDivider>
              <IonList className="ion-padding-horizontal">
                <AccountApplicationItem field={getField('dscsaContactName')} />
                <AccountApplicationItem field={getField('dscsaContactEmail')} />
                <AccountApplicationItem field={getField('dscsaContactPhone')} />
              </IonList>
            </IonCardContent>
          </IonCard>
        </IonCol>
        <IonCol size="12" sizeLg="6">
          <IonCard className="fullHeight">
            <IonCardHeader>
              <IonCardTitle>Licensing</IonCardTitle>
            </IonCardHeader>
            <IonCardContent className="ion-no-padding">
              <IonList className="ion-padding-horizontal">
                <AccountApplicationItem field={getField('deaLicenseNum')} />
                <AccountApplicationItem
                  field={getField('deaLicenseExpiration')}
                />
                <AccountApplicationItem field={getField('stateLicenseNum')} />
                <AccountApplicationItem
                  field={getField('stateLicenseExpiration')}
                />
              </IonList>
              <IonItemDivider>
                <IonLabel>Attachments</IonLabel>
              </IonItemDivider>

              {application ? (
                <ListApplicationDocuments
                  application={application}
                  className="ion-padding-horizontal"
                />
              ) : null}
            </IonCardContent>
          </IonCard>
        </IonCol>
      </IonRow>
      <Divider text="Bill To / Ship To" className="light" />
      <IonRow>
        <IonCol size="12" sizeLg="6">
          <IonCard className="fullHeight">
            <IonCardHeader>
              <IonCardTitle>Bill To</IonCardTitle>
            </IonCardHeader>
            <IonCardContent className="ion-no-padding">
              <IonItemDivider>
                <IonLabel>Billing Address</IonLabel>
              </IonItemDivider>
              <IonList className="ion-padding-horizontal">
                <AccountApplicationItem field={getField('billingAddress1')} />
                <AccountApplicationItem field={getField('billingAddress2')} />
                <AccountApplicationItem field={getField('billingCity')} />
                <AccountApplicationItem field={getField('billingState')} />
                <AccountApplicationItem field={getField('billingZip')} />
              </IonList>
              <IonItemDivider>
                <IonLabel>Billing Contact</IonLabel>
              </IonItemDivider>
              <IonList className="ion-padding-horizontal">
                <AccountApplicationItem field={getField('billingEmail')} />
              </IonList>
            </IonCardContent>
          </IonCard>
        </IonCol>
        <IonCol size="12" sizeLg="6">
          <IonCard className="fullHeight">
            <IonCardHeader>
              <IonCardTitle>Ship To</IonCardTitle>
            </IonCardHeader>
            <IonCardContent className="ion-no-padding">
              <IonItemDivider>
                <IonLabel>Ship To Address</IonLabel>
              </IonItemDivider>
              <IonList className="ion-padding-horizontal">
                <AccountApplicationItem field={getField('address1')} />
                <AccountApplicationItem field={getField('address2')} />
                <AccountApplicationItem field={getField('city')} />
                <AccountApplicationItem field={getField('state')} />
                <AccountApplicationItem field={getField('zip')} />
              </IonList>
              <IonItemDivider>
                <IonLabel>Other</IonLabel>
              </IonItemDivider>
              <IonList className="ion-padding-horizontal">
                <AccountApplicationItem field={getField('classOfTrade')} />
                <AccountApplicationItem field={getField('gcp')} />
                <AccountApplicationItem field={getField('gln')} />
                <AccountApplicationItem field={getField('hin')} />
              </IonList>
            </IonCardContent>
          </IonCard>
        </IonCol>
      </IonRow>

      <SandozTermsInputForm
        applicationId={id}
        application={application}
        loading={loading}
        setLoading={setLoading}
      />
    </Page>
  );
};
