import { IonButton, IonSpinner, useIonRouter } from '@ionic/react';
import { Breadcrumb, Breadcrumbs } from 'src/components/shared/Breadcrumbs';
import { useApi } from 'src/hooks/useApi';
import { Organization } from 'src/interfaces/Organization';
import { Page } from 'src/layouts/Page';
import { useId, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setToast } from 'src/redux/appReducer';
import {
  FormAddLocation,
  AddLocationSchema,
} from 'src/components/buyer/FormAddLocation';
import { LicenseType } from 'src/types/LicenseTypes';

interface AddLicenseSchema {
  type: LicenseType;
  name: string;
  number: string;
  expiration: string;
  file: string; //base64 encoded string
  filesize: number;
  filename: string;
}

export const LocationAddPage: React.FC = () => {
  const api = useApi();
  const formId = useId();
  const organization: Organization | null = useSelector(
    (state: any) => state.app.organization
  );
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const router = useIonRouter();

  const breadcrumbs: Breadcrumb[] = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Settings', path: '/settings', direction: 'back' },
    { label: 'Locations', path: '/settings/locations', direction: 'back' },
    { label: 'Add Location' },
  ];

  const onSubmit = (locationData: AddLocationSchema) => {
    if (!organization) {
      return;
    }
    setLoading(true);

    let dea_license: AddLicenseSchema | null = null;
    if (locationData.deaLicenseNum && locationData.deaLicenseExpiration) {
      dea_license = {
        type: 'dea',
        name: 'DEA License',
        number: locationData.deaLicenseNum,
        expiration: locationData.deaLicenseExpiration,
        file: locationData.deaLicenseFileBase64,
        filesize: locationData.deaLicenseFilesize,
        filename: locationData.deaLicenseFilename,
      };
    }
    let state_license: AddLicenseSchema | null = null;
    if (locationData.stateLicenseNum && locationData.stateLicenseExpiration) {
      state_license = {
        type: 'state',
        name: 'State License',
        number: locationData.stateLicenseNum,
        expiration: locationData.stateLicenseExpiration,
        file: locationData.stateLicenseFileBase64,
        filesize: locationData.stateLicenseFilesize,
        filename: locationData.stateLicenseFilename,
      };
    }

    // the backend supports mass inserts, so post requests are passed in as an array.
    // in this case we're only creating one location, so we're passing in an array with only one object.
    const formData = [
      {
        organization_id: organization.organization_id,
        name: locationData.locationName,
        location_type_id: locationData.locationTypeId,
        location_340b_type_id: locationData.location340bTypeId,
        facility_id: locationData.facilityId,
        department_id: locationData.departmentId,
        address_name: locationData.addressName,
        address1: locationData.address1,
        address2: locationData.address2,
        city: locationData.city,
        state: locationData.state,
        postal_code: locationData.postalCode,
        country: 'US',
        billing_address_name: locationData.billingAddressName,
        billing_address1: locationData.billingAddress1,
        billing_address2: locationData.billingAddress2,
        billing_city: locationData.billingCity,
        billing_state: locationData.billingState,
        billing_postal_code: locationData.billingPostalCode,
        billing_country: 'US',
        opais_id: locationData.opaisId,
        gcp: locationData.gcp,
        gln: locationData.gln,
        hin: locationData.hin,
        dea_license: dea_license, // nullable
        state_license: state_license, // nullable
      },
    ];
    console.log(formData);
    api
      .post(`location`, formData)
      .then((response) => {
        if (response.status === 201) {
          const location = response.data; // because we're creating a single location, we're only expecting one record in response.
          setLoading(false);
          dispatch(
            setToast({
              header: 'Location created',
              message: `Successfully created location "${location.name}"`,
            })
          );
          router.push('/settings/locations', 'back');
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const Footer = () => {
    return (
      <div className="ion-text-right">
        <IonButton
          color="dark"
          fill="clear"
          routerLink="/settings/locations"
          routerDirection="back"
          disabled={loading}
        >
          Cancel
        </IonButton>
        <IonButton color="primary" type="submit" form={formId}>
          Create Location
          {loading && (
            <IonSpinner slot="end" name="crescent" className="margin-left-sm" />
          )}
        </IonButton>
      </div>
    );
  };

  return (
    <Page title="Add Location" footer={<Footer />}>
      <Breadcrumbs breadcrumbs={breadcrumbs}></Breadcrumbs>
      <FormAddLocation formId={formId} onSubmit={onSubmit} loading={loading} />
    </Page>
  );
};
