import { IonButton, IonIcon, useIonViewWillEnter } from '@ionic/react';
import { useId, useState } from 'react';
import { useParams } from 'react-router';
import { Breadcrumb, Breadcrumbs } from 'src/components/shared/Breadcrumbs';
import { useApi } from 'src/hooks/useApi';
import { SupplierTerm } from 'src/interfaces/SupplierTerm';
import { Page } from 'src/layouts/Page';
import {
  FormEditSupplierTerm,
  SupplierTermSchema,
} from './FormEditSupplierTerm';
import { saveOutline } from 'ionicons/icons';
import { useDispatch } from 'react-redux';
import { setToast } from 'src/redux/appReducer';

export const TermPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const formId = useId();
  const api = useApi();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [supplierTerm, setSupplierTerm] = useState<SupplierTerm | null>(null);

  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Settings', path: '/supplier/settings', direction: 'back' },
    {
      label: 'Term Documents',
      path: '/supplier/settings/terms',
      direction: 'back',
    },
    { label: supplierTerm ? supplierTerm.name : '' },
  ];

  useIonViewWillEnter(() => {
    getSupplierTerm();
  }, []);

  const getSupplierTerm = () => {
    setLoading(true);
    api
      .get(`supplier_term/${id}`)
      .then((response: any) => {
        if (response.status === 200) {
          setSupplierTerm(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateSupplierTerm = (updated: SupplierTermSchema) => {
    if (!supplierTerm) {
      return;
    }
    setLoading(true);
    const formData = {
      name: updated.name,
      content: updated.fileBase64,
      filesize: updated.filesize,
      original_filename: updated.filename,
      public: true,
      inputs: updated.inputs,
    };
    api
      .put(`supplier_term/${supplierTerm.supplier_term_id}`, formData)
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            setToast({
              message: 'Successfully Updated',
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteSupplierTerm = () => {
    //
  };

  const Footer = () => {
    return (
      <div className="d-flex ion-justify-content-end ion-align-items-center">
        <IonButton
          disabled={loading}
          // onClick={revertChanges}
          color="medium"
          fill="clear"
          className="ion-margin-end"
        >
          Cancel
        </IonButton>
        <IonButton form={formId} type="submit" disabled={loading}>
          Save
          <IonIcon slot="end" icon={saveOutline} />
        </IonButton>
      </div>
    );
  };

  return (
    <Page title="Supplier Term" footer={<Footer />}>
      <Breadcrumbs breadcrumbs={breadcrumbs}></Breadcrumbs>
      <FormEditSupplierTerm
        formId={formId}
        supplierTerm={supplierTerm}
        loading={loading}
        onSubmit={(supplierTerm: SupplierTermSchema) => {
          updateSupplierTerm(supplierTerm);
        }}
      />
    </Page>
  );
};
