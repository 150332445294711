import { IonButton, IonIcon } from '@ionic/react';
import { filterCircleOutline } from 'ionicons/icons';

import './ButtonFilter.css';

export const ButtonFilter: React.FC<{
  id?: string;
  className?: string;
  onClick?: any;
  disabled?: boolean;
}> = ({ id, className, onClick, disabled }) => {
  return (
    <IonButton
      id={id}
      className={`buttonFilter ${className ? className : ''}`}
      onClick={(e) => (onClick ? onClick(e) : '')}
      size="small"
      fill="clear"
      color="medium"
      disabled={disabled || undefined}
    >
      <IonIcon slot="icon-only" size="large" icon={filterCircleOutline} />
    </IonButton>
  );
};
