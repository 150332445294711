import { zodResolver } from '@hookform/resolvers/zod';
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonTitle,
  IonToggle,
  IonToolbar,
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import { useEffect, useId, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Divider } from 'src/components/shared/Divider';
import { FormError } from 'src/components/shared/FormError';
import { FormInput } from 'src/components/shared/FormInput';
import { LoadingInline } from 'src/components/shared/LoadingInline';
import { SelectOrganizationType } from 'src/components/shared/SelectOrganizationType';
import { useApi } from 'src/hooks/useApi';
import { useUtils } from 'src/hooks/useUtils';
import { Organization } from 'src/interfaces/Organization';
import { Supplier } from 'src/interfaces/Supplier';
import { setToast } from 'src/redux/appReducer';
import { z } from 'zod';

const formSchema = z
  .object({
    name: z.string().min(1, { message: 'Name is required' }),
    organizationTypeId: z
      .number({
        errorMap: () => ({ message: 'Organization Type is required' }),
      })
      .int(),
    number: z.string(),
    logo: z.string(),
    featured: z.boolean(),
  })
  // Some fields are required if the Organization type is "supplier"
  .refine(
    (schema) =>
      schema.organizationTypeId === 2 && schema.number.length < 1
        ? false
        : true,
    {
      message: 'Supplier Number is required',
      path: ['number'],
    }
  )
  .refine(
    (schema) =>
      schema.organizationTypeId === 2 && schema.logo.length < 1 ? false : true,
    {
      message: 'Logo is required',
      path: ['logo'],
    }
  );

type FormSchema = z.infer<typeof formSchema>;

const urlify = (str: string): string => {
  return str.toLowerCase().split(' ').join('-');
};

export const ModalManageOrganization: React.FC<{
  isOpen: boolean;
  onDismiss: (data?: any) => void;
  organization?: Organization | null;
}> = ({ isOpen, onDismiss, organization }) => {
  const { isSupplierOrganization } = useUtils();
  const formId = useId();
  const api = useApi();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    register,
    resetField,
    formState: { errors, isSubmitted, isValid },
    watch,
  } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      organizationTypeId: undefined,
      number: '',
      logo: '',
      featured: false,
    },
  });

  const name = watch('name');
  const organizationTypeId = watch('organizationTypeId');
  const number = watch('number');
  const logo = watch('logo');

  useEffect(() => {
    if (!isOpen) {
      resetField('name');
      resetField('organizationTypeId');
      resetField('number');
      resetField('logo');
      resetField('featured');
    }
  }, [isOpen]);

  useEffect(() => {
    if (name) {
      setValue('logo', `assets/suppliers/${urlify(name)}-logo.png`);
    } else {
      resetField('logo');
    }
  }, [name]);

  const createOrganization = (data: FormSchema) => {
    setLoading(true);
    api
      .post('organization', [
        {
          name: data.name,
          description: null,
          organization_type_id: data.organizationTypeId,
        },
      ])
      .then((response) => {
        if (response.status === 201) {
          const organization: Organization = response.data[0];
          if (isSupplierOrganization(organization)) {
            // create Supplier if needed
            createSupplier(organization, data.number, data.logo, data.featured);
          } else {
            // Show success if not needed
            dispatch(
              setToast({
                header: 'Success!',
                message: `Created Organization "${data.name}"`,
              })
            );
            onDismiss(organization);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /**
   * After organization is created, create the Supplier (if necessary)
   * @param organization
   * @param number
   * @param logo
   * @param featured
   */
  const createSupplier = (
    organization: Organization,
    number: string,
    logo: string,
    featured: boolean
  ) => {
    setLoading(true);
    api
      .post('supplier', [
        {
          organization_id: organization.organization_id,
          name: organization.name,
          description: organization.description,
          supplier_type_id: 2, // hardcoded to "Manufacturer". To be useful in the future, the entire concept of "Supplier Type" needs to be redesigned.
          number,
          logo,
          featured,
        },
      ])
      .then((response) => {
        if (response.status === 201) {
          const supplier: Supplier = response.data[0];
          dispatch(
            setToast({
              header: 'Success!',
              message: `Created Supplier "${supplier.name}"`,
            })
          );
          onDismiss(supplier);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <IonModal isOpen={isOpen} onIonModalWillDismiss={onDismiss}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Add Organization</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onDismiss} fill="clear">
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <form id={formId} onSubmit={handleSubmit(createOrganization)}>
          <IonList lines="none">
            <IonRow>
              <IonCol size="12" sizeMd="6">
                <Controller
                  name="organizationTypeId"
                  control={control}
                  render={({ field }) => {
                    return (
                      <SelectOrganizationType
                        disabled={loading}
                        value={field.value}
                        onIonChange={(e) => {
                          setValue(
                            'organizationTypeId',
                            e.detail.value || undefined
                          );
                        }}
                        errorMessage={errors.organizationTypeId?.message}
                      />
                    );
                  }}
                />
              </IonCol>
              <IonCol size="12">
                <FormInput
                  label="Organization Name"
                  onIonChange={(e) => {
                    setValue('name', e.detail.value);
                  }}
                  {...register('name')}
                  errorMessage={errors.name?.message}
                  disabled={loading}
                  placeholder="Acme Co."
                />
              </IonCol>
            </IonRow>
            {organizationTypeId && organizationTypeId === 2 ? (
              <>
                <IonRow>
                  <IonCol>
                    <Divider text="Additional Supplier fields" />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12">
                    <FormInput
                      label="Supplier Number"
                      onIonChange={(e) => {
                        setValue('number', e.detail.value);
                      }}
                      {...register('number')}
                      errorMessage={errors.number?.message}
                      disabled={loading}
                    />
                  </IonCol>
                  <IonCol size="12">
                    <FormInput
                      label="Supplier logo"
                      onIonChange={(e) => {
                        setValue('logo', e.detail.value);
                      }}
                      {...register('logo')}
                      errorMessage={errors.logo?.message}
                      disabled={loading}
                      placeholder="assets/suppliers/name-logo.png"
                      helperText={`URL or relative path. Be sure to change the file extension from ".png" if it's different`}
                    />
                  </IonCol>
                  <IonCol size="12">
                    <Controller
                      name="featured"
                      control={control}
                      render={({ field }) => {
                        return (
                          <IonItem lines="none" className="no-padding">
                            <IonLabel position="stacked">Featured</IonLabel>
                            <IonToggle
                              checked={field.value}
                              onIonChange={(e: any) => {
                                setValue('featured', e.detail.checked);
                              }}
                              disabled={loading}
                            />
                            {errors.featured && (
                              <FormError>{errors.featured?.message}</FormError>
                            )}
                          </IonItem>
                        );
                      }}
                    />
                  </IonCol>
                </IonRow>
              </>
            ) : null}
          </IonList>
        </form>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton color="medium" onClick={onDismiss}>
              Cancel
            </IonButton>
            <IonButton
              disabled={loading}
              form={formId}
              type="submit"
              fill="solid"
            >
              {'Create'}
              {loading && (
                <span className="margin-left-sm">
                  <LoadingInline color="light" />
                </span>
              )}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};
