import {
  IonButton,
  IonCard,
  IonCardHeader,
  IonCol,
  IonIcon,
  IonRouterLink,
  IonRow,
  IonSearchbar,
  IonSkeletonText,
} from '@ionic/react';
import { ModalSupplierAdd } from 'src/components/buyer/ModalSupplierAdd';
import { Breadcrumb, Breadcrumbs } from 'src/components/shared/Breadcrumbs';
import { LogoSupplier } from 'src/components/shared/LogoSupplier';
import { Page } from 'src/layouts/Page';
import { Supplier } from 'src/interfaces/Supplier';
import { addOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';

import { useUtils } from 'src/hooks/useUtils';
import { Account } from 'src/interfaces/Account';
import { AppUserSelf } from 'src/interfaces/AppUserSelf';
import { useSelector } from 'react-redux';
import { SupplierTypeName } from 'src/types/SupplierTypeName';

export const SuppliersPage: React.FC = () => {
  const utils = useUtils();
  const authUser: AppUserSelf | null = useSelector(
    (state: any) => state.auth.user
  );
  const allSuppliers: Supplier[] | null = useSelector(
    (state: any) => state.app.allSuppliers
  );
  const accounts: Account[] | null = useSelector(
    (state: any) => state.app.accounts
  );
  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Suppliers' },
  ];

  const [supplierModalOpen, setSupplierModalOpen] = useState(false);
  const [mySuppliers, setMySuppliers] = useState<Supplier[]>([]);
  const [suppliersFiltered, setSuppliersFiltered] = useState<Supplier[]>([]);

  const [filterType, setFilterType] = useState<SupplierTypeName>('All');
  const [search, setSearch] = useState<string | null>(null);

  useEffect(() => {
    if (allSuppliers && accounts) {
      setMySuppliers(
        allSuppliers.filter((supplier) =>
          accounts.find(
            (account) => account.supplier_id === supplier.supplier_id
          )
        )
      );
    }
  }, [allSuppliers, accounts]);

  useEffect(() => {
    if (mySuppliers.length === 0) {
      setSuppliersFiltered([]);
    } else {
      const filtered = mySuppliers.filter((supplier) =>
        supplier.name.toLowerCase().includes(search?.toLowerCase() || '')
      );
      setSuppliersFiltered(filtered);
    }
  }, [mySuppliers, filterType, search]);

  const onCloseSupplierModal = (data?: Supplier) => {
    setSupplierModalOpen(false);
  };

  const SupplierCol: React.FC<{ supplier?: Supplier }> = ({ supplier }) => {
    return (
      <IonCol size="12" sizeMd="6" sizeXl="4">
        <IonCard
          button
          routerLink={
            supplier ? `/suppliers/${supplier.supplier_id}` : undefined
          }
        >
          <IonCardHeader className="ion-text-center">
            {(supplier && <LogoSupplier src={supplier.logo} />) || (
              <IonSkeletonText
                animated={true}
                className="supplierLogo"
              ></IonSkeletonText>
            )}
            <div className="ion-text-center">
              {(supplier && (
                <IonRouterLink className="font-size-large">
                  {supplier?.name}
                </IonRouterLink>
              )) || (
                <IonSkeletonText
                  animated={true}
                  style={{
                    height: 'var(--font-size-large)',
                    width: '100px',
                    margin: '0 auto',
                  }}
                />
              )}
            </div>
          </IonCardHeader>
        </IonCard>
      </IonCol>
    );
  };

  const AddSupplierButton: React.FC = () => {
    return (
      <IonButton size="small" onClick={() => setSupplierModalOpen(true)}>
        Add Supplier
        <IonIcon icon={addOutline} slot="end"></IonIcon>
      </IonButton>
    );
  };

  return (
    <Page title="Suppliers" className="suppliers">
      <Breadcrumbs breadcrumbs={breadcrumbs}>
        {authUser && !utils.isAnalyst(authUser) ? <AddSupplierButton /> : null}
        <ModalSupplierAdd
          isOpen={supplierModalOpen}
          onClose={(data?: any) => onCloseSupplierModal(data)}
          onDismiss={(data?: any) => onCloseSupplierModal(data)}
        />
      </Breadcrumbs>
      {mySuppliers?.length > 0 ? (
        <>
          <IonRow className="ion-justify-content-center">
            <IonCol size="12" sizeMd="6" sizeXl="4">
              <IonSearchbar
                value={search}
                onIonChange={(e: any) => setSearch(e.detail.value as string)}
                className="ion-text-left ion-no-padding"
                placeholder="Search"
              />
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            {suppliersFiltered.length > 0 ? (
              <>
                {suppliersFiltered.map((supplier) => {
                  return (
                    <SupplierCol
                      supplier={supplier}
                      key={supplier.supplier_id}
                    />
                  );
                })}
              </>
            ) : (
              <div className="ion-text-center">
                <p className="ion-text-center font-style-italic">
                  Nothing to display
                </p>
              </div>
            )}
          </IonRow>
        </>
      ) : (
        <div className="ion-text-center">
          <p className="ion-text-center">
            No supplier accounts found for this location.
          </p>
          <AddSupplierButton />
        </div>
      )}
    </Page>
  );
};
