import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonModal,
  IonTitle,
  IonToolbar,
  IonicSlides,
  useIonAlert,
} from '@ionic/react';
import { useApi } from 'src/hooks/useApi';
import { Account } from 'src/interfaces/Account';
import { Location } from 'src/interfaces/Location';
import { Supplier } from 'src/interfaces/Supplier';
import { chevronBackOutline, closeOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './ModalManageAccounts.css';

import { FormManageAccounts } from 'src/components/shared/FormManageAccounts';
import { FormManageAccountsEdit } from 'src/components/shared/FormManageAccountsEdit';
import { useUtils } from 'src/hooks/useUtils';
import { setToast } from 'src/redux/appReducer';
import { Swiper as SwiperInterface } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

export const ModalManageAccounts: React.FC<{
  isOpen: boolean;
  onDismiss: any;
  location?: Location;
  supplier: Supplier;
}> = ({ isOpen, onDismiss, location, supplier }) => {
  const api = useApi();
  const utils = useUtils();
  const dispatch = useDispatch();

  const [presentAlert] = useIonAlert();

  const accounts: Account[] | null = useSelector(
    (state: any) => state.app.accounts
  );

  const [swiperInstance, setSwiperInstance] = useState<SwiperInterface>();

  const [loading, setLoading] = useState(false);
  const [existingAccounts, setExistingAccounts] = useState<Account[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [newAccountIds, setNewAccountIds] = useState<number[]>([]);
  const [submitTrigger, setSubmitTrigger] = useState<number>(0);

  const [searched, setSearched] = useState(false);

  useEffect(() => {
    if (isOpen && location) {
      getAccounts();
    }
  }, [isOpen]);

  useEffect(() => {
    slideTo(0);
  }, [accounts]);

  useEffect(() => {
    if (selectedAccount) {
      slideTo(1);
    } else {
      slideTo(0);
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (activeIndex === 0) {
      resetModal();
    }
  }, [activeIndex]);

  const slideTo = (index: number) => {
    if (swiperInstance) {
      swiperInstance.slideTo(index);
    }
  };
  const slideNext = () => {
    swiperInstance?.slideNext();
  };
  const slidePrev = () => {
    swiperInstance?.slidePrev();
  };
  const onActiveIndexChange = (e?: any) => {
    const index = swiperInstance?.activeIndex;
    setActiveIndex(index || 0);
  };

  const getAccounts = () => {
    if (!location) {
      return;
    }
    setLoading(true);
    api
      .get(
        `location/${location?.location_id}/accounts?supplier_id=${supplier.supplier_id}`
      )
      .then((response) => {
        if (response.status === 200) {
          setExistingAccounts(response.data || []);
        }
        setLoading(false);
      });
  };

  const resetModal = () => {
    setSelectedAccount(null);
    setSubmitTrigger(0);
  };

  const onClickRemove = () => {
    if (!selectedAccount) return;
    presentAlert({
      header: 'Remove Account?',
      message: selectedAccount.description,
      subHeader: utils.formatAccount(selectedAccount),
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Remove',
          role: 'confirm',
        },
      ],
      onDidDismiss: (e: any) => {
        if (e.detail.role === 'confirm') {
          removeAccount();
        }
      },
    });
  };

  const removeAccount = () => {
    if (!selectedAccount || !location) return;
    const supplierAccounts = accounts?.filter((account: Account) => {
      return account.supplier_id === supplier.supplier_id;
    });
    const index = supplierAccounts?.findIndex(
      (account: Account) => account.account_id === selectedAccount?.account_id
    );
    if (index !== undefined && index > -1) {
      supplierAccounts?.splice(index, 1);
    }
    const formData = {
      supplier_id: supplier.supplier_id,
      account_ids: supplierAccounts?.map(
        (account: Account) => account.account_id
      ),
    };
    setLoading(true);
    api
      .put(`location/${location.location_id}/account_map_location`, formData)
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            setToast({
              message: 'Account removed successfully',
            })
          );
          getAccounts();
          slideTo(0);
        }
        setLoading(false);
      });
  };

  const onAccountUpdated = () => {
    getAccounts();
    setSelectedAccount(null);
    slideTo(0);
  };

  return (
    <IonModal
      isOpen={isOpen}
      onIonModalWillDismiss={onDismiss}
      className="accountsModal"
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {activeIndex > 0 && (
              <IonButton onClick={slidePrev} fill="clear">
                <IonIcon icon={chevronBackOutline} slot="start"></IonIcon>
                Back
              </IonButton>
            )}
          </IonButtons>
          <IonTitle>Manage Accounts for {location?.name}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onDismiss}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-no-padding">
        <Swiper
          onSwiper={(swiper) => setSwiperInstance(swiper)}
          modules={[IonicSlides]}
          initialSlide={0}
          speed={400}
          allowTouchMove={false}
          onActiveIndexChange={() => onActiveIndexChange()}
        >
          <SwiperSlide>
            {/* Edit Accounts */}
            {location ? (
              <FormManageAccounts
                loading={loading}
                setLoading={setLoading}
                supplier={supplier}
                location={location}
                newAccountIds={newAccountIds}
                setNewAccountIds={setNewAccountIds}
                existingAccounts={existingAccounts}
                editAccount={(account: Account) => {
                  setSelectedAccount(account);
                }}
                submitTrigger={submitTrigger}
                onClickAddMore={() => {
                  slideNext();
                }}
              />
            ) : null}
          </SwiperSlide>
          <SwiperSlide>
            {/* Add new accounts */}
            {location ? (
              <>
                {selectedAccount ? (
                  <FormManageAccountsEdit
                    account={selectedAccount}
                    supplier={supplier}
                    onAccountUpdated={(account: Account) => {
                      onAccountUpdated();
                    }}
                  />
                ) : (
                  <FormManageAccounts
                    loading={loading}
                    setLoading={setLoading}
                    supplier={supplier}
                    location={location}
                    newAccountIds={newAccountIds}
                    setNewAccountIds={setNewAccountIds}
                    searched={searched}
                    setSearched={setSearched}
                    submitTrigger={submitTrigger}
                  />
                )}
              </>
            ) : null}
          </SwiperSlide>
        </Swiper>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              className="ion-margin-horizontal"
              color="dark"
              onClick={onDismiss}
            >
              Cancel
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            {activeIndex > 0 && selectedAccount ? (
              <IonButton color="danger" onClick={onClickRemove}>
                Remove
              </IonButton>
            ) : null}

            {activeIndex > 0 && !selectedAccount ? (
              <IonButton
                fill="solid"
                color="primary"
                className="ion-margin-horizontal"
                disabled={loading || newAccountIds.length < 1}
                onClick={() => setSubmitTrigger(submitTrigger + 1)}
              >
                Add Account{newAccountIds.length > 1 ? 's' : ''}
              </IonButton>
            ) : null}
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};
