import { zodResolver } from '@hookform/resolvers/zod';
import { IonCol, IonList, IonRow } from '@ionic/react';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormInput } from 'src/components/shared/FormInput';
import { SelectState } from 'src/components/shared/SelectState';
import { Address } from 'src/interfaces/Address';
import { v } from 'src/utils/validators';
import { z } from 'zod';

const addressSchema = z.object({
  addressName: v.addressName(),
  address1: v.address1(),
  address2: v.address2(),
  city: v.city(),
  state: v.state(),
  postalCode: v.zip(),
});

export type AddressSchema = z.infer<typeof addressSchema>;

export const FormAddress: React.FC<{
  formId: string;
  address?: Address;
  onSubmit: (location: AddressSchema) => void;
  loading: boolean;
  setChanged?: (changed: boolean) => void;
  revertToggle?: boolean;
}> = ({ formId, address, onSubmit, loading, setChanged, revertToggle }) => {
  const {
    handleSubmit,
    control,
    setValue,
    register,
    watch,
    formState: { errors },
  } = useForm<AddressSchema>({
    resolver: zodResolver(addressSchema),
    defaultValues: {
      addressName: address?.name || '',
      address1: address?.address1 || '',
      address2: address?.address2 || '',
      city: address?.city || '',
      state: address?.state || '',
      postalCode: address?.postal_code,
    },
  });
  const addressName = watch('addressName');
  const address1 = watch('address1');
  const address2 = watch('address2');
  const city = watch('city');
  const state = watch('state');
  const postalCode = watch('postalCode');

  const changed =
    addressName !== (address?.name || '') ||
    address1 !== (address?.address1 || '') ||
    address2 !== (address?.address2 || undefined) ||
    city !== (address?.city || '') ||
    state !== (address?.state || '') ||
    postalCode !== (address?.postal_code || '');

  /**
   * When the address changes, populate the form with the address's data
   */
  useEffect(() => {
    if (address) {
      populateData();
    }
  }, [address]);

  /**
   * When "revertToggle" changes AND the component has already been populated with data,
   * revert the form to the original data
   */
  useEffect(() => {
    if (changed) {
      populateData();
    }
  }, [revertToggle]);

  /**
   * Tell the parent component that the data has changed
   */
  useEffect(() => {
    if (setChanged) {
      setChanged(changed);
    }
  }, [changed]);

  const populateData = () => {
    if (address) {
      setValue('addressName', address.name);
      setValue('address1', address.address1);
      setValue('address2', address.address2 || undefined);
      setValue('city', address.city);
      setValue('state', address.state);
      setValue('postalCode', address.postal_code);
    }
  };

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <IonList lines="none">
        <IonRow>
          <IonCol size="12" sizeLg="8" sizeXl="6">
            <FormInput
              label="Address Name"
              onIonChange={(e) => {
                setValue('addressName', e.detail.value);
              }}
              {...register('addressName')}
              errorMessage={errors.addressName?.message}
            />
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol>
            <FormInput
              label="Street Address"
              onIonChange={(e) => {
                setValue('address1', e.detail.value);
              }}
              {...register('address1')}
              errorMessage={errors.address1?.message}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <FormInput
              label="Suite, Unit, etc. (optional)"
              onIonChange={(e) => {
                setValue('address2', e.detail.value);
              }}
              {...register('address2')}
              errorMessage={errors.address2?.message}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <FormInput
              label="City"
              onIonChange={(e) => {
                setValue('city', e.detail.value);
              }}
              {...register('city')}
              errorMessage={errors.city?.message}
            />
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol size="12" sizeMd="6">
            <Controller
              name="state"
              control={control}
              render={({ field }) => {
                return (
                  <SelectState
                    disabled={loading}
                    errorMessage={errors.state?.message}
                    rounded={true}
                    value={field.value}
                    onIonChange={(e) => {
                      setValue('state', e.detail.value);
                    }}
                  />
                );
              }}
            />
          </IonCol>
          <IonCol size="12" sizeMd="6">
            <FormInput
              {...register('postalCode')}
              label="Zip"
              disabled={loading}
              onIonChange={(e) => {
                setValue('postalCode', e.detail.value);
              }}
              errorMessage={errors.postalCode?.message}
            />
          </IonCol>
        </IonRow>
      </IonList>
    </form>
  );
};
