import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Supplier } from 'src/interfaces/Supplier';
import { SupplierSettings } from 'src/interfaces/SupplierSettings';
import { SupplierSettingsTerms } from 'src/interfaces/SupplierSettingsTerms';

interface InitialState {
  supplier: Supplier | null;
  supplierLoading: boolean;
  settings: SupplierSettings | null;
  settingsLoading: boolean;
  settingApplicationEnabled: boolean;
  settingApplicationTerms: SupplierSettingsTerms[];
  settingAccountSyncEnabled: boolean;
  settingCatalogSyncEnabled: boolean;
}

const initialState: InitialState = {
  supplier: null,
  supplierLoading: true,
  settings: null,
  settingsLoading: true,
  settingApplicationEnabled: false,
  settingApplicationTerms: [],
  settingAccountSyncEnabled: true,
  settingCatalogSyncEnabled: false,
};

/**
 * supplier slice intended for data related to the supplier portal.
 */
const supplierSlice = createSlice({
  name: 'supplier',
  initialState: initialState,
  reducers: {
    setSupplier: (state, action: PayloadAction<Supplier | null>) => {
      state.supplier = action.payload;
    },
    setSupplierLoading: (state, action: PayloadAction<boolean>) => {
      state.supplierLoading = action.payload;
    },
    setSupplierSettings: (
      state,
      action: PayloadAction<SupplierSettings | null>
    ) => {
      state.settings = action.payload;
    },
    setSupplierSettingsLoading: (state, action: PayloadAction<boolean>) => {
      state.settingsLoading = action.payload;
    },
    setSettingApplicationEnabled: (state, action: PayloadAction<boolean>) => {
      state.settingApplicationEnabled = action.payload;
    },
    setSettingApplicationTerms: (
      state,
      action: PayloadAction<SupplierSettingsTerms[]>
    ) => {
      state.settingApplicationTerms = action.payload;
    },
    setSettingAccountSyncEnabled: (state, action: PayloadAction<boolean>) => {
      state.settingAccountSyncEnabled = action.payload;
    },
    setSettingCatalogSyncEnabled: (state, action: PayloadAction<boolean>) => {
      state.settingCatalogSyncEnabled = action.payload;
    },
  },
});

export const supplierReducer = supplierSlice.reducer;
export const {
  setSupplier,
  setSupplierLoading,
  setSupplierSettings,
  setSupplierSettingsLoading,
  setSettingApplicationEnabled,
  setSettingApplicationTerms,
  setSettingAccountSyncEnabled,
  setSettingCatalogSyncEnabled,
} = supplierSlice.actions;
