import { IonIcon } from '@ionic/react';
import { useUtils } from 'src/hooks/useUtils';
import { informationCircleOutline } from 'ionicons/icons';
import { useId } from 'react';

export const StockNumber: React.FC<{
  quantity: string | number;
  updated: string;
}> = ({ quantity, updated }) => {
  const id = useId();
  const { formatDateTime } = useUtils();

  return (
    <span className="d-inline-flex ion-align-items-center">
      <span style={{ marginRight: '2px' }}>Inv:</span>
      <span className="font-weight-600 text-color-black">{quantity}</span>
      <IonIcon
        id={id}
        size="small"
        icon={informationCircleOutline}
        color="primary"
        style={{ marginLeft: '2px' }}
        data-tooltip-id="global-tooltip"
        data-tooltip-html={`
          <p class="ion-no-margin">Last Updated:</p>
          <p class="ion-no-margin">${formatDateTime(updated)}</p>
        `}
        data-tooltip-place="bottom"
      />
    </span>
  );
};
