import { useIonViewWillEnter } from '@ionic/react';
import { CardStat } from 'src/components/shared/CardStat';
import { useApi } from 'src/hooks/useApi';
import { useUtils } from 'src/hooks/useUtils';
import { Location } from 'src/interfaces/Location';
import { Supplier } from 'src/interfaces/Supplier';
import { cashOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const CardStatSpend: React.FC<{
  label?: string;
  minDate: string | null;
  supplier?: Supplier;
}> = ({ label, minDate, supplier }) => {
  const api = useApi();
  const utils = useUtils();
  const selectedLocation: Location | null = useSelector(
    (state: any) => state.app.selectedLocation
  );
  const [loading, setLoading] = useState(true);
  const [spendAmount, setSpendAmount] = useState<number | null>(null);

  useEffect(() => {
    getSpendAmount();
  }, []);

  useIonViewWillEnter(() => {
    getSpendAmount();
  }, []);

  useEffect(() => {
    getSpendAmount();
  }, [minDate, selectedLocation]);

  const getSpendAmount = () => {
    if (!selectedLocation || !minDate) {
      return;
    }
    setLoading(true);

    api
      .get(`location/${selectedLocation.location_id}/stats/spend`, {
        supplier_id: supplier ? supplier.supplier_id : undefined,
        min_date: minDate || undefined,
      })
      .then((response) => {
        if (response.status === 200) {
          const data = response.data;
          if (data.spend !== undefined && typeof data.spend === 'number') {
            setSpendAmount(data.spend);
          } else {
            setSpendAmount(null);
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <CardStat
      color="success"
      title={supplier ? `${supplier.name} Spend` : 'Spend'}
      stat={utils.toUsd(spendAmount || 0, 0)}
      statTitle={label}
      icon={cashOutline}
      loading={loading}
    />
  );
};
