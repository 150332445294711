import { ERROR_MESSAGE } from "src/constants"
import { setToast } from "src/redux/appReducer"

export const getErrorMessage = new Map([
    ["400", "Bad Request: Invalid parameters provided!"],
    ["401", "Unauthorized: Invalid credentials or token!"],
    ["403_get", "You lack permission to access that resource."],
    ["403_post", "You lack permission to perform that action."],
    ["404", "Resource not found"],
    ["408", "Request Timeout: The server timed out while retrieving details!"],
    ["500", "The server encountered an error. Please try again!"],
    ["502", "Service temporarily unavailable. Please try again later!"],
    ["503", "Service temporarily unavailable. Please try again later!"],
    ["504", "Service temporarily unavailable. Please try again later!"],
])

export const getToastErrorMessage = (error: string) => {
return(
    setToast({
        header: 'Error:',
        message: getErrorMessage.get(error) || ERROR_MESSAGE,
        color: 'danger',
        duration: 3000,
      })
)
}