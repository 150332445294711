import { faHospital } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRadio,
  IonRadioGroup,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { Toast } from 'src/components/shared/ToastManager';
import { useApi } from 'src/hooks/useApi';
import { AppUserMapLocation } from 'src/interfaces/AppUserMapLocation';
import { AppUserSelf } from 'src/interfaces/AppUserSelf';
import { Location } from 'src/interfaces/Location';
import {
  checkmarkCircleOutline,
  closeCircleOutline,
  closeOutline,
} from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLocation, setToast } from 'src/redux/appReducer';

export const ModalChangeLocation: React.FC<{
  onDismiss: (data?: string | null | undefined | number, role?: string) => void;
}> = ({ onDismiss }) => {
  const api = useApi();
  const authUser: AppUserSelf | null = useSelector(
    (state: any) => state.auth.user
  );
  const selectedLocation: Location | null = useSelector(
    (state: any) => state.app.selectedLocation
  );
  const dispatch = useDispatch();

  const [search, setSearch] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [userMapLocations, setUserMapLocations] = useState<
    AppUserMapLocation[]
  >([]);
  const [userLocationsFiltered, setUserLocationsFiltered] = useState<
    AppUserMapLocation[]
  >([]);
  const [selectedLocationId, setSelectedLocationId] = useState<number>();

  useEffect(() => {
    getUserLocations();
  }, []);

  useEffect(() => {
    filterLocations();
  }, [search, userMapLocations, selectedLocation]);

  useEffect(() => {
    if (selectedLocationId) {
      getLocation();
    }
  }, [selectedLocationId]);

  const filterLocations = () => {
    setUserLocationsFiltered(
      userMapLocations.filter(
        (userMapLocation: AppUserMapLocation) =>
          // Filter out the currently selected location
          userMapLocation.location_id !== selectedLocation?.location_id &&
          // Filter by search query
          userMapLocation.location_name
            .toLowerCase()
            .includes(search.toLowerCase())
      )
    );
  };

  const getUserLocations = () => {
    if (!authUser) {
      return;
    }
    setLoading(true);
    api
      .get(`/app_user/${authUser.app_user_id}/app_user_map_locations`)
      .then(({ data }) => {
        setUserMapLocations(data || []);
        setUserLocationsFiltered(data || []);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getLocation = () => {
    setLoading(true);
    api
      .get(`/location/${selectedLocationId}`)
      .then(({ data }) => {
        if (data.location_id !== undefined) {
          dispatch(setLocation(data));
          dispatch(
            setToast({
              header: 'Success!',
              message: `Switched to ${data.name}`,
              icon: checkmarkCircleOutline,
            })
          );
          onDismiss();
        } else {
          showToast('There was a problem changing locations', 'danger');
          dispatch(
            setToast({
              message: `There was a problem changing locations`,
              icon: closeCircleOutline,
              color: 'danger',
            })
          );
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const showToast = (
    message: string,
    color: 'success' | 'danger',
    header?: string | undefined
  ) => {
    const toast: Toast = {
      header,
      message,
      color: color,
    };
    dispatch(setToast(toast));
  };
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Change Location</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => onDismiss()}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <div>
            <IonSearchbar
              value={search}
              onIonChange={(e: any) => setSearch(e.detail.value as string)}
              placeholder="Search for a Location"
            />
            <div className="ion-padding-horizontal ion-margin-bottom d-flex ion-align-items-end ion-justify-content-between">
              <p className="ion-no-margin ion-margin-end">
                <FontAwesomeIcon
                  icon={faHospital}
                  className="margin-right-sm"
                />
                {selectedLocation?.name}
              </p>
              <span className="font-style-italic">currently selected</span>
            </div>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {userLocationsFiltered.length > 0 ? (
          <IonList>
            <IonRadioGroup
              value={selectedLocationId}
              onIonChange={(e: any) => setSelectedLocationId(e.detail.value)}
            >
              {userLocationsFiltered.map((userMapLocation) => (
                <IonItem key={userMapLocation.location_id} disabled={loading}>
                  <IonLabel>{userMapLocation.location_name}</IonLabel>
                  <IonRadio
                    slot="end"
                    value={userMapLocation.location_id}
                    defaultChecked={true}
                  />
                </IonItem>
              ))}
            </IonRadioGroup>
          </IonList>
        ) : (
          <div className="ion-padding ion-text-center">
            <p className="font-style-italic">No locations found</p>
          </div>
        )}
      </IonContent>
    </>
  );
};
