import {
  IonButton,
  IonButtons,
  IonCol,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import { DataTableDesktop } from 'src/components/shared/DataTable/DataTableDesktop';
import { DataTableMobile } from 'src/components/shared/DataTable/DataTableMobile';
import { useCurrentScreenWidth } from 'src/hooks/useCurrentScreenWidth';
import { chevronBackOutline, chevronForwardOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import './DataTable.css';
import { DataTableHeader } from './interfaces/DataTableHeader';
import { DataTableItemAction } from './interfaces/DataTableItemAction';
import { DataTablePagination } from './interfaces/DataTablePagination';
import { DataTableRow } from './interfaces/DataTableRow';

export const DataTable: React.FC<{
  children?: any;
  headers: DataTableHeader[];
  rows: DataTableRow[];
  onClickRow?: any;
  mobileBreakPoint?: number;
  actions?: (item: any) => DataTableItemAction[];
  search?: boolean;
  loading?: boolean;
  title?: string;
  subtitle?: any;
  onPaginationChange?: (pagination: DataTablePagination) => void;
  totalCount?: number;
  itemsPerPage?: number;
  itemsPerPageOptions?: number[];
  orderBy?: string | null;
  orderByDesc?: boolean;
  setOrderBy?: (value: string | null) => void;
  setOrderByDesc?: (value: boolean) => void;
  hidePagination?: boolean;
}> = ({
  children,
  headers,
  rows,
  onClickRow,
  mobileBreakPoint,
  actions,
  search = true,
  loading,
  title,
  subtitle,
  onPaginationChange,
  totalCount,
  itemsPerPageOptions = [10, 15, 25, 50],
  itemsPerPage = itemsPerPageOptions[0],
  orderBy,
  orderByDesc,
  setOrderBy,
  setOrderByDesc,
  hidePagination,
}) => {
  const width = useCurrentScreenWidth();

  const [query, setQuery] = useState('');
  const [rowsFiltered, setRowsFiltered] = useState<DataTableRow[]>();

  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(itemsPerPage);

  useEffect(() => {
    updatePagination();
  }, [page, orderBy, orderByDesc]);

  useEffect(() => {
    setPage(1);
    updatePagination();
  }, [limit]);

  useEffect(() => {
    filterRows();
  }, [rows, query]);

  const updatePagination = () => {
    const offset = limit * page - limit;
    const pagination: DataTablePagination = {
      page,
      limit,
      offset,
    };
    if (onPaginationChange) {
      onPaginationChange(pagination);
    }
  };

  const onClickNext = () => {
    if (!pageNextDisabled()) {
      setPage(page + 1);
    }
  };

  const onClickPrev = () => {
    if (page < 2) {
      setPage(1);
    } else {
      setPage(page - 1);
    }
  };

  const pageNextDisabled = (): boolean => {
    if (totalCount) {
      return totalCount <= limit * page;
    }
    return true;
  };

  const filterRows = () => {
    if (search && query) {
      const filtered = rows.filter((row) => {
        for (let key in row.item) {
          if (
            row.item[key] !== undefined &&
            row.item[key] !== null &&
            row.item[key].toString().toLowerCase().includes(query.toLowerCase())
          ) {
            return true;
          }
        }
      });
      setRowsFiltered(filtered);
    } else {
      setRowsFiltered(rows);
    }
  };

  const calculateTitleWidth = () => {
    if (children && search) {
      return '4';
    } else if ((children && !search) || (!children && search)) {
      return '6';
    }
    return '12';
  };

  const calculateChildrenWidth = () => {
    if (title && search) {
      return '4';
    } else if ((title && !search) || (!title && search)) {
      return '6';
    }
    return '12';
  };

  const calculateSearchWidth = () => {
    if (title && children) {
      return '4';
    } else if ((title && !children) || (!title && children)) {
      return '6';
    }
    return '6';
  };

  return (
    <div className="dataTable">
      {(title || children || search) && (
        <IonRow className="ion-align-items-center tableControls">
          {title && (
            <IonCol
              className="ion-no-padding"
              size="12"
              sizeMd="6"
              sizeLg={calculateTitleWidth()}
            >
              <h2 className="tableTitle">{title}</h2>
              {subtitle && <p className="tableSubtitle">{subtitle}</p>}
            </IonCol>
          )}
          {children && (
            <IonCol
              size="12"
              sizeMd="6"
              sizeLg={calculateChildrenWidth()}
              className="ion-text-right"
            >
              {children}
            </IonCol>
          )}

          {search && (
            <IonCol
              size="12"
              sizeMd="6"
              sizeLg={calculateSearchWidth()}
              offset={!title && !children ? '6' : ''}
            >
              <IonSearchbar
                value={query}
                onIonChange={(e) => setQuery(e.detail.value as string)}
                disabled={loading}
              />
            </IonCol>
          )}
        </IonRow>
      )}
      {width >= (mobileBreakPoint || 768) && (
        <DataTableDesktop
          headers={headers}
          rows={rowsFiltered ? rowsFiltered : rows}
          onClickRow={onClickRow}
          actions={actions}
          loading={loading}
          orderBy={orderBy}
          orderByDesc={orderByDesc}
          setOrderBy={setOrderBy}
          setOrderByDesc={setOrderByDesc}
        />
      )}
      {width < (mobileBreakPoint || 768) && (
        <DataTableMobile
          headers={headers}
          rows={rowsFiltered ? rowsFiltered : rows}
          onClickRow={onClickRow}
          actions={actions}
          loading={loading}
        />
      )}
      {onPaginationChange && !hidePagination ? (
        <IonRow className="dataTableFooter ion-padding-horizontal padding-top-xs padding-bottom-xs">
          <IonCol
            size="12"
            className="d-flex flex-wrap ion-align-items-center ion-justify-content-end"
          >
            <div className="d-inline-flex flex-wrap ion-align-items-center ion-justify-content-end">
              <IonItem lines="none">
                <IonLabel>Items Per Page</IonLabel>
                <IonSelect
                  interface="popover"
                  value={limit}
                  name="limit"
                  onIonChange={(e: any) => setLimit(e.detail.value as number)}
                  className="text-color-black"
                  disabled={loading}
                >
                  {itemsPerPageOptions.map((value: number) => (
                    <IonSelectOption key={value} value={value}>
                      {value}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
            </div>
            <IonButtons className="ion-justify-content-end ion-margin-start">
              {totalCount !== undefined ? (
                <span className="ion-margin-horizontal">
                  Total Count:{' '}
                  <strong className="text-color-black">{totalCount}</strong>
                </span>
              ) : null}
              <IonButton
                color="primary"
                onClick={onClickPrev}
                disabled={page < 2 || loading}
              >
                <IonIcon icon={chevronBackOutline}></IonIcon>
              </IonButton>
              <span className="text-color-black">{page}</span>
              <IonButton
                color="primary"
                onClick={onClickNext}
                disabled={pageNextDisabled() || loading}
              >
                <IonIcon icon={chevronForwardOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonCol>
        </IonRow>
      ) : null}
    </div>
  );
};
