import { IonSpinner } from '@ionic/react';
import { useEffect, useState } from 'react';
import { Subtitle } from 'src/components/shared/Subtitle';
import { useApi } from 'src/hooks/useApi';
import { Address } from 'src/interfaces/Address';
import { Location } from 'src/interfaces/Location';

export const LocationCol: React.FC<{ location: Location }> = ({ location }) => {
  const api = useApi();
  const [address, setAddress] = useState<Address | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (location) {
      getAddress();
    } else {
      setAddress(null);
    }
  }, [location]);

  const getAddress = () => {
    setLoading(true);
    api
      .get(`address/${location.address_id}`)
      .then((res) => {
        setAddress(res.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <p>{location.name}</p>
      {loading || !address ? (
        <IonSpinner name="crescent" color="primary" />
      ) : (
        <>
          <Subtitle>
            {address.address1}{' '}
            {address.address2 ? ` ${address.address2}` : null}
          </Subtitle>
          <Subtitle>
            {address.city} {address.state}, {address.postal_code},{' '}
            {address.country}
          </Subtitle>
        </>
      )}
    </>
  );
};
