import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonIcon,
  IonRow,
  IonicSlides,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useUtils } from 'src/hooks/useUtils';
import { Supplier } from 'src/interfaces/Supplier';
import { Swiper as SwiperInterface } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { LogoSupplier } from '../LogoSupplier';

import '@ionic/react/css/ionic-swiper.css';
import { chevronBackOutline } from 'ionicons/icons';
import { ListSupplierContacts } from 'src/components/buyer/ListSupplierContacts';
import 'swiper/css';

export const ViewSupplierSupport: React.FC<{ search: string | null }> = ({
  search,
}) => {
  const utils = useUtils();
  const allSuppliers: Supplier[] | null = useSelector(
    (state: any) => state.app.allSuppliers
  );
  const [suppliersFiltered, setSuppliersFiltered] = useState<Supplier[]>([]);
  const [selectedSupplier, setSelectedSupplier] = useState<Supplier | null>(
    null
  );
  const [swiperInstance, setSwiperInstance] = useState<SwiperInterface>();

  useEffect(() => {
    filterSuppliers();
  }, [allSuppliers, search]);

  useEffect(() => {
    if (selectedSupplier) {
      slideTo(1);
    } else {
      slideTo(0);
    }
  }, [selectedSupplier]);

  const filterSuppliers = () => {
    if (!allSuppliers) return;
    const standard = allSuppliers.filter((supplier) =>
      supplier.name.toLowerCase().includes(search?.toLowerCase() || '')
    );
    setSuppliersFiltered(standard);
  };

  const slideTo = (index: number, speed?: number) => {
    if (swiperInstance) {
      swiperInstance.slideTo(index, speed);
    }
  };

  const onActiveIndexChange = (e?: any) => {
    if (swiperInstance) {
      const index = swiperInstance.activeIndex;
    }
  };

  return (
    <>
      <Swiper
        onSwiper={(swiper) => setSwiperInstance(swiper)}
        modules={[IonicSlides]}
        initialSlide={0}
        speed={400}
        allowTouchMove={false}
        onActiveIndexChange={() => onActiveIndexChange()}
      >
        {/* Slide 1 */}
        <SwiperSlide>
          <IonRow className="ion-padding-horizontal">
            <IonCol size="12">
              <h2 className="font-size-large text-color-black ion-text-center">
                Select a Supplier to view contact info
              </h2>
            </IonCol>
            <IonCol
              size="12"
              className="ion-no-padding ion-no-margin d-flex flex-wrap ion-justify-content-center"
            >
              {suppliersFiltered.map((supplier) => {
                return (
                  <IonCol
                    key={supplier.name}
                    size="12"
                    sizeSm="6"
                    sizeMd="4"
                    className="ion-text-center ion-no-padding padding-xs"
                  >
                    <IonCard
                      key={supplier.supplier_id}
                      button
                      onClick={() => setSelectedSupplier(supplier as Supplier)}
                    >
                      <IonCardContent className="ion-text-center">
                        <LogoSupplier src={supplier.logo} />
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                );
              })}
              {suppliersFiltered.length === 0 && (
                <>
                  <p className="font-style-italic">
                    No suppliers match your search
                  </p>
                </>
              )}
            </IonCol>
          </IonRow>
        </SwiperSlide>

        {/* Slide 2 */}
        <SwiperSlide>
          <IonRow className="fullWidth ion-justify-content-start ion-align-items-center ion-text-left ion-padding-horizontal">
            <IonCol size="6">
              <IonButton
                onClick={() => setSelectedSupplier(null)}
                fill="outline"
                size="small"
              >
                <IonIcon slot="start" icon={chevronBackOutline} />
                All Suppliers
              </IonButton>
            </IonCol>
            <IonCol size="6" className="ion-text-right">
              <LogoSupplier src={selectedSupplier?.logo} />
            </IonCol>
          </IonRow>
          <IonRow className="fullWidth ion-justify-content-start ion-text-left">
            <IonCol size="12" className="ion-no-padding">
              {selectedSupplier && (
                <ListSupplierContacts supplier={selectedSupplier} />
              )}
            </IonCol>
          </IonRow>
        </SwiperSlide>
      </Swiper>
    </>
  );
};
