import { IonAvatar } from '@ionic/react';
import './UserAvatar.css';
import { useUtils } from 'src/hooks/useUtils';
import { AppUser } from 'src/interfaces/AppUser';
import { AppUserSelf } from 'src/interfaces/AppUserSelf';

export const UserAvatar: React.FC<{
  user: AppUser | AppUserSelf;
}> = ({ user }) => {
  const utils = useUtils();
  return (
    <IonAvatar className="userAvatar">
      <span>
        {utils.getUserInitials(user.first_name, user.last_name || '')}
      </span>
    </IonAvatar>
  );
};
