import { SupplierConfig } from 'src/config/supplierConfigurations';

export const SandozConfig: SupplierConfig = {
  number: '005387188', // Sandoz
  account: {
    accountKey: 'ship_to',
    accountLabel: 'Ship To Number',
    accountHelperText: 'Enter the Ship To Number, provided by Sandoz',
    accountRules: [
      {
        pattern: /.*/,
        message: '',
      },
    ],
    validationKey: 'zip',
    validationLabel: 'Zip Code',
    validationHelperText:
      'Enter the zip code associated with your Ship To Number',
    validationRules: [
      {
        pattern: /.*/,
        message: '',
      },
    ],
    transform: (value: string): string => {
      const contractNumber = value.split('-')[2];
      if (contractNumber !== undefined) {
        return contractNumber;
      }
      // if transform results in undefined, just return the initial value
      return value;
    },
  },
};
