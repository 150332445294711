import { IonButton, IonChip, IonIcon } from '@ionic/react';
import { Subtitle } from 'src/components/shared/Subtitle';
import { useUtils } from 'src/hooks/useUtils';
import { attachOutline, closeOutline } from 'ionicons/icons';
import { useEffect, useId, useState } from 'react';
import './FileInputLegacy.css';

export const FileInputLegacy: React.FC<{
  file: File | null | undefined;
  setFile: (file: File | undefined) => void;
  setBase64: (base64Content: string | null) => void;
  buttonText?: string;
  accept?: string;
  placeholder?: string;
  showThumbnail?: boolean;
  showFilesize?: boolean;
  onChange?: (file: File | null) => void;
  rounded?: boolean;
  disabled?: boolean;
  errorMessage?: string;
}> = ({
  file,
  setFile,
  setBase64,
  buttonText,
  accept,
  placeholder = 'Choose File',
  showThumbnail,
  showFilesize = true,
  onChange,
  rounded = false,
  disabled = false,
  errorMessage,
}) => {
  const id = useId();
  const { formatBytes } = useUtils();
  const [fileName, setFileName] = useState<string | null>(null);
  const [fileSize, setFileSize] = useState<number | null>(null);
  const [base64Content, setBase64Content] = useState<string | null>(null);
  const [fileType, setFileType] = useState<string | null>(null);

  const isImage = fileType?.includes('image');

  useEffect(() => {
    if (file) {
      setFileName(file.name);
      setFileSize(file.size);
    } else {
      setBase64Content(null);
      setFileName(null);
      setFileSize(null);
    }
  }, [file]);

  useEffect(() => {
    if (base64Content) {
      const type = base64Content.split(';')[0].split(':')[1];
      setFileType(type);
    } else {
      setFileType(null);
    }
    setBase64(base64Content);
  }, [base64Content]);

  const onFileChange = (fileChangeEvent: any) => {
    const file = fileChangeEvent.target.files[0];
    if (file) {
      setFile(file);
      const reader = new FileReader();
      reader.onload = (e: any) => {
        setBase64Content(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      setFile(undefined);
    }
    if (onChange) {
      onChange(file || null);
    }
  };

  const selectFile = () => {
    document.getElementById(id)?.click();
  };

  return (
    <div
      className={`fileInput fullWidth d-flex ion-align-items-center ${
        showThumbnail && isImage ? 'thumbnail-visible' : ''
      } ${rounded ? 'rounded' : ''}`}
    >
      <IonButton
        onClick={selectFile}
        size="small"
        className="ion-no-margin"
        disabled={disabled}
      >
        {buttonText ? buttonText : <IonIcon icon={attachOutline} />}
      </IonButton>

      <div className="d-inline-flex ion-justify-content-between ion-align-items-center fullWidth ellipsis">
        <div className="labelContainer">
          {file ? (
            <>
              <Subtitle className="font-style-italic ellipsis ion-no-margin">
                {fileName}
              </Subtitle>

              {showFilesize ? (
                <IonChip
                  className="font-size-xs ion-no-padding padding-left-xs padding-right-xs ion-no-margin"
                  style={{
                    cursor: 'default',
                    userSelect: 'none',
                    height: '20px',
                  }}
                >
                  <strong>{fileSize && formatBytes(fileSize, 1)}</strong>
                </IonChip>
              ) : null}
            </>
          ) : (
            <Subtitle className="font-style-italic ion-no-margin">
              {placeholder}
            </Subtitle>
          )}
        </div>
        {file ? (
          <div className="d-flex ion-align-items-center ion-justify-content-end">
            {showThumbnail && isImage ? (
              <img
                src={base64Content!}
                alt="uploadThumbnail"
                className="thumbnail"
              />
            ) : null}
            <IonButton
              className="ion-no-margin ion-no-padding margin-left-sm"
              color="danger"
              fill="clear"
              size="small"
              onClick={() => setFile(undefined)}
              disabled={disabled}
            >
              <IonIcon icon={closeOutline} slot="icon-only" />
            </IonButton>
          </div>
        ) : null}
      </div>

      <input
        id={id}
        type="file"
        onChange={onFileChange}
        style={{ display: 'none' }}
        aria-hidden="true"
        accept={accept}
      />
    </div>
  );
};
