import { zodResolver } from '@hookform/resolvers/zod';
import {
  IonButton,
  IonCard,
  IonCol,
  IonIcon,
  IonRow,
  IonText,
} from '@ionic/react';
import { ShipToSchema } from 'src/features/accountApplication/components/sandoz/FormManageShipTo';
import { ModalShipTo } from 'src/features/accountApplication/components/sandoz/ModalShipTo';
import { FormError } from 'src/components/shared/FormError';
import { addOutline, closeOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { DataTableHeader } from 'src/components/shared/DataTable/interfaces/DataTableHeader';
import { DataTableItemAction } from 'src/components/shared/DataTable/interfaces/DataTableItemAction';
import { DataTable } from 'src/components/shared/DataTable';
import { DataTableRow } from 'src/components/shared/DataTable/interfaces/DataTableRow';

export interface ShipToFormData extends ShipToSchema {
  location_id: number;
  location_name: string;
}

const applicationStep3Schema = z.object({
  shipToCount: z
    .number()
    .min(1, { message: 'You must add at least 1 Ship To location' }),
});
export type ApplicationStep3Schema = z.infer<typeof applicationStep3Schema>;

export const ApplicationStep3: React.FC<{
  formId: string;
  loading?: boolean;
  setIsValid: (isValid: boolean) => void;
  setIsSubmitted: (isSubmitted: boolean) => void;

  onSubmitted: (data: ShipToFormData[]) => void;
}> = ({ formId, loading, setIsValid, setIsSubmitted, onSubmitted }) => {
  const {
    handleSubmit,
    control,
    setValue,
    register,
    getValues,
    watch,
    formState: { errors, isSubmitted, isValid },
  } = useForm<ApplicationStep3Schema>({
    resolver: zodResolver(applicationStep3Schema),
    defaultValues: {
      shipToCount: 0,
    },
  });

  useEffect(() => {
    setIsValid(isValid);
  }, [isValid]);

  useEffect(() => {
    setIsSubmitted(isSubmitted);
  }, [isSubmitted]);

  // STEP 3 (Ship To)
  const [shipToLocations, setShipToLocations] = useState<ShipToFormData[]>([]);
  const [selectedShipTo, setSelectedShipTo] = useState<ShipToFormData | null>(
    null
  );
  const [shipToOpen, setShipToOpen] = useState(false);

  useEffect(() => {
    setValue('shipToCount', shipToLocations.length);
  }, [shipToLocations]);

  const headers: DataTableHeader[] = [
    { text: 'Location Name', key: 'name' },
    { text: 'Address', key: 'address' },
    { text: 'Licensing', key: 'licensing' },
    { text: 'GCP', key: 'gcp' },
    { text: 'GLN', key: 'gln' },
    { text: 'HIN', key: 'hin' },
  ];

  const actions = (shipTo: ShipToFormData): DataTableItemAction[] => {
    if (!loading) {
      return [
        {
          label: 'Remove',
          icon: <IonIcon icon={closeOutline} />,
          color: 'danger',
          callback: (shipTo: ShipToFormData) => {
            const newShipToLocations = [...shipToLocations];
            const index = newShipToLocations.findIndex(
              (s) => s.location_id === shipTo.location_id
            );
            newShipToLocations.splice(index, 1);
            setShipToLocations(newShipToLocations);
          },
        },
      ];
    }
    return [];
  };

  const onClickAddShipTo = () => {
    setShipToOpen(true);
  };

  const onSubmit: (data: ApplicationStep3Schema) => void = (data) => {
    setIsValid(true);
    onSubmitted(shipToLocations);
  };

  return (
    <>
      <form id={formId} onSubmit={handleSubmit(onSubmit)}>
        <IonRow>
          <IonCol size="12">
            <IonCard>
              <DataTable
                title="Ship To Locations"
                subtitle="You may add one or more Ship To Locations"
                headers={headers}
                actions={actions}
                search={false}
                onClickRow={(row: DataTableRow) => {
                  if (loading) {
                    console.log(
                      'attempted to click row while form was loading'
                    );
                  } else {
                    setSelectedShipTo(row.item as ShipToFormData);
                    setShipToOpen(true);
                  }
                }}
                rows={shipToLocations.map(
                  (shipTo: ShipToFormData): DataTableRow => {
                    const row: DataTableRow = {
                      item: shipTo,
                      key: shipTo.location_id,
                      columns: [
                        {
                          header: 'name',
                          content: (
                            <>
                              <IonText className="font-size-large">
                                {shipTo.location_name}
                              </IonText>
                            </>
                          ),
                        },
                        {
                          header: 'address',
                          content: (
                            <>
                              <p>
                                {shipTo.address1}
                                {shipTo.address2 && (
                                  <span>,&nbsp;{shipTo.address2}</span>
                                )}
                              </p>
                              <p>
                                {shipTo.city} {shipTo.state}, {shipTo.zip}
                              </p>
                            </>
                          ),
                        },
                        {
                          header: 'licensing',
                          content: (
                            <>
                              <p>DEA#: {shipTo.deaLicenseNum}</p>
                              <p>State#: {shipTo.stateLicenseNum}</p>
                            </>
                          ),
                        },
                        {
                          header: 'gcp',
                          content: <p>{shipTo.gcp}</p>,
                        },
                        {
                          header: 'gln',
                          content: <p>{shipTo.gln}</p>,
                        },
                        {
                          header: 'hin',
                          content: <p>{shipTo.hin}</p>,
                        },
                      ],
                    };
                    return row;
                  }
                )}
              >
                <div className="fullWidth d-flex ion-justify-content-end">
                  <input type="hidden" {...register('shipToCount')} />
                  {errors.shipToCount && (
                    <FormError className="font-size-small ion-margin-end">
                      {errors.shipToCount.message}
                    </FormError>
                  )}
                  <IonButton
                    color="primary"
                    size="small"
                    onClick={onClickAddShipTo}
                    className="ion-no-margin"
                    disabled={loading}
                  >
                    Add Ship To
                    <IonIcon slot="end" icon={addOutline} />
                  </IonButton>
                </div>
              </DataTable>
            </IonCard>
          </IonCol>
        </IonRow>
      </form>

      <ModalShipTo
        shipTo={selectedShipTo || undefined}
        isOpen={shipToOpen}
        onDismiss={(shipTo?: ShipToFormData) => {
          setShipToOpen(false);
          setSelectedShipTo(null);
          if (shipTo) {
            const foundIndex = shipToLocations.findIndex(
              (stLocation) => stLocation.location_id === shipTo.location_id
            );
            if (foundIndex > -1) {
              const newShipToLocations = [...shipToLocations];
              newShipToLocations[foundIndex] = shipTo;
              setShipToLocations(newShipToLocations);
            } else {
              setShipToLocations([...shipToLocations, shipTo]);
            }
          }
        }}
      />
    </>
  );
};
