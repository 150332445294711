import { z, ZodOptional, ZodString } from 'zod';

export const v = {
  // AppUser.first_name
  firstName: (name: string = 'First Name', required: boolean = true) => {
    return z
      .string()
      .min(1, { message: `${name} is required` })
      .max(25, { message: `${name} is too long` });
  },
  // AppUser.last_name
  lastName: (name: string = 'Last Name', required: boolean = true) => {
    return z
      .string()
      .min(1, { message: `${name} is required` })
      .max(25, { message: `${name} is too long` });
  },
  // AppUser.email_address
  email: (name: string = 'Email', required: boolean = true) => {
    return z
      .string()
      .min(1, { message: `${name} is required` })
      .email({
        message: 'Must be a valid email',
      });
  },
  // 10-11 digit phone number, optionally required.
  phone: (name: string = 'Phone', required: boolean = true) => {
    let validator: ZodString | ZodOptional<ZodString> = z
      .string()
      .regex(/^(\d{10}|\d{11})?$/, {
        message: 'Please enter a valid 10 or 11-digit phone number',
      });
    if (required) {
      validator = validator.min(1, { message: `${name} is required` });
    } else {
      validator = validator.optional();
    }
    return validator;
  },
  // Organization.name
  organizationName: (
    name: string = 'Organization Name',
    required: boolean = true
  ) => {
    return z
      .string()
      .min(1, { message: `${name} is required` })
      .max(50, { message: `${name} must be 50 characters or less` });
  },
  // Location.name
  locationName: (name: string = 'Location Name', required: boolean = true) => {
    return z
      .string()
      .min(1, { message: `${name} is required` })
      .max(100, { message: 'Field is too long' });
  },
  // Address.name
  addressName: (name: string = 'Address Name', required: boolean = true) => {
    let validator: ZodString | ZodOptional<ZodString> = z
      .string()
      .max(100, { message: `${name} must be 100 characters or less` });
    if (required) {
      validator = validator.min(1, { message: `${name} is required` });
    } else {
      validator = validator.optional();
    }
    return validator;
  },
  // Address.address1
  address1: (name: string = 'Street Address', required: boolean = true) => {
    let validator: ZodString | ZodOptional<ZodString> = z
      .string()
      .max(100, { message: `${name} must be 100 characters or less` });
    if (required) {
      validator = validator.min(1, { message: `${name} is required` });
    } else {
      validator = validator.optional();
    }
    return validator;
  },
  // Address.address2
  address2: (name: string = 'Suite, Unit, etc.', required: boolean = false) => {
    let validator: ZodString | ZodOptional<ZodString> = z
      .string()
      .max(100, { message: `${name} must be 100 characters or less` });
    if (required) {
      validator = validator.min(1, { message: `${name} is required` });
    } else {
      validator = validator.optional();
    }
    return validator;
  },
  // Address.city
  city: (name: string = 'City', required: boolean = true) => {
    let validator: ZodString | ZodOptional<ZodString> = z
      .string()
      .max(50, { message: `${name} must be 50 characters or less` });
    if (required) {
      validator = validator.min(1, { message: `${name} is required` });
    } else {
      validator = validator.optional();
    }
    return validator;
  },
  // Address.state
  state: (name: string = 'State', required: boolean = true) => {
    let validator: ZodString | ZodOptional<ZodString> = z.string();
    if (required) {
      validator = validator.min(1, { message: `${name} is required` });
    } else {
      validator = validator.optional();
    }
    return validator;
  },
  // Address.postal_code
  zip: (name: string = 'Zip', required: boolean = true) => {
    let validator: ZodString | ZodOptional<ZodString> = z
      .string()
      .max(30, { message: 'Field is too long' })
      .regex(/^([0-9]{5}(?:-[0-9]{4})?)?$/, {
        message: 'Please enter a valid zip code',
      });
    if (required) {
      validator = validator.min(1, { message: `${name} is required` });
    } else {
      validator = validator.optional();
    }
    return validator;
  },
  /**
   * Validation matches normal DEA number, ex. AA1234567 (2 alpha, followed by 7 numeric)
   * and "dummy" trulla DEA number, ex. TRU123456 (TRU followed by 6 numeric)
   * @param name
   * @param required
   * @returns
   */
  deaLicenseNum: (name: string = 'DEA Number', required: boolean = true) => {
    return z
      .string()
      .min(1, { message: `${name} is required` })
      .regex(/^(?:[\w]{2}[\d]{7})|(?:TRU[\d]{6})$/, {
        message: 'Please enter a valid DEA Number',
      });
  },
  deaLicenseFile: (name: string = 'DEA License', required: boolean = false) => {
    return z
      .instanceof(File)
      .refine((file) => file.size < 5000000, {
        message: `${name} file must be less than 5MB`,
      })
      .optional();
  },
  deaLicenseFileBase64: (
    name: string = 'DEA License',
    required: boolean = true
  ) => {
    return z.string().min(1, { message: `${name} file is required` });
  },
  stateLicenseNum: (
    name: string = 'State License number',
    required: boolean = true
  ) => {
    return z
      .string()
      .min(1, { message: `${name} is required` })
      .max(255, { message: `${name} is too long` });
  },
  stateLicenseFile: (
    name: string = 'State License',
    required: boolean = false
  ) => {
    return z
      .instanceof(File)
      .refine((file) => file.size < 5000000, {
        message: `${name} file must be less than 5MB`,
      })
      .optional();
  },
  stateLicenseFileBase64: (
    name: string = 'State License',
    required: boolean = true
  ) => {
    return z.string().min(1, { message: `${name} file is required` });
  },
  // Global Locator Number
  gln: (name: string = 'GLN', required: boolean = true) => {
    let validator: ZodString | ZodOptional<ZodString> = z
      .string()
      .regex(/^([\d]{13})?$/, {
        message: 'Please enter a valid GLN (13 digit number)',
      });
    if (required) {
      validator = validator.min(1, { message: `${name} is required` });
    } else {
      validator = validator.optional();
    }
    return validator;
  },
  // Global Company Prefix
  gcp: (name: string = 'GCP', required: boolean = false) => {
    return z
      .string()
      .regex(/^([\d]{4,12})?$/, {
        message: 'Please enter a valid GCP (4-12 digit number)',
      })
      .optional();
  },
  // Hospital Identification Number
  hin: (name: string = 'HIN', required: boolean = false) => {
    return z
      .string()
      .regex(/^([\d\w]{9})?$/, {
        message: 'Please enter a valid HIN (9 characters, alphanumeric)',
      })
      .optional();
  },
  date: (name: string = 'Date', required: boolean = true) => {
    return z
      .string()
      .min(1, { message: `${name} is required` })
      .regex(
        /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/,
        {
          message: 'Please enter a valid Date in the "yyyy-mm-dd" format',
        }
      );
  },
};
