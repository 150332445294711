import { AuthRoute } from 'src/routes/AuthRoute';
import { GuestRoute } from 'src/routes/GuestRoute';
import { OrgBuyerRoute } from 'src/routes/OrgBuyerRoute';
import { OrgSupplierRoute } from 'src/routes/OrgSupplierRoute';
import { RoleAdminRoute } from 'src/routes/RoleAdminRoute';
import { RoleBuyerRoute } from 'src/routes/RoleBuyerRoute';
import { RoleSuperRoute } from 'src/routes/RoleSuperRoute';

import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { IonRouterOutlet } from '@ionic/react';

import { AppUserSelf } from 'src/interfaces/AppUserSelf';
import { Organization } from 'src/interfaces/Organization';

import { AccountApplicationPage } from 'src/pages/buyer/AccountApplicationPage';
import { AnalyticsPage } from 'src/pages/buyer/AnalyticsPage';
import { CartPage } from 'src/pages/buyer/CartPage';
import { HomePage as BuyerHomePage } from 'src/pages/buyer/HomePage';
import { ItemsPage } from 'src/pages/buyer/ItemsPage';
import { LocationAddPage } from 'src/pages/buyer/LocationAddPage';
import { LocationPage } from 'src/pages/buyer/LocationPage';
import { LocationUsersPage } from 'src/pages/buyer/LocationUsersPage';
import { LocationsPage } from 'src/pages/buyer/LocationsPage';
import { OrderPage } from 'src/pages/buyer/OrderPage';
import { OrdersPage } from 'src/pages/buyer/OrdersPage';
import { SettingSupplierPage } from 'src/pages/buyer/SettingSupplierPage';
import { SettingSuppliersPage } from 'src/pages/buyer/SettingSuppliersPage';
import { SettingsPage } from 'src/pages/buyer/SettingsPage';
import { SupplierPage } from 'src/pages/buyer/SupplierPage';
import { SuppliersPage } from 'src/pages/buyer/SuppliersPage';
import { LandingPage } from 'src/pages/shared/LandingPage';
import { LoadingPage } from 'src/pages/shared/LoadingPage';
import { NoUserPage } from 'src/pages/shared/NoUserPage';
import { NotFoundPage } from 'src/pages/shared/NotFoundPage';
import { RegisterPage } from 'src/pages/shared/RegisterPage';
import { RequestDemoPage } from 'src/pages/shared/RequestDemoPage';
import { UserAddPage } from 'src/pages/shared/UserAddPage';
import { UserPage } from 'src/pages/shared/UserPage';
import { UserProfilePage } from 'src/pages/shared/UserProfilePage';
import { UsersPage } from 'src/pages/shared/UsersPage';

import { AccountApplicationPage as SupplierAccountApplicationPage } from 'src/pages/supplier/AccountApplicationPage';
import { AccountApplicationsPage as SupplierAccountApplicationsPage } from 'src/pages/supplier/AccountApplicationsPage';
import { AccountsPage as SupplierAccountsPage } from 'src/pages/supplier/AccountsPage';
import { CatalogsPage as SupplierCatalogsPage } from 'src/pages/supplier/CatalogsPage';
import { DealsPage as SupplierDealsPage } from 'src/pages/supplier/DealsPage';
import { HomePage as SupplierHomePage } from 'src/pages/supplier/HomePage';
import { SalesPage as SupplierSalesPage } from 'src/pages/supplier/SalesPage';
import { SettingsPage as SupplierSettingsPage } from 'src/pages/supplier/SettingsPage';
import { TermPage as SupplierTermPage } from 'src/pages/supplier/TermPage';
import { TermsPage as SupplierTermsPage } from 'src/pages/supplier/TermsPage';

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Redirect, Route } from 'react-router-dom';
import { useUtils } from 'src/hooks/useUtils';

import { OnboardRequestPage as SuperOnboardRequestPage } from 'src/pages/superadmin/OnboardRequestPage';
import { OnboardRequestsPage as SuperOnboardRequestsPage } from 'src/pages/superadmin/OnboardRequestsPage';
import { OrganizationsPage as SuperOrganizationsPage } from 'src/pages/superadmin/OrganizationsPage';
import { OrganizationPage as SuperOrganizationPage } from 'src/pages/superadmin/OrganizationPage';
import { LocationsPage as SuperLocationsPage } from 'src/pages/superadmin/LocationsPage';
import { LocationPage as SuperLocationPage } from 'src/pages/superadmin/LocationPage';
import { OrdersPage as SuperOrdersPage } from 'src/pages/superadmin/OrdersPage';
import { OrderPage as SuperOrderPage } from 'src/pages/superadmin/OrderPage';
import { UsersPage as SuperUsersPage } from 'src/pages/superadmin/UsersPage';
import { UserPage as SuperUserPage } from 'src/pages/superadmin/UserPage';

export const Routes: React.FC = () => {
  const utils = useUtils();
  const isAuthenticated = useIsAuthenticated();
  const { inProgress, accounts } = useMsal();

  const authUser: AppUserSelf | null = useSelector(
    (state: any) => state.auth.user
  );
  const userLoading: boolean = useSelector(
    (state: any) => state.auth.userLoading
  );
  const organization: Organization | null = useSelector(
    (state: any) => state.app.organization
  );
  const location = useLocation();

  useEffect(() => {
    if (window.InlineManual !== undefined) {
      window.InlineManual('update');
    }
  }, [location.pathname]);

  return (
    <IonRouterOutlet animated={true}>
      <Route path="/home">
        <Redirect to="/" />
      </Route>
      {/* Home route */}
      <Route
        path="/"
        exact={true}
        render={(props) => {
          if (
            isAuthenticated &&
            authUser &&
            utils.isSupplierOrganization(organization)
          ) {
            return <SupplierHomePage />;
          } else if (
            isAuthenticated &&
            authUser &&
            utils.isBuyerOrganization(organization)
          ) {
            return <BuyerHomePage />;
          } else if (isAuthenticated && !userLoading && !authUser) {
            return <NoUserPage />;
          } else if (
            !isAuthenticated &&
            inProgress !== InteractionStatus.Startup &&
            inProgress !== InteractionStatus.HandleRedirect &&
            accounts.length === 0
          ) {
            return <LandingPage />;
          }
          return <LoadingPage />;
        }}
      />

      <GuestRoute path="/login" component={LoadingPage} exact={true} />
      <GuestRoute path="/register" component={RegisterPage} exact={true} />

      <GuestRoute
        path="/request-demo"
        component={RequestDemoPage}
        exact={true}
      />
      <AuthRoute path="/profile" component={UserProfilePage} exact={true} />

      {/* BEGIN buyer routes */}
      <OrgBuyerRoute path="/analytics" component={AnalyticsPage} exact={true} />
      <OrgBuyerRoute path="/orders" component={OrdersPage} exact={true} />
      <RoleBuyerRoute path="/orders/cart" component={CartPage} exact={true} />
      <OrgBuyerRoute
        path="/orders/:id([0-9]+)"
        component={OrderPage}
        exact={true}
      />
      <OrgBuyerRoute path="/items" component={ItemsPage} exact={true} />
      <OrgBuyerRoute path="/suppliers" component={SuppliersPage} exact={true} />
      <OrgBuyerRoute
        path="/suppliers/:id([0-9]+)"
        component={SupplierPage}
        exact={true}
      />
      <OrgBuyerRoute
        path="/suppliers/:id([0-9]+)/apply"
        component={AccountApplicationPage}
        exact={true}
      />
      <RoleAdminRoute path="/settings" component={SettingsPage} exact={true} />
      <RoleAdminRoute
        path="/settings/locations"
        component={LocationsPage}
        exact={true}
      />
      <RoleAdminRoute
        path="/settings/locations/add"
        component={LocationAddPage}
        exact={true}
      />
      <RoleAdminRoute
        path="/settings/locations/:id([0-9]+)"
        component={LocationPage}
        exact={true}
      />
      <RoleAdminRoute
        path="/settings/locations/:id([0-9]+)/users"
        component={LocationUsersPage}
        exact={true}
      />
      <RoleAdminRoute
        path="/settings/suppliers"
        component={SettingSuppliersPage}
        exact={true}
      />
      <RoleAdminRoute
        path="/settings/suppliers/:id([0-9]+)"
        component={SettingSupplierPage}
        exact={true}
      />
      <RoleAdminRoute
        path="/settings/users"
        component={UsersPage}
        exact={true}
      />
      <RoleAdminRoute
        path="/settings/users/add"
        component={UserAddPage}
        exact={true}
      />
      <RoleAdminRoute
        path="/settings/users/:id([0-9]+)"
        component={UserPage}
        exact={true}
      />
      {/* END buyer routes */}

      {/* BEGIN supplier routes */}
      <OrgSupplierRoute
        path="/supplier/sales"
        component={SupplierSalesPage}
        exact={true}
      />
      <OrgSupplierRoute
        path="/supplier/accounts"
        component={SupplierAccountsPage}
        exact={true}
      />
      <OrgSupplierRoute
        path="/supplier/accounts/applications"
        component={SupplierAccountApplicationsPage}
        exact={true}
      />
      <OrgSupplierRoute
        path="/supplier/accounts/applications/:id([0-9]+)"
        component={SupplierAccountApplicationPage}
        exact={true}
      />
      <OrgSupplierRoute
        path="/supplier/catalogs"
        component={SupplierCatalogsPage}
        exact={true}
      />
      <OrgSupplierRoute
        path="/supplier/deals"
        component={SupplierDealsPage}
        exact={true}
      />
      <OrgSupplierRoute
        path="/supplier/settings"
        component={SupplierSettingsPage}
        exact={true}
      />
      <OrgSupplierRoute
        path="/supplier/settings/terms"
        component={SupplierTermsPage}
        exact={true}
      />
      <OrgSupplierRoute
        path="/supplier/settings/terms/:id([0-9]+)"
        component={SupplierTermPage}
        exact={true}
      />
      <OrgSupplierRoute
        path="/supplier/settings/users"
        component={UsersPage} // same page as the buyer one.
        exact={true}
      />
      <OrgSupplierRoute
        path="/supplier/settings/users/add"
        component={UserAddPage} // same page as the buyer one.
        exact={true}
      />
      <OrgSupplierRoute
        path="/supplier/settings/users/:id([0-9]+)"
        component={UserPage} // same page as the buyer one.
        exact={true}
      />
      {/* END supplier routes */}

      {/* BEGIN super routes */}
      <RoleSuperRoute
        path="/admin/requests"
        component={SuperOnboardRequestsPage}
        exact={true}
      />
      <RoleSuperRoute
        path="/admin/requests/:id([0-9]+)"
        component={SuperOnboardRequestPage}
        exact={true}
      />
      <RoleSuperRoute
        path="/admin/organizations"
        component={SuperOrganizationsPage}
        exact={true}
      />
      <RoleSuperRoute
        path="/admin/organizations/:organization_id([0-9]+)"
        component={SuperOrganizationPage}
        exact={true}
      />
      <RoleSuperRoute
        path="/admin/organizations/:organization_id([0-9]+)/locations"
        component={SuperLocationsPage}
        exact={true}
      />
      <RoleSuperRoute
        path="/admin/organizations/:organization_id([0-9]+)/locations/:location_id([0-9]+)"
        component={SuperLocationPage}
        exact={true}
      />
      <RoleSuperRoute
        path="/admin/organizations/:organization_id([0-9]+)/orders"
        component={SuperOrdersPage}
        exact={true}
      />
      <RoleSuperRoute
        path="/admin/organizations/:organization_id([0-9]+)/orders/:order_id([0-9]+)"
        component={SuperOrderPage}
        exact={true}
      />
      <RoleSuperRoute
        path="/admin/organizations/:organization_id([0-9]+)/users"
        component={SuperUsersPage}
        exact={true}
      />
      <RoleSuperRoute
        path="/admin/organizations/:organization_id([0-9]+)/users/:app_user_id([0-9]+)"
        component={SuperUserPage}
        exact={true}
      />

      {/* END super routes */}

      {/* Catch all route */}
      <Route
        render={(props) => {
          if (!isAuthenticated && accounts.length === 0) {
            return <Redirect to="/" />;
          }
          return <NotFoundPage />;
        }}
      ></Route>
    </IonRouterOutlet>
  );
};
