import {
  IonButton,
  IonIcon,
  IonRouterLink,
  useIonViewWillEnter,
} from '@ionic/react';
import { ModalSettingSupplier } from 'src/components/buyer/ModalSettingSupplier';
import { DataTable } from 'src/components/shared/DataTable';
import { DataTableHeader } from 'src/components/shared/DataTable/interfaces/DataTableHeader';
import { DataTableItemAction } from 'src/components/shared/DataTable/interfaces/DataTableItemAction';
import { DataTablePagination } from 'src/components/shared/DataTable/interfaces/DataTablePagination';
import { DataTableRow } from 'src/components/shared/DataTable/interfaces/DataTableRow';
import { LogoSupplier } from 'src/components/shared/LogoSupplier';
import { useApi } from 'src/hooks/useApi';
import { Organization } from 'src/interfaces/Organization';
import { Supplier } from 'src/interfaces/Supplier';
import { pencilOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const TableSuppliers: React.FC = () => {
  const api = useApi();
  const organization: Organization | null = useSelector(
    (state: any) => state.app.organization
  );
  const [loading, setLoading] = useState(false);
  const [allSuppliers, setAllSuppliers] = useState<Supplier[]>([]);
  const [pagination, setPagination] = useState<DataTablePagination | null>(
    null
  );
  const [orderBy, setOrderBy] = useState<string | null>('name');
  const [orderByDesc, setOrderByDesc] = useState<boolean>(true);
  const [totalCount, setTotalCount] = useState<number>(0);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState<Supplier>();
  const headers: DataTableHeader[] = [
    { text: 'Name', key: 'logo', width: '1%' },
    { text: '', key: 'name' },
    { text: 'Setup/Not Setup', key: 'status', align: 'center', width: '30%' },
  ];

  useIonViewWillEnter(() => {
    getAllSuppliers();
  }, []);

  useEffect(() => {
    getAllSuppliers();
  }, [organization, pagination]);

  useEffect(() => {
    if (selectedSupplier) {
      setIsOpen(true);
    }
  }, [selectedSupplier]);

  const getAllSuppliers = () => {
    if (!organization || !pagination) {
      return;
    }
    setLoading(true);
    api
      .get(`organization/${organization.organization_id}/suppliers`, {
        limit: pagination.limit,
        offset: pagination.offset,
        type: undefined,
      })
      .then((response) => {
        if (response.status === 200) {
          setAllSuppliers(response.data.result || []);
          setTotalCount(response.data.total);
        }
        setLoading(false);
      });
  };

  const actions = (supplier: Supplier): DataTableItemAction[] => {
    return [
      {
        label: 'Manage Supplier',
        icon: <IonIcon icon={pencilOutline} />,
        routerLink: (supplier: Supplier) => {
          return `/settings/suppliers/${supplier.supplier_id}`;
        },
        callback: (supplier: Supplier) => null,
      },
    ];
  };

  const onClickManageAccounts = (supplier: Supplier) => {
    setSelectedSupplier(supplier);
  };

  const onDidDismiss = (data?: any) => {
    setSelectedSupplier(undefined);
    setIsOpen(false);
  };

  const ButtonLocationStatus: React.FC<{ supplier: Supplier }> = ({
    supplier,
  }) => {
    return (
      <IonButton
        size="small"
        fill="clear"
        color="primary"
        onClick={() => onClickManageAccounts(supplier)}
      >
        {`${supplier.location_count_setup}/${supplier.location_count_total} Locations`}
      </IonButton>
    );
  };

  return (
    <>
      <DataTable
        title="Suppliers"
        headers={headers}
        onPaginationChange={(pagination: DataTablePagination) =>
          setPagination(pagination)
        }
        orderBy={orderBy}
        orderByDesc={orderByDesc}
        setOrderBy={setOrderBy}
        setOrderByDesc={setOrderByDesc}
        itemsPerPage={15}
        totalCount={totalCount}
        rows={allSuppliers.map((supplier): DataTableRow => {
          const row: DataTableRow = {
            item: supplier,
            key: supplier.supplier_id,
            columns: [
              {
                header: 'logo',
                content: (
                  <LogoSupplier
                    src={supplier.logo}
                    style={{ width: '125px' }}
                    routerLink={`/settings/suppliers/${supplier.supplier_id}`}
                    className="ion-margin-end"
                  />
                ),
              },
              {
                header: 'name',
                content: (
                  <IonRouterLink
                    className="font-size-large"
                    routerLink={`/settings/suppliers/${supplier.supplier_id}`}
                  >
                    {supplier.name}
                  </IonRouterLink>
                ),
              },
              {
                header: 'status',
                content: <ButtonLocationStatus supplier={supplier} />,
              },
            ],
          };
          return row;
        })}
        actions={actions}
        loading={loading}
      ></DataTable>

      <ModalSettingSupplier
        isOpen={isOpen}
        supplier={selectedSupplier}
        onDismiss={(data?: any) => onDidDismiss(data)}
      />
    </>
  );
};
