import { IonButton, IonInput } from '@ionic/react';
import { useApi } from 'src/hooks/useApi';
import { Account } from 'src/interfaces/Account';
import { ItemCatalog } from 'src/interfaces/ItemCatalog';
import { Location } from 'src/interfaces/Location';
import { ShoppingCart } from 'src/interfaces/ShoppingCart';
import { ItemShoppingCart } from 'src/interfaces/ItemShoppingCart';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCartItems, setCarts, setToast } from 'src/redux/appReducer';

export const ButtonAddToCart: React.FC<{
  item: ItemCatalog;
  account?: Account;
  onQuantityChange?: (quantity: number | null) => void;
}> = ({ item, account, onQuantityChange }) => {
  const api = useApi();
  const cartItems: ItemShoppingCart[] = useSelector(
    (state: any) => state.app.cartItems
  );
  const selectedLocation: Location | null = useSelector(
    (state: any) => state.app.selectedLocation
  );
  const [cartItem, setCartItem] = useState<ItemShoppingCart | null>();
  const [quantity, setQuantity] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (cartItems.length < 1 || !account) {
      setCartItem(null);
      return;
    }
    const found = cartItems.find((cartItem: ItemShoppingCart) => {
      return (
        item.supplier_item_number === cartItem.supplier_item_number &&
        account.number === cartItem.account_number
      );
    });
    if (found) {
      setCartItem(found);
    }
  }, [cartItems]);

  useEffect(() => {
    if (cartItem) {
      setQuantity(cartItem.quantity);
    }
  }, [cartItem]);

  useEffect(() => {
    if (onQuantityChange) {
      onQuantityChange(quantity);
    }
  }, [quantity]);

  const addToCart = () => {
    if (quantity === 0) {
      return;
    }
    const postData = [
      {
        location_id: selectedLocation?.location_id,
        account_id: account?.account_id,
        quantity: quantity,
        supplier_item_number: item.supplier_item_number,
      },
    ];
    api.post('shopping_cart_item', postData).then(({ data }) => {
      const shoppingCartItem = data[0];
      if (shoppingCartItem) {
        dispatch(
          setToast({
            message: `"${item.item_description}" x${quantity} added to cart!`,
          })
        );
        setQuantity(0);
      }
      getShoppingCarts();
    });
  };

  const updateItem = () => {
    if (quantity !== null && quantity < 1) {
      deleteShoppingCartItem();
    } else {
      setLoading(true);
      const putData = {
        quantity: quantity,
        supplier_item_number: cartItem?.supplier_item_number,
      };
      api
        .put(`shopping_cart_item/${cartItem?.shopping_cart_item_id}`, putData)
        .then(({ data }) => {
          getShoppingCarts();
          setLoading(false);
          dispatch(
            setToast({
              header: 'Item updated',
              message: item.item_description,
            })
          );
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  const deleteShoppingCartItem = () => {
    setLoading(true);
    api
      .delete(`shopping_cart_item/${cartItem?.shopping_cart_item_id}`)
      .then((response) => {
        if (response.status === 204) {
          getShoppingCarts();
          setCartItem(null);
          setQuantity(null);
          dispatch(
            setToast({
              header: 'Item removed from cart',
              message: item.item_description,
            })
          );
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getShoppingCarts = () => {
    api
      .get(`location/${selectedLocation?.location_id}/shopping_carts`)
      .then((response) => {
        if (response.status === 200) {
          const data: ShoppingCart[] = response.data;
          dispatch(setCarts(data));
        }
        getShoppingCartItems();
      });
  };

  const getShoppingCartItems = () => {
    api
      .get(`location/${selectedLocation?.location_id}/shopping_cart_items`)
      .then((response) => {
        if (response.status === 200) {
          const data: ItemShoppingCart[] = response.data;
          dispatch(setCartItems(data));
        }
      });
  };

  const handleFocus = (e: any) => {
    e.target.select();
  };

  const handleKeyDown = (e: any, item: ItemCatalog) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (quantity === null || loading) {
        return;
      }
      if (cartItem) {
        updateItem();
      } else {
        addToCart();
      }
    }
  };

  return (
    <div className="fullWidth d-flex ion-align-items-center ion-justify-content-end">
      <div>
        <IonInput
          type="number"
          placeholder="QTY"
          value={quantity}
          onFocus={(e: any) => handleFocus(e)}
          onKeyDown={(e: any) => handleKeyDown(e, item)}
          onIonChange={(e: any) =>
            setQuantity(+e.detail.value > -1 ? +e.detail.value : 0)
          }
          min={0}
          style={{ maxWidth: '100%', marginLeft: 'auto' }}
          className="ion-margin-horizontal rounded"
          disabled={loading}
        ></IonInput>
      </div>
      <div>
        {cartItem ? (
          <IonButton
            disabled={quantity === cartItem.quantity || loading}
            onClick={() => updateItem()}
            className="ion-margin-start"
          >
            Update Qty
          </IonButton>
        ) : (
          <IonButton
            disabled={quantity === 0 || quantity === null || loading}
            onClick={() => addToCart()}
            className="ion-margin-start"
          >
            Add To Cart
          </IonButton>
        )}
      </div>
    </div>
  );
};
