import {
  IonCol,
  IonGrid,
  IonicSlides,
  IonRow,
  useIonRouter,
} from '@ionic/react';
import { CardCentered } from './CardCentered';
import { Page } from 'src/layouts/Page';
import { useEffect, useState } from 'react';
import { Swiper as SwiperInterface } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import '@ionic/react/css/ionic-swiper.css';
import 'swiper/css';

import { useApi } from 'src/hooks/useApi';
import { useDispatch } from 'react-redux';
import { Step1Form, Step1Schema } from './Step1Form';
import { Step2Form, Step2Schema } from './Step2Form';
import { setToast } from 'src/redux/appReducer';
import axios from 'axios';
import { useLocation } from 'react-router';
import { SlideHeader } from 'src/components/shared/SlideHeader';
import { LogoTrulla } from 'src/components/shared/LogoTrulla';

export const RegisterPage: React.FC = () => {
  // TODO: look for "email" query param in url and pre-populate email field
  const api = useApi();
  const router = useIonRouter();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [swiperInstance, setSwiperInstance] = useState<SwiperInterface>();

  const [loading, setLoading] = useState(false);

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string | undefined>();

  const [organizationName, setOrganizationName] = useState<string>('');
  const [locationName, setLocationName] = useState<string>('');
  const [addressLine1, setAddressLine1] = useState<string | undefined>('');
  const [addressLine2, setAddressLine2] = useState<string | undefined>();
  const [city, setCity] = useState<string | undefined>('');
  const [state, setState] = useState<string | undefined>('');
  const [zip, setZip] = useState<string | undefined>('');
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);

  useEffect(() => {
    const email = params.get('email') || null;
    if (email) {
      setEmail(email);
    }
  }, []);

  const slidePrev = () => {
    if (swiperInstance) {
      swiperInstance.slidePrev();
    }
  };

  const slideNext = () => {
    if (swiperInstance) {
      swiperInstance.slideNext();
    }
  };

  const onActiveIndexChange = (e?: any) => {
    const index = swiperInstance?.activeIndex;
    setActiveIndex(index || 0);
  };

  // this is only called if the step1form is already valid
  const onSubmitStep1Form = (data: Step1Schema) => {
    slideNext();
  };

  // this is only called if the step2form is already valid
  const onSubmitStep2Form = (data: Step2Schema) => {
    register();
  };

  const register = () => {
    const formData = {
      firstName: firstName,
      lastName: lastName,
      userEmail: email,
      userOnboardUrl: 'https://xxxxxxxxx/api/users/onboard',
      clientId: '06bb8ed1-b036-4775-8990-f23874320628',
      sendGridData: {
        onboardEmail: {
          templateId: 'd-377832b2188248329f79cxxxxxxxxxxx',
          templateData: {
            username: 'Naman Gupta',
            applicationname: 'Trulla Direct',
            organizationname: 'Aditya Birla Corp.',
            url: 'https://urltologin.com',
            subject: 'User Onboard Request',
          },
        },
      },
      additionalProperties: {
        // user specific details
        roleId: '3',
        locationName: 'Zirakpur',
        street: 'Walking Street',
        city: 'Chandigarh',
        state: 'Punjab',
        zip: '160101',
        phone1: '7558419121',
        firstName: firstName,
        lastName: lastName,
        organizationName: 'MOJO Corporation',
      },
    };

    // const formData = {
    //   organizationName: organizationName,
    //   userFirstName: firstName,
    //   userLastName: lastName,
    //   userEmail: email,
    //   phone1: phone,
    //   phone2: null,
    //   userOnboardUrl: 'https://trulladirectdevwebapi.azurewebsites.net/', // This is the URL that should be used by the Azure fn to insert an onboardrequest into the app db
    //   locationName: locationName,
    //   street: addressLine2 ? `${addressLine1}, ${addressLine2}` : addressLine1,
    //   city: city,
    //   state: state,
    //   zip: zip,
    //   clientId: process.env.REACT_APP_MSAL_CLIENT_ID_ONBOARD_API,
    // };

    // setLoading(true);
    console.log(formData);

    // axios
    //   .post(
    //     'https://land.trulladirect.tinkeractive.com/275g3sggir7p8oqc6cla38wi3hvdgqm6',
    //     formData
    //   )
    //   // .post(
    //   //   `${process.env.REACT_APP_BASE_URL_ONBOARD_API}/onboardrequests`,
    //   //   formData
    //   // )
    //   .then((response) => {
    //     if (response.status === 201 || response.status === 200) {
    //       dispatch(
    //         setToast({
    //           header: 'Successfully submitted!',
    //           message:
    //             'Our team will review the information to approve your account. You should expect a response within 1 business day',
    //           duration: 20000,
    //           buttons: [
    //             {
    //               text: 'Ok',
    //               role: 'cancel',
    //             },
    //           ],
    //         })
    //       );
    //       router.push('/', 'back', 'pop');
    //     }
    //     setLoading(false);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     setLoading(false);
    //     dispatch(
    //       setToast({
    //         header: 'Error',
    //         color: 'danger',
    //         message:
    //           'There was an error submitting your request. Please wait a moment and try again.',
    //         duration: 3000,
    //       })
    //     );
    //   });
  };

  return (
    <Page className="guest" title="Register">
      <IonRow className="ion-margin-vertical">
        <IonCol className="ion-text-center">
          <LogoTrulla />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <CardCentered title="Create Account">
            <IonRow>
              <IonCol className="ion-margin-horizontal ion-padding-horizontal">
                <SlideHeader
                  activeIndex={activeIndex}
                  steps={[{ label: 'About You' }, { label: 'Your Facility' }]}
                />
              </IonCol>
            </IonRow>

            <Swiper
              onSwiper={(swiper) => setSwiperInstance(swiper)}
              modules={[IonicSlides]}
              initialSlide={0}
              speed={400}
              allowTouchMove={false}
              onActiveIndexChange={() => onActiveIndexChange()}
            >
              {/* SLIDE 1 */}
              <SwiperSlide>
                <IonGrid>
                  <Step1Form
                    loading={loading}
                    onSubmit={onSubmitStep1Form}
                    firstName={firstName}
                    lastName={lastName}
                    email={email}
                    phone={phone}
                    setFirstName={setFirstName}
                    setLastName={setLastName}
                    setEmail={setEmail}
                    setPhone={setPhone}
                  />
                </IonGrid>
              </SwiperSlide>

              {/* SLIDE 2 */}
              <SwiperSlide>
                {activeIndex === 1 && (
                  <IonGrid>
                    <Step2Form
                      loading={loading}
                      slidePrev={slidePrev}
                      onSubmit={onSubmitStep2Form}
                      organizationName={organizationName}
                      locationName={locationName}
                      addressLine1={addressLine1}
                      addressLine2={addressLine2}
                      city={city}
                      state={state}
                      zip={zip}
                      termsAccepted={termsAccepted}
                      setOrganizationName={setOrganizationName}
                      setLocationName={setLocationName}
                      setAddressLine1={setAddressLine1}
                      setAddressLine2={setAddressLine2}
                      setCity={setCity}
                      setState={setState}
                      setZip={setZip}
                      setTermsAccepted={setTermsAccepted}
                    />
                  </IonGrid>
                )}
              </SwiperSlide>
            </Swiper>
          </CardCentered>
        </IonCol>
      </IonRow>
    </Page>
  );
};
