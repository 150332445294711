import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import { useUtils } from 'src/hooks/useUtils';
import { useEffect, useState } from 'react';

export interface TimeFrame {
  label: string;
  value: string;
}

export const SelectTimeFrame: React.FC<{
  onUpdate: (timeFrame: TimeFrame) => void;
}> = ({ onUpdate }) => {
  const utils = useUtils();
  const [dateString, setDateString] = useState<string | null>(
    utils.subDays(30)
  );

  const timeFrameOptions = [
    {
      label: 'Last 30 Days',
      value: utils.subDays(30),
    },
    {
      label: 'Last 12 Months',
      value: utils.subMonths(12),
    },
    {
      label: 'All Time',
      value: '1970-01-01',
    },
  ];

  useEffect(() => {
    const timeFrame = timeFrameOptions.find((t) => t.value === dateString);
    if (timeFrame) {
      onUpdate(timeFrame);
    }
  }, [dateString]);

  return (
    <IonItem lines="full" color="transparent">
      <IonLabel>Time Frame</IonLabel>
      <IonSelect
        value={dateString}
        interface="popover"
        onIonChange={(e) => setDateString(e.detail.value as string)}
      >
        {timeFrameOptions.map((option) => (
          <IonSelectOption key={option.label} value={option.value}>
            {option.label}
          </IonSelectOption>
        ))}
      </IonSelect>
    </IonItem>
  );
};
