import { useAccount, useMsal } from '@azure/msal-react';
import { onboardTokenRequest } from 'src/config/authConfig';
import axios, { AxiosResponse } from 'axios';
import { MsalAccessToken } from 'src/interfaces/MsalAccessToken';
import jwtDecode from 'jwt-decode';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setAccessToken } from 'src/redux/authReducer';

export const useAzureOnboardApi = () => {
  const { instance, accounts: msalAccounts } = useMsal();
  const msalAccount = useAccount(msalAccounts[0] || {});

  const controllerRef = useRef(new AbortController());
  const dispatch = useDispatch();

  // This will return an axios response
  // OR an axios error
  const azureOnboardApi = {
    get: (url: string, params?: any) => {
      return request('get', url, null, params);
    },
    post: (url: string, body?: any) => {
      return request('post', url, body);
    },
    put: (url: string, body?: any) => {
      return request('put', url, body);
    },
    delete: (url: string, body?: any) => {
      return request('delete', url, body);
    },
  };

  const request = (
    method: 'get' | 'post' | 'put' | 'delete',
    url: string,
    body?: any,
    params?: any
  ): Promise<AxiosResponse | any> => {
    if (isExpired()) {
      return instance
        .acquireTokenSilent({
          scopes: onboardTokenRequest.scopes,
          account: msalAccount || undefined,
        })
        .then((msalResponse) => {
          const token = msalResponse.accessToken;
          localStorage.setItem('td-onboard-token', token);
          const decoded = jwtDecode(token);
          dispatch(setAccessToken(decoded as MsalAccessToken));
          return apiRequest(method, url, token, body, params);
        })
        .catch((msalError) => {
          // in case if silent token acquisition fails, fallback to an interactive method
        });
    } else {
      const token = localStorage.getItem('td-onboard-token');
      if (token) {
        const decoded = jwtDecode(token);
        dispatch(setAccessToken(decoded as MsalAccessToken));
      }
      return apiRequest(method, url, token, body, params);
    }
  };

  const apiRequest = (
    method: 'get' | 'post' | 'put' | 'delete',
    url: string,
    token: string | null,
    body?: any,
    params?: any
  ) => {
    return axios.request({
      data: body,
      signal: controllerRef.current.signal,
      baseURL: process.env.REACT_APP_BASE_URL_ONBOARD_API,
      method,
      url,
      params: params,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const isExpired = (): boolean => {
    const cachedToken = localStorage.getItem('td-onboard-token');
    if (!cachedToken) {
      return true;
    }
    const decoded: any = jwtDecode(cachedToken);
    const now = new Date();
    const expiresAt = new Date((decoded.exp - 60) * 1000); // subtract 1 minute, and convert to milliseconds
    return now >= expiresAt;
  };

  return azureOnboardApi;
};
