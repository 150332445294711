import {
  IonButton,
  IonCol,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
  IonRouterLink,
  IonRow,
  IonSearchbar,
  IonText,
  IonToggle,
  useIonAlert,
  useIonViewWillEnter,
} from '@ionic/react';
import {
  checkmarkCircleOutline,
  closeOutline,
  pencilOutline,
  refreshOutline,
} from 'ionicons/icons';
import { ButtonFilter } from 'src/components/shared/ButtonFilter';

import { useEffect, useId, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from 'src/components/shared/DataTable';
import { DataTableHeader } from 'src/components/shared/DataTable/interfaces/DataTableHeader';
import { DataTableItemAction } from 'src/components/shared/DataTable/interfaces/DataTableItemAction';
import { DataTablePagination } from 'src/components/shared/DataTable/interfaces/DataTablePagination';
import { DataTableRow } from 'src/components/shared/DataTable/interfaces/DataTableRow';
import { SelectRole } from 'src/components/shared/SelectRole';
import { useApi } from 'src/hooks/useApi';
import { useEndpoints } from 'src/hooks/useEndpoints';
import { useUtils } from 'src/hooks/useUtils';
import { AppUser } from 'src/interfaces/AppUser';
import { Organization } from 'src/interfaces/Organization';
import { setToast } from 'src/redux/appReducer';
import { Supplier } from 'src/interfaces/Supplier';
import { ShowAlertOptions, useAlert } from 'src/hooks/useAlert';

export const TableUsers: React.FC = () => {
  const api = useApi();
  const { showAlert } = useAlert();
  const { createB2cUser, deleteB2cUser } = useEndpoints();
  const utils = useUtils();
  const filterButtonId = useId();
  const organization: Organization | null = useSelector(
    (state: any) => state.app.organization
  );
  const supplier: Supplier | null = useSelector(
    (state: any) => state.supplier.supplier
  );
  const dispatch = useDispatch();
  const [presentAlert] = useIonAlert();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<AppUser[]>([]);

  const [filterDeactivated, setFilterDeactivated] = useState(false);
  const [filterRoleId, setFilterRoleId] = useState<number>();

  const [search, setSearch] = useState<string | null>(null);
  const [pagination, setPagination] = useState<DataTablePagination | null>(
    null
  );
  const [totalCount, setTotalCount] = useState<number>(0);
  const [orderBy, setOrderBy] = useState<string | null>('first_name');
  const [orderByDesc, setOrderByDesc] = useState<boolean>(false);
  const headers: DataTableHeader[] = [
    { text: 'Name', key: 'first_name', sortable: true },
    { text: 'Email Address', key: 'email_address', sortable: true },
    { text: 'Admin', key: 'admin', align: 'center' },
    { text: 'Created', key: 'created', sortable: true },
    { text: 'Updated', key: 'updated', sortable: true },
  ];

  useIonViewWillEnter(() => {
    if (pagination) {
      getUsers();
    }
  }, [pagination]);

  useEffect(() => {
    if (pagination) {
      getUsers();
    }
  }, [organization, filterDeactivated, filterRoleId, pagination, search]);

  const actions = (user: AppUser): DataTableItemAction[] => {
    const actions: DataTableItemAction[] = [
      {
        label: 'Manage User',
        icon: <IonIcon icon={pencilOutline} />,
        routerLink: (user: AppUser) => {
          return `${supplier ? '/supplier' : ''}/settings/users/${
            user.app_user_id
          }`;
        },
        callback: (user: AppUser) => {
          return;
        },
      },
    ];
    if (user.active) {
      actions.push({
        label: 'Deactivate User',
        icon: <IonIcon icon={closeOutline} />,
        color: 'danger',
        callback: (user: AppUser) => {
          onClickDeactivate(user);
        },
      });
    } else {
      actions.push({
        label: 'Reactivate User',
        icon: <IonIcon icon={refreshOutline} />,
        color: 'success',
        callback: (user: AppUser) => {
          onClickReactivate(user);
        },
      });
    }
    return actions;
  };

  const clearFilters = () => {
    setFilterDeactivated(false);
    setFilterRoleId(undefined);
  };

  const getUsers = () => {
    if (!organization || !pagination) {
      return;
    }
    setLoading(true);
    api
      .get(`organization/${organization.organization_id}/app_users`, {
        limit: pagination.limit,
        offset: pagination.offset,
        order_by: orderBy || undefined,
        order_by_desc: orderByDesc,
        active: !filterDeactivated,
        app_role_id: filterRoleId || undefined,
        search: search || undefined,
      })
      .then((response) => {
        if (response.status === 200) {
          setUsers(response.data.result || []);
          setTotalCount(response.data.total);
        }
        setLoading(false);
      });
  };

  const onClickDeactivate = (user: AppUser) => {
    presentAlert({
      header: 'Deactivate the following user?',
      message: `${user.first_name} ${user.last_name}`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Deactivate',
          cssClass: 'text-color-danger',
          role: 'confirm',
        },
      ],
      onWillDismiss: (e: any) => {
        if (e.detail.role === 'confirm') {
          deactivateUser(user);
        }
      },
    });
  };

  const deactivateUser = (user: AppUser) => {
    setLoading(true);
    api.delete(`app_user/${user.app_user_id}`).then(async (response) => {
      console.log(response);
      if (response.status === 204) {
        await deleteB2cUser(
          user,
          (response: any) => {
            getUsers();
            dispatch(
              setToast({
                message: 'Successfully deactivated user',
              })
            );
          },
          (response: any) => {
            setLoading(false);
            getUsers();
            const options: ShowAlertOptions = {
              header: 'Possible Issue',
              subHeader:
                'This user was removed from the Application, but could not be removed from the Authentication system',
              message:
                'This user has been removed from the Application, and will NOT be able to use Trulla Direct. However, this user could not be removed from the Authentication system. If this user is part of an organization using an Identity Provider, they will need to have their access revoked from that provider.',
              confirmText: 'Ok',
            };
            showAlert(options, (confirm: boolean) => {
              //
            });
          }
        );
      } else {
        dispatch(
          setToast({ message: 'Error deactivating user', color: 'danger' })
        );
      }
    });
  };

  const onClickReactivate = (user: AppUser) => {
    presentAlert({
      header: 'Reactivate the following user?',
      message: `${user.first_name} ${user.last_name}`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Reactivate',
          cssClass: 'text-color-success',
          role: 'confirm',
        },
      ],
      onWillDismiss: (e: any) => {
        if (e.detail.role === 'confirm') {
          reactivateUser(user);
        }
      },
    });
  };

  const reactivateUser = (user: AppUser) => {
    setLoading(true);
    const userData = { ...user };
    userData.active = true;
    api.put(`app_user/${user.app_user_id}`, userData).then(async (response) => {
      await createB2cUser(user, false, (response: any) => {
        setLoading(false);
        getUsers();
        dispatch(
          setToast({
            message: 'User has been reactivated successfully',
          })
        );
      });
    });
  };

  return (
    <DataTable
      title="Users"
      headers={headers}
      actions={actions}
      loading={loading}
      onPaginationChange={(pagination: DataTablePagination) =>
        setPagination(pagination)
      }
      orderBy={orderBy}
      orderByDesc={orderByDesc}
      setOrderBy={setOrderBy}
      setOrderByDesc={setOrderByDesc}
      totalCount={totalCount}
      search={false}
      rows={users.map((user: AppUser): DataTableRow => {
        const row: DataTableRow = {
          item: user,
          key: user.app_user_id,
          columns: [
            {
              header: 'first_name',
              content: (
                <>
                  <IonRouterLink
                    className="font-size-large"
                    routerLink={`${
                      supplier ? '/supplier' : ''
                    }/settings/users/${user.app_user_id}`}
                  >
                    {user.first_name} {user.last_name}
                  </IonRouterLink>
                  {!user.active ? (
                    <IonText color="danger">
                      <p className="ion-no-margin font-style-italic font-size-xs">
                        Deactivated
                      </p>
                    </IonText>
                  ) : null}
                </>
              ),
            },
            {
              header: 'email_address',
              content: <p>{user.email_address}</p>,
            },
            {
              header: 'admin',
              content: (
                <IonIcon
                  color="success"
                  className="font-size-large"
                  icon={utils.isAdmin(user) ? checkmarkCircleOutline : ''}
                />
              ),
            },
            {
              header: 'created',
              content: (
                <p>
                  {user.created ? utils.formatDateTime(user.created) : null}
                </p>
              ),
            },
            {
              header: 'updated',
              content: (
                <>
                  {(!user.active && (
                    <IonText color="danger">
                      <p>
                        {user.updated
                          ? utils.formatDateTime(user.updated)
                          : null}
                      </p>
                    </IonText>
                  )) || (
                    <p>
                      {user.updated ? utils.formatDateTime(user.updated) : null}
                    </p>
                  )}
                </>
              ),
            },
          ],
        };
        return row;
      })}
    >
      <IonRow>
        <IonCol size="12" sizeSm="4">
          {(filterDeactivated || filterRoleId) && (
            <IonButton fill="clear" size="small" onClick={clearFilters}>
              Clear Filters
            </IonButton>
          )}
          <ButtonFilter id={filterButtonId} />
          <IonPopover
            trigger={filterButtonId}
            triggerAction="click"
            className="filterPopover"
          >
            <IonContent>
              <IonList lines="none" className="padding-sm">
                <IonItem button detail={false} className="filterItem">
                  <IonLabel>Deactivated</IonLabel>
                  <IonToggle
                    slot="end"
                    checked={filterDeactivated}
                    onIonChange={(e) =>
                      setFilterDeactivated(e.detail.checked as boolean)
                    }
                  />
                </IonItem>
                <div className="fullWidth d-flex ion-justify-content-between ion-align-items-end">
                  <SelectRole
                    value={filterRoleId}
                    onIonChange={(e) => setFilterRoleId(e.detail.value)}
                    className="fullWidth"
                  />

                  {filterRoleId && (
                    <IonButton
                      size="small"
                      fill="clear"
                      color="medium"
                      onClick={() => setFilterRoleId(undefined)}
                      className="ion-no-padding"
                    >
                      <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
                    </IonButton>
                  )}
                </div>
              </IonList>
            </IonContent>
          </IonPopover>
        </IonCol>
        <IonCol size="12" sizeSm="8">
          <IonSearchbar
            value={search}
            onIonChange={(e: any) => setSearch(e.detail.value as string)}
            debounce={350}
            className="ion-text-left"
          />
        </IonCol>
      </IonRow>
    </DataTable>
  );
};
