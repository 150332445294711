import { useIonToast } from '@ionic/react';
import './ToastManager.css';
import { checkmarkCircleOutline, closeCircleOutline } from 'ionicons/icons';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setToast } from 'src/redux/appReducer';

export interface Toast {
  header?: string;
  message: string;
  buttons?: any[];
  color?:
    | 'success'
    | 'danger'
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'light'
    | 'medium'
    | 'dark';
  duration?: number;
  mode?: 'ios' | 'md';
  position?: 'bottom' | 'middle' | 'top';
  icon?: any;
}

export const ToastManager: React.FC = () => {
  const toast: Toast | null = useSelector((state: any) => state.app.toast);
  const dispatch = useDispatch();
  const [present, dismiss] = useIonToast();

  const getIcon = () => {
    switch (toast?.color) {
      case undefined:
        return checkmarkCircleOutline;
      case 'success':
        return checkmarkCircleOutline;
      case 'danger':
        return closeCircleOutline;
      default:
        return undefined;
    }
  };

  useEffect(() => {
    if (toast) {
      dismiss().then(() => {
        present({
          icon: toast.icon || getIcon(),
          cssClass: 'globalToast',
          header: toast.header || undefined,
          message: toast.message,
          buttons: toast.buttons || undefined,
          color: toast.color || 'success',
          duration: toast.duration || 2000,
          mode: toast.mode || 'ios',
          position: toast.position || 'bottom',
          onDidDismiss: () => dispatch(setToast(null)),
        });
      });
    }
  }, [toast]);

  return null;
};
