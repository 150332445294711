import { IonInput, IonItem, IonLabel, IonNote } from '@ionic/react';
import './FormInputLegacy.css';
import { useEffect, useState } from 'react';
import { FileInputLegacy } from './FileInputLegacy';
import { SelectState } from './SelectState';

export interface ValidationRule {
  pattern?: RegExp;
  message: string;
  validate?: (value: any) => boolean;
}

export const FormInputLegacy: React.FC<{
  label: string;
  labelPosition?: 'floating' | 'fixed' | 'stacked';
  name: string;
  placeholder?: string;
  inputType?: 'text' | 'state' | 'file';
  type?: 'text' | 'email' | 'password' | 'tel';
  required?: boolean;
  value: any;
  setValue: (value: any) => void;
  rules?: ValidationRule[];
  disabled?: boolean;
  readonly?: boolean;
  accept?: string;
  helperText?: string;
  rounded?: boolean;
}> = ({
  label,
  labelPosition = 'floating',
  name,
  placeholder,
  inputType = 'text',
  type = 'text',
  required = false,
  value,
  setValue,
  rules,
  disabled,
  readonly,
  accept,
  helperText,
  rounded = false,
}) => {
  const [errors, setErrors] = useState<string[]>([]);
  const [touched, setTouched] = useState(false);
  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (required) {
      if (errors.length === 0 && touched) {
        setValid(true);
      } else {
        setValid(false);
      }
    } else {
      if (errors.length === 0) {
        setValid(true);
      } else {
        setValid(false);
      }
    }
  }, [required, value, errors, touched]);

  useEffect(() => {
    if (touched) {
      doValidation();
    }
  }, [value]);

  const doValidation = () => {
    const newErrors: string[] = [];
    if (required && !value) {
      newErrors.push(`${label} is required`);
    }
    if (rules) {
      rules.forEach((rule) => {
        if (rule.pattern) {
          if (!rule.pattern.test(value)) {
            if (required || (!required && value)) {
              newErrors.push(rule.message);
            }
          }
        } else if (rule.validate) {
          if (!rule.validate(value)) {
            if (required || (!required && value)) {
              newErrors.push(rule.message);
            }
          }
        }
      });
    }
    setErrors(newErrors);
  };

  const handleBlur = () => {
    setTouched(true);
    doValidation();
  };

  const handleChange = (value: any) => {
    setTouched(true);
    setValue(value);
  };
  return (
    <>
      {(inputType === 'text' || inputType === 'file') && (
        <IonItem
          className={`formInput ${valid ? 'ion-valid' : 'ion-invalid'} ${
            rounded ? 'rounded' : ''
          }`}
        >
          <IonLabel position={labelPosition}>{label}</IonLabel>
          {inputType === 'text' ? (
            <IonInput
              type={type}
              name={name}
              required={required}
              value={value}
              onIonBlur={handleBlur}
              onIonChange={(e: any) => handleChange(e.detail.value)}
              placeholder={placeholder || undefined}
              disabled={disabled}
              readonly={readonly}
              className={rounded ? `rounded` : ''}
            />
          ) : null}
          {inputType === 'file' ? (
            <FileInputLegacy
              placeholder={placeholder}
              accept={accept}
              file={value}
              setFile={setValue}
              setBase64={(base64Content: string | null) => null}
              showThumbnail={true}
              onChange={(value: File | null) => handleChange(value)}
              rounded={rounded}
              disabled={disabled}
            />
          ) : null}
          {errors.length > 0 ? (
            <IonNote slot="error">
              <ul style={{ margin: 0, padding: '0 0 0 var(--app-spacing)' }}>
                {errors.map((error) => (
                  <li key={error}>
                    <span>{error}</span>
                  </li>
                ))}
              </ul>
            </IonNote>
          ) : null}
          {helperText ? <IonNote slot="helper">{helperText}</IonNote> : null}
        </IonItem>
      )}
      {inputType === 'state' && (
        <SelectState
          value={value}
          onIonChange={(e: any) => setValue(e.detail.value)}
          onIonBlur={() => handleBlur()}
          // errors={errors}
          disabled={disabled}
          rounded={rounded}
        />
      )}
    </>
  );
};
