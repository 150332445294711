import './LogoTrulla.css';
import React from 'react';

export const LogoTrulla: React.FC<{
  height?: string;
  style?: React.CSSProperties;
}> = ({ style }) => {
  return (
    <img
      className="trullaLogo"
      src="assets/SMRx_Trulla_Direct_Logo.png"
      alt="SpendMend Trulla logo"
      style={style}
    />
  );
};
