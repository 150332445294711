import { zodResolver } from '@hookform/resolvers/zod';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonIcon,
  IonList,
  IonRow,
  useIonRouter,
} from '@ionic/react';
import { checkmarkCircleOutline, saveOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { CardActions } from 'src/components/shared/CardActions';
import { FormInput } from 'src/components/shared/FormInput';
import { FormInputTextarea } from 'src/components/shared/FormInputTextarea';
import { ModalSupplierTermViewer } from 'src/components/shared/ModalSupplierTermViewer';
import { Subtitle } from 'src/components/shared/Subtitle';
import { useApi } from 'src/hooks/useApi';
import { useUtils } from 'src/hooks/useUtils';
import { AccountApplicationTerm } from 'src/interfaces/AccountApplicationTerm';
import { SupplierAccountApplication } from 'src/interfaces/SupplierAccountApplication';
import { TermInput } from 'src/interfaces/TermInput';
import { setToast } from 'src/redux/appReducer';
import { v } from 'src/utils/validators';
import { z } from 'zod';

interface SupplierField {
  key: string;
  name: string;
  value: any;
}

const sandozFormSchema = z.object({
  contractStartDate: v.date('Contract Start Date', true),
  customerEmail: v.email('Customer Email', true),
  comments: z.string().optional(),
});
type SandozFormSchema = z.infer<typeof sandozFormSchema>;

export const SandozTermsInputForm: React.FC<{
  applicationId: string;
  application: SupplierAccountApplication | null;
  loading: boolean;
  setLoading: (value: boolean) => void;
}> = ({ applicationId, application, loading, setLoading }) => {
  const api = useApi();
  const dispatch = useDispatch();
  const utils = useUtils();
  const router = useIonRouter();

  const [termLoading, setTermLoading] = useState(false);
  const [term, setTerm] = useState<AccountApplicationTerm | null>(null);
  const [termViewerOpen, setTermViewerOpen] = useState(false);
  const [selectedTerm, setSelectedTerm] =
    useState<AccountApplicationTerm | null>(null);
  const [termInputs, setTermInputs] = useState<TermInput[]>([]);

  const {
    handleSubmit,
    control,
    setValue,
    register,
    getValues,
    watch,
    trigger,
    formState: { errors, isSubmitted, isValid },
  } = useForm<SandozFormSchema>({
    resolver: zodResolver(sandozFormSchema),
    defaultValues: {
      //
    },
  });

  useEffect(() => {
    getAcceptedTerm();
  }, [applicationId]);
  useEffect(() => {
    if (term) {
      setValue('contractStartDate', getTermValue('contractStartDate') || '');
      setValue('customerEmail', getTermValue('customerEmail') || '');
    } else {
      setValue('contractStartDate', '');
      setValue('customerEmail', '');
    }
  }, [term]);

  useEffect(() => {
    if (application) {
      setValue('comments', application?.supplier_notes || '');
    } else {
      setValue('comments', '');
    }
  }, [application]);

  const getAcceptedTerm = () => {
    if (!applicationId) return;
    setTermLoading(true);
    api
      .get(`account_application/${applicationId}/account_application_terms`)
      .then((response) => {
        if (response.status === 200) {
          // This operates under the assumption there can only be one T&C document tied to a given application.
          const terms: AccountApplicationTerm[] = response.data;
          const term = terms[0];
          setTerm(term);
        }
        setTermLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setTermLoading(false);
      });
  };

  const getTermValue = (key: string) => {
    if (!term) {
      return null;
    }
    const field = term.inputs?.find((f) => f.key === key);
    return field?.value || null;
  };

  const onClickPreviewTerm = async (term: AccountApplicationTerm) => {
    const isValid = await trigger();
    if (isValid) {
      const inputs = term.inputs?.map((input: TermInput) => {
        input.value = getValues(input.key as any);
        return input;
      });
      setTermInputs(inputs || []);
      setSelectedTerm(term);
      setTermViewerOpen(true);
    }
  };

  const onClickSaveInputs = async (term: AccountApplicationTerm) => {
    const isValid = await trigger();
    if (isValid) {
      const inputs = term.inputs?.map((input: TermInput) => {
        input.value = getValues(input.key as any);
        return input;
      });
      setLoading(true);
      api
        .put(`account_application_term/${term.account_application_term_id}`, {
          inputs: inputs,
        })
        .then((response) => {
          if (response.status === 200) {
            dispatch(
              setToast({
                message: 'Additional Inputs saved successfully',
              })
            );
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const submit = async (approve: boolean) => {
    const isValid = await trigger();
    if (isValid) {
      if (approve) {
        handleSubmit(onApprove)();
      } else {
        handleSubmit(onReject)();
      }
    }
  };

  const onSubmit = (data: SandozFormSchema) => {
    // default submit action does nothing
  };

  const onReject = (data: SandozFormSchema) => {
    setLoading(true);
    const fields: SupplierField[] = [
      {
        key: 'contractStartDate',
        name: 'Contract Start Date',
        value: data.contractStartDate,
      },
      {
        key: 'customerEmail',
        name: 'Email for packaging changes',
        value: data.customerEmail,
      },
    ];
    const formData = {
      supplier_notes: data.comments,
      supplier_fields: fields,
    };
    api
      .put(`account_application/${applicationId}/reject`, formData)
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            setToast({
              message: 'Application has been rejected.',
              color: 'success',
            })
          );
          router.push('/supplier/accounts/applications', 'back');
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onApprove = (data: SandozFormSchema) => {
    setLoading(true);
    const formData = {
      supplier_notes: data.comments,
      inputs: term?.inputs,
    };
    api
      .put(`account_application/${applicationId}/approve`, formData)
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            setToast({
              message: 'Application has been approved.',
              color: 'success',
            })
          );
          router.push('/supplier/accounts/applications', 'back');
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /**
   * Determine if a specific AccountApplicationTerm has an input by the given name
   * @param term
   * @param name
   * @returns boolean
   */
  const hasInput = (term: AccountApplicationTerm, name: string): boolean => {
    if (!term.inputs) {
      return false;
    }
    const foundIndex = term.inputs.findIndex((index) => index.key === name);
    return foundIndex > -1;
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <IonRow>
          {term && (
            <IonCol size="12">
              <IonCard>
                <IonCardContent className="ion-no-padding ion-padding-horizontal ion-padding-top">
                  <IonRow>
                    <IonCol size="10">
                      <h2 className="font-size-large">{term.name}</h2>
                      <Subtitle>{term.original_filename}</Subtitle>
                    </IonCol>
                    <IonCol size="2" className="ion-text-right">
                      <IonIcon
                        icon={checkmarkCircleOutline}
                        color="success"
                        className="font-size-large d-inline-flex ion-align-items-center"
                        data-tooltip-id="global-tooltip"
                        data-tooltip-place="left"
                        data-tooltip-html={`
                          Terms Accepted</br>
                          ${utils.formatDateTime(term.created)}
                        `}
                      />
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="12">
                      <IonList lines="none">
                        <IonRow>
                          {hasInput(term, 'contractStartDate') && (
                            <IonCol size="12" sizeMd="6" sizeLg="4" sizeXl="3">
                              <FormInput
                                {...register('contractStartDate')}
                                label="Contract Start Date"
                                onIonChange={(e) => {
                                  setValue('contractStartDate', e.detail.value);
                                }}
                                disabled={
                                  application?.application_status !== 'pending'
                                }
                                placeholder="yyyy-mm-dd"
                                errorMessage={errors.contractStartDate?.message}
                              />
                            </IonCol>
                          )}
                          {hasInput(term, 'customerEmail') && (
                            <IonCol size="12" sizeMd="6" sizeLg="4" sizeXl="6">
                              <FormInput
                                {...register('customerEmail')}
                                label="Email for packaging changes"
                                onIonChange={(e) => {
                                  setValue('customerEmail', e.detail.value);
                                }}
                                disabled={
                                  application?.application_status !== 'pending'
                                }
                                placeholder="customer@organization.com"
                                errorMessage={errors.customerEmail?.message}
                              />
                            </IonCol>
                          )}
                        </IonRow>
                      </IonList>
                    </IonCol>
                  </IonRow>
                </IonCardContent>
                <CardActions>
                  <IonButton
                    size="small"
                    fill="outline"
                    onClick={() => onClickPreviewTerm(term)}
                  >
                    Preview Inputs
                  </IonButton>
                  <IonButton
                    size="small"
                    onClick={() => onClickSaveInputs(term)}
                    disabled={application?.application_status !== 'pending'}
                  >
                    Save Inputs
                    <IonIcon icon={saveOutline} slot="end" />
                  </IonButton>
                </CardActions>
              </IonCard>
            </IonCol>
          )}
        </IonRow>
        <IonRow>
          <IonCol size="12">
            <IonCard className="fullHeight">
              <IonCardHeader>
                <IonCardTitle>Comments (optional)</IonCardTitle>
                <Subtitle>
                  Add any comments here regarding your decision.
                </Subtitle>
                <Subtitle>
                  The applicant will not be able to see these.
                </Subtitle>
              </IonCardHeader>
              <IonCardContent>
                <IonList lines="none">
                  <FormInputTextarea
                    disabled={application?.application_status !== 'pending'}
                    {...register('comments')}
                    label="Comments (optional)"
                    onIonChange={(e) => {
                      setValue('comments', e.detail.value);
                    }}
                    placeholder="Add any comments here..."
                    errorMessage={errors.comments?.message}
                  />
                </IonList>
              </IonCardContent>
              <CardActions>
                <div className="fullWidth d-flex ion-align-items-center ion-justify-content-end">
                  <IonButton
                    color="danger"
                    onClick={() => submit(false)}
                    disabled={application?.application_status !== 'pending'}
                  >
                    Reject
                  </IonButton>
                  <IonButton
                    color="success"
                    onClick={() => submit(true)}
                    disabled={application?.application_status !== 'pending'}
                  >
                    Approve
                  </IonButton>
                </div>
              </CardActions>
            </IonCard>
          </IonCol>
        </IonRow>
      </form>

      <ModalSupplierTermViewer
        isOpen={termViewerOpen}
        accountApplicationTerm={selectedTerm}
        termInputs={termInputs}
        onWillDismiss={() => {
          setTermViewerOpen(false);
          setSelectedTerm(null);
          setTermInputs([]);
        }}
      />
    </>
  );
};
