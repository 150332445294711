import { IonButton, IonIcon } from '@ionic/react';
import {
  addOutline,
  closeOutline,
  eyeOutline,
  pencilOutline,
} from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { DataTable } from 'src/components/shared/DataTable';
import { DataTableHeader } from 'src/components/shared/DataTable/interfaces/DataTableHeader';
import { DataTableItemAction } from 'src/components/shared/DataTable/interfaces/DataTableItemAction';
import { DataTableRow } from 'src/components/shared/DataTable/interfaces/DataTableRow';
import { ModalSupplierTermViewer } from 'src/components/shared/ModalSupplierTermViewer';
import { SupplierTerm } from 'src/interfaces/SupplierTerm';
import { TermInput } from 'src/interfaces/TermInput';
import { ModalManageTermInput } from './ModalManageTermInput';

export const TableTermInputs: React.FC<{
  supplierTerm: SupplierTerm | null;
  onUpdate: (termInputs: TermInput[]) => void;
}> = ({ supplierTerm, onUpdate }) => {
  const [termInputs, setTermInputs] = useState<TermInput[]>([]);
  const [selectedInput, setSelectedInput] = useState<TermInput | null>(null);
  const [inputModalOpen, setInputModalOpen] = useState(false);

  const [termViewerOpen, setTermViewerOpen] = useState(false);
  const headers: DataTableHeader[] = [
    { text: 'ID', key: 'key' },
    { text: 'Name', key: 'name' },
    { text: 'Page', key: 'page' },
    { text: 'Coordinates (X, Y)', key: 'coordinates' },
    { text: 'Color', key: 'color' },
    { text: 'Font Size', key: 'fontSize' },
  ];
  useEffect(() => {
    if (supplierTerm) {
      setTermInputs(supplierTerm.inputs || []);
    } else {
      setTermInputs([]);
    }
  }, [supplierTerm]);

  useEffect(() => {
    onUpdate(termInputs);
  }, [termInputs]);

  const actions = (termInput: TermInput): DataTableItemAction[] => {
    const actions = [
      {
        label: 'Edit',
        icon: <IonIcon icon={pencilOutline} />,
        callback: (termInput: TermInput) => {
          setSelectedInput(termInput);
          setInputModalOpen(true);
        },
      },
      {
        label: 'Delete',
        color: 'danger',
        icon: <IonIcon icon={closeOutline} />,
        callback: (termInput: TermInput) => {
          const newTermInputs = [...termInputs];
          const index = newTermInputs.findIndex((s) => s.key === termInput.key);
          newTermInputs.splice(index, 1);
          setTermInputs(newTermInputs);
        },
      },
    ];
    return actions;
  };
  const previewInputs = () => {
    setTermViewerOpen(true);
  };

  return (
    <>
      <DataTable
        title="Additional Inputs"
        headers={headers}
        actions={actions}
        search={false}
        // loading={loading}
        onClickRow={(row: DataTableRow) => {
          setSelectedInput(row.item);
          setInputModalOpen(true);
        }}
        rows={termInputs.map((input: TermInput): DataTableRow => {
          const row: DataTableRow = {
            item: input,
            key: input.key,
            columns: [
              {
                header: 'key',
                content: <p>{input.key}</p>,
              },
              {
                header: 'name',
                content: <p>{input.name}</p>,
              },
              {
                header: 'page',
                content: <p>{input.page}</p>,
              },
              {
                header: 'coordinates',
                content: (
                  <p>
                    ({input.pos_x}, {input.pos_y})
                  </p>
                ),
              },
              {
                header: 'color',
                content: <p>{input.color}</p>,
              },
              {
                header: 'fontSize',
                content: <p>{input.font_size}</p>,
              },
            ],
          };
          return row;
        })}
      >
        <IonButton
          size="small"
          fill="outline"
          onClick={previewInputs}
          className="ion-margin-end"
        >
          Preview All Inputs
          <IonIcon icon={eyeOutline} slot="end" />
        </IonButton>
        <IonButton size="small" onClick={() => setInputModalOpen(true)}>
          Add
          <IonIcon icon={addOutline} slot="end" />
        </IonButton>
      </DataTable>
      {/* Modal to add/edit a "TermInput" */}
      <ModalManageTermInput
        isOpen={inputModalOpen}
        termInput={selectedInput}
        onWillDismiss={(termInput?: TermInput) => {
          setSelectedInput(null);
          setInputModalOpen(false);
          if (termInput) {
            const foundIndex = termInputs.findIndex((input, index) => {
              return input.name === termInput.name;
            });
            if (foundIndex > -1) {
              const newTermInputs = [...termInputs];
              newTermInputs[foundIndex] = termInput;
              setTermInputs(newTermInputs);
            } else {
              // push to array
              setTermInputs([...termInputs, termInput]);
            }
          }
        }}
      />
      {/* Modal responsible for showing the inputs in the PDF */}
      <ModalSupplierTermViewer
        isOpen={termViewerOpen}
        supplierTerm={supplierTerm}
        termInputs={termInputs.map((input) => {
          return { ...input, value: input.key };
        })}
        onWillDismiss={() => {
          setTermViewerOpen(false);
          setSelectedInput(null);
        }}
      />
    </>
  );
};
