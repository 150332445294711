import {
  IonIcon,
  IonRouterLink,
  useIonAlert,
  useIonViewWillEnter,
} from '@ionic/react';
import {
  checkmarkCircleOutline,
  closeOutline,
  eyeOutline,
  pencilOutline,
} from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from 'src/components/shared/DataTable';
import { DataTableHeader } from 'src/components/shared/DataTable/interfaces/DataTableHeader';
import { DataTableItemAction } from 'src/components/shared/DataTable/interfaces/DataTableItemAction';
import { DataTableRow } from 'src/components/shared/DataTable/interfaces/DataTableRow';
import { ModalSupplierTermViewer } from 'src/components/shared/ModalSupplierTermViewer';
import { Subtitle } from 'src/components/shared/Subtitle';
import { useApi } from 'src/hooks/useApi';
import { useUtils } from 'src/hooks/useUtils';
import { Supplier } from 'src/interfaces/Supplier';
import { SupplierTerm } from 'src/interfaces/SupplierTerm';
import { setToast } from 'src/redux/appReducer';

export const TableSupplierTerms: React.FC<{ refresh?: boolean }> = ({
  refresh,
}) => {
  const api = useApi();
  const utils = useUtils();
  const [presentAlert] = useIonAlert();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [selectedTerm, setSelectedTerm] = useState<SupplierTerm | null>(null);
  const [supplierTerms, setSupplierTerms] = useState<SupplierTerm[]>([]);

  const supplier: Supplier | null = useSelector(
    (state: any) => state.supplier.supplier
  );
  const headers: DataTableHeader[] = [
    { text: 'ID', key: 'id' },
    { text: 'Display Name', key: 'name' },
    { text: 'Filename', key: 'filename' },
    { text: 'Last Updated', key: 'updated' },
    { text: 'Default', key: 'default', align: 'center' },
  ];

  useIonViewWillEnter(() => {
    getSupplierTerms();
  }, [supplier, refresh]);

  useEffect(() => {
    getSupplierTerms();
  }, [supplier, refresh]);

  const getSupplierTerms = () => {
    if (!supplier) return;
    setLoading(true);
    api
      .get(`supplier/${supplier?.supplier_id}/supplier_terms`, {
        show_all: true,
      })
      .then((response) => {
        if (response.status === 200) {
          const terms: SupplierTerm[] = response.data;
          setSupplierTerms(terms);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const actions = (supplierTerm: SupplierTerm): DataTableItemAction[] => {
    const actions: DataTableItemAction[] = [
      {
        label: 'View',
        icon: <IonIcon icon={eyeOutline} />,
        callback: (supplierTerm: SupplierTerm) => {
          setSelectedTerm(supplierTerm);
        },
      },
      {
        label: 'Manage',
        icon: <IonIcon icon={pencilOutline} />,
        routerLink: (supplierTerm: SupplierTerm) => {
          return `/supplier/settings/terms/${supplierTerm.supplier_term_id}`;
        },
        callback: (supplierTerm: SupplierTerm) => {
          // console.log('Manage');
        },
      },
    ];
    // TODO: Consider adding a "Make Default" action
    // if (!supplierTerm.is_default) {
    //   actions.push({
    //     label: 'Make Default',
    //     color: 'primary',
    //     icon: <IonIcon icon={checkmarkCircleOutline} />,
    //     callback: (supplierTerm: SupplierTerm) => {
    //       makeDefault(supplierTerm);
    //     },
    //   });
    // }
    actions.push({
      label: 'Delete',
      color: 'danger',
      icon: <IonIcon icon={closeOutline} />,
      callback: (supplierTerm: SupplierTerm) => {
        onClickDelete(supplierTerm);
      },
    });
    return actions;
  };

  const onClickDelete = (supplierTerm: SupplierTerm) => {
    presentAlert({
      header: 'Delete the following Document?',
      message: `${supplierTerm.name}`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Delete',
          cssClass: 'text-color-danger',
          role: 'confirm',
        },
      ],
      onWillDismiss: (e: any) => {
        if (e.detail.role === 'confirm') {
          deleteSupplierTerm(supplierTerm);
        }
      },
    });
  };
  const deleteSupplierTerm = (supplierTerm: SupplierTerm) => {
    setLoading(true);
    api
      .delete(`/supplier_term/${supplierTerm.supplier_term_id}`)
      .then((response) => {
        if (response.status === 204) {
          setLoading(false);
          getSupplierTerms();
          dispatch(
            setToast({
              message: 'Successfully deleted document',
            })
          );
        } else {
          setLoading(false);
          dispatch(
            setToast({ message: 'Error deleting document', color: 'danger' })
          );
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  const makeDefault = (supplierTerm: SupplierTerm) => {
    console.log('make default');
  };

  return (
    <>
      <DataTable
        title="Terms Documents"
        subtitle="Documents here will be shown to buyers who submit an application in the Account Wizard."
        headers={headers}
        actions={actions}
        rows={supplierTerms.map((term: SupplierTerm): DataTableRow => {
          const row: DataTableRow = {
            item: term,
            key: term.supplier_term_id,
            columns: [
              {
                header: 'id',
                content: <p>{term.supplier_term_id}</p>,
              },
              {
                header: 'name',
                content: (
                  <>
                    <IonRouterLink
                      className="font-size-large"
                      routerLink={`/supplier/settings/terms/${term.supplier_term_id}`}
                    >
                      {term.name}
                    </IonRouterLink>
                  </>
                ),
              },
              {
                header: 'filename',
                content: (
                  <Subtitle className="font-size-xs">
                    {term.original_filename}
                  </Subtitle>
                ),
              },
              {
                header: 'updated',
                content: <p>{utils.formatDateTime(term.updated)}</p>,
              },
              {
                header: 'default',
                content: (
                  <IonIcon
                    color="primary"
                    className="font-size-large"
                    icon={term.is_default ? checkmarkCircleOutline : ''}
                  />
                ),
              },
            ],
          };
          return row;
        })}
        loading={loading}
      ></DataTable>

      <ModalSupplierTermViewer
        isOpen={!!selectedTerm}
        supplierTerm={selectedTerm}
        onWillDismiss={() => {
          setSelectedTerm(null);
        }}
      />
    </>
  );
};
