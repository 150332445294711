import {
  IonContent,
  IonFooter,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  useIonAlert,
} from '@ionic/react';

import { useMsal } from '@azure/msal-react';
import { faHeadset, faLifeRing } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { logOutOutline } from 'ionicons/icons';
import { useDispatch } from 'react-redux';
import { Divider } from 'src/components/shared/Divider';
import { LogoTrulla } from 'src/components/shared/LogoTrulla';
import { setContactUsOpen } from 'src/redux/appReducer';
import { logout } from 'src/redux/authReducer';
import './Menu.css';
import { MenuItems } from './MenuItems';

export const Menu: React.FC = () => {
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const [presentAlert] = useIonAlert();

  const onClickLogout = () => {
    presentAlert({
      header: 'Logout',
      message: 'Are you sure you want to Logout?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Logout',
          role: 'confirm',
        },
      ],
      onDidDismiss: (e: any) => {
        if (e.detail.role === 'confirm') {
          dispatch(logout());
          instance.logoutRedirect();
        }
      },
    });
  };

  return (
    <IonMenu contentId="mainContent" type="overlay" swipeGesture={false}>
      <IonContent>
        <IonList>
          <IonListHeader class="logoHeader">
            <LogoTrulla style={{ height: '40px' }} />
          </IonListHeader>

          <MenuItems />
        </IonList>
      </IonContent>
      <IonFooter>
        <IonList lines="none">
          <IonMenuToggle autoHide={false}>
            <IonItem
              button
              detail={false}
              onClick={() => dispatch(setContactUsOpen(true))}
            >
              <span slot="start">
                <FontAwesomeIcon icon={faHeadset} />
              </span>
              <IonLabel>Contact Us</IonLabel>
            </IonItem>
            <IonItem id="inline-manual-help" button detail={false}>
              <span slot="start">
                <FontAwesomeIcon icon={faLifeRing} />
              </span>
              <IonLabel>Help</IonLabel>
            </IonItem>
            <Divider className="ion-no-margin" />
            <IonItem button detail={false} onClick={onClickLogout}>
              <IonIcon slot="start" icon={logOutOutline}></IonIcon>
              Logout
            </IonItem>
          </IonMenuToggle>
        </IonList>
      </IonFooter>
    </IonMenu>
  );
};
