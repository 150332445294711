import {
  faHospital,
  faRotate,
  faUsers,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IonCol,
  IonIcon,
  IonRouterLink,
  IonRow,
  IonSearchbar,
  IonText,
  useIonViewWillEnter,
} from '@ionic/react';
import { pencilOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from 'src/components/shared/DataTable';
import { DataTableHeader } from 'src/components/shared/DataTable/interfaces/DataTableHeader';
import { DataTableItemAction } from 'src/components/shared/DataTable/interfaces/DataTableItemAction';
import { DataTablePagination } from 'src/components/shared/DataTable/interfaces/DataTablePagination';
import { DataTableRow } from 'src/components/shared/DataTable/interfaces/DataTableRow';
import { useAlert } from 'src/hooks/useAlert';
import { useApi } from 'src/hooks/useApi';
import { useEndpoints } from 'src/hooks/useEndpoints';
import { useUtils } from 'src/hooks/useUtils';
import { AppUserSelf } from 'src/interfaces/AppUserSelf';
import { Organization } from 'src/interfaces/Organization';
import { setLocation, setToast } from 'src/redux/appReducer';

const headers: DataTableHeader[] = [
  {
    text: 'Organization ID',
    key: 'organization_id',
    sortable: false,
    width: '150px',
  },
  { text: 'Organization Name', key: 'name', sortable: true },
  { text: 'Organization Type', key: 'type', sortable: true },
  { text: 'Created', key: 'created', sortable: true },
  { text: 'Updated', key: 'updated', sortable: true },
];

export const TableOrganizations: React.FC = () => {
  const api = useApi();
  const { isBuyerOrganization } = useUtils();
  const { switchOrganizations } = useEndpoints();
  const dispatch = useDispatch();
  const { formatDateTime } = useUtils();
  const { showAlert } = useAlert();

  const authUser: AppUserSelf | null = useSelector(
    (state: any) => state.auth.user
  );

  const [loading, setLoading] = useState(false);
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [search, setSearch] = useState<string | null>(null);
  const [pagination, setPagination] = useState<DataTablePagination | null>(
    null
  );
  const [totalCount, setTotalCount] = useState<number>(0);
  const [orderBy, setOrderBy] = useState<string | null>('name');
  const [orderByDesc, setOrderByDesc] = useState<boolean>(false);

  useIonViewWillEnter(() => {
    getOrganizations();
  }, []);

  useEffect(() => {
    if (pagination) {
      getOrganizations();
    }
  }, [pagination, search]);

  const getOrganizations = () => {
    if (!pagination) {
      return;
    }
    setLoading(true);
    api
      .get('admin/organizations', {
        limit: pagination.limit,
        offset: pagination.offset,
        order_by: orderBy ? orderBy : undefined,
        order_by_desc: orderByDesc,
        search: search ? search : undefined,
      })
      .then((response) => {
        if (response.status === 200) {
          setOrganizations(response.data.result || []);
          setTotalCount(response.data.total);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const actions = (organization: Organization): DataTableItemAction[] => {
    let actions = [
      {
        label: 'Switch to this Organization',
        icon: <FontAwesomeIcon icon={faRotate} />,
        callback: (organization: Organization) => {
          const options = {
            header: organization.name,
            message: 'Would you like to switch your user to this organization?',
          };
          showAlert(options, (confirm: boolean) => {
            if (confirm) {
              switchOrganizations(organization);
            } else {
              console.log('Cancelled');
            }
          });
          return null;
        },
      },
      {
        label: 'Manage Organization',
        icon: <IonIcon icon={pencilOutline} />,
        routerLink: (organization: Organization) => {
          return `/admin/organizations/${organization.organization_id}`;
        },
        callback: (organization: Organization) => {
          return null;
        },
      },
      {
        label: 'Manage Users',
        icon: <FontAwesomeIcon icon={faUsers} />,
        routerLink: (organization: Organization) => {
          return `/admin/organizations/${organization.organization_id}/users`;
        },
        callback: (organization: Organization) => {
          return null;
        },
      },
    ];
    if (isBuyerOrganization(organization)) {
      actions.push({
        label: 'Manage Locations',
        icon: <FontAwesomeIcon icon={faHospital} />,
        routerLink: (organization: Organization) => {
          return `/admin/organizations/${organization.organization_id}/locations`;
        },
        callback: (organization: Organization) => {
          return null;
        },
      });
    }
    return actions;
  };

  return (
    <>
      <DataTable
        title="Organizations"
        headers={headers}
        actions={actions}
        loading={loading}
        search={false}
        onPaginationChange={(pagination: DataTablePagination) =>
          setPagination(pagination)
        }
        orderBy={orderBy}
        orderByDesc={orderByDesc}
        setOrderBy={setOrderBy}
        setOrderByDesc={setOrderByDesc}
        totalCount={totalCount}
        rows={organizations.map((organization: Organization): DataTableRow => {
          const row: DataTableRow = {
            item: organization,
            key: organization.organization_id,
            columns: [
              {
                header: 'organization_id',
                content: (
                  <>
                    <p>{organization.organization_id}</p>
                  </>
                ),
              },
              {
                header: 'name',
                content: (
                  <>
                    <IonRouterLink
                      className="font-size-large d-block"
                      routerLink={`/admin/organizations/${organization.organization_id}`}
                    >
                      {organization.name}
                    </IonRouterLink>
                    {organization.organization_id ===
                      authUser?.organization_id && (
                      <IonText color="tertiary" className="d-block">
                        Currently Selected
                      </IonText>
                    )}
                  </>
                ),
              },
              {
                header: 'type',
                content: (
                  <>
                    <p>{organization.organization_type_name}</p>
                  </>
                ),
              },
              {
                header: 'created',
                content: (
                  <p>
                    {organization.created
                      ? formatDateTime(organization.created)
                      : null}
                  </p>
                ),
              },
              {
                header: 'updated',
                content: (
                  <p>
                    {organization.updated
                      ? formatDateTime(organization.updated)
                      : null}
                  </p>
                ),
              },
            ],
          };

          return row;
        })}
      >
        <IonRow>
          <IonCol size="12" sizeSm="8" offsetSm="4">
            <IonSearchbar
              placeholder="Search Organizations..."
              value={search}
              onIonChange={(e: any) => setSearch(e.detail.value as string)}
              debounce={350}
              className="ion-text-left"
            />
          </IonCol>
        </IonRow>
      </DataTable>
    </>
  );
};
