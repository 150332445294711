import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonSkeletonText,
  useIonViewWillEnter,
} from '@ionic/react';
import { addOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Breadcrumb, Breadcrumbs } from 'src/components/shared/Breadcrumbs';
import { ButtonEdit } from 'src/components/shared/ButtonEdit';
import { CardActions } from 'src/components/shared/CardActions';
import { Divider } from 'src/components/shared/Divider';
import { LogoSupplier } from 'src/components/shared/LogoSupplier';
import { ModalSupplierTermViewer } from 'src/components/shared/ModalSupplierTermViewer';
import { Subtitle } from 'src/components/shared/Subtitle';
import { useApi } from 'src/hooks/useApi';
import { Contact } from 'src/interfaces/Contact';
import { Supplier } from 'src/interfaces/Supplier';
import { SupplierTerm } from 'src/interfaces/SupplierTerm';
import { Page } from 'src/layouts/Page';
import { CardSupplierContact } from './CardSupplierContact';
import { ModalSupplierContact } from './ModalSupplierContact';

export const SettingsPage: React.FC = () => {
  const supplier: Supplier | null = useSelector(
    (state: any) => state.supplier.supplier
  );
  const api = useApi();

  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);

  const [name, setName] = useState<string>();
  const [contacts, setContacts] = useState<Contact[]>([]);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);

  const [documentViewerOpen, setDocumentViewerOpen] = useState(false);

  const [selectedDocument, setSelectedDocument] = useState<SupplierTerm | null>(
    null
  );

  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Settings' },
  ];

  useIonViewWillEnter(() => {
    getContacts();
  }, []);

  useEffect(() => {
    setName(supplier?.name);
    getContacts();
  }, [supplier]);

  const revertChanges = () => {
    setName(supplier?.name);
  };

  const onClickCancel = () => {
    revertChanges();
    setEditing(false);
  };

  const onClickSave = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setEditing(false);
      alert('save changes');
    }, 1000);
  };

  const getContacts = () => {
    if (!supplier) {
      return;
    }
    setLoading(true);
    api.get(`supplier/${supplier.supplier_id}/contacts`).then((response) => {
      if (response.status === 200) {
        setContacts(response.data || []);
      }
      setLoading(false);
    });
  };

  return (
    <Page title="Supplier Settings" className="supplierSettings">
      <Breadcrumbs breadcrumbs={breadcrumbs}></Breadcrumbs>
      <IonRow>
        <IonCol size="12" sizeMd="6" sizeXl="5">
          <IonCard>
            <IonCardHeader className="padding-bottom-xs">
              <IonCardTitle>Organization</IonCardTitle>
            </IonCardHeader>
            <IonCardContent className="ion-no-padding ion-padding-bottom">
              <div className="ion-text-left padding-left">
                {(loading && (
                  <IonSkeletonText animated={true} className="supplierLogo" />
                )) || (
                  <LogoSupplier
                    src={supplier?.logo}
                    style={{ objectPosition: 'left' }}
                  />
                )}
              </div>
              <IonList>
                <IonItem lines="inset">
                  <IonLabel position="stacked">Organization Name</IonLabel>
                  <IonInput
                    disabled={loading}
                    readonly={!editing}
                    value={name}
                    onIonChange={(e: any) => setName(e.detail.value)}
                  />
                </IonItem>
              </IonList>
            </IonCardContent>
            <CardActions>
              <ButtonEdit
                size="small"
                editing={editing}
                loading={loading}
                onClickCancel={onClickCancel}
                onClickEdit={() => setEditing(true)}
                onClickSave={() => onClickSave()}
              />
            </CardActions>
          </IonCard>
        </IonCol>
      </IonRow>
      <Divider />
      <IonRow>
        <IonCol size="12">
          {/* TODO: Move Supplier Contacts to its own page. */}
          <h2 className="sectionTitle margin-bottom-xs">
            Contacts{' '}
            <IonButton
              fill="clear"
              size="small"
              color="primary"
              onClick={() => setIsOpen(true)}
              className="ion-no-margin ion-no-padding"
              style={{ verticalAlign: 'middle' }}
              data-tooltip-id="global-tooltip"
              data-tooltip-content="Add Contact"
              data-tooltip-place="bottom"
            >
              <IonIcon slot="icon-only" icon={addOutline} />
            </IonButton>
          </h2>
          <Subtitle>Contact info here will be shown to buyers.</Subtitle>
        </IonCol>
      </IonRow>
      <IonRow>
        {contacts.map((contact: Contact) => (
          <IonCol size="12" sizeXl="6" key={contact.contact_id}>
            <CardSupplierContact
              contact={contact}
              onClickEdit={(contact: Contact) => {
                setSelectedContact(contact);
                setIsOpen(true);
              }}
            />
          </IonCol>
        ))}
      </IonRow>
      <ModalSupplierContact
        isOpen={isOpen}
        onDismiss={(data?: Contact[]) => {
          setIsOpen(false);
          setSelectedContact(null);
          getContacts();
        }}
        supplierContact={selectedContact}
      />
      <ModalSupplierTermViewer
        isOpen={documentViewerOpen}
        supplierTerm={selectedDocument}
        onWillDismiss={() => {
          setDocumentViewerOpen(false);
          setSelectedDocument(null);
        }}
      />
    </Page>
  );
};
