import { zodResolver } from '@hookform/resolvers/zod';
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonList,
  IonModal,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import { useEffect, useId, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { FormFileInput } from 'src/components/shared/FormFileInput';
import { FormInput } from 'src/components/shared/FormInput';
import { LoadingInline } from 'src/components/shared/LoadingInline';
import { useApi } from 'src/hooks/useApi';
import { Supplier } from 'src/interfaces/Supplier';
import { SupplierTerm } from 'src/interfaces/SupplierTerm';
import { setToast } from 'src/redux/appReducer';
import { z } from 'zod';

const formSchema = z.object({
  name: z.string().min(1, { message: 'Name is required' }),
  fileBase64: z.string().min(1, { message: 'File is required' }),
  filesize: z
    .number()
    .max(5000000, { message: 'File too large. Must be less than 5MB' }),
  filename: z.string(),
});

type FormSchema = z.infer<typeof formSchema>;

export const ModalAddSupplierTerm: React.FC<{
  isOpen: boolean;
  onDismiss: (data?: any) => void;
}> = ({ isOpen, onDismiss }) => {
  const formId = useId();
  const api = useApi();
  const dispatch = useDispatch();
  const supplier: Supplier | null = useSelector(
    (state: any) => state.supplier.supplier
  );

  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    register,
    getValues,
    watch,
    reset,
    formState: { errors, isSubmitted, isValid },
  } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      filesize: 0,
      name: '',
      fileBase64: '',
    },
  });

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen]);

  // create the SupplierTerm.
  const onSubmit = (data: FormSchema) => {
    if (!supplier || !data) {
      return;
    }
    setLoading(true);
    const formData = [
      {
        supplier_id: supplier.supplier_id,
        name: data.name,
        filesize: data.filesize,
        original_filename: data.filename,
        public: true,
        content: data.fileBase64,
      },
    ];
    api
      .post(`supplier_term`, formData)
      .then((response) => {
        if (response.status === 201) {
          dispatch(
            setToast({
              message: 'Successfully Created',
            })
          );
          const supplierTerm: SupplierTerm = response.data[0];
          onDismiss(supplierTerm);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <IonModal isOpen={isOpen} onIonModalWillDismiss={onDismiss}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Add Terms Document</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onDismiss} fill="clear">
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding">
        <form id={formId} onSubmit={handleSubmit(onSubmit)}>
          <IonList lines="none">
            <IonRow>
              <IonCol size="12">
                <FormInput
                  label="Display Name"
                  helperText="The name a buyer will see when they view this document"
                  onIonChange={(e) => {
                    setValue('name', e.detail.value);
                  }}
                  {...register('name')}
                  errorMessage={errors.name?.message}
                />
              </IonCol>
              <IonCol size="12">
                <Controller
                  name="fileBase64"
                  control={control}
                  render={({ field }) => {
                    return (
                      <FormFileInput
                        label="File"
                        placeholder="Supported types: pdf. File size: 5MB or smaller."
                        accept="application/pdf"
                        value={field.value}
                        setValue={(filesize, filename, base64Content) => {
                          setValue('filesize', filesize || 0);
                          setValue('filename', filename || '');
                          setValue('fileBase64', base64Content || '');
                        }}
                        showThumbnail={true}
                        errorMessage={errors?.fileBase64?.message}
                        disabled={loading}
                      />
                    );
                  }}
                />
              </IonCol>
            </IonRow>
          </IonList>
        </form>
      </IonContent>

      <IonFooter>
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton color="medium" onClick={onDismiss}>
              Cancel
            </IonButton>
            <IonButton
              disabled={loading}
              form={formId}
              type="submit"
              fill="solid"
            >
              {'Create'}
              {loading && (
                <span className="margin-left-sm">
                  <LoadingInline color="light" />
                </span>
              )}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};
