import { useIsAuthenticated } from '@azure/msal-react';
import { AppUserSelf } from 'src/interfaces/AppUserSelf';
import { LoadingPage } from 'src/pages/shared/LoadingPage';
import { NoUserPage } from 'src/pages/shared/NoUserPage';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

export const AuthRoute = ({ component: Component, ...rest }: any) => {
  const isAuthenticated = useIsAuthenticated();
  const authUser: AppUserSelf | null = useSelector(
    (state: any) => state.auth.user
  );
  const sessionExpired: boolean | null = useSelector(
    (state: any) => state.auth.sessionExpired
  );
  const userLoading: boolean = useSelector(
    (state: any) => state.auth.userLoading
  );

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated && authUser) {
          return <Component {...props} />;
        } else if (
          (isAuthenticated && !userLoading && !authUser) ||
          sessionExpired === true
        ) {
          return <NoUserPage />;
        }
        return <LoadingPage />;
      }}
    />
  );
};
