import {
  IonCard,
  IonCardContent,
  IonCol,
  IonProgressBar,
  IonRow,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useApi } from 'src/hooks/useApi';
import { Organization } from 'src/interfaces/Organization';
import { Subtitle } from '../Subtitle';

interface AdminUser {
  app_user_id: number;
  app_role_name: string;
  organization_id: number;
  organization_name: string;
  first_name: string;
  last_name: string;
  email_address: string;
}

export const ViewAdminSupport: React.FC<{ search: string | null }> = ({
  search,
}) => {
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [admins, setAdmins] = useState<AdminUser[]>([]);
  const [adminsFiltered, setAdminsFiltered] = useState<AdminUser[]>([]);

  const organization: Organization | null = useSelector(
    (state: any) => state.app.organization
  );

  useEffect(() => {
    getAdmins();
  }, []);

  useEffect(() => {
    filterAdmins();
  }, [admins, search]);

  const getAdmins = () => {
    if (!organization) return;
    setLoading(true);
    api
      .get(`organization/${organization.organization_id}/admins`)
      .then((response) => {
        if (response.status === 200) {
          const data: AdminUser[] = response.data;
          setAdmins(data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const filterAdmins = () => {
    if (!admins) return;
    const standard = admins.filter(
      (admin) =>
        admin.first_name.toLowerCase().includes(search?.toLowerCase() || '') ||
        admin.last_name.toLowerCase().includes(search?.toLowerCase() || '') ||
        admin.email_address.toLowerCase().includes(search?.toLowerCase() || '')
    );
    setAdminsFiltered(standard);
  };

  return (
    <>
      <IonRow className="ion-padding-horizontal">
        <IonCol size="12">
          <p className="ion-no-margin font-size-small margin-bottom-sm">
            Your organization's Admins are listed below. They can help you with
            the following:
          </p>
          <ul className="ion-no-margin font-size-small">
            <li>Adding a new user</li>
            <li>Adding a new location</li>
            <li>Changing which location(s) you have access to</li>
          </ul>
        </IonCol>
        {/* Admin list */}
        <IonCol size="12">
          {loading ? (
            <IonProgressBar
              type="indeterminate"
              color="primary"
            ></IonProgressBar>
          ) : (
            <>
              {adminsFiltered.map((admin) => (
                <IonCard
                  key={admin.app_user_id}
                  style={{ marginBottom: 'var(--app-spacing-small)' }}
                >
                  <IonCardContent className="ion-no-padding padding-sm">
                    <IonRow className="ion-align-items-center">
                      <IonCol size="12" sizeMd="6">
                        <h3 className="ion-no-margin font-size-large">
                          {admin.first_name} {admin.last_name}
                        </h3>
                        <Subtitle>Admin</Subtitle>
                      </IonCol>
                      <IonCol size="12" sizeMd="6" className="ion-text-right">
                        <a
                          className="font-size-default"
                          href={`mailto:${admin.email_address}`}
                        >
                          {admin.email_address}
                        </a>
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              ))}
            </>
          )}
        </IonCol>
      </IonRow>
    </>
  );
};
