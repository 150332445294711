import { SandozConfig } from './supplier/SandozConfig';
import { DemoConfig } from './supplier/DemoConfig';
import { SafecorConfig } from './supplier/SafecorConfig';

export interface ItemCustomAttribute {
  key: string;
  label: string;
  transform: (value: any) => any;
}

export interface SupplierConfig {
  number: string;
  account: {
    transform?: (value: string) => string;
    accountKey: string;
    accountLabel: string;
    accountHelperText: string;
    accountRules: Array<{
      pattern: RegExp;
      message: string;
    }>;
    validationKey: string;
    validationLabel: string;
    validationHelperText: string;
    validationRules: Array<{
      pattern: RegExp;
      message: string;
    }>;
  };
}

// CUSTOM CONFIGURATIONS
export const supplierConfigurations: SupplierConfig[] = [
  DemoConfig,
  SandozConfig,
  SafecorConfig,
];

// DEFAULT CONFIGURATIONS OPTION
export const defaultSupplierConfig: SupplierConfig = {
  number: '',
  account: {
    accountKey: 'account_key',
    accountLabel: 'Account Number',
    accountHelperText: 'Enter an Account Number',
    accountRules: [
      {
        pattern: /.*/,
        message: '',
      },
    ],
    validationKey: 'dea_number',
    validationLabel: 'DEA Number',
    validationHelperText: 'Enter the DEA number associated with this account',
    validationRules: [
      {
        pattern: /.*/,
        message: '',
      },
    ],
    transform: (value: string): string => {
      return value;
    },
  },
};
