import {
  IonButton,
  IonCheckbox,
  IonCol,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonRow,
} from '@ionic/react';
import { ChipAccount } from 'src/components/buyer/ChipAccount';
import { Divider } from 'src/components/shared/Divider';
import { LoadingInline } from 'src/components/shared/LoadingInline';
import { useApi } from 'src/hooks/useApi';
import { Account } from 'src/interfaces/Account';
import { Location } from 'src/interfaces/Location';
import { Supplier } from 'src/interfaces/Supplier';
import { searchOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAccounts, setToast } from 'src/redux/appReducer';
import { SupplierConfig } from 'src/config/supplierConfigurations';

export const FormManageAccountsAdd: React.FC<{
  loading: boolean;
  setLoading: (value: boolean) => void;
  newAccountIds: number[];
  setNewAccountIds: (accountIds: number[]) => void;
  searched?: boolean;
  setSearched?: (value: boolean) => void;
  supplierConfig: SupplierConfig | null;
  supplier: Supplier;
  location: Location;
  submitTrigger: number;
}> = ({
  loading,
  setLoading,
  newAccountIds,
  setNewAccountIds,
  searched,
  setSearched,
  supplierConfig,
  supplier,
  location,
  submitTrigger,
}) => {
  const api = useApi();
  const dispatch = useDispatch();

  const [accountValue, setAccountValue] = useState<string | null>(null);
  const [validationValue, setValidationValue] = useState<string | null>(null);
  const [supplierAccounts, setSupplierAccounts] = useState<any[]>([]);

  useEffect(() => {
    if (submitTrigger > 0) {
      addAccounts();
    }
  }, [submitTrigger]);

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (loading || !accountValue || !validationValue) {
        return false;
      }
      onClickSearch();
    }
  };

  const onClickSearch = () => {
    setNewAccountIds([]);
    if (setSearched) {
      setSearched(true);
    }
    const params = {
      account_key: supplierConfig?.account.accountKey,
      account_value: accountValue,
      validation_value: validationValue,
    };
    setLoading(true);
    api
      .get(`supplier/${supplier.supplier_id}/validate_accounts`, params)
      .then((response) => {
        if (response.status === 200) {
          setSupplierAccounts(response.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleChange = (account_id: number, value: boolean) => {
    const tempArray = [...newAccountIds];
    if (value) {
      tempArray.push(account_id);
    } else {
      const index = tempArray.indexOf(account_id);
      if (index > -1) {
        tempArray.splice(index, 1);
      }
    }
    setNewAccountIds(tempArray);
  };

  const addAccounts = () => {
    if (!location) {
      return;
    }
    setLoading(true);
    const formData = [
      {
        account_ids: newAccountIds,
      },
    ];
    api
      .post(`location/${location.location_id}/account_map_location`, formData)
      .then((response) => {
        if (response.status === 201) {
          setNewAccountIds([]);
          dispatch(
            setToast({
              message: `Account${
                response.data.length > 0 ? 's' : ''
              } added Successfully!`,
            })
          );
          getAccounts();
          resetForm();
        }
        setLoading(false);
      });
  };

  const getAccounts = () => {
    api.get(`location/${location?.location_id}/accounts`).then((response) => {
      if (response.status === 200) {
        const data: Account[] = response.data;
        dispatch(setAccounts(data));
      }
    });
  };

  const resetForm = () => {
    setValidationValue(null);
    setAccountValue(null);
    setSupplierAccounts([]);
    if (setSearched) setSearched(false);
  };

  return (
    <div className="ion-padding-horizontal">
      <IonRow>
        <IonCol>
          {/* ADD ACCOUNTS */}
          {supplierConfig ? (
            <>
              <IonList lines="none">
                <IonItem disabled={loading} className="no-padding">
                  <IonInput
                    className="rounded"
                    type="text"
                    value={accountValue}
                    onIonChange={(e: any) => setAccountValue(e.detail.value)}
                    placeholder={`${supplierConfig.account.accountLabel}`}
                    onKeyDown={handleKeyDown}
                  />
                  {supplierConfig.account.accountHelperText ? (
                    <IonNote slot="helper">
                      {supplierConfig.account.accountHelperText}
                    </IonNote>
                  ) : null}
                </IonItem>
                <IonItem
                  disabled={loading}
                  className="no-padding margin-top-sm"
                >
                  <IonInput
                    className="rounded"
                    type="text"
                    value={validationValue}
                    onIonChange={(e: any) => setValidationValue(e.detail.value)}
                    placeholder={`${supplierConfig.account.validationLabel}`}
                    onKeyDown={handleKeyDown}
                  />
                  {supplierConfig.account.validationHelperText ? (
                    <IonNote slot="helper">
                      {supplierConfig.account.validationHelperText}
                    </IonNote>
                  ) : null}
                </IonItem>
              </IonList>
              <div className="fullWidth ion-margin-top ion-text-center">
                <IonButton
                  onClick={onClickSearch}
                  color="primary"
                  fill="outline"
                  shape="round"
                  disabled={loading || !accountValue || !validationValue}
                  type="submit"
                >
                  Search
                  <IonIcon slot="end" icon={searchOutline} />
                </IonButton>
              </div>
            </>
          ) : (
            <LoadingInline />
          )}
        </IonCol>
      </IonRow>
      {supplierAccounts.length > 0 ? (
        <>
          <Divider />
          <IonRow>
            <IonCol>
              <p className="ion-text-left ion-no-margin margin-bottom-sm font-weight-500">
                Select each account you would like to associate with this
                location:
              </p>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonList lines="none">
                {supplierAccounts.map(
                  (supplierAccount: Account, index: any) => {
                    return (
                      <IonItem disabled={loading} key={index}>
                        <IonCheckbox
                          slot="start"
                          onIonChange={(e: any) =>
                            handleChange(
                              supplierAccount.account_id,
                              e.detail.checked
                            )
                          }
                          checked={
                            newAccountIds.indexOf(supplierAccount.account_id) >
                            -1
                          }
                        />
                        <IonLabel>
                          <ChipAccount account={supplierAccount} />
                        </IonLabel>
                      </IonItem>
                    );
                  }
                )}
              </IonList>
            </IonCol>
          </IonRow>
        </>
      ) : null}
      {supplierAccounts.length === 0 && searched ? (
        <>
          <Divider />
          <IonRow>
            <IonCol>
              <p className="ion-text-left ion-no-margin margin-bottom-sm font-weight-500 font-style-italic">
                There were no accounts found that match the provided information
              </p>
            </IonCol>
          </IonRow>
        </>
      ) : null}
    </div>
  );
};
