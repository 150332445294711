import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonFooter,
  IonIcon,
  IonRow,
  IonToolbar,
} from '@ionic/react';
import { Page } from 'src/layouts/Page';

import '@ionic/react/css/ionic-swiper.css';
import 'swiper/css';

import { LogoTrulla } from 'src/components/shared/LogoTrulla';
import { chevronBackOutline } from 'ionicons/icons';

export const RequestDemoPage: React.FC = () => {
  const width = 625;
  const height = 700;
  const footerHeight = 44;

  return (
    <Page title="Request a Demo">
      <IonRow className="ion-margin-vertical">
        <IonCol className="ion-text-center">
          <LogoTrulla />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="ion-text-center">
          <IonCard
            style={{
              width: `${width}px`,
              height: `${height + footerHeight}px`,
              margin: '0 auto',
            }}
          >
            <IonCardContent
              className="ion-no-padding"
              style={{ height: `${height}px` }}
            >
              <iframe
                title="request-demo"
                width={`${width}px`}
                height={`${height}px`}
                src="https://forms.office.com/r/iE4ix7crKy?embed=true"
                style={{ border: 'none', maxWidth: '100%', maxHeight: '100vh' }}
                allowFullScreen={true}
              ></iframe>
            </IonCardContent>
            <IonFooter>
              <IonToolbar>
                <IonButtons slot="start">
                  <IonButton fill="clear" routerLink="/" routerDirection="back">
                    <IonIcon slot="start" icon={chevronBackOutline}></IonIcon>
                    Back
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonFooter>
          </IonCard>
        </IonCol>
      </IonRow>
    </Page>
  );
};
