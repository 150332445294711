import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import { useId } from 'react';
import { TermInput } from 'src/interfaces/TermInput';
import { FormManageTermInput, TermInputSchema } from './FormManageTermInput';
import './ModalManageTermInput.css';

export const ModalManageTermInput: React.FC<{
  isOpen?: boolean;
  onWillDismiss: (termInput?: TermInput) => void;
  termInput?: TermInput | null;
}> = ({ isOpen, onWillDismiss, termInput }) => {
  const formId = useId();
  const editing = !!termInput;
  return (
    <IonModal
      isOpen={isOpen}
      onIonModalWillDismiss={() => onWillDismiss()}
      className="modalManageTermInput"
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>{editing ? 'Edit Input' : 'Add Input'}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => onWillDismiss()} fill="clear">
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <FormManageTermInput
          formId={formId}
          termInput={termInput}
          onSubmit={(termInput?: TermInputSchema) => {
            if (termInput) {
              onWillDismiss(termInput);
            }
          }}
        />
      </IonContent>

      <IonFooter>
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton color="medium" onClick={() => onWillDismiss()}>
              Cancel
            </IonButton>
            <IonButton form={formId} type="submit">
              {editing ? 'Confirm' : 'Add'}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};
