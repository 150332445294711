import { IonCard, IonCol, IonRow } from '@ionic/react';
import { useParams } from 'react-router';
import { Page } from 'src/layouts/Page';

export const LocationPage: React.FC = () => {
  const { organization_id, location_id } = useParams<{
    organization_id: string;
    location_id: string;
  }>();
  return (
    <Page title="LocationPage">
      <IonRow>
        <IonCol>
          <IonCard>LocationPage</IonCard>
          <p>Organization ID: {organization_id}</p>
          <p>Location ID: {location_id}</p>
        </IonCol>
      </IonRow>
    </Page>
  );
};
