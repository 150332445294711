import { IonChip, IonIcon, IonRouterLink } from '@ionic/react';
import { ModalItemDetails } from './ModalItemDetails';
import { useUtils } from 'src/hooks/useUtils';
import { Account } from 'src/interfaces/Account';
import { ItemCatalog } from 'src/interfaces/ItemCatalog';
import { ItemPurchaseOrder } from 'src/interfaces/ItemPurchaseOrder';
import { ItemShoppingCart } from 'src/interfaces/ItemShoppingCart';
import { Supplier } from 'src/interfaces/Supplier';
import { searchOutline } from 'ionicons/icons';
import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { StockNumber } from '../StockNumber';
import { StockStatus } from '../StockStatus';

export const ItemDescriptionCol: React.FC<{
  item: ItemCatalog | ItemShoppingCart | ItemPurchaseOrder;
  account?: Account;
  clickable?: boolean;
}> = ({ item, account, clickable = true }) => {
  const utils = useUtils();
  const allSuppliers: Supplier[] | null = useSelector(
    (state: any) => state.app.allSuppliers
  );
  const [itemProperties, setItemProperties] = useState<any[]>([]);

  const supplierName = allSuppliers?.find(
    (supplier) => supplier.supplier_id === account?.supplier_id
  )?.name;
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (item) {
      const properties: any[] = [];
      if (item.item_ndc && item.item_ndc !== 'NONE') {
        properties.push(
          <span
            data-tooltip-id="global-tooltip"
            data-tooltip-place="bottom"
            data-tooltip-content="NDC"
          >
            {utils.formatNdc(item.item_ndc)}
          </span>
        );
      }
      if (item.supplier_item_number) {
        properties.push(
          <span
            data-tooltip-id="global-tooltip"
            data-tooltip-place="bottom"
            data-tooltip-content="Item Number"
          >
            {item.supplier_item_number}
          </span>
        );
      }
      if (item.custom_attributes?.on_hand_quantity) {
        properties.push(
          <StockNumber
            quantity={item.custom_attributes?.on_hand_quantity}
            updated={item.created}
          />
        );
      } else if (item.custom_attributes?.on_hand_status) {
        properties.push(
          <StockStatus
            status={item.custom_attributes?.on_hand_status}
            updated={item.created}
          />
        );
      }
      if (account?.supplier_id) {
        properties.push(
          <IonRouterLink
            routerLink={`/suppliers/${account?.supplier_id}`}
            routerDirection="forward"
            style={{ pointerEvents: 'all' }}
          >
            {supplierName}
          </IonRouterLink>
        );
      }
      setItemProperties(properties);
    } else {
      setItemProperties([]);
    }
  }, [item, account]);

  const SimilarityBadge: React.FC = () => {
    if ('similarity' in item) {
      let color = 'light';
      let text = '';
      let icon = null;

      if (item.similarity === 1) {
        color = 'success';
        text = 'Exact Match';
        icon = searchOutline;
      } else if (item.similarity > 0.6) {
        color = 'primary';
        text = 'Good Match';
        icon = searchOutline;
      } else if (item.similarity > 0.25) {
        color = 'warning';
        text = 'Possible Match';
        icon = searchOutline;
      } else if (item.similarity > 0.1) {
        color = 'danger';
        text = 'Poor Match';
        icon = searchOutline;
      }
      if (item.similarity > 0.1) {
        const confidence = Math.round(item.similarity * 100);
        return (
          <>
            <IonChip
              className="searchConfidenceChip"
              style={{ height: '25px' }}
              color={color}
              data-tooltip-id="global-tooltip"
              data-tooltip-place="bottom"
              data-tooltip-html={`
              <p class="ion-no-margin text-color-${color}">${text}</p>
              <p class="ion-no-margin">Confidence: <span class="text-color-${color}">${confidence}%</span></p>
            `}
            >
              {icon && <IonIcon icon={icon} color={color} />}
              <span className="font-size-xs">{confidence}%</span>
            </IonChip>
          </>
        );
      }
    }

    return null;
  };

  return (
    <>
      <div className="itemCol">
        <IonRouterLink
          onClick={() => {
            clickable && setIsOpen(true);
          }}
          className="font-size-default font-weight-500"
          color={clickable ? 'secondary' : 'dark'}
          style={{ cursor: clickable ? 'pointer' : undefined }}
        >
          {item.item_description}
        </IonRouterLink>
        <SimilarityBadge />
        <p>
          {itemProperties.map((itemProperty: any, index) => {
            return (
              <Fragment key={index}>
                {itemProperty}
                {index < itemProperties.length - 1 ? ' | ' : null}
              </Fragment>
            );
          })}
        </p>
      </div>

      <ModalItemDetails
        isOpen={isOpen}
        account={account}
        item={item}
        onDismiss={() => {
          setIsOpen(false);
        }}
      />
    </>
  );
};
