import { SupplierConfig } from 'src/config/supplierConfigurations';

export const DemoConfig: SupplierConfig = {
  number: 'DEMO', // Demo Manufacturer
  account: {
    accountKey: 'account_id',
    accountLabel: 'Account Number',
    accountHelperText: 'This is provided by Demo Manufacturer',
    accountRules: [
      {
        pattern: /.*/,
        message: '',
      },
    ],
    validationKey: 'zip',
    validationLabel: 'Zip Code',
    validationHelperText:
      'Enter the zip code associated with your Account number',
    validationRules: [
      {
        pattern: /.*/,
        message: '',
      },
    ],
  },
};
