import {
  IonContent,
  IonFooter,
  IonGrid,
  IonPage,
  IonToolbar,
  useIonViewWillEnter,
} from '@ionic/react';
import './Page.css';

export const Page: React.FC<{
  children: any;
  footer?: any;
  className?: string;
  title?: string;
  updateDocTitle?: boolean;
}> = ({ children, footer, className, title, updateDocTitle = true }) => {
  useIonViewWillEnter(() => {
    if (title && updateDocTitle) {
      document.title = `${title} | Trulla Direct`;
    } else {
      document.title = 'Trulla Direct';
    }
  });

  return (
    <IonPage className={`pageLayout ${className ? className : ''}`}>
      <IonContent className="ion-padding">
        <IonGrid className="pageLayoutGrid">{children}</IonGrid>
      </IonContent>

      {footer && (
        <IonFooter className="pageActions">
          <IonToolbar>
            <div className="footerContainer">{footer}</div>
          </IonToolbar>
        </IonFooter>
      )}
    </IonPage>
  );
};
