import { AlertButton, useIonAlert } from '@ionic/react';

export interface ShowAlertOptions {
  color?: string /* 'primary' | 'secondary' | 'success' | 'danger' */;
  header?: string;
  subHeader?: string;
  message?: string;
  buttons?: AlertButton[];
  cancelText?: string;
  confirmText?: string;
}

export const useAlert = () => {
  const [presentAlert] = useIonAlert();

  const showAlert = (
    options: ShowAlertOptions,
    onDismiss: (confirm: boolean) => void
  ) => {
    presentAlert({
      header: options.header,
      subHeader: options.subHeader,
      message: options.message,
      buttons: [
        {
          role: 'cancel',
          text: options.cancelText || 'Cancel',
          handler: () => onDismiss(false),
        },
        {
          role: 'confirm',
          text: options.confirmText || 'Confirm',
          cssClass: options.color ? `text-color-${options.color}` : '',
          handler: () => onDismiss(true),
        },
      ],
    });
  };

  return { showAlert };
};
