import { IonCard, IonCol, IonRow } from '@ionic/react';
import { TableSuppliers } from './TableSuppliers';
import { Breadcrumb, Breadcrumbs } from 'src/components/shared/Breadcrumbs';
import { Page } from 'src/layouts/Page';

export const SettingSuppliersPage: React.FC = () => {
  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Settings', path: '/settings', direction: 'back' },
    { label: 'Suppliers' },
  ];

  return (
    <Page title="Supplier Settings">
      <Breadcrumbs breadcrumbs={breadcrumbs} title="Supplier Settings" />
      <IonRow>
        <IonCol>
          <IonCard>
            <TableSuppliers />
          </IonCard>
        </IonCol>
      </IonRow>
    </Page>
  );
};
