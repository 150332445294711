import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { useEndpoints } from 'src/hooks/useEndpoints';
import { useUtils } from 'src/hooks/useUtils';
import { AppUserSelf } from 'src/interfaces/AppUserSelf';
import { Location } from 'src/interfaces/Location';
import { Organization } from 'src/interfaces/Organization';
import { Supplier } from 'src/interfaces/Supplier';
import { SupplierSettings } from 'src/interfaces/SupplierSettings';
import { setAllSuppliers, setLocation } from 'src/redux/appReducer';
import { logout } from 'src/redux/authReducer';
import {
  setSettingAccountSyncEnabled,
  setSettingApplicationEnabled,
  setSettingApplicationTerms,
  setSettingCatalogSyncEnabled,
  setSupplier,
} from 'src/redux/supplierReducer';

export const EffectsManager: React.FC = () => {
  const {
    getAuthUser,
    getOrganization,
    getSupplier,
    getAllSuppliers,
    getSelectedLocation,
    getAccounts,
    getShoppingCarts,
    getSupplierSettings,
  } = useEndpoints();
  const { instance, inProgress, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();
  const { isSupplierOrganization, isBuyerOrganization } = useUtils();

  // app state
  const authUser: AppUserSelf | null = useSelector(
    (state: any) => state.auth.user
  );
  const selectedLocation: Location | null = useSelector(
    (state: any) => state.app.selectedLocation
  );
  const organization: Organization | null = useSelector(
    (state: any) => state.app.organization
  );

  // supplier state
  const selectedSupplier: Supplier | null = useSelector(
    (state: any) => state.supplier.supplier
  );
  const supplierSettings: SupplierSettings | null = useSelector(
    (state: any) => state.supplier.settings
  );

  const timeoutValue = process.env.REACT_APP_SESSION_TIMEOUT || 60;

  const { isIdle } = useIdleTimer({
    timeout: 60000 * +timeoutValue,
    onIdle: () => {
      if (isAuthenticated) {
        dispatch(logout());
        instance.logoutRedirect();
      }
    },
    crossTab: true,
    syncTimers: 1000,
  });

  // run once on mount
  useEffect(() => {
    window.addEventListener('load', getLastActive);
    window.addEventListener('beforeunload', setLastActive);
    return () => {
      window.removeEventListener('load', getLastActive);
      window.removeEventListener('beforeunload', setLastActive);
    };
  }, []);

  // authUser
  useEffect(() => {
    if (authUser) {
      // update inline manual user info
      window.InlineManual('update', {
        email: authUser.email_address,
        name: `${authUser.first_name} ${authUser.last_name}`,
      });
      getOrganization();
    }
  }, [authUser]);

  // isAuthenticated
  useEffect(() => {
    if (isAuthenticated) {
      if (accounts.length > 0) {
        instance.setActiveAccount(accounts[0]);
      }
      getAuthUser();
    }
  }, [isAuthenticated]);

  // organization
  useEffect(() => {
    if (organization) {
      if (isSupplierOrganization(organization)) {
        getSupplier();
      } else {
        dispatch(setSupplier(null));
      }
      if (isBuyerOrganization(organization)) {
        // only if user is a buyer.
        getAllSuppliers();
        getSelectedLocation();
      } else {
        dispatch(setAllSuppliers(null));
        dispatch(setLocation(null));
      }
    }
  }, [organization]);

  // selectedLocation
  useEffect(() => {
    if (selectedLocation) {
      getAccounts();
      getShoppingCarts();
    }
  }, [selectedLocation]);

  // selectedSupplier
  useEffect(() => {
    if (selectedSupplier) {
      getSupplierSettings();
    }
  }, [selectedSupplier]);

  // supplierSettings
  useEffect(() => {
    if (supplierSettings) {
      // set application settings
      dispatch(
        setSettingApplicationEnabled(
          supplierSettings.account?.application?.enabled || false
        )
      );
      dispatch(
        setSettingApplicationTerms(
          supplierSettings.account?.application?.terms || []
        )
      );
      dispatch(
        setSettingAccountSyncEnabled(
          supplierSettings.account?.sync?.enabled || false
        )
      );
      dispatch(
        setSettingCatalogSyncEnabled(
          supplierSettings.catalog?.sync?.enabled || false
        )
      );
    } else {
      dispatch(setSettingApplicationEnabled(false));
      dispatch(setSettingApplicationTerms([]));
      dispatch(setSettingAccountSyncEnabled(false));
      dispatch(setSettingCatalogSyncEnabled(false));
    }
  }, [supplierSettings]);

  const getLastActive = () => {
    let lastActive = localStorage.getItem('lastActive');
    if (lastActive && localStorage.getItem('td-token')) {
      let currentDate = Date.now();
      const timeDifference = currentDate - +lastActive;

      // Convert the time difference to minutes
      const minutesDifference = Math.floor(timeDifference / (1000 * 60));

      if (minutesDifference > +timeoutValue) {
        dispatch(logout());
        instance.logoutRedirect();
      }

      if (lastActive) {
        localStorage.removeItem('lastActive');
      }
    }
  };

  const setLastActive = () => {
    let currentDate = Date.now();
    if (localStorage.getItem('td-token')) {
      localStorage.setItem('lastActive', currentDate.toString());
    }
  };

  return null;
};
