import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormError } from 'src/components/shared/FormError';
import { useEndpoints } from 'src/hooks/useEndpoints';
import { OrganizationType } from 'src/interfaces/OrganizationType';

export const SelectOrganizationType: React.FC<{
  value?: number | null;
  onIonChange?: (e: any) => void;
  disabled?: boolean;
  errorMessage?: string;
}> = ({ value, onIonChange, disabled = false, errorMessage }) => {
  const { getOrganizationTypes } = useEndpoints();
  const organizationTypes: OrganizationType[] | null = useSelector(
    (state: any) => state.admin.organizationTypes
  );
  const organizationTypesLoading: boolean = useSelector(
    (state: any) => state.admin.organizationTypesLoading
  );

  useEffect(() => {
    if (!organizationTypes) {
      getOrganizationTypes();
    }
  }, []);

  return (
    <IonItem
      className={`no-padding ${errorMessage ? 'ion-invalid' : 'ion-valid'}`}
      detail={false}
    >
      <IonLabel position="stacked">Organization Type</IonLabel>
      <IonSelect
        value={value}
        onIonChange={onIonChange}
        interface="popover"
        interfaceOptions={{
          alignment: 'end',
          showBackdrop: true,
          dismissOnSelect: true,
        }}
        disabled={disabled || organizationTypesLoading}
        className="rounded"
      >
        {organizationTypes?.map((organizationType: OrganizationType) => {
          return (
            <IonSelectOption
              key={organizationType.organization_type_id}
              value={organizationType.organization_type_id}
            >
              {organizationType.name}
            </IonSelectOption>
          );
        })}
      </IonSelect>
      {errorMessage && <FormError>{errorMessage}</FormError>}
    </IonItem>
  );
};
