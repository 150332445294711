import {
  IonItem,
  IonLabel,
  IonProgressBar,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import { useApi } from 'src/hooks/useApi';
import { AppRole } from 'src/interfaces/AppRole';
import { useEffect, useState } from 'react';
import { FormError } from './FormError';

export const SelectRole: React.FC<{
  value?: number;
  onIonChange?: (e: any) => void;
  disabled?: boolean;
  errorMessage?: string;
  className?: string;
}> = ({ value, onIonChange, disabled, errorMessage, className }) => {
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [appRoles, setAppRoles] = useState<AppRole[]>([]);
  useEffect(() => {
    getAppRoles();
  }, []);

  const getAppRoles = () => {
    setLoading(true);
    api
      .get('app_roles')
      .then(({ data }) => {
        setAppRoles(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  return (
    <IonItem
      className={`no-padding ${errorMessage ? 'ion-invalid' : 'ion-valid'}
          ${className ? className : ''}
        `}
      detail={false}
    >
      <IonLabel position="stacked">Role</IonLabel>
      <IonSelect
        value={value}
        onIonChange={onIonChange}
        disabled={disabled || loading}
        interface="popover"
        interfaceOptions={{
          dismissOnSelect: true,
          size: 'cover',
          cssClass: 'selectRole',
        }}
        placeholder="Select Role"
        className="rounded"
      >
        {appRoles.map((appRole: AppRole) => {
          return (
            <IonSelectOption
              key={appRole.app_role_id}
              value={appRole.app_role_id}
            >
              {appRole.description}
            </IonSelectOption>
          );
        })}
      </IonSelect>
      {loading && (
        <div
          style={{
            width: 'calc(100% - 18px)',
            margin: '0 auto',
          }}
        >
          <IonProgressBar color="primary" type="indeterminate" />
        </div>
      )}
      {errorMessage && <FormError>{errorMessage}</FormError>}
    </IonItem>
  );
};
