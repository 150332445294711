import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
  IonText,
} from '@ionic/react';
import { ellipsisVerticalCircleOutline } from 'ionicons/icons';
import { DataTableItemAction } from './interfaces/DataTableItemAction';

export const DataTableActions: React.FC<{
  actions: (item: any) => DataTableItemAction[];
  item: any;
}> = ({ actions, item }) => {
  const randomId = Math.random().toString(16).substring(2, 17);

  return (
    <div>
      <IonButton
        id={randomId}
        onClick={(e) => e.stopPropagation()}
        color="medium"
        fill="clear"
        className="ion-no-padding"
        style={{ height: 'auto' }}
      >
        <IonIcon
          slot="icon-only"
          icon={ellipsisVerticalCircleOutline}
        ></IonIcon>
      </IonButton>
      <IonPopover
        className="dataTableActionsPopover"
        triggerAction="click"
        trigger={randomId}
        dismissOnSelect={true}
      >
        <IonContent color="transparent">
          <IonList lines="none" color="transparent">
            {actions(item).map((action) => {
              return (
                <IonItem
                  key={action.label}
                  button
                  detail={false}
                  routerLink={
                    action.routerLink ? action.routerLink(item) : undefined
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    action.callback(item);
                  }}
                >
                  <IonText slot="start" color={action.color}>
                    {action.icon}
                  </IonText>
                  <IonLabel color={action.color}>{action.label}</IonLabel>
                </IonItem>
              );
            })}
          </IonList>
        </IonContent>
      </IonPopover>
    </div>
  );
};
