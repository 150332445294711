import {
  IonAccordion,
  IonAccordionGroup,
  IonContent,
  IonFooter,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  useIonAlert,
} from '@ionic/react';

import { useMsal } from '@azure/msal-react';
import {
  faBooks,
  faBoxesStacked,
  faFileInvoiceDollar,
  faHeadset,
  faLifeRing,
  faScrewdriverWrench,
  faTags,
  faUsers,
} from '@fortawesome/pro-light-svg-icons';
import { faHouse } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cartOutline, logOutOutline, settingsOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Divider } from 'src/components/shared/Divider';
import { LogoTrulla } from 'src/components/shared/LogoTrulla';
import { useUtils } from 'src/hooks/useUtils';
import { AppUserSelf } from 'src/interfaces/AppUserSelf';
import { Location } from 'src/interfaces/Location';
import { Organization } from 'src/interfaces/Organization';
import { setContactUsOpen } from 'src/redux/appReducer';
import { logout } from 'src/redux/authReducer';
import './Menu.css';

interface AppPage {
  title: string;
  url?: string | null;
  icon: any;
  open?: boolean | null;
  routerDirection?: 'root' | 'forward' | 'back' | 'none';
  exact?: boolean;
  isVisible: boolean;
  disabled?: boolean;
  children?: ChildPage[];
}
interface ChildPage {
  title: string;
  url: string;
  routerDirection: 'root' | 'forward' | 'back' | 'none';
  exact: boolean;
  isVisible: boolean;
  disabled?: boolean;
}

export const MenuItems: React.FC = () => {
  const {
    isBuyerOrganization,
    isSupplierOrganization,
    isAnalyst,
    isBuyer,
    isAdmin,
    isSuperAdmin,
  } = useUtils();
  const { instance } = useMsal();
  const authUser: AppUserSelf | null = useSelector(
    (state: any) => state.auth.user
  );
  const organization: Organization | null = useSelector(
    (state: any) => state.app.organization
  );
  const selectedLocation: Location | null = useSelector(
    (state: any) => state.app.selectedLocation
  );
  const accountApplicationEnabled: boolean = useSelector(
    (state: any) => state.supplier.settingApplicationEnabled
  );
  const [appPages, setAppPages] = useState<AppPage[]>([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const [presentAlert] = useIonAlert();

  const buyerPages: AppPage[] = [
    {
      title: 'Home',
      url: '/',
      icon: faHouse,
      routerDirection: 'root',
      exact: true,
      isVisible: true,
    },
    {
      title: 'Suppliers',
      url: '/suppliers',
      icon: faBoxesStacked,
      routerDirection: 'root',
      exact: false,
      isVisible: !!selectedLocation,
    },
    {
      title: 'Order',
      icon: cartOutline,
      isVisible: !!selectedLocation,
      children: [
        {
          title: 'Shopping Cart',
          url: '/orders/cart',
          routerDirection: 'root',
          exact: false,
          isVisible:
            isBuyer(authUser) || isAdmin(authUser) || isSuperAdmin(authUser),
        },
        {
          title: 'Order History',
          url: '/orders',
          routerDirection: 'root',
          exact: true,
          isVisible: true,
        },
      ],
    },
    // {
    //   title: 'Analytics',
    //   url: '/analytics',
    //   icon: pieChartOutline,
    //   routerDirection: 'root',
    //   exact: false,
    //   isVisible: !!selectedLocation,
    // },
    {
      title: 'Settings',
      icon: settingsOutline,
      isVisible: isAdmin(authUser) || isSuperAdmin(authUser),
      children: [
        {
          title: 'General',
          url: '/settings',
          routerDirection: 'root',
          exact: true,
          isVisible: true,
        },
        {
          title: 'Locations',
          url: '/settings/locations',
          routerDirection: 'root',
          exact: false,
          isVisible: true,
        },
        {
          title: 'Users',
          url: '/settings/users',
          routerDirection: 'root',
          exact: false,
          isVisible: true,
        },
        {
          title: 'Suppliers',
          url: '/settings/suppliers',
          routerDirection: 'root',
          exact: false,
          isVisible: true,
        },
      ],
    },
  ];

  const supplierPages: AppPage[] = [
    {
      title: 'Home',
      url: '/',
      icon: faHouse,
      routerDirection: 'root',
      exact: false,
      isVisible: true,
    },
    {
      title: 'Sales',
      url: '/supplier/sales',
      icon: faFileInvoiceDollar,
      routerDirection: 'root',
      exact: false,
      isVisible: true,
      disabled: true,
    },
    {
      title: 'Accounts',
      icon: faUsers,
      isVisible: !!authUser && (isAdmin(authUser) || isSuperAdmin(authUser)),
      children: [
        {
          title: 'Overview',
          url: '/supplier/accounts',
          routerDirection: 'root',
          exact: false,
          isVisible: true,
          disabled: true,
        },
        {
          title: 'Applications',
          url: '/supplier/accounts/applications',
          routerDirection: 'root',
          exact: false,
          isVisible: accountApplicationEnabled,
        },
      ],
    },
    {
      title: 'Catalogs',
      url: '/supplier/catalogs',
      icon: faBooks,
      routerDirection: 'root',
      exact: false,
      isVisible: true,
      disabled: true,
    },
    // {
    //   title: 'Returns',
    //   url: '/supplier/returns',
    //   icon: faArrowTurnDownLeft,
    //   routerDirection: 'root',
    //   exact: false,
    //   isVisible: true,
    // },
    {
      title: 'Deals',
      url: '/supplier/deals',
      icon: faTags,
      routerDirection: 'root',
      exact: false,
      isVisible: false,
      disabled: true,
    },
    {
      title: 'Settings',
      icon: settingsOutline,
      isVisible: isAdmin(authUser) || isSuperAdmin(authUser),
      children: [
        {
          title: 'General',
          url: '/supplier/settings',
          routerDirection: 'root',
          exact: true,
          isVisible: true,
        },
        {
          title: 'Terms Documents',
          url: '/supplier/settings/terms',
          routerDirection: 'root',
          exact: false,
          isVisible: true,
        },
        {
          title: 'Users',
          url: '/supplier/settings/users',
          routerDirection: 'root',
          exact: false,
          isVisible: true,
        },
      ],
    },
  ];

  const superAdminPages: AppPage[] = [
    {
      title: 'Trulla Admin',
      icon: faScrewdriverWrench,
      isVisible: isSuperAdmin(authUser),
      children: [
        {
          title: 'Organizations',
          url: '/admin/organizations',
          routerDirection: 'root',
          exact: false,
          isVisible: true,
        },
        {
          title: 'Registration Requests',
          url: '/admin/requests',
          routerDirection: 'root',
          exact: false,
          isVisible: true,
        },
      ],
    },
  ];

  useEffect(() => {
    if (organization) {
      if (isBuyerOrganization(organization)) {
        setAppPages([...buyerPages, ...superAdminPages]);
      } else if (isSupplierOrganization(organization)) {
        setAppPages([...supplierPages, ...superAdminPages]);
      }
    }
  }, [authUser, organization, selectedLocation, accountApplicationEnabled]);

  /**
   * Determine whether or not a menu item should be considered "active"
   * @param page
   * @returns
   */
  const menuItemActive = ({ url, exact }: AppPage | ChildPage): boolean => {
    if (!url) return false;
    if (exact) return url === location.pathname;
    const r = new RegExp(`^${url}`, 'i');
    return r.test(location.pathname);
  };

  return (
    <IonAccordionGroup>
      {appPages.map((page) => {
        if (!page.isVisible) {
          return null;
        }
        return (
          <div key={page.title}>
            {page.url && (
              <IonMenuToggle autoHide={false}>
                <IonItem
                  className={menuItemActive(page) ? 'selected' : ''}
                  routerLink={page.url ? page.url : undefined}
                  routerDirection={page.routerDirection}
                  lines="none"
                  detail={false}
                  disabled={page.disabled}
                >
                  {(typeof page.icon === 'string' && (
                    <IonIcon slot="start" icon={page.icon} />
                  )) || (
                    <span slot="start">
                      <FontAwesomeIcon icon={page.icon} />
                    </span>
                  )}
                  <IonLabel>
                    {page.title}
                    {page.disabled && ' (Coming Soon)'}
                  </IonLabel>
                </IonItem>
              </IonMenuToggle>
            )}
            {page.children && page.children.length > 0 && (
              <IonAccordion>
                <IonItem slot="header" lines="none">
                  {(typeof page.icon === 'string' && (
                    <IonIcon slot="start" icon={page.icon} />
                  )) || (
                    <span slot="start">
                      <FontAwesomeIcon icon={page.icon} />
                    </span>
                  )}
                  <IonLabel>{page.title}</IonLabel>
                </IonItem>
                <div slot="content">
                  {page.children.map((child) => {
                    if (child.isVisible) {
                      return (
                        <IonMenuToggle key={child.title} autoHide={false}>
                          <IonItem
                            className={menuItemActive(child) ? 'selected' : ''}
                            routerLink={child.url}
                            routerDirection={child.routerDirection}
                            lines="none"
                            detail={false}
                            disabled={child.disabled}
                          >
                            <IonLabel>
                              {child.title}
                              {child.disabled && ' (Coming Soon)'}
                            </IonLabel>
                          </IonItem>
                        </IonMenuToggle>
                      );
                    }
                    return null;
                  })}
                </div>
              </IonAccordion>
            )}
          </div>
        );
      })}
    </IonAccordionGroup>
  );
};
