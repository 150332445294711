import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useUtils } from 'src/hooks/useUtils';
import { AppUserSelf } from 'src/interfaces/AppUserSelf';
import { Organization } from 'src/interfaces/Organization';
import { LoadingPage } from 'src/pages/shared/LoadingPage';
import { NotFoundPage } from 'src/pages/shared/NotFoundPage';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

export const OrgSupplierRoute = ({ component: Component, ...rest }: any) => {
  const utils = useUtils();
  const isAuthenticated = useIsAuthenticated();
  const { inProgress, accounts } = useMsal();
  const authUser: AppUserSelf | null = useSelector(
    (state: any) => state.auth.user
  );
  const organization: Organization | null = useSelector(
    (state: any) => state.app.organization
  );

  return (
    <Route
      {...rest}
      render={(props) => {
        if (
          isAuthenticated &&
          authUser &&
          utils.isSupplierOrganization(organization)
        ) {
          return <Component {...props} />;
        } else if (authUser && !utils.isSupplierOrganization(organization)) {
          return <NotFoundPage />;
        } else if (
          !isAuthenticated &&
          inProgress !== InteractionStatus.Startup &&
          inProgress !== InteractionStatus.HandleRedirect &&
          accounts.length === 0
        ) {
          return <Redirect to="/login" />;
        }
        return <LoadingPage />;
      }}
    />
  );
};
