import {
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonTitle,
  IonToggle,
  IonToolbar,
} from '@ionic/react';
import {
  addOutline,
  closeOutline,
  cloudUploadOutline,
  settingsSharp,
} from 'ionicons/icons';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumb, Breadcrumbs } from 'src/components/shared/Breadcrumbs';
import { Subtitle } from 'src/components/shared/Subtitle';
import { Page } from 'src/layouts/Page';
import { setSettingCatalogSyncEnabled } from 'src/redux/supplierReducer';
import { TableSupplierCatalogs } from './TableSupplierCatalogs';
import styles from '../supplierStyles.module.css';
import { FormInputLegacy } from 'src/components/shared/FormInputLegacy';

export const CatalogsPage: React.FC = () => {
  const dispatch = useDispatch();
  const [addCatalogOpen, setAddCatalogOpen] = useState(false);
  const catalogSync: boolean = useSelector(
    (state: any) => state.supplier.settingCatalogSyncEnabled
  );

  const [isOpen, setIsOpen] = useState(false);

  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Catalogs' },
  ];

  const onDismiss = () => {
    setIsOpen(false);
    setAddCatalogOpen(false);
  };

  return (
    <Page title="Supplier Catalogs">
      <Breadcrumbs breadcrumbs={breadcrumbs}>
        {!catalogSync ? (
          <>
            <IonButton
              disabled={catalogSync}
              size="small"
              data-tooltip-id="global-tooltip"
              data-tooltip-content="CSV import"
            >
              Upload Catalog
              <IonIcon slot="end" icon={cloudUploadOutline} />
            </IonButton>
            <IonButton
              disabled={catalogSync}
              size="small"
              onClick={() => setAddCatalogOpen(true)}
            >
              Add item
              <IonIcon slot="end" icon={addOutline} />
            </IonButton>
          </>
        ) : null}
        <IonButton
          fill="clear"
          size="small"
          color="medium"
          onClick={() => setIsOpen(true)}
        >
          <IonIcon slot="icon-only" icon={settingsSharp} />
        </IonButton>
      </Breadcrumbs>
      <IonRow>
        <IonCol>
          <IonCard>
            <TableSupplierCatalogs catalogSync={catalogSync} />
          </IonCard>
        </IonCol>
      </IonRow>
      <IonModal
        id="modalCatalogSettings"
        isOpen={isOpen}
        onDidDismiss={onDismiss}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>Catalog Settings</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={onDismiss}>
                <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonList lines="full">
            <IonItem className="ion-margin-bottom">
              <IonLabel>Enable Catalog Sync</IonLabel>
              <IonToggle
                checked={catalogSync}
                onIonChange={(e: any) =>
                  dispatch(setSettingCatalogSyncEnabled(e.detail.checked))
                }
              />

              <div slot="helper">
                <Subtitle>Catalogs will be synced through the vendor.</Subtitle>
                <Subtitle className="text-style-italic font-weight-500">
                  Note: Turning this on disables the ability to upload and add
                  catalogs within Trulla Direct
                </Subtitle>
              </div>
            </IonItem>
          </IonList>
        </IonContent>
      </IonModal>
      <IonModal
        className={styles.accountModal}
        isOpen={addCatalogOpen}
        onDidDismiss={onDismiss}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>Add Item</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={onDismiss} fill="clear">
                <IonIcon icon={closeOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent className="ion-padding-horizontal ion-padding-bottom">
          <IonList lines="none">
            <IonRow>
              <IonCol size="12">
                <FormInputLegacy
                  value={null}
                  setValue={(value) => console.log(value)}
                  name="itemNumber"
                  label="Item Number"
                  placeholder=""
                  labelPosition="stacked"
                  required={true}
                  rounded={true}
                />
              </IonCol>
              <IonCol size="12">
                <FormInputLegacy
                  value={null}
                  setValue={(value) => console.log(value)}
                  name="ndc"
                  label="NDC"
                  placeholder=""
                  labelPosition="stacked"
                  required={true}
                  rounded={true}
                />
              </IonCol>
              <IonCol size="12">
                <FormInputLegacy
                  value={null}
                  setValue={(value) => console.log(value)}
                  name="description"
                  label="Description"
                  placeholder="Lorem Ipsum"
                  labelPosition="stacked"
                  required={true}
                  rounded={true}
                />
              </IonCol>
              <IonCol size="12">
                <FormInputLegacy
                  value={null}
                  setValue={(value) => console.log(value)}
                  name="price"
                  label="Price"
                  placeholder=""
                  labelPosition="stacked"
                  required={true}
                  rounded={true}
                />
              </IonCol>
            </IonRow>
          </IonList>
        </IonContent>
        <IonFooter>
          <IonToolbar>
            <div slot="end">
              <IonButton
                color="dark"
                size="small"
                fill="clear"
                onClick={onDismiss}
              >
                Cancel
              </IonButton>
              <IonButton size="small" onClick={onDismiss}>
                Add <IonIcon icon={addOutline} slot="end" />
              </IonButton>
            </div>
          </IonToolbar>
        </IonFooter>
      </IonModal>
    </Page>
  );
};
