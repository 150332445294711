import { zodResolver } from '@hookform/resolvers/zod';
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonList,
  IonRow,
} from '@ionic/react';
import { SelectGpo } from 'src/components/shared/SelectGpo';
import { FormInput } from 'src/components/shared/FormInput';
import { useUtils } from 'src/hooks/useUtils';
import { AppUserSelf } from 'src/interfaces/AppUserSelf';
import { Organization } from 'src/interfaces/Organization';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { v } from 'src/utils/validators';
import { z } from 'zod';

const applicationStep1Schema = z
  .object({
    orgName: v.organizationName(),
    orgContactName: z
      .string()
      .min(1, { message: 'Organization Contact is required' })
      .max(50, { message: 'Organization Contact is too long' }),
    orgContactEmail: v.email(),
    primaryGpo: z
      .string()
      .min(1, { message: 'Primary GPO Affiliation is required' })
      .max(50, { message: 'Field is too long' }),
    otherGpo: z.string().max(50, { message: 'Field is too long' }),
    dscsaContactName: z
      .string()
      .max(50, { message: 'DSCSA Contact is too long' })
      .optional(),
    dscsaContactEmail: z.string().email().optional().or(z.literal('')),
    dscsaContactPhone: v.phone('DSCSA Contact Phone', false),
    dscsaContactPhoneExt: z.string().optional(),
  })
  .refine(
    (schema) =>
      schema.primaryGpo === 'Other' &&
      (schema.otherGpo === undefined || schema.otherGpo === '')
        ? false
        : true,
    {
      message: 'This field is required',
      path: ['otherGpo'],
    }
  );

export type ApplicationStep1Schema = z.infer<typeof applicationStep1Schema>;

export const ApplicationStep1: React.FC<{
  formId: string;
  loading?: boolean;
  setIsValid: (isValid: boolean) => void;
  setIsSubmitted: (isSubmitted: boolean) => void;
  onSubmitted: (data: ApplicationStep1Schema) => void;
}> = ({ formId, loading, setIsValid, setIsSubmitted, onSubmitted }) => {
  const utils = useUtils();
  const organization: Organization | null = useSelector(
    (state: any) => state.app.organization
  );
  const authUser: AppUserSelf | null = useSelector(
    (state: any) => state.auth.user
  );
  const {
    handleSubmit,
    control,
    setValue,
    register,
    getValues,
    watch,
    formState: { errors, isSubmitted, isValid },
  } = useForm<ApplicationStep1Schema>({
    resolver: zodResolver(applicationStep1Schema),
    defaultValues: {
      orgName: organization?.name || '',
      orgContactName: authUser ? utils.getFullName(authUser) : '',
      orgContactEmail: authUser?.email_address || '',
      primaryGpo: '',
      otherGpo: '',
      dscsaContactName: '',
      dscsaContactEmail: '',
      dscsaContactPhone: '',
      dscsaContactPhoneExt: '',
    },
  });

  const [isOther, setIsOther] = useState<boolean>(false);

  const gpoValue = watch('primaryGpo');

  useEffect(() => {
    setIsValid(isValid);
  }, [isValid]);

  useEffect(() => {
    setIsSubmitted(isSubmitted);
  }, [isSubmitted]);

  useEffect(() => {
    setIsOther(gpoValue === 'Other');
  }, [gpoValue]);

  useEffect(() => {
    if (!isOther) {
      setValue('otherGpo', '');
    }
  }, [isOther]);

  const onSubmit = (data: ApplicationStep1Schema) => {
    setIsValid(true);
    onSubmitted(data);
  };

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <IonRow>
        <IonCol size="12" sizeLg="6">
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>Organization</IonCardTitle>
            </IonCardHeader>
            <IonCardContent className="ion-padding">
              <IonList lines="none">
                <IonRow>
                  <IonCol>
                    <FormInput
                      {...register('orgName')}
                      label="Organization Name"
                      disabled={loading}
                      onIonChange={(e) => {
                        setValue('orgName', e.detail.value);
                      }}
                      placeholder="Organization Name"
                      helperText="Legal Entity"
                      errorMessage={errors.orgName?.message}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <FormInput
                      {...register('orgContactName')}
                      label="Contact Name"
                      disabled={loading}
                      onIonChange={(e) => {
                        setValue('orgContactName', e.detail.value);
                      }}
                      placeholder="John Doe"
                      errorMessage={errors.orgContactName?.message}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <FormInput
                      {...register('orgContactEmail')}
                      label="Contact Email"
                      disabled={loading}
                      onIonChange={(e) => {
                        setValue('orgContactEmail', e.detail.value);
                      }}
                      placeholder="contact@organization.com"
                      errorMessage={errors.orgContactEmail?.message}
                    />
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol size="12" sizeLg="6">
                    <Controller
                      name="primaryGpo"
                      control={control}
                      render={({ field }) => {
                        return (
                          <SelectGpo
                            value={field.value}
                            disabled={loading}
                            errorMessage={errors.primaryGpo?.message}
                            onIonChange={(e) => {
                              setValue('primaryGpo', e.detail.value);
                            }}
                          />
                        );
                      }}
                    />
                  </IonCol>
                  {isOther ? (
                    <IonCol size="12" sizeLg="6">
                      <FormInput
                        {...register('otherGpo')}
                        label="Other"
                        disabled={loading}
                        onIonChange={(e) => {
                          setValue('otherGpo', e.detail.value);
                        }}
                        errorMessage={errors.otherGpo?.message}
                      />
                    </IonCol>
                  ) : null}
                </IonRow>
              </IonList>
            </IonCardContent>
          </IonCard>
        </IonCol>
        <IonCol size="12" sizeLg="6">
          <IonCard className="fullWidth ion-text-left">
            <IonCardHeader>
              <IonCardTitle>DSCSA Contact</IonCardTitle>
            </IonCardHeader>
            <IonCardContent className="ion-padding">
              <IonList lines="none">
                <IonRow>
                  <IonCol size="12">
                    <FormInput
                      {...register('dscsaContactName')}
                      label="DSCSA Contact Name (optional)"
                      disabled={loading}
                      onIonChange={(e) => {
                        setValue('dscsaContactName', e.detail.value);
                      }}
                      placeholder="John Doe"
                      errorMessage={errors.dscsaContactName?.message}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12">
                    <FormInput
                      {...register('dscsaContactEmail')}
                      label="DSCSA Contact Email (optional)"
                      disabled={loading}
                      onIonChange={(e) => {
                        setValue('dscsaContactEmail', e.detail.value);
                      }}
                      placeholder="contact@organization.com"
                      errorMessage={errors.dscsaContactEmail?.message}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="8">
                    <FormInput
                      label="DSCSA Contact Phone (optional)"
                      disabled={loading}
                      type="tel"
                      onIonChange={(e) => {
                        setValue('dscsaContactPhone', e.detail.value as string);
                      }}
                      {...register('dscsaContactPhone')}
                      errorMessage={errors.dscsaContactPhone?.message}
                    />
                  </IonCol>

                  <IonCol size="4">
                    <FormInput
                      {...register('dscsaContactPhoneExt')}
                      label="Extension (optional)"
                      disabled={loading}
                      onIonChange={(e) => {
                        setValue(
                          'dscsaContactPhoneExt',
                          e.detail.value as string
                        );
                      }}
                      errorMessage={errors.dscsaContactPhoneExt?.message}
                    />
                  </IonCol>
                </IonRow>
              </IonList>
            </IonCardContent>
          </IonCard>
        </IonCol>
      </IonRow>
    </form>
  );
};
