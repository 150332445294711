import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import './SelectLocation.css';
import { LoadingInline } from 'src/components/shared/LoadingInline';
import { useApi } from 'src/hooks/useApi';
import { AppUserMapLocation } from 'src/interfaces/AppUserMapLocation';
import { AppUserSelf } from 'src/interfaces/AppUserSelf';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const SelectLocation: React.FC<{
  locationId: number | null;
  setLocationId: (value: number | null) => void;
  disabled?: boolean;
}> = ({ locationId, setLocationId, disabled }) => {
  const api = useApi();
  const authUser: AppUserSelf | null = useSelector(
    (state: any) => state.auth.user
  );
  const [loading, setLoading] = useState(false);
  const [userMapLocations, setUserMapLocations] = useState<
    AppUserMapLocation[]
  >([]);

  useEffect(() => {
    getUserLocations();
  }, []);

  const getUserLocations = () => {
    if (!authUser) {
      return;
    }
    setLoading(true);
    api
      .get(`/app_user/${authUser.app_user_id}/app_user_map_locations`)
      .then((response) => {
        if (response.status === 200) {
          const data: AppUserMapLocation[] = response.data;
          setUserMapLocations(data || []);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <IonItem color="transparent" className="selectLocation">
      <IonLabel position="stacked" className="margin-left-xs">
        Selected Location
      </IonLabel>
      <IonSelect
        value={locationId}
        interface="popover"
        onIonChange={(e) => setLocationId(e.detail.value)}
        placeholder="Select Location"
        disabled={disabled}
      >
        {loading ? (
          <LoadingInline />
        ) : (
          <>
            {userMapLocations.map((userMapLocation) => (
              <IonSelectOption
                key={userMapLocation.location_id}
                value={userMapLocation.location_id}
              >
                {userMapLocation.location_name}
              </IonSelectOption>
            ))}
          </>
        )}
      </IonSelect>
    </IonItem>
  );
};
