import { faPenToSquare } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonItem,
  IonLabel,
  IonList,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import './CardSupplierContact.css';
import { useUtils } from 'src/hooks/useUtils';
import { Contact } from 'src/interfaces/Contact';
import { ContactAttribute } from 'src/interfaces/ContactAttribute';

export const CardSupplierContact: React.FC<{
  contact: Contact;
  onClickEdit: (contact: Contact) => void;
}> = ({ contact, onClickEdit }) => {
  const utils = useUtils();
  return (
    <IonCard className="supplierContact">
      <IonToolbar>
        <IonTitle>{contact.name}</IonTitle>
        <IonButtons slot="end">
          <IonButton onClick={() => onClickEdit(contact)}>
            <span slot="icon-only">
              <FontAwesomeIcon icon={faPenToSquare} />
            </span>
          </IonButton>
        </IonButtons>
      </IonToolbar>
      <IonCardContent className="ion-no-padding">
        <IonList>
          <IonItem>
            <IonLabel>Email</IonLabel>
            <p slot="end" className="ion-text-right">
              <a href={`mailto:${contact.email_address}`}>
                {contact.email_address}
              </a>
            </p>
          </IonItem>

          {contact.phone1 && (
            <IonItem>
              <IonLabel>Phone</IonLabel>
              <p slot="end" className="ion-text-right">
                <a href={`tel:${contact.phone1}`}>
                  {utils.formatPhone(contact.phone1)}
                </a>
              </p>
            </IonItem>
          )}
          {contact.phone2 && (
            <IonItem>
              <IonLabel>Secondary Phone</IonLabel>
              <p slot="end" className="ion-text-right">
                <a href={`tel:${contact.phone2}`}>
                  {utils.formatPhone(contact.phone2)}
                </a>
              </p>
            </IonItem>
          )}
          {contact.attributes.map((attribute: ContactAttribute) => (
            <IonItem key={attribute.contact_attribute_id}>
              <IonLabel>{attribute.name}</IonLabel>
              <p slot="end" className="ion-text-right">
                {attribute.value}
              </p>
            </IonItem>
          ))}
        </IonList>
      </IonCardContent>
    </IonCard>
  );
};
