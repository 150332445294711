import { zodResolver } from '@hookform/resolvers/zod';
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCheckbox,
  IonCol,
  IonItem,
  IonLabel,
  IonList,
  IonProgressBar,
  IonRow,
  IonText,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ButtonDownloadSupplierTerm } from 'src/components/shared/ButtonDownloadSupplierTerm';
import { FormError } from 'src/components/shared/FormError';
import { LoadingInline } from 'src/components/shared/LoadingInline';
import { LogoSupplier } from 'src/components/shared/LogoSupplier';
import { ModalSupplierTermViewer } from 'src/components/shared/ModalSupplierTermViewer';
import { Supplier } from 'src/interfaces/Supplier';
import { SupplierTerm } from 'src/interfaces/SupplierTerm';
import { z } from 'zod';

const applicationStep4Schema = z.object({
  termsAccepted: z.boolean().refine((val) => val === true, {
    message: 'You must read and accept all Terms & Conditions on this page',
  }),
});
export type ApplicationStep4Schema = z.infer<typeof applicationStep4Schema>;

export const ApplicationStep4: React.FC<{
  formId: string;
  loading?: boolean;
  supplier: Supplier;
  supplierTerms: SupplierTerm[];
  supplierTermsLoading: boolean;
  setIsValid: (isValid: boolean) => void;
  setIsSubmitted: (isSubmitted: boolean) => void;
  onSubmitted: (data: ApplicationStep4Schema) => void;
}> = ({
  formId,
  loading,
  supplier,
  supplierTerms,
  supplierTermsLoading,
  setIsValid,
  setIsSubmitted,
  onSubmitted,
}) => {
  const [docViewerOpen, setDocViewerOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<SupplierTerm | null>(
    null
  );

  // STEP 4 (Terms)
  const [supplierTermsAcceptedCount, setSupplierDocsAcceptedCount] =
    useState<number>(0);

  const {
    handleSubmit,
    control,
    setValue,
    register,
    getValues,
    watch,
    formState: { errors, isSubmitted, isValid },
  } = useForm<ApplicationStep4Schema>({
    resolver: zodResolver(applicationStep4Schema),
    defaultValues: {
      termsAccepted:
        supplierTermsAcceptedCount === supplierTerms.length ? true : false,
    },
  });

  useEffect(() => {
    setIsValid(isValid);
  }, [isValid]);

  useEffect(() => {
    if (getValues('termsAccepted') === true) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [watch('termsAccepted')]);

  useEffect(() => {
    setIsSubmitted(isSubmitted);
  }, [isSubmitted]);

  useEffect(() => {
    if (
      supplierTerms.length > 0 &&
      supplierTermsAcceptedCount === supplierTerms.length
    ) {
      setValue('termsAccepted', true);
    } else {
      setValue('termsAccepted', false);
    }
  }, [supplierTermsAcceptedCount, supplierTerms]);

  const handleTermsCheckbox = (e: any) => {
    if (e.detail.checked) {
      setSupplierDocsAcceptedCount(supplierTermsAcceptedCount + 1);
    } else {
      setSupplierDocsAcceptedCount(supplierTermsAcceptedCount - 1);
    }
  };

  const onSubmit: (data: ApplicationStep4Schema) => void = (data) => {
    onSubmitted(data);
  };
  return (
    <>
      <form id={formId} onSubmit={handleSubmit(onSubmit)}>
        <IonRow>
          <IonCol>
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Terms & Conditions</IonCardTitle>
              </IonCardHeader>
              <IonCardContent className="ion-padding">
                <LogoSupplier
                  src={supplier?.logo}
                  title={supplier?.name}
                  alt={supplier?.name}
                />
                {supplierTermsLoading ? (
                  <IonProgressBar type="indeterminate" color="primary" />
                ) : (
                  <IonList className="ion-padding-bottom">
                    {supplierTerms.map((doc: SupplierTerm) => {
                      return (
                        <IonItem key={doc.supplier_term_id} lines="none">
                          <IonCheckbox
                            onIonChange={(e) => handleTermsCheckbox(e)}
                            disabled={loading}
                          />
                          <IonLabel className="fullWidth ion-text-wrap">
                            I have read and agree to the{' '}
                            <IonText color="primary">
                              <a
                                onClick={() => {
                                  setSelectedDocument(doc);
                                  setDocViewerOpen(true);
                                }}
                                style={{ cursor: 'pointer' }}
                              >
                                {doc.name}
                              </a>
                            </IonText>
                          </IonLabel>
                          <ButtonDownloadSupplierTerm
                            supplierTerm={doc}
                            disabled={loading}
                          />
                        </IonItem>
                      );
                    })}
                    <input type="hidden" {...register('termsAccepted')} />
                    {errors.termsAccepted && (
                      <FormError className="font-size-small ion-margin-end">
                        {errors.termsAccepted.message}
                      </FormError>
                    )}
                  </IonList>
                )}
                {loading ? (
                  <div className="ion-text-center">
                    <LoadingInline large={true} />
                    <p>Submitting...</p>
                  </div>
                ) : null}
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
      </form>

      <ModalSupplierTermViewer
        isOpen={docViewerOpen}
        supplierTerm={selectedDocument}
        onWillDismiss={() => {
          setSelectedDocument(null);
          setDocViewerOpen(false);
        }}
      />
    </>
  );
};
