import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useUtils } from 'src/hooks/useUtils';
import { AppUserSelf } from 'src/interfaces/AppUserSelf';
import { Organization } from 'src/interfaces/Organization';
import { LoadingPage } from 'src/pages/shared/LoadingPage';
import { NoUserPage } from 'src/pages/shared/NoUserPage';
import { NotFoundPage } from 'src/pages/shared/NotFoundPage';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

export const RoleBuyerRoute = ({ component: Component, ...rest }: any) => {
  const utils = useUtils();
  const isAuthenticated = useIsAuthenticated();
  const { inProgress, accounts } = useMsal();
  const authUser: AppUserSelf | null = useSelector(
    (state: any) => state.auth.user
  );
  const sessionExpired: boolean | null = useSelector(
    (state: any) => state.auth.sessionExpired
  );
  const userLoading: boolean = useSelector(
    (state: any) => state.auth.userLoading
  );
  const organization: Organization | null = useSelector(
    (state: any) => state.app.organization
  );

  return (
    <Route
      {...rest}
      render={(props) => {
        if (
          isAuthenticated &&
          authUser &&
          organization &&
          (utils.isBuyer(authUser) ||
            utils.isAdmin(authUser) ||
            utils.isSuperAdmin(authUser))
        ) {
          if (utils.isBuyerOrganization(organization)) {
            // authenticated buyer user
            return <Component {...props} />;
          } else {
            return <NotFoundPage />;
          }
        } else if (
          isAuthenticated &&
          authUser &&
          organization &&
          utils.isAnalyst(authUser)
        ) {
          return <Redirect to="/" />;
        } else if (
          (isAuthenticated && !userLoading && !authUser) ||
          sessionExpired === true
        ) {
          return <NoUserPage />;
        } else if (
          !isAuthenticated &&
          inProgress !== InteractionStatus.Startup &&
          inProgress !== InteractionStatus.HandleRedirect &&
          accounts.length === 0
        ) {
          return <Redirect to="/login" />;
        }
        return <LoadingPage />;
      }}
    />
  );
};
