import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonRow,
  IonSkeletonText,
} from '@ionic/react';
import { BadgeOrderStatus } from 'src/components/buyer/BadgeOrderStatus';
import { TablePurchaseOrderItems } from './TablePurchaseOrderItems';
import { Breadcrumbs, Breadcrumb } from 'src/components/shared/Breadcrumbs';
import { Divider } from 'src/components/shared/Divider';
import { Page } from 'src/layouts/Page';
import { useApi } from 'src/hooks/useApi';
import { useUtils } from 'src/hooks/useUtils';
import { Location } from 'src/interfaces/Location';
import { PurchaseOrder } from 'src/interfaces/PurchaseOrder';
import { Supplier } from 'src/interfaces/Supplier';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Address } from 'src/interfaces/Address';

export const OrderPage: React.FC = () => {
  const api = useApi();
  const { formatDate } = useUtils();
  const { id } = useParams<{ id: string }>();
  const allSuppliers: Supplier[] | null = useSelector(
    (state: any) => state.app.allSuppliers
  );
  const [loading, setLoading] = useState(false);
  const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrder>();

  const [location, setLocation] = useState<Location | null>(null);
  const [locationLoading, setLocationLoading] = useState(false);

  const [address, setAddress] = useState<Address | null>(null);
  const [addressLoading, setAddressLoading] = useState(false);

  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Orders', path: '/orders', direction: 'back' },
    { label: purchaseOrder ? purchaseOrder.purchase_order_number : id },
  ];

  useEffect(() => {
    getPurchaseOrder();
  }, [id]);

  useEffect(() => {
    if (purchaseOrder) {
      getLocation();
    } else {
      setLocation(null);
    }
  }, [purchaseOrder]);

  useEffect(() => {
    if (location) {
      getAddress();
    } else {
      setAddress(null);
    }
  }, [location]);

  const getPurchaseOrder = () => {
    setLoading(true);
    api
      .get(`purchase_order/${id}`)
      .then((response) => {
        const data: PurchaseOrder = response.data;
        setPurchaseOrder(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getLocation = () => {
    if (!purchaseOrder) {
      return;
    }
    setLocationLoading(true);
    api.get(`location/${purchaseOrder.location_id}`).then((response) => {
      const data: Location = response.data;
      setLocation(data);
      setLocationLoading(false);
    });
  };

  const getAddress = () => {
    if (!location) return;
    setAddressLoading(true);
    api
      .get(`address/${location.address_id}`)
      .then((res) => {
        setAddress(res.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setAddressLoading(false);
      });
  };

  return (
    <Page title="Order Detail" footer={/*<Footer />*/ undefined}>
      <Breadcrumbs breadcrumbs={breadcrumbs} title="Order Detail" />

      <IonRow>
        <IonCol size="12">
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>
                {(loading && (
                  <IonSkeletonText animated={true} style={{ width: '300px' }} />
                )) ||
                  `PO #: ${purchaseOrder?.purchase_order_number}`}
              </IonCardTitle>
              {purchaseOrder ? (
                <BadgeOrderStatus
                  status={purchaseOrder.status}
                  className="ion-margin-start"
                />
              ) : null}
            </IonCardHeader>
            <IonCardContent className="ion-no-padding">
              {/* <Divider className="ion-no-margin" /> */}
              <IonRow className="ion-padding">
                <IonCol
                  size="12"
                  sizeMd="6"
                  className="ion-text-left ion-no-padding"
                >
                  {!locationLoading && location ? (
                    <h2 className="font-size-xl">{location.name}</h2>
                  ) : (
                    <>
                      <IonSkeletonText
                        animated={true}
                        style={{ width: '200px', height: '20px' }}
                      />
                    </>
                  )}
                  {!addressLoading && address ? (
                    <>
                      <p>
                        {address.address1}
                        {address.address2 && (
                          <span>,&nbsp;{address.address2}</span>
                        )}
                      </p>
                      <p>
                        {address.city} {address.state}, {address.postal_code}
                      </p>
                    </>
                  ) : (
                    <>
                      <IonSkeletonText
                        animated={true}
                        style={{ width: '125px', height: '16px' }}
                      />
                      <IonSkeletonText
                        animated={true}
                        style={{ width: '160px', height: '16px' }}
                      />
                    </>
                  )}
                </IonCol>
                <IonCol
                  size="12"
                  sizeMd="6"
                  className="ion-text-right ion-no-padding"
                >
                  <h3 className="font-size-large">To</h3>
                  <h3 className="font-size-large">
                    {
                      allSuppliers?.find(
                        (supplier) =>
                          supplier.supplier_id === purchaseOrder?.supplier_id
                      )?.name
                    }
                  </h3>
                  <p>PO Date: {formatDate(purchaseOrder?.created || '')}</p>
                </IonCol>
              </IonRow>
              <Divider className="ion-no-margin" />
              <TablePurchaseOrderItems
                poLoading={loading}
                purchaseOrder={purchaseOrder}
              />
            </IonCardContent>
          </IonCard>
        </IonCol>
      </IonRow>
    </Page>
  );
};
