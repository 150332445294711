import {
  IonInput,
  IonItem,
  IonLabel,
  IonNote,
  IonTextarea,
} from '@ionic/react';
import { FormError } from './FormError';
import { forwardRef } from 'react';

/**
 * This requires the use of "forwardRef" for react-hook-form to work properly.
 */
export const FormInputTextarea: React.FC<{
  label?: string;
  onIonChange: (e: any) => void;
  type?: 'text' | 'email' | 'tel' | 'password' | 'date';
  readonly?: boolean;
  disabled?: boolean;
  placeholder?: string;
  helperText?: string;
  errorMessage?: string;
  ref?: any;
}> = forwardRef(
  (
    {
      label,
      onIonChange,
      type = 'text',
      readonly = false,
      disabled = false,
      placeholder,
      helperText,
      errorMessage,
      ...props
    },
    ref
  ) => {
    return (
      <IonItem className="no-padding">
        <IonLabel position="stacked">{label}</IonLabel>
        <IonTextarea
          ref={ref}
          className="rounded"
          onIonChange={onIonChange}
          readonly={readonly}
          disabled={disabled}
          placeholder={placeholder}
          {...props}
          autoGrow={true}
          style={{
            '--background': '#f5f5f5',
            '--padding-end': '10px',
            '--padding-start': '10px',
            '--placeholder-opacity': '0.8',
          }}
        />
        {errorMessage && <FormError>{errorMessage}</FormError>}
        {helperText ? <IonNote slot="helper">{helperText}</IonNote> : null}
      </IonItem>
    );
  }
);
