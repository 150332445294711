import { IonCard, IonCol, IonRow } from '@ionic/react';
import { useParams } from 'react-router';
import { Page } from 'src/layouts/Page';

export const OrderPage: React.FC = () => {
  const { organization_id, order_id } = useParams<{
    organization_id: string;
    order_id: string;
  }>();
  return (
    <Page title="OrderPage">
      <IonRow>
        <IonCol>
          <IonCard>OrderPage</IonCard>
          <p>Organization ID: {organization_id}</p>
          <p>Order ID: {order_id}</p>
        </IonCol>
      </IonRow>
    </Page>
  );
};
