import { SupplierTerm } from 'src/interfaces/SupplierTerm';

import { zodResolver } from '@hookform/resolvers/zod';
import { IonCard, IonCardContent, IonCol, IonList, IonRow } from '@ionic/react';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormFileInput } from 'src/components/shared/FormFileInput';
import { FileDataSchema } from 'src/components/shared/FormFileInput/FormFileInput';
import { FormInput } from 'src/components/shared/FormInput';
import { TermInput } from 'src/interfaces/TermInput';
import { z } from 'zod';
import { termInputSchema } from './FormManageTermInput';
import { TableTermInputs } from './TableTermInputs';

const supplierTermSchema = z.object({
  name: z.string().min(1, { message: 'Name is required' }),
  fileBase64: z.string().min(1, { message: 'File is required' }),
  filesize: z
    .number()
    .max(5000000, { message: 'File too large. Must be less than 5MB' }),
  filename: z.string(),
  inputs: z.array(termInputSchema),
});
export type SupplierTermSchema = z.infer<typeof supplierTermSchema>;

export const FormEditSupplierTerm: React.FC<{
  formId: string;
  supplierTerm: SupplierTerm | null;
  loading?: boolean;
  onSubmit: (data: SupplierTermSchema) => void;
}> = ({ formId, supplierTerm, loading, onSubmit }) => {
  const [supplierTermFile, setSupplierTermFile] =
    useState<FileDataSchema | null>(null);

  const {
    handleSubmit,
    control,
    setValue,
    register,
    getValues,
    watch,
    reset,
    formState: { errors, isSubmitted, isValid },
  } = useForm<SupplierTermSchema>({
    resolver: zodResolver(supplierTermSchema),
    defaultValues: {
      filesize: supplierTerm?.filesize || 0,
      name: supplierTerm?.name || '',
      fileBase64: supplierTerm?.content || '',
    },
  });

  useEffect(() => {
    populateData();
  }, [supplierTerm]);

  const populateData = () => {
    if (supplierTerm) {
      setValue('name', supplierTerm.name);
      setValue('filesize', supplierTerm.filesize);
      setValue('fileBase64', supplierTerm.content || '');
      setSupplierTermFile({
        name: supplierTerm.original_filename || '',
        filename: supplierTerm.original_filename || '',
        filesize: supplierTerm.filesize || 0,
        base64Content: supplierTerm.content || '',
      });
    } else {
      setValue('name', '');
      setValue('filesize', 0);
      setValue('fileBase64', '');
      setSupplierTermFile(null);
    }
  };

  return (
    <>
      <form id={formId} onSubmit={handleSubmit(onSubmit)}>
        <IonRow>
          <IonCol size="12">
            <IonCard>
              <IonCardContent>
                <IonList lines="none">
                  <IonRow>
                    <IonCol size="12" sizeMd="6">
                      <FormInput
                        label="Name"
                        helperText="The name a buyer will see when they view this document"
                        onIonChange={(e) => {
                          setValue('name', e.detail.value);
                        }}
                        {...register('name')}
                        errorMessage={errors.name?.message}
                      />
                    </IonCol>
                    <IonCol size="12" sizeMd="6">
                      <Controller
                        name="fileBase64"
                        control={control}
                        render={({ field }) => {
                          return (
                            <FormFileInput
                              existingFile={supplierTermFile}
                              label="File"
                              placeholder="Supported types: pdf. File size: 5MB or smaller."
                              accept="application/pdf"
                              value={field.value}
                              setValue={(filesize, filename, base64Content) => {
                                setValue('filesize', filesize || 0);
                                setValue('filename', filename || '');
                                setValue('fileBase64', base64Content || '');
                              }}
                              showThumbnail={true}
                              errorMessage={errors?.fileBase64?.message}
                              disabled={loading}
                            />
                          );
                        }}
                      />
                    </IonCol>
                  </IonRow>
                </IonList>
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
      </form>
      <IonRow>
        <IonCol size="12">
          <IonCard>
            <TableTermInputs
              supplierTerm={supplierTerm}
              onUpdate={(termInputs: TermInput[]) => {
                setValue('inputs', termInputs);
              }}
            />
          </IonCard>
        </IonCol>
      </IonRow>
    </>
  );
};
