import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonProgressBar,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { PDFViewer } from '@progress/kendo-react-pdf-viewer';
import { closeOutline } from 'ionicons/icons';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import { useEffect, useState } from 'react';
import { useApi } from 'src/hooks/useApi';
import { AccountApplicationTerm } from 'src/interfaces/AccountApplicationTerm';
import { SupplierTerm } from 'src/interfaces/SupplierTerm';
import { SupplierTermContent } from 'src/interfaces/SupplierTermContent';
import { TermInput } from 'src/interfaces/TermInput';
import './ModalSupplierTermViewer.css';
import { AccountApplicationTermContent } from 'src/interfaces/AccountApplicationTermContent';
/**
 * file viewer for base64 encoded pdf and image files.
 */
export const ModalSupplierTermViewer: React.FC<{
  isOpen: boolean;
  supplierTerm?: SupplierTerm | null;
  accountApplicationTerm?: AccountApplicationTerm | null;
  termInputs?: TermInput[];
  onWillDismiss: () => void;
}> = ({
  isOpen,
  supplierTerm,
  accountApplicationTerm,
  termInputs,
  onWillDismiss,
}) => {
  const api = useApi();
  const [content, setContent] = useState<string | null>(null);
  const [data, setData] = useState<string | null>(null);
  const [showDocument, setShowDocument] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (supplierTerm || accountApplicationTerm) {
      getDocumentContents();
    } else {
      setContent(null);
      setLoading(true);
      setShowDocument(false);
    }
  }, [isOpen, supplierTerm, accountApplicationTerm]);

  useEffect(() => {
    if (content) {
      modifyContents(content);
    } else {
      setData(null);
    }
  }, [content, termInputs]);

  const modifyContents = async (content: string) => {
    if (!termInputs) {
      // if no modifications, show document as is.
      setData(content);
      return;
    }

    // Load a PDFDocument from the existing PDF bytes
    const pdfDoc = await PDFDocument.load(content);
    // Embed the Helvetica font
    const helveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
    const pages = pdfDoc.getPages();

    termInputs.forEach((input: TermInput) => {
      const page = pages[input.page];
      page.drawText(input.value || '', {
        x: input.pos_x,
        y: input.pos_y,
        size: input.font_size,
        font: helveticaBold,
        color: rgb(input.color[0], input.color[1], input.color[2]),
      });
    });
    const updated = await pdfDoc.saveAsBase64();
    setData(updated);
  };

  const getDocumentContents = () => {
    if (supplierTerm) {
      setLoading(true);
      api
        .get(`supplier_term/${supplierTerm.supplier_term_id}/content`)
        .then((response) => {
          if (response.status === 200) {
            const documentContent: SupplierTermContent = response.data;
            setContent(documentContent.content);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (accountApplicationTerm) {
      setLoading(true);
      api
        .get(
          `account_application_term/${accountApplicationTerm.account_application_term_id}/content`
        )
        .then((response) => {
          if (response.status === 200) {
            const documentContent: AccountApplicationTermContent =
              response.data;
            setContent(documentContent.content);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      return;
    }
  };

  return (
    <IonModal
      isOpen={isOpen}
      onIonModalWillDismiss={() => {
        onWillDismiss();
      }}
      style={{ '--min-height': '80%', '--width': '800px' }}
      onIonModalDidPresent={() => setShowDocument(true)}
      className="supplierDocViewer"
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            {supplierTerm?.name || accountApplicationTerm?.name}
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onWillDismiss} fill="clear">
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      {loading || !data || !showDocument ? (
        <IonProgressBar type="indeterminate" color="primary" />
      ) : (
        <IonContent>
          <PDFViewer
            data={data}
            defaultZoom={0.95}
            tools={[
              'zoomInOut',
              'zoom',
              'spacer',
              'selection',
              'spacer',
              'download',
            ]}
            saveFileName={
              supplierTerm?.name || accountApplicationTerm?.name || 'document'
            }
          />
        </IonContent>
      )}
    </IonModal>
  );
};
