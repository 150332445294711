import { useUtils } from 'src/hooks/useUtils';
import { Organization } from 'src/interfaces/Organization';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BuyerHeader } from './BuyerHeader';
import './Header.css';
import { SupplierHeader } from './SupplierHeader';

export const Header: React.FC = () => {
  const utils = useUtils();
  const organization: Organization | null = useSelector(
    (state: any) => state.app.organization
  );
  const [isSupplier, setIsSupplier] = useState(false);
  useEffect(() => {
    if (organization) {
      setIsSupplier(utils.isSupplierOrganization(organization));
    }
  }, [organization]);

  // Determine which header to use depending on the type of organization the user belongs to.
  return <>{isSupplier ? <SupplierHeader /> : <BuyerHeader />}</>;
};
