import { zodResolver } from '@hookform/resolvers/zod';
import {
  IonButton,
  IonCheckbox,
  IonCol,
  IonIcon,
  IonItem,
  IonList,
  IonRow,
  IonSpinner,
  IonText,
} from '@ionic/react';
import { FormError } from 'src/components/shared/FormError';
import { FormInput } from 'src/components/shared/FormInput';
import { ModalTerms } from 'src/components/shared/ModalTerms';
import { SelectState } from 'src/components/shared/SelectState';
import { chevronBackOutline } from 'ionicons/icons';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { v } from 'src/utils/validators';

const step2Schema = z.object({
  organizationName: v.organizationName(),
  locationName: v.locationName(),
  address1: v.address1(),
  address2: v.address2(),
  city: v.city(),
  state: v.state(),
  zip: v.zip(),
  termsAccepted: z.literal(true, {
    errorMap: () => ({ message: 'You must accept the Terms of Use' }),
  }),
});

export type Step2Schema = z.infer<typeof step2Schema>;

export const Step2Form: React.FC<{
  loading: boolean;
  onSubmit: SubmitHandler<Step2Schema>;
  slidePrev: () => void;
  organizationName: Step2Schema['organizationName'];
  locationName: Step2Schema['locationName'];
  addressLine1: Step2Schema['address1'];
  addressLine2: Step2Schema['address2'];
  city: Step2Schema['city'];
  state: Step2Schema['state'];
  zip: Step2Schema['zip'];
  termsAccepted: boolean | undefined;
  setOrganizationName: (value: Step2Schema['organizationName']) => void;
  setLocationName: (value: Step2Schema['locationName']) => void;
  setAddressLine1: (value: Step2Schema['address1']) => void;
  setAddressLine2: (value: Step2Schema['address2']) => void;
  setCity: (value: Step2Schema['city']) => void;
  setState: (value: Step2Schema['state']) => void;
  setZip: (value: Step2Schema['zip']) => void;
  setTermsAccepted: (value: Step2Schema['termsAccepted']) => void;
}> = ({
  loading,
  onSubmit,
  slidePrev,
  organizationName,
  locationName,
  addressLine1,
  addressLine2,
  city,
  state,
  zip,
  termsAccepted = false,
  setOrganizationName,
  setLocationName,
  setAddressLine1,
  setAddressLine2,
  setCity,
  setState,
  setZip,
  setTermsAccepted,
}) => {
  const [termsOpen, setTermsOpen] = useState(false);
  const {
    handleSubmit,
    control,
    setValue,
    register,
    getValues,
    watch,
    formState: { errors, isDirty, isValid },
  } = useForm<Step2Schema>({
    resolver: zodResolver(step2Schema),
    defaultValues: {
      organizationName: organizationName,
      locationName: locationName,
      address1: addressLine1,
      address2: addressLine2,
      city: city,
      state: state,
      zip: zip,
      termsAccepted: termsAccepted as true,
    },
  });

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <IonList lines="none">
          <IonRow>
            <IonCol size="12">
              <FormInput
                label="Organization Name"
                disabled={loading}
                onIonChange={(e) => {
                  setValue('organizationName', e.detail.value as string);
                  setOrganizationName(e.detail.value as string);
                }}
                {...register('organizationName')}
                errorMessage={errors.organizationName?.message}
              />
            </IonCol>
            <IonCol size="12">
              <FormInput
                label="Location (Facility) Name"
                disabled={loading}
                onIonChange={(e) => {
                  setValue('locationName', e.detail.value as string);
                  setLocationName(e.detail.value as string);
                }}
                {...register('locationName')}
                errorMessage={errors.locationName?.message}
              />
            </IonCol>
            <IonCol size="12">
              <FormInput
                label="Street Address"
                disabled={loading}
                onIonChange={(e) => {
                  setValue('address1', e.detail.value as string);
                  setAddressLine1(e.detail.value as string);
                }}
                {...register('address1')}
                errorMessage={errors.address1?.message}
              />
            </IonCol>
            <IonCol size="12">
              <FormInput
                label="Suite, Unit, etc."
                disabled={loading}
                onIonChange={(e) => {
                  setValue('address2', e.detail.value as string);
                  setAddressLine2(e.detail.value as string);
                }}
                {...register('address2')}
                errorMessage={errors.address2?.message}
              />
            </IonCol>
            <IonCol size="12">
              <FormInput
                label="City"
                disabled={loading}
                onIonChange={(e) => {
                  setValue('city', e.detail.value as string);
                  setCity(e.detail.value as string);
                }}
                {...register('city')}
                errorMessage={errors.city?.message}
              />
            </IonCol>
            <IonCol size="6">
              <Controller
                name="state"
                control={control}
                render={({ field }) => {
                  return (
                    <SelectState
                      disabled={loading}
                      errorMessage={errors.state?.message}
                      rounded={true}
                      value={field.value || null}
                      onIonChange={(e) => {
                        setValue('state', e.detail.value as string);
                        setState(e.detail.value as string);
                      }}
                    />
                  );
                }}
              />
            </IonCol>
            <IonCol size="6">
              <FormInput
                label="Zip"
                disabled={loading}
                onIonChange={(e) => {
                  setValue('zip', e.detail.value as string);
                  setZip(e.detail.value as string);
                }}
                {...register('zip')}
                errorMessage={errors.zip?.message}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <IonItem disabled={loading} className="no-padding">
                <Controller
                  name="termsAccepted"
                  control={control}
                  render={({ field }) => {
                    return (
                      <IonCheckbox
                        checked={field.value}
                        onIonChange={(e) => {
                          setValue('termsAccepted', e.detail.checked as true);
                          setTermsAccepted(e.detail.checked as true);
                        }}
                      />
                    );
                  }}
                />
                <div className="fullWidth ion-text-wrap">
                  By creating a Trulla Direct account, I understand and agree to
                  Trulla's{' '}
                  <IonText color="primary">
                    <a
                      onClick={() => setTermsOpen(true)}
                      style={{ cursor: 'pointer' }}
                    >
                      Terms of Use.
                    </a>
                  </IonText>
                </div>
                <span slot="helper">
                  {errors.termsAccepted && (
                    <FormError>{errors.termsAccepted.message}</FormError>
                  )}
                </span>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonList>

        <IonRow className="ion-justify-content-between ion-margin-top">
          <IonButton
            fill="clear"
            onClick={() => slidePrev()}
            disabled={loading}
          >
            <IonIcon slot="start" icon={chevronBackOutline} />
            Previous
          </IonButton>
          <IonButton disabled={loading} type="submit">
            Submit
            {loading && (
              <IonSpinner
                slot="end"
                name="crescent"
                style={{ marginLeft: '5px' }}
              />
            )}
          </IonButton>
        </IonRow>
      </form>
      <ModalTerms isOpen={termsOpen} onDismiss={() => setTermsOpen(false)} />
    </>
  );
};
