import { zodResolver } from '@hookform/resolvers/zod';
import { IonCol, IonList, IonRow } from '@ionic/react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormInput } from 'src/components/shared/FormInput';
import { useUtils } from 'src/hooks/useUtils';
import { TermInput } from 'src/interfaces/TermInput';
import { z } from 'zod';

export const termInputSchema = z.object({
  key: z.string().min(1, { message: 'Key is required' }),
  name: z
    .string()
    .min(1, { message: 'Name is required' })
    .regex(
      /^[a-zA-Z0-9\s]+$/,
      'Must contain only Letters, Numbers, and Spaces.'
    ), // alphanumeric, with spaces
  page: z.number(),
  pos_x: z.number(),
  pos_y: z.number(),
  color: z.number().array().length(3),
  font_size: z.number(),
});
export type TermInputSchema = z.infer<typeof termInputSchema>;

export const FormManageTermInput: React.FC<{
  formId: string;
  termInput?: TermInput | null;
  onSubmit: (termInput: TermInputSchema) => void;
}> = ({ formId, termInput, onSubmit }) => {
  const utils = useUtils();
  const {
    handleSubmit,
    control,
    setValue,
    register,
    getValues,
    watch,
    reset,
    formState: { errors, isSubmitted, isValid },
  } = useForm<TermInputSchema>({
    resolver: zodResolver(termInputSchema),
    defaultValues: {
      key: termInput?.key || '',
      name: termInput?.name || '',
      page: termInput?.page || 0,
      pos_x: termInput?.pos_x || 0,
      pos_y: termInput?.pos_y || 0,
      color: termInput?.color || [0, 0, 0],
      font_size: termInput?.font_size || 8,
    },
  });

  const name = watch('name');

  useEffect(() => {
    if (name) {
      setValue('key', utils.camelCase(name));
    } else {
      setValue('key', '');
    }
  }, [name]);

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <IonList lines="none">
        <div className="fullWidth">
          <IonRow>
            <IonCol size="12">
              <FormInput
                label="Name"
                placeholder="Input Name"
                onIonChange={(e) => {
                  setValue('name', e.detail.value);
                }}
                {...register('name')}
                errorMessage={errors.name?.message}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <FormInput
                label="Page"
                helperText="First page is 0"
                placeholder="0"
                onIonChange={(e) => {
                  setValue('page', +e.detail.value);
                }}
                {...register('page')}
                errorMessage={errors.page?.message}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="6">
              <FormInput
                label="X"
                placeholder="32"
                onIonChange={(e) => {
                  setValue('pos_x', +e.detail.value);
                }}
                {...register('pos_x')}
                errorMessage={errors.pos_x?.message}
              />
            </IonCol>
            <IonCol size="6">
              <FormInput
                label="Y"
                placeholder="278"
                onIonChange={(e) => {
                  setValue('pos_y', +e.detail.value);
                }}
                {...register('pos_y')}
                errorMessage={errors.pos_y?.message}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <FormInput
                label="Font size"
                helperText="Defaults to 8"
                type="number"
                onIonChange={(e) => {
                  setValue('font_size', +e.detail.value);
                }}
                {...register('font_size')}
                errorMessage={errors.font_size?.message}
              />
            </IonCol>
          </IonRow>
        </div>
      </IonList>
    </form>
  );
};
