import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonPopover,
} from '@ionic/react';
import { useUtils } from 'src/hooks/useUtils';
import { informationCircleOutline } from 'ionicons/icons';
import { useEffect, useId, useState } from 'react';

export const StockStatus: React.FC<{
  status: 1 | 2 | 3;
  updated: string;
}> = ({ status, updated }) => {
  const id = useId();
  const { formatDateTime } = useUtils();
  const [color, setColor] = useState<string>('light');
  const [message, setMessage] = useState<string | null>(null);

  useEffect(() => {
    if (status) {
      switch (+status) {
        case 1:
          setColor('success');
          setMessage('Product above Safety Stock Level');
          break;
        case 2:
          setColor('warning');
          setMessage(
            'Product projected to ship but may be below Safety Stock or have supply gap'
          );
          break;
        case 3:
          setColor('danger');
          setMessage('No available supply');
          break;
        default:
          setColor('light');
          setMessage('Unknown');
      }
    }
  }, [status]);

  return (
    <span
      className="d-inline-flex ion-align-items-center"
      style={{ lineHeight: 1 }}
    >
      <span>Inv:</span>
      <span
        data-tooltip-id="global-tooltip"
        data-tooltip-html={`
          <p class="ion-no-margin text-color-${color}">${message}</p>
          <p class="ion-no-margin">Last Updated:</p>
          <p class="ion-no-margin">${formatDateTime(updated)}</p>
        `}
        data-tooltip-place="bottom"
        style={{ marginLeft: '3px' }}
      >
        <FontAwesomeIcon
          icon={faCircle}
          className={`font-size-sm text-color-${color}`}
        />
      </span>
    </span>
  );
};
