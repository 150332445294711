import {
  IonButton,
  IonCol,
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPopover,
  IonRouterLink,
  IonRow,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  useIonViewWillEnter,
} from '@ionic/react';
import { BadgeOrderStatus } from 'src/components/buyer/BadgeOrderStatus';
import { ButtonChangeLocation } from 'src/components/buyer/ButtonChangeLocation';
import { ButtonFilter } from 'src/components/shared/ButtonFilter';
import { DataTable } from 'src/components/shared/DataTable';
import { DataTableHeader } from 'src/components/shared/DataTable/interfaces/DataTableHeader';
import { DataTablePagination } from 'src/components/shared/DataTable/interfaces/DataTablePagination';
import { DataTableRow } from 'src/components/shared/DataTable/interfaces/DataTableRow';
import { Subtitle } from 'src/components/shared/Subtitle';
import { useApi } from 'src/hooks/useApi';
import { useUtils } from 'src/hooks/useUtils';
import { Location } from 'src/interfaces/Location';
import { Supplier } from 'src/interfaces/Supplier';
import { calendarOutline, closeOutline } from 'ionicons/icons';
import { useEffect, useId, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { PurchaseOrderStatus } from 'src/types/PurchaseOrderStatus';
import { PurchaseOrder } from 'src/interfaces/PurchaseOrder';
import './TableOrders.css';

export const TableOrders: React.FC<{
  filterStatus?: PurchaseOrderStatus | null;
  setFilterStatus?: (value: PurchaseOrderStatus | null) => void;
  itemsPerPageOptions?: number[];
  title?: string;
  hidePagination?: boolean;
  hideChangeLocation?: boolean;
}> = ({
  filterStatus,
  setFilterStatus,
  itemsPerPageOptions = [10, 15, 25, 50],
  title,
  hidePagination = false,
  hideChangeLocation,
}) => {
  const api = useApi();
  const utils = useUtils();
  const filterButtonId = useId();
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState<PurchaseOrder[]>([]);
  const [pagination, setPagination] = useState<DataTablePagination | null>(
    null
  );
  const [totalCount, setTotalCount] = useState<number>(0);
  const [orderBy, setOrderBy] = useState<string | null>('created');
  const [orderByDesc, setOrderByDesc] = useState<boolean>(true);
  const [search, setSearch] = useState<string | null>(null);
  const [filterStartDate, setFilterStartDate] = useState<string | null>(null);
  const [filterEndDate, setFilterEndDate] = useState<string | null>(null);
  const [filterSupplier, setFilterSupplier] = useState<number | null>(null);
  const [filtersPopover, setFiltersPopover] = useState(false);

  const tableHeaders: Array<DataTableHeader> = [
    { text: 'Purchase Order', key: 'purchase_order_number', sortable: true },
    { text: 'Order Date', key: 'created', sortable: true },
    { text: 'Latest Activity', key: 'updated', sortable: true },
    { text: 'Status', key: 'status', align: 'right' },
  ];

  const startDatetime = useRef<null | HTMLIonDatetimeElement>(null);
  const endDatetime = useRef<null | HTMLIonDatetimeElement>(null);

  const location = useLocation();

  const params = new URLSearchParams(location.search);

  useEffect(() => {
    let supplierId = params.get('supplier_id') || null;
    if (supplierId) {
      setFilterSupplier(supplierId ? +supplierId : null);
    } else {
      setFilterSupplier(null);
    }
  }, [location.search]);

  const allSuppliers: Supplier[] | null = useSelector(
    (state: any) => state.app.allSuppliers
  );
  const selectedLocation: Location | null = useSelector(
    (state: any) => state.app.selectedLocation
  );

  useEffect(() => {
    if (filterStartDate) {
      startDatetime.current?.cancel(true);
    }
  }, [filterStartDate]);

  useEffect(() => {
    if (filterEndDate) {
      endDatetime.current?.cancel(true);
    }
  }, [filterEndDate]);

  useIonViewWillEnter(() => {
    getPurchaseOrders();
  }, [selectedLocation]);

  useEffect(() => {
    getPurchaseOrders();
  }, [
    search,
    pagination,
    selectedLocation,
    filterStatus,
    filterStartDate,
    filterEndDate,
    filterSupplier,
  ]);

  const getPurchaseOrders = () => {
    if (!selectedLocation || !pagination) {
      return;
    }
    setLoading(true);
    api
      .get(`location/${selectedLocation.location_id}/purchase_orders`, {
        status: filterStatus ? filterStatus : null,
        supplier_id: filterSupplier ? filterSupplier : null,
        min_date: filterStartDate ? filterStartDate : null,
        max_date: filterEndDate ? filterEndDate : null,
        limit: pagination.limit,
        offset: pagination.offset,
        order_by: orderBy ? orderBy : null,
        order_by_desc: orderByDesc,
        search: search ? search : undefined,
      })
      .then((response) => {
        if (response.status === 200) {
          setOrders(response.data.result || []);
          setTotalCount(response.data.total);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const clearFilters = (): void => {
    setFilterStartDate(null);
    setFilterEndDate(null);
    setFilterSupplier(null);
    if (setFilterStatus) {
      setFilterStatus(null);
    }
  };

  const isLessThanEndDate = (datetime: string) => {
    if (!filterEndDate) {
      return true;
    }
    const date = new Date(datetime);
    const endDate = new Date(filterEndDate);
    if (date < endDate) {
      return true;
    }
    return false;
  };

  const isGreaterThanStartDate = (datetime: string) => {
    if (!filterStartDate) {
      return true;
    }
    const date = new Date(datetime);
    const startDate = new Date(filterStartDate);
    if (date > startDate) {
      return true;
    }
    return false;
  };

  return (
    <DataTable
      title={title || undefined}
      onPaginationChange={(pagination: DataTablePagination) =>
        setPagination(pagination)
      }
      orderBy={orderBy}
      orderByDesc={orderByDesc}
      setOrderBy={setOrderBy}
      setOrderByDesc={setOrderByDesc}
      totalCount={totalCount}
      headers={tableHeaders}
      itemsPerPageOptions={itemsPerPageOptions}
      hidePagination={hidePagination}
      loading={loading}
      search={false}
      subtitle={
        hideChangeLocation ? (
          ''
        ) : (
          <span>
            Order history for{' '}
            <strong className="text-color-black">
              {selectedLocation?.name}
            </strong>{' '}
            <ButtonChangeLocation
              style={{ verticalAlign: '-8px', height: '100%' }}
            />
          </span>
        )
      }
      rows={orders.map((order): DataTableRow => {
        const row = {
          item: order,
          key: order.purchase_order_id,
          columns: [
            {
              header: 'purchase_order_number',
              content: (
                <>
                  <IonRouterLink
                    className="font-size-default"
                    routerLink={`/orders/${order.purchase_order_id}`}
                  >
                    {order.purchase_order_number}
                  </IonRouterLink>
                  <Subtitle>
                    {order.line_item_count} Line Items |{' '}
                    <IonRouterLink
                      routerLink={`/suppliers/${order.supplier_id}`}
                      routerDirection="forward"
                      className="font-size-small"
                    >
                      {
                        allSuppliers?.find(
                          (supplier) =>
                            supplier.supplier_id === order.supplier_id
                        )?.name
                      }
                    </IonRouterLink>
                  </Subtitle>
                </>
              ),
            },
            {
              header: 'created',
              content: (
                <>
                  <p>{utils.formatDateTime(order.created)}</p>
                  <Subtitle>{order.created_by_name}</Subtitle>
                </>
              ),
            },
            {
              header: 'updated',
              content: (
                <>
                  <p>{utils.formatDateTime(order.updated)}</p>
                </>
              ),
            },
            {
              header: 'status',
              content: <BadgeOrderStatus status={order.status} />,
            },
          ],
        };
        return row;
      })}
    >
      <IonRow>
        <IonCol size="12" sizeSm="4">
          {filterStatus ||
          filterStartDate ||
          filterEndDate ||
          filterSupplier ? (
            <IonButton fill="clear" size="small" onClick={clearFilters}>
              Clear Filters
            </IonButton>
          ) : null}
          <ButtonFilter
            id={filterButtonId}
            disabled={loading}
            onClick={() => setFiltersPopover(true)}
          />
          <IonPopover
            trigger={filterButtonId}
            isOpen={filtersPopover}
            triggerAction="click"
            onIonPopoverWillDismiss={() => setFiltersPopover(false)}
            className="popoverOrderFilters"
          >
            <IonContent>
              <IonList>
                <IonItem>
                  <IonLabel>Start date</IonLabel>
                  <IonDatetimeButton datetime="startDate">
                    {!filterStartDate && (
                      <IonIcon slot="date-target" icon={calendarOutline} />
                    )}
                  </IonDatetimeButton>
                  <IonModal keepContentsMounted={true}>
                    <IonContent>
                      <IonDatetime
                        id="startDate"
                        ref={startDatetime}
                        presentation="date"
                        value={filterStartDate}
                        onIonChange={(e) =>
                          setFilterStartDate(e.detail.value as string)
                        }
                        isDateEnabled={isLessThanEndDate}
                      ></IonDatetime>
                    </IonContent>
                  </IonModal>
                  {filterStartDate && (
                    <IonButton
                      size="small"
                      fill="clear"
                      color="medium"
                      onClick={() => setFilterStartDate(null)}
                      className="ion-no-padding"
                    >
                      <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
                    </IonButton>
                  )}
                </IonItem>
                <IonItem>
                  <IonLabel>End date</IonLabel>
                  <IonDatetimeButton datetime="endDate">
                    {!filterEndDate && (
                      <IonIcon slot="date-target" icon={calendarOutline} />
                    )}
                  </IonDatetimeButton>
                  <IonModal keepContentsMounted={true}>
                    <IonContent>
                      <IonDatetime
                        id="endDate"
                        ref={endDatetime}
                        presentation="date"
                        value={filterEndDate}
                        onIonChange={(e) =>
                          setFilterEndDate(e.detail.value as string)
                        }
                        isDateEnabled={isGreaterThanStartDate}
                      ></IonDatetime>
                    </IonContent>
                  </IonModal>
                  {filterEndDate && (
                    <IonButton
                      size="small"
                      fill="clear"
                      color="medium"
                      onClick={() => setFilterEndDate(null)}
                      className="ion-no-padding"
                    >
                      <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
                    </IonButton>
                  )}
                </IonItem>
                <IonItem lines="none">
                  <IonLabel position="stacked">Supplier</IonLabel>

                  <IonSelect
                    placeholder="Filter by Supplier"
                    interface="popover"
                    interfaceOptions={{
                      alignment: 'end',
                      dismissOnSelect: true,
                      size: 'auto',
                    }}
                    onIonChange={(e) => setFilterSupplier(e.detail.value)}
                  >
                    {allSuppliers?.map((supplier) => (
                      <IonSelectOption
                        key={supplier.supplier_id}
                        value={supplier.supplier_id}
                      >
                        {supplier.name}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
              </IonList>
            </IonContent>
          </IonPopover>
        </IonCol>
        <IonCol size="12" sizeSm="8">
          <IonSearchbar
            value={search}
            onIonChange={(e: any) => setSearch(e.detail.value as string)}
            debounce={350}
            className="ion-text-left"
            placeholder="Search"
          />
        </IonCol>
      </IonRow>
    </DataTable>
  );
};
