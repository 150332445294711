import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { TableSupplierLocations } from 'src/components/buyer/TableSupplierLocations/TableSupplierLocations';
import { closeOutline } from 'ionicons/icons';
import { Supplier } from 'src/interfaces/Supplier';

export const ModalSettingSupplier: React.FC<{
  isOpen: boolean;
  supplier?: Supplier;
  onDismiss: (data?: any) => void;
}> = ({ isOpen, supplier, onDismiss }) => {
  return (
    <IonModal
      id="settingSupplierModal"
      style={{
        '--width': '900px',
      }}
      isOpen={isOpen}
      onDidDismiss={onDismiss}
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>Manage {supplier?.name} Accounts</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onDismiss} fill="clear">
              <IonIcon icon={closeOutline}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-no-padding">
        {supplier && <TableSupplierLocations supplier={supplier} />}
      </IonContent>
    </IonModal>
  );
};
