import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import { FormError } from 'src/components/shared/FormError';

export const SelectGpo: React.FC<{
  value: string | null;
  onIonChange: (e: any) => void;
  errorMessage?: string;
  disabled?: boolean;
  label?: string;
}> = ({
  value,
  onIonChange,
  errorMessage,
  disabled = false,
  label = 'Primary GPO Affiliation',
}) => {
  const primaryGpos = [
    'Premier',
    'Vizient',
    'HealthTrust',
    'Other',
    'Not Applicable',
  ];
  return (
    <IonItem className="fullWidth">
      <IonLabel position="stacked">{label}</IonLabel>
      <IonSelect
        className="fullWidth rounded"
        onIonChange={onIonChange}
        placeholder="Select GPO"
        value={value}
        disabled={disabled}
        interface="popover"
        interfaceOptions={{
          dismissOnSelect: true,
          size: 'cover',
        }}
      >
        {primaryGpos.map((gpo) => (
          <IonSelectOption value={gpo} key={gpo}>
            {gpo}
          </IonSelectOption>
        ))}
      </IonSelect>
      {errorMessage && <FormError>{errorMessage}</FormError>}
    </IonItem>
  );
};
