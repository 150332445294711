import { SupplierConfig } from 'src/config/supplierConfigurations';

export const SafecorConfig: SupplierConfig = {
  number: 'SAFECOR',
  account: {
    accountKey: 'ship_to_number',
    accountLabel: 'Ship To DEA Number',
    accountHelperText: 'Enter the DEA number associated with your Ship To',
    accountRules: [
      {
        pattern: /.*/,
        message: '',
      },
    ],
    validationKey: 'zip',
    validationLabel: 'Bill To DEA Number',
    validationHelperText: 'Enter the DEA number associated with your Bill To',
    validationRules: [
      {
        pattern: /.*/,
        message: '',
      },
    ],
    transform: (value: string): string => {
      // AB1234567-CD7654321
      // returns CD7654321
      const contractNumber = value.split('-')[1];
      if (contractNumber !== undefined) {
        return contractNumber;
      }
      // if transform results in undefined, just return the initial value
      return value;
    },
  },
};
