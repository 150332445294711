import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonNote,
  IonPopover,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import { FormError } from 'src/components/shared/FormError';
import { LoadingInline } from 'src/components/shared/LoadingInline';
import { useApi } from 'src/hooks/useApi';
import { AppUserSelf } from 'src/interfaces/AppUserSelf';
import { informationCircleOutline } from 'ionicons/icons';
import { useId, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './sandozStyles.module.css';
import { Subtitle } from 'src/components/shared/Subtitle';

interface ClassOfTradeOption {
  label: string;
  description: string | null;
}

const classOfTradeOptions: ClassOfTradeOption[] = [
  {
    label: 'Dispensing Physician',
    description:
      '(1) if a physician, is a duly licensed physician and has obtained the necessary licenses and permits to dispense outpatient drugs/medications to its patients within its office setting in accordance with applicable federal, state and local laws and regulations (“Law”); (2) Customer shall not engage and is not engaged in retail sales of any Products to patients being treated outside of the Customer office, to the general public or any third party (3) all Products purchased under the Agreement are exclusively dispensed via a valid prescription in accordance with each of the foregoing (collectively, “Own Use”) and applicable Laws and (4) all Products purchased under this Agreement are in inventory, separated and apart from Products purchased under any other agreement with Supplier.',
  },
  {
    label: 'Closed Door In-Office Pharmacy',
    description:
      '(1) it is a physician group practice, that owns and operates a duly licensed in-office pharmacy, (2) that dispenses outpatient drugs/medications to patients of Customer that are prescribed by a physician of Customer in accordance with applicable federal, state and local laws and regulations; (3) its in-office pharmacy services only the patients of the Customer and not to the general public; (4) the Customer shall not engage and is not engaged in retail sales of any Products to patients being treated outside of the Member office, to the general public or any third party (5) all Products purchased under the Agreement is exclusively dispensed via a valid prescription in accordance with each of the foregoing Own Use and applicable Laws and (6) all Products purchased under this Agreement are in inventory, separated and apart from Products purchased under any other agreement with Supplier.',
  },
];

export const SelectClassOfTrade: React.FC<{
  value: string;
  onIonChange?: (e: any) => void;
  disabled?: boolean;
  errorMessage?: string;
}> = ({ value, onIonChange, disabled, errorMessage }) => {
  const buttonId = useId();

  const selectedOption = classOfTradeOptions.find(
    (item) => item.label === value
  );

  return (
    <div className="fullWidth d-flex ion-justify-content-between">
      <IonItem
        className={`no-padding ${errorMessage ? 'ion-invalid' : 'ion-valid'}`}
        detail={false}
      >
        <IonLabel position="stacked" className="margin-left-xs">
          Select Class of Trade
        </IonLabel>
        <IonSelect
          value={value}
          onIonChange={onIonChange}
          interface="popover"
          interfaceOptions={{
            alignment: 'end',
            showBackdrop: true,
            dismissOnSelect: true,
          }}
          disabled={disabled}
          className="rounded"
        >
          {classOfTradeOptions.map((item) => (
            <IonSelectOption key={item.label} value={item.label}>
              {item.label}
            </IonSelectOption>
          ))}
        </IonSelect>
        {errorMessage && <FormError>{errorMessage}</FormError>}
      </IonItem>
      <IonButton
        id={buttonId}
        fill="clear"
        size="small"
        style={{
          marginTop: '35px',
        }}
        disabled={!selectedOption}
      >
        <IonIcon icon={informationCircleOutline} slot="icon-only" />
      </IonButton>
      <IonPopover
        className={styles.classOfTradePopover}
        trigger={selectedOption ? buttonId : undefined}
        keepContentsMounted={true}
      >
        <IonContent className="ion-padding">
          <Subtitle>Definition</Subtitle>
          <p className="ion-no-margin font-weight-600">
            {selectedOption?.label}
          </p>
          <p className="ion-no-margin ion-margin-top">
            {selectedOption?.description}
          </p>
        </IonContent>
      </IonPopover>
    </div>
  );
};
