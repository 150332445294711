import { useSelector } from 'react-redux';
import { Supplier } from 'src/interfaces/Supplier';

import { AccountApplicationPageSandoz } from './supplier/sandoz/AccountApplicationPageSandoz';

/**
 * This component is responsible for determining which Page is shown, as this will differ by supplier.
 * The page is determined by "supplier.number"
 * This identifier is less likely to change over time compared to a "supplier.name" or "supplier.supplier_id"
 */
export const AccountApplicationPage: React.FC = () => {
  const supplier: Supplier | null = useSelector(
    (state: any) => state.supplier.supplier
  );

  return (
    <>
      {supplier && supplier.number === '005387188' && (
        <AccountApplicationPageSandoz />
      )}
    </>
  );
};
