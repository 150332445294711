import { IonButton, IonChip, IonIcon } from '@ionic/react';
import { attachOutline } from 'ionicons/icons';
import { useEffect, useId, useState } from 'react';
import { Subtitle } from 'src/components/shared/Subtitle';
import styles from 'src/components/shared/formStyles.module.css';
import { useUtils } from 'src/hooks/useUtils';
import { License } from 'src/interfaces/License';

export interface LicenseFileDataSchema {
  name: string;
  filename: string;
  filesize: number;
  file: string; // base64 encoded string
}

export const FormLicenseInput: React.FC<{
  license?: LicenseFileDataSchema | null;
  value: string | null;
  setValue: (
    filesize: number | null,
    filename: string | null,
    base64Content: string | null
  ) => void;
  label: string;
  placeholder?: string;
  buttonText?: string;
  accept?: string;
  showThumbnail?: boolean;
  showFilesize?: boolean;
  disabled?: boolean;
  errorMessage?: string;
}> = ({
  license,
  value,
  setValue,
  label,
  placeholder = 'Choose File',
  buttonText,
  accept,
  showThumbnail,
  showFilesize = true,
  disabled = false,
}) => {
  const inputId = useId();
  const { formatBytes } = useUtils();
  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const [fileSize, setFileSize] = useState<number | null>(null);
  const [base64Content, setBase64Content] = useState<string | null>(null);
  const [fileType, setFileType] = useState<string | null>(null);

  const isImage = fileType?.includes('image');

  useEffect(() => {
    if (file) {
      setFileName(file.name);
      setFileSize(file.size);
    }
  }, [file]);

  useEffect(() => {
    if (license) {
      setFileName(license.name);
      setFileSize(license.filesize);
      setBase64Content(license.file);
    }
  }, [license]);

  useEffect(() => {
    if (base64Content) {
      const type = base64Content.split(';')[0].split(':')[1];
      setFileType(type);
    } else {
      setFileType(null);
    }
    setValue(fileSize, fileName, base64Content);
  }, [fileSize, fileName, base64Content]);

  const onFileChange = (fileChangeEvent: any) => {
    const file = fileChangeEvent.target.files[0];
    if (file) {
      setFile(file);
      const reader = new FileReader();
      reader.onload = (e: any) => {
        setBase64Content(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      setFile(null);
    }
  };

  const selectFile = () => {
    document.getElementById(inputId)?.click();
  };

  return (
    <div
      className={`${styles.fileInput} fullWidth d-flex ion-align-items-center ${
        showThumbnail && isImage ? styles.fileInputShowThumbnail : ''
      }`}
    >
      <IonButton
        onClick={selectFile}
        size="small"
        className="ion-no-margin"
        disabled={disabled}
        data-tooltip-id="global-tooltip"
      >
        {buttonText ? buttonText : <IonIcon icon={attachOutline} />}
      </IonButton>

      <div className="d-inline-flex ion-justify-content-between ion-align-items-center fullWidth ellipsis">
        <div className={styles.fileInputLabelContainer}>
          {file || license ? (
            <>
              <Subtitle className="font-style-italic ellipsis ion-no-margin">
                {fileName}
              </Subtitle>

              {showFilesize ? (
                <IonChip
                  className="font-size-xs ion-no-padding padding-left-xs padding-right-xs ion-no-margin"
                  style={{
                    cursor: 'default',
                    userSelect: 'none',
                    height: '20px',
                  }}
                >
                  <strong>{fileSize && formatBytes(fileSize, 1)}</strong>
                </IonChip>
              ) : null}
            </>
          ) : (
            <Subtitle className="font-style-italic ion-no-margin">
              {placeholder}
            </Subtitle>
          )}
        </div>
        {file || license ? (
          <div className="d-flex ion-align-items-center ion-justify-content-end">
            {showThumbnail && isImage ? (
              <img
                src={base64Content!}
                alt="uploadThumbnail"
                className={styles.fileInputThumbnail}
              />
            ) : null}
          </div>
        ) : null}
      </div>

      <input
        id={inputId}
        type="file"
        onChange={onFileChange}
        style={{ display: 'none' }}
        aria-hidden="true"
        accept={accept}
      />
    </div>
  );
};
