import { zodResolver } from '@hookform/resolvers/zod';
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonRow,
} from '@ionic/react';
import { SelectAccountType } from 'src/components/shared/SelectAccountType';
import { CardActions } from 'src/components/shared/CardActions';
import { FormError } from 'src/components/shared/FormError';
import { LogoSupplier } from 'src/components/shared/LogoSupplier';
import { useApi } from 'src/hooks/useApi';
import { useUtils } from 'src/hooks/useUtils';
import { Account } from 'src/interfaces/Account';
import { Supplier } from 'src/interfaces/Supplier';
import { saveOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { setToast } from 'src/redux/appReducer';
import { z } from 'zod';

const formSchema = z.object({
  description: z.string().min(1, { message: 'Description is required' }),
  accountTypeId: z.number().min(1, { message: 'Account Type is required' }),
});

type FormSchema = z.infer<typeof formSchema>;

export const FormManageAccountsEdit: React.FC<{
  account: Account;
  supplier: Supplier;
  onAccountUpdated: (account: Account) => void;
}> = ({ account, supplier, onAccountUpdated }) => {
  const api = useApi();
  const utils = useUtils();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValue('description', account.description);
    setValue('accountTypeId', account.account_type_id);
  }, []);

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    register,
    formState: { errors, isDirty, isValid },
  } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
  });

  const onSubmit = (data: FormSchema) => {
    console.log(data);
    save(data);
  };

  const save = (data: FormSchema) => {
    setLoading(true);
    const formData = {
      buyers_description: data.description,
      buyers_account_type_id: data.accountTypeId,
    };
    api
      .put(`/account/${account.account_id}`, formData)
      .then((response) => {
        if (response.status === 200) {
          const data: Account[] = response.data;
          onAccountUpdated(data[0]);
          dispatch(setToast({ message: 'Account updated' }));
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <IonGrid className="ion-no-padding">
      <IonRow>
        <IonCol
          size="12"
          className="d-flex ion-align-items-center ion-justify-content-center padding-xs"
        >
          <LogoSupplier src={supplier.logo} />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="ion-no-padding">
          <IonItemDivider>
            Account: {utils.formatAccount(account)}
          </IonItemDivider>
        </IonCol>
      </IonRow>
      <form onSubmit={handleSubmit(onSubmit)} className="ion-padding">
        <IonRow>
          <IonCol size="12">
            <IonList lines="none">
              <IonItem className="no-padding">
                <IonLabel position="stacked" className="margin-left-xs">
                  Description
                </IonLabel>
                <IonInput
                  type="text"
                  placeholder="Description"
                  className="rounded"
                  disabled={loading}
                  value={getValues('description') as string}
                  onIonChange={(e) => {
                    setValue('description', e.detail.value as string);
                  }}
                  {...register('description')}
                />
                {errors.description && (
                  <FormError>{errors.description.message}</FormError>
                )}
              </IonItem>
            </IonList>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="12" sizeMd="6">
            <IonList lines="none">
              <Controller
                name="accountTypeId"
                control={control}
                render={({ field }) => {
                  return (
                    <SelectAccountType
                      value={getValues('accountTypeId') as number}
                      setValue={(value) => setValue('accountTypeId', value)}
                      disabled={loading}
                      errorMessage={errors.accountTypeId?.message}
                    />
                  );
                }}
              />
            </IonList>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="12" className="ion-text-right">
            <IonButton size="small" disabled={loading} type="submit">
              Save
              <IonIcon icon={saveOutline} slot="end" />
            </IonButton>
          </IonCol>
        </IonRow>
      </form>
    </IonGrid>
  );
};
