import { useIonAlert } from '@ionic/react';
import { AppUserSelf } from 'src/interfaces/AppUserSelf';
import { Location } from 'src/interfaces/Location';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useUtils } from './useUtils';

export const useAlertAddSupplier = () => {
  const utils = useUtils();
  const [presentAlert] = useIonAlert();
  const authUser: AppUserSelf | null = useSelector(
    (state: any) => state.auth.user
  );
  const selectedLocation: Location | null = useSelector(
    (state: any) => state.app.selectedLocation
  );
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    if (authUser) {
      if (utils.isAnalyst(authUser)) {
        setMessage(
          'Contact a Buyer or an Admin at your organization to add a Supplier to this location.'
        );
      } else {
        setMessage('Add at least one Supplier to get started');
      }
    }
  }, [authUser]);

  const showAlert = (onWillDismiss: (e: any) => void) => {
    const pathName = window.location.pathname;
    const onApplicationPage = !!pathName.match(
      /\/suppliers\/(?:[0-9]+)\/apply/
    );
    if (onApplicationPage) {
      // don't show the alert if already on the application page.
      return;
    }
    presentAlert({
      header: 'No Suppliers Configured',
      subHeader: `Selected location: ${selectedLocation?.name}`,
      message: message,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: !!authUser && utils.isAnalyst(authUser) ? 'Ok' : 'Add Supplier',
          role: 'confirm',
        },
      ],
      onWillDismiss: onWillDismiss,
    });
  };

  return { showAlert };
};
