import { IonSelect, IonSelectOption } from '@ionic/react';
import { PopoverAccountMeta } from 'src/components/shared/PopoverAccountMeta';
import { SupplierConfig } from 'src/config/supplierConfigurations';
import { useUtils } from 'src/hooks/useUtils';
import { Account } from 'src/interfaces/Account';
import { Supplier } from 'src/interfaces/Supplier';
import { useEffect, useState } from 'react';

export const SelectAccount: React.FC<{
  accounts: Account[];
  supplier: Supplier;
  value?: Account | null;
  onIonChange?: any;
  disabled?: boolean;
}> = ({ accounts, supplier, value, onIonChange, disabled }) => {
  const utils = useUtils();
  const [supplierConfig, setSupplierConfig] = useState<SupplierConfig | null>(
    null
  );

  useEffect(() => {
    const customConfig = utils.getSupplierConfig(supplier?.number);
    setSupplierConfig(customConfig);
  }, [supplier]);

  const hasDates = !!(
    value?.custom_attributes?.start_date || value?.custom_attributes?.end_date
  );

  return (
    <div className="fullWidth d-flex ion-align-items-center">
      <div className="fullWidth margin-right-xs">
        <IonSelect
          value={value}
          disabled={disabled}
          onIonChange={onIonChange}
          interface="popover"
          interfaceOptions={{
            dismissOnSelect: true,
            size: 'cover',
            alignment: 'end',
          }}
          placeholder="Select Account"
          className="rounded ion-text-left"
        >
          {accounts.length > 0 ? (
            accounts.map((account) => {
              return (
                <IonSelectOption
                  key={account.account_id}
                  value={account}
                  className="font-weight-500"
                >
                  {supplierConfig && supplierConfig.account?.transform ? (
                    <>
                      {account.description ? `${account.description} - ` : null}
                      {supplierConfig.account?.transform(account.number)}
                    </>
                  ) : (
                    <>
                      {account.description}&nbsp;-&nbsp;{account.number}
                    </>
                  )}
                </IonSelectOption>
              );
            })
          ) : (
            <IonSelectOption value={null}>No accounts found</IonSelectOption>
          )}
        </IonSelect>
      </div>
      {value && value.custom_attributes && hasDates ? (
        <PopoverAccountMeta supplier={supplier} account={value} />
      ) : null}
    </div>
  );
};
