import { IonButtons, IonHeader, IonMenuButton, IonToolbar } from '@ionic/react';
import { SuperInfo } from './SuperInfo';
import { UserInfo } from './UserInfo';
import { UserInfoPopover } from './UserInfoPopover';

export const SupplierHeader: React.FC = () => {
  return (
    <IonHeader id="appHeader">
      <IonToolbar id="desktopToolbar" color="secondary">
        <IonButtons slot="start">
          <IonMenuButton></IonMenuButton>
        </IonButtons>
        <UserInfo />
        <UserInfoPopover />

        {/* only display if the user is a superadmin */}
        <SuperInfo />
      </IonToolbar>
    </IonHeader>
  );
};
