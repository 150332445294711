import { IonIcon, useIonViewWillEnter } from '@ionic/react';
import { DataTable } from 'src/components/shared/DataTable';
import { DataTableHeader } from 'src/components/shared/DataTable/interfaces/DataTableHeader';
import { DataTableItemAction } from 'src/components/shared/DataTable/interfaces/DataTableItemAction';
import { DataTableRow } from 'src/components/shared/DataTable/interfaces/DataTableRow';
import { useApi } from 'src/hooks/useApi';
import { useUtils } from 'src/hooks/useUtils';
import { pencilOutline, trashOutline } from 'ionicons/icons';
import { useState } from 'react';

interface Catalog {
  id: number;
  accountSpecific: boolean;
  typeSpecific: boolean;
  accountType: '340B' | 'GPO' | 'WAC' | null; // if type specific
  accountNumber: number | null; // if account specific
  accountName: string | null; // if account specific
  vendorItemNumber: string | number | null;
  ndc: string | null;
  description: string | null;
  packSize: number;
  packPrice: number;
  dating: string | null;
}

export const TableSupplierCatalogs: React.FC<{ catalogSync: boolean }> = ({
  catalogSync,
}) => {
  const api = useApi();
  const utils = useUtils();
  const [loading, setLoading] = useState(false);
  const [catalogs, setCatalogs] = useState<any[]>([]);

  //   ○ Catalog Type (Account Specific, Type Specific, or All)
  //   ○ Account Type (if Type Specific)
  //   ○ Account Number (if Account Specific)
  //   ○ Account Name (if Account Specific)
  //   ○ Vendor Item Number
  //   ○ NDC
  //   ○ Item Description
  //   ○ Pack Size
  //   ○ Pack Price
  // Dating
  const headers: DataTableHeader[] = [
    // { text: 'Catalog Type', key: 'type' },
    // { text: 'Account Type', key: 'accountType' },
    { text: 'Account Number', key: 'accountNumber' },
    { text: 'Account Name', key: 'accountName' },
    { text: 'Vendor Item Number', key: 'vendorItemNumber', width: '200px' },
    // { text: 'NDC', key: 'ndc', width: '200px' },
    { text: 'Description', key: 'description' },
    // { text: 'Pack Size', key: 'packSize' },
    { text: 'Price', key: 'packPrice', align: 'right' },
    { text: 'Dating', key: 'dating' },
  ];

  useIonViewWillEnter(() => {
    getCatalogs();
  }, []);

  const mockCatalogs: any[] = [
    {
      id: 1,
      accountSpecific: true,
      typeSpecific: false,
      accountType: null,
      accountNumber: 'RB0453237',
      accountName: 'Example Hospital',
      vendorItemNumber: '14789-0251-10',
      ndc: '14789-0251-10',
      description:
        'EMERPHED PFS (EPHEDRINE SULFATE) INJ 25MG/5ML, 10PACK Syringes',
      packPrice: '356.00',
      dating: 'Minimum 3 months',
    },
    {
      id: 2,
      accountSpecific: true,
      typeSpecific: false,
      accountType: null,
      accountNumber: 'RB0453237',
      accountName: 'Example Hospital',
      vendorItemNumber: '14789-0252-10',
      ndc: '14789-0252-10',
      description:
        'EMERPHED PFS (EPHEDRINE SULFATE) INJ 50MG/10ML, 10PACK Syringes',
      packPrice: '494.00',
      dating: 'Minimum 3 months',
    },
    // {
    //   id: 1,
    //   accountSpecific: true,
    //   typeSpecific: false,
    //   accountType: null,
    //   accountNumber: 83565039,
    //   accountName: "O'Kon, Crona and Green",
    //   vendorItemNumber: 'VIN-3986759',
    //   ndc: '10781-3158-95',
    //   description: 'Aperiam hic tempore est dolor ipsam voluptates.',
    //   packSize: 420,
    //   packPrice: '811.76',
    // dating: 'Minimum 3 months',
    // },
    // {
    //   id: 2,
    //   accountSpecific: false,
    //   typeSpecific: true,
    //   accountType: 'WAC',
    //   accountNumber: null,
    //   accountName: null,
    //   vendorItemNumber: 'VIN-6456783',
    //   ndc: '20781-3158-95',
    //   description: 'Dolore voluptates eum cupiditate soluta fugiat enim sit.',
    //   packSize: 109,
    //   packPrice: '334.14',
    //   dating: 'Minimum 12 months',
    // },
    // {
    //   id: 3,
    //   accountSpecific: true,
    //   typeSpecific: false,
    //   accountType: null,
    //   accountNumber: 54293001,
    //   accountName: 'Upton - Kulas',
    //   vendorItemNumber: 'VIN-1928849',
    //   ndc: '30781-3158-95',
    //   description:
    //     'Repudiandae odio laudantium ab corporis est minima corporis.',
    //   packSize: 326,
    //   packPrice: '555.32',
    //   dating: 'Minimum 6 months',
    // },
    // {
    //   id: 4,
    //   accountSpecific: true,
    //   typeSpecific: false,
    //   accountType: null,
    //   accountNumber: 41452475,
    //   accountName: 'Donnelly - Lockman',
    //   vendorItemNumber: 'VIN-9028284',
    //   ndc: '40781-3158-95',
    //   description: 'Nobis quibusdam amet itaque vero quas et omnis.',
    //   packSize: 185,
    //   packPrice: '172.84',
    //   dating: null,
    // },
    // {
    //   id: 5,
    //   accountSpecific: false,
    //   typeSpecific: true,
    //   accountType: 'WAC',
    //   accountNumber: null,
    //   accountName: null,
    //   vendorItemNumber: 'VIN-4737074',
    //   ndc: '50781-3158-95',
    //   description:
    //     'Natus voluptatibus sint nulla harum eius tenetur perspiciatis.',
    //   packSize: 178,
    //   packPrice: '59.74',
    //   dating: 'Minimum 12 months',
    // },
    // {
    //   id: 6,
    //   accountSpecific: true,
    //   typeSpecific: false,
    //   accountType: null,
    //   accountNumber: 86313416,
    //   accountName: "O'Hara Inc",
    //   vendorItemNumber: 'VIN-6648952',
    //   ndc: '60781-3158-95',
    //   description: 'Soluta ea aperiam.',
    //   packSize: 296,
    //   packPrice: '951.84',
    //   dating: null,
    // },
    // {
    //   id: 7,
    //   accountSpecific: false,
    //   typeSpecific: true,
    //   accountType: 'GPO',
    //   accountNumber: null,
    //   accountName: null,
    //   vendorItemNumber: 'VIN-1097762',
    //   ndc: '70781-3158-95',
    //   description: 'Iste fugiat magnam.',
    //   packSize: 481,
    //   packPrice: '725.29',
    //   dating: 'Minimum 6 months',
    // },
    // {
    //   id: 8,
    //   accountSpecific: false,
    //   typeSpecific: true,
    //   accountType: 'WAC',
    //   accountNumber: null,
    //   accountName: null,
    //   vendorItemNumber: 'VIN-3018378',
    //   ndc: '80781-3158-95',
    //   description: 'Optio explicabo nesciunt vitae eligendi doloribus ipsa.',
    //   packSize: 279,
    //   packPrice: '797.99',
    //   dating: 'Minimum 12 months',
    // },
    // {
    //   id: 9,
    //   accountSpecific: true,
    //   typeSpecific: false,
    //   accountType: null,
    //   accountNumber: 20105420,
    //   accountName: 'Kuhn, Cassin and Bosco',
    //   vendorItemNumber: 'VIN-1468623',
    //   ndc: '90781-3158-95',
    //   description: 'Quisquam incidunt optio pariatur commodi.',
    //   packSize: 94,
    //   packPrice: '489.10',
    //   dating: null,
    // },
    // {
    //   id: 10,
    //   accountSpecific: false,
    //   typeSpecific: true,
    //   accountType: null,
    //   accountNumber: null,
    //   accountName: null,
    //   vendorItemNumber: 'VIN-7728010',
    //   ndc: '100781-3158-95',
    //   description: 'Laboriosam accusantium accusamus quidem.',
    //   packSize: 281,
    //   packPrice: '83.02',
    //   dating: null,
    // },
  ];

  const getCatalogs = () => {
    setLoading(true);
    setTimeout(() => {
      setCatalogs(mockCatalogs);
      setLoading(false);
    }, 150);

    // axios
    //   .get('supplierCatalogs', { baseURL: process.env.REACT_APP_MOCK_URL })
    //   .then(({ data }) => {
    //     setCatalogs(data);
    //     setLoading(false);
    //   });
  };

  const actions = (catalog: any): DataTableItemAction[] => {
    const actions = [
      {
        label: 'Manage Item',
        icon: <IonIcon icon={pencilOutline} />,
        callback: (catalog: any) => {
          console.log('manage catalog item');
        },
      },
    ];
    if (!catalogSync) {
      const action = {
        label: 'Delete Item',
        icon: <IonIcon icon={trashOutline} color="danger" />,
        color: 'danger',
        callback: (catalog: any) => {
          console.log('Delete catalog item');
        },
      };
      actions.push(action);
    }
    return actions;
  };

  return (
    <DataTable
      title="Catalogs"
      headers={headers}
      actions={actions}
      rows={catalogs.map((catalog: any): DataTableRow => {
        const row: DataTableRow = {
          item: catalog,
          key: catalog.id,
          columns: [
            // {
            //   header: 'type',
            //   content: (
            //     <>
            //       {catalog.accountSpecific && <p>Account Specific</p>}
            //       {catalog.typeSpecific && <p>Type Specific</p>}
            //     </>
            //   ),
            // },
            // {
            //   header: 'accountType',
            //   content: <p>{catalog.accountType}</p>,
            // },
            {
              header: 'accountNumber',
              content: <p>{catalog.accountNumber}</p>,
            },
            {
              header: 'accountName',
              content: <p>{catalog.accountName}</p>,
            },
            {
              header: 'vendorItemNumber',
              content: <p>{catalog.vendorItemNumber}</p>,
            },
            // {
            //   header: 'ndc',
            //   content: <p>{catalog.ndc}</p>,
            // },
            {
              header: 'description',
              content: <p>{catalog.description}</p>,
            },
            // {
            //   header: 'packSize',
            //   content: <p>{catalog.packSize}</p>,
            // },
            {
              header: 'packPrice',
              content: (
                <p className="text-color-black font-weight-500 font-size-normal">
                  {utils.toUsd(+catalog.packPrice)}
                </p>
              ),
            },
            {
              header: 'dating',
              content: <p>{catalog.dating}</p>,
            },
          ],
        };
        return row;
      })}
      loading={loading}
    ></DataTable>
  );
};
