import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  IonItem,
  IonList,
  useIonAlert,
} from '@ionic/react';
import { LoadingInline } from 'src/components/shared/LoadingInline';
import { Subtitle } from 'src/components/shared/Subtitle';
import { useApi } from 'src/hooks/useApi';
import { Organization } from 'src/interfaces/Organization';
import { OrganizationDomain } from 'src/interfaces/OrganizationDomain';
import { addCircleOutline, closeOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setToast } from 'src/redux/appReducer';
import { ModalManageOrgDomain } from './ModalManageOrgDomain';

export const CardOrganizationDomains: React.FC = () => {
  const api = useApi();
  const organization: Organization | null = useSelector(
    (state: any) => state.app.organization
  );
  const [orgDomainOpen, setOrgDomainOpen] = useState(false);
  const [presentAlert] = useIonAlert();
  const dispatch = useDispatch();
  const [domainsLoading, setDomainsLoading] = useState(false);
  const [domains, setDomains] = useState<OrganizationDomain[]>([]);

  useEffect(() => {
    getOrganizationDomains();
  }, [organization]);

  const getOrganizationDomains = () => {
    if (!organization) {
      return;
    }
    setDomainsLoading(true);
    api
      .get(
        `organization/${organization.organization_id}/organization_domains`,
        {
          active: true,
        }
      )
      .then(({ data }) => {
        setDomains(data);
        setDomainsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setDomainsLoading(false);
      });
  };

  const onClickAdd = () => {
    setOrgDomainOpen(true);
    // presentAlert({
    //   header: 'Add Domain',
    //   inputs: [
    //     {
    //       placeholder: 'your-organization.com',
    //       attributes: {
    //         maxlength: 63,
    //       },
    //     },
    //   ],
    //   buttons: [
    //     {
    //       text: 'Cancel',
    //       role: 'cancel',
    //     },
    //     {
    //       text: 'Ok',
    //       role: 'confirm',
    //     },
    //   ],
    //   onWillDismiss: (e: any) => {
    //     if (e.detail.role === 'confirm') {
    //       const domainName = e.detail.data.values[0];
    //       if (domainName && domainName.length > 0) {
    //         createOrganizationDomain(domainName);
    //       }
    //     }
    //   },
    // });
  };

  const onClickRemove = (domain: OrganizationDomain) => {
    presentAlert({
      header: 'Remove domain',
      subHeader: `"${domain.name}"`,
      message: 'Are you sure you want to remove this domain?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Remove',
          role: 'confirm',
        },
      ],
      onWillDismiss: (e: any) => {
        if (e.detail.role === 'confirm') {
          deactivate(domain);
        }
      },
    });
  };

  const deactivate = (domain: OrganizationDomain) => {
    setDomainsLoading(true);
    api
      .delete(`organization_domain/${domain.organization_domain_id}`)
      .then((response) => {
        getOrganizationDomains();
        dispatch(
          setToast({
            header: 'Success',
            message: `Successfully removed domain "${domain.name}"`,
          })
        );
        setDomainsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setDomainsLoading(false);
      });
  };

  return (
    <>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            <div className="d-flex ion-align-items-center ion-justify-content-between">
              <span>Email Domains</span>
              <IonButton fill="clear" size="small" onClick={onClickAdd}>
                <IonIcon icon={addCircleOutline} slot="icon-only" />
              </IonButton>
            </div>
          </IonCardTitle>
          <Subtitle>
            Add any email domains that should be associated with your
            organization. Domains should be unique to your organization, and
            can't be one of the free providers (like gmail.com)
          </Subtitle>
        </IonCardHeader>
        <IonCardContent>
          {domainsLoading ? (
            <LoadingInline />
          ) : (
            <IonList lines="full">
              {domains.map((domain) => {
                return (
                  <IonItem key={domain.organization_domain_id}>
                    {domain.name}
                    <IonButtons slot="end">
                      <IonButton
                        color="danger"
                        onClick={() => onClickRemove(domain)}
                      >
                        <IonIcon icon={closeOutline} slot="icon-only" />
                      </IonButton>
                    </IonButtons>
                  </IonItem>
                );
              })}
            </IonList>
          )}
        </IonCardContent>
      </IonCard>
      <ModalManageOrgDomain
        isOpen={orgDomainOpen}
        onDismiss={(data?: any) => {
          setOrgDomainOpen(false);
          getOrganizationDomains();
        }}
      />
    </>
  );
};
