import { Supplier } from 'src/interfaces/Supplier';

import { FormStep } from 'src/features/accountApplication/interfaces/FormStep';
import { SupplierTerm } from 'src/interfaces/SupplierTerm';
import './FormAccountApplication.css';
import { FormAccountApplicationSandoz } from './sandoz/FormAccountApplicationSandoz';

/**
 * This component is responsible for determining which form is shown.
 * The form is determined by "supplier.number"
 * This identifier is less likely to change over time compared to a "supplier.name" or "supplier.supplier_id"
 */
export const FormAccountApplication: React.FC<{
  supplier: Supplier | null;
  activeIndex: number;
  loading?: boolean;
  supplierTerms: SupplierTerm[];
  supplierTermsLoading: boolean;
  setLoading: (loading: boolean) => void;
  setActiveIndex: (index: number) => void;
  setFormSteps: (steps: FormStep[]) => void;
}> = ({
  supplier,
  activeIndex,
  loading,
  supplierTerms,
  supplierTermsLoading,
  setLoading,
  setActiveIndex,
  setFormSteps,
}) => {
  const isSandoz = supplier?.number === '005387188';
  return (
    <div id="formAccountApplication">
      {isSandoz ? (
        <FormAccountApplicationSandoz
          supplier={supplier}
          activeIndex={activeIndex}
          loading={loading}
          supplierTerms={supplierTerms}
          supplierTermsLoading={supplierTermsLoading}
          setLoading={setLoading}
          setActiveIndex={setActiveIndex}
          setFormSteps={setFormSteps}
        />
      ) : null}
    </div>
  );
};
