import { IonIcon, IonItem, IonRouterLink } from '@ionic/react';
import { documentOutline } from 'ionicons/icons';
import { useState } from 'react';
import { AccountApplicationTerm } from 'src/interfaces/AccountApplicationTerm';
import { ModalSupplierTermViewer } from '../ModalSupplierTermViewer';

export const TermLink: React.FC<{
  term: AccountApplicationTerm;
}> = ({ term }) => {
  const [termViewerOpen, setTermViewerOpen] = useState(false);

  const onClickViewTerm = () => {
    setTermViewerOpen(true);
  };

  return (
    <>
      <IonItem button onClick={onClickViewTerm} detail={false}>
        <IonIcon slot="start" icon={documentOutline} />
        <IonRouterLink>{term.name}</IonRouterLink>
      </IonItem>

      <ModalSupplierTermViewer
        isOpen={termViewerOpen}
        accountApplicationTerm={term}
        termInputs={term.inputs || undefined}
        onWillDismiss={() => {
          setTermViewerOpen(false);
        }}
      />
    </>
  );
};
