import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonPopover,
} from '@ionic/react';
import { informationCircleOutline } from 'ionicons/icons';
import { Fragment, useEffect, useId, useState } from 'react';
import { useSelector } from 'react-redux';
import { useApi } from 'src/hooks/useApi';
import { Account } from 'src/interfaces/Account';
import { AccountApplicationTerm } from 'src/interfaces/AccountApplicationTerm';
import { Location } from 'src/interfaces/Location';
import { Supplier } from 'src/interfaces/Supplier';
import { LoadingInline } from '../LoadingInline';
import './PopoverAccountMeta.css';
import { TermLink } from './TermLink';

export const PopoverAccountMeta: React.FC<{
  account: Account;
  supplier: Supplier;
  trigger?: string;
  alignment?: 'start' | 'center' | 'end';
  side?: 'top' | 'bottom' | 'left' | 'right' | 'start' | 'end';
}> = ({
  account,
  supplier,
  trigger,
  alignment = 'center',
  side = 'bottom',
}) => {
  const api = useApi();
  const triggerId = useId();
  const custom_attributes = account.custom_attributes;

  const selectedLocation: Location | null = useSelector(
    (state: any) => state.app.selectedLocation
  );

  const [attributes, setAttributes] = useState<any[]>([]);
  const [termsLoading, setTermsLoading] = useState(false);
  const [terms, setTerms] = useState<AccountApplicationTerm[]>([]);

  useEffect(() => {
    getAccountTerms();
  }, [account, selectedLocation]);

  useEffect(() => {
    if (account) {
      const attrs = [];
      custom_attributes?.start_date &&
        attrs.push({
          label: 'Start Date',
          value: custom_attributes.start_date,
        });
      custom_attributes?.end_date &&
        attrs.push({
          label: 'End Date',
          value: custom_attributes.end_date,
        });
      setAttributes(attrs);
    }
  }, [account]);

  const getAccountTerms = () => {
    if (!account || !selectedLocation) {
      return;
    }
    setTermsLoading(true);
    api
      .get(`account/${account.account_id}/terms`, {
        location_id: selectedLocation.location_id,
      })
      .then((response) => {
        if (response.status === 200) {
          const terms: AccountApplicationTerm[] = response.data;
          setTerms(terms);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setTermsLoading(false);
      });
  };

  return (
    <>
      {!trigger ? (
        <IonButton
          id={triggerId}
          className="popoverAccountMetaTrigger ion-no-margin"
          fill="clear"
          size="small"
        >
          <IonIcon
            color="primary"
            size="default"
            icon={informationCircleOutline}
            slot="icon-only"
          />
        </IonButton>
      ) : null}
      <IonPopover
        className="popoverAccountMeta"
        trigger={trigger || triggerId}
        triggerAction="click"
        alignment={alignment}
        side={side}
      >
        <IonContent>
          <IonList lines="full">
            <IonItemDivider>
              <IonLabel>Effective Dates</IonLabel>
            </IonItemDivider>
            {attributes.map((attribute) => (
              <IonItem key={attribute.label}>
                <IonLabel>{attribute.label}</IonLabel>
                <p>{attribute.value}</p>
              </IonItem>
            ))}
            {terms.length > 0 && (
              <IonItemDivider>
                <IonLabel>Documents</IonLabel>
              </IonItemDivider>
            )}
            {termsLoading ? (
              <LoadingInline />
            ) : (
              <>
                {terms.map((term: AccountApplicationTerm) => (
                  <Fragment key={term.account_application_term_id}>
                    <TermLink term={term} />
                  </Fragment>
                ))}
              </>
            )}
          </IonList>
        </IonContent>
      </IonPopover>
    </>
  );
};
