import './CardActions.css';

export const CardActions: React.FC<{ className?: any; children?: any }> = ({
  className,
  children,
}) => {
  return (
    <div className={`cardActions ${className ? className : ''}`}>
      {children}
    </div>
  );
};
