import {
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonProgressBar,
} from '@ionic/react';
import { useApi } from 'src/hooks/useApi';
import { useUtils } from 'src/hooks/useUtils';
import { Contact } from 'src/interfaces/Contact';
import { Supplier } from 'src/interfaces/Supplier';
import { Fragment, useEffect, useState } from 'react';

import './ListSupplierContacts.css';

export const ListSupplierContacts: React.FC<{ supplier?: Supplier }> = ({
  supplier,
}) => {
  const api = useApi();
  const { formatPhone } = useUtils();

  const [contacts, setContacts] = useState<Contact[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getContacts();
  }, [supplier]);

  const getContacts = () => {
    if (!supplier) {
      return;
    }
    setLoading(true);
    api
      .get(`supplier/${supplier.supplier_id}/contacts`)
      .then(({ data }) => {
        setContacts(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  return (
    <>
      {loading ? (
        <IonProgressBar type="indeterminate" color="primary" />
      ) : (
        <IonList className="listSupplierContacts">
          {contacts.map((contact: Contact) => (
            <Fragment key={contact.contact_id}>
              <IonItemDivider>
                <IonLabel>{contact.name}</IonLabel>
              </IonItemDivider>
              {contact.email_address && (
                <IonItem>
                  <IonLabel>Email</IonLabel>
                  <p slot="end" className="ion-text-right">
                    <a href={`mailto:${contact.email_address}`}>
                      {contact.email_address}
                    </a>
                  </p>
                </IonItem>
              )}
              {contact.phone1 && (
                <IonItem>
                  <IonLabel>Phone</IonLabel>
                  <p slot="end" className="ion-text-right">
                    <a href={`tel:${contact.phone1}`}>
                      {formatPhone(contact.phone1)}
                    </a>
                  </p>
                </IonItem>
              )}
              {contact.phone2 && (
                <IonItem>
                  <IonLabel>Secondary Phone</IonLabel>
                  <p slot="end" className="ion-text-right">
                    <a href={`tel:${contact.phone2}`}>
                      {formatPhone(contact.phone2)}
                    </a>
                  </p>
                </IonItem>
              )}
              {contact.attributes &&
                contact.attributes.length > 0 &&
                contact.attributes.map((attribute) => (
                  <IonItem
                    key={attribute.contact_attribute_id}
                    data-tooltip-id="global-tooltip"
                    data-tooltip-content={attribute.description}
                    data-tooltip-place="bottom"
                  >
                    <IonLabel>{attribute.name}</IonLabel>
                    <p slot="end" className="ion-text-right">
                      {attribute.value}
                    </p>
                  </IonItem>
                ))}
            </Fragment>
          ))}
          {/*
          <IonItemDivider>Documents</IonItemDivider>
          <IonItem
            href="/assets/doc/lorem_ipsum.pdf"
            target="_blank"
            detail={false}
          >
            <IonLabel>Terms & Conditions</IonLabel>
            <IonIcon slot="end" icon={openOutline} />
          </IonItem>
          <IonItem
            lines="none"
            href="/assets/doc/lorem_ipsum.pdf"
            target="_blank"
            detail={false}
          >
            <IonLabel>Returns Policy</IonLabel>
            <IonIcon slot="end" icon={openOutline} />
          </IonItem> */}
        </IonList>
      )}
    </>
  );
};
