import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormError } from 'src/components/shared/FormError';
import { LoadingInline } from 'src/components/shared/LoadingInline';
import { useApi } from 'src/hooks/useApi';
import { AccountType } from 'src/interfaces/AccountType';
import { setAccountTypes } from 'src/redux/appReducer';
import './SelectAccountType.css';

export const SelectAccountType: React.FC<{
  value: number | null;
  setValue: (value: number) => void;
  disabled?: boolean;
  errorMessage?: string;
}> = ({ value, setValue, disabled, errorMessage }) => {
  const api = useApi();
  const dispatch = useDispatch();
  const accountTypes: AccountType[] | null = useSelector(
    (state: any) => state.app.accountTypes
  );

  useEffect(() => {
    getAccountTypes();
  }, []);

  const getAccountTypes = () => {
    api.get('account_types').then((response) => {
      if (response.status === 200) {
        const data: AccountType[] = response.data;
        dispatch(setAccountTypes(data));
      }
    });
  };

  return (
    <IonItem color="transparent" className="no-padding selectAccountType">
      <IonLabel position="stacked" className="margin-left-xs">
        Account Type
      </IonLabel>
      <IonSelect
        value={value}
        interface="popover"
        onIonChange={(e) => setValue(e.detail.value)}
        placeholder="Select Account Type"
        disabled={disabled}
      >
        {accountTypes ? (
          <>
            {accountTypes.map((accountType) => (
              <IonSelectOption
                key={accountType.account_type_id}
                value={accountType.account_type_id}
              >
                {accountType.name}
              </IonSelectOption>
            ))}
          </>
        ) : (
          <LoadingInline />
        )}
      </IonSelect>
      {errorMessage && <FormError>{errorMessage}</FormError>}
    </IonItem>
  );
};
