import { IonButton, IonCard, IonCol, IonIcon, IonRow } from '@ionic/react';
import { addOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { Breadcrumb, Breadcrumbs } from 'src/components/shared/Breadcrumbs';
import { Page } from 'src/layouts/Page';
import { ModalManageOrganization } from './ModalManageOrganization';
import { TableOrganizations } from './TableOrganizations';
import { OrganizationType } from 'src/interfaces/OrganizationType';
import { useSelector } from 'react-redux';
import { useEndpoints } from 'src/hooks/useEndpoints';

export const OrganizationsPage: React.FC = () => {
  const { getOrganizationTypes } = useEndpoints();
  const [isOpen, setIsOpen] = useState(false);
  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Trulla Admin' },
    { label: 'Organizations' },
  ];
  const organizationTypes: OrganizationType[] | null = useSelector(
    (state: any) => state.admin.organizationTypes
  );

  useEffect(() => {
    if (!organizationTypes) {
      getOrganizationTypes();
    }
  }, []);

  return (
    <Page title="Organizations">
      <Breadcrumbs breadcrumbs={breadcrumbs} title="Organizations">
        <IonButton size="small" color="primary" onClick={() => setIsOpen(true)}>
          Add Organization
          <IonIcon icon={addOutline} slot="end" />
        </IonButton>
      </Breadcrumbs>
      <IonRow>
        <IonCol>
          <IonCard>
            <TableOrganizations />
          </IonCard>
        </IonCol>
      </IonRow>
      <ModalManageOrganization
        isOpen={isOpen}
        onDismiss={(data?: any) => {
          setIsOpen(false);
        }}
      />
    </Page>
  );
};
