import {
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonProgressBar,
} from '@ionic/react';
import { downloadOutline, eyeOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useApi } from 'src/hooks/useApi';
import { SupplierAccountApplication } from 'src/interfaces/SupplierAccountApplication';
import { SupplierAccountApplicationDocument } from 'src/interfaces/SupplierAccountApplicationDocument';

export const ListApplicationDocuments: React.FC<{
  application: SupplierAccountApplication;
  className?: string;
}> = ({ application, className }) => {
  const api = useApi();
  const [loading, setLoading] = useState(true);

  const [docs, setDocs] = useState<SupplierAccountApplicationDocument[]>([]);
  const [docViewerOpen, setDocViewerOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] =
    useState<SupplierAccountApplicationDocument | null>(null);

  useEffect(() => {
    if (application) {
      getApplicationDocs();
    }
  }, [application]);

  const getApplicationDocs = () => {
    if (!application) {
      return;
    }
    setLoading(true);
    api
      .get(
        `account_application/${application.account_application_id}/account_application_documents`
      )
      .then((response) => {
        if (response.status === 200) {
          const docs: SupplierAccountApplicationDocument[] = response.data;
          setDocs(docs);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getType = (doc: SupplierAccountApplicationDocument) => {
    let type = '';
    if (doc.content.includes('data:application/pdf')) {
      type = 'pdf';
    } else if (doc.content.includes('data:image/')) {
      type = 'image';
    }
    return type;
  };

  /**
   * Capture the file extension (jpg, png, pdf, csv, etc.) from the base64 string
   * @param doc
   * @returns
   */
  const getExt = (doc: SupplierAccountApplicationDocument) => {
    const regex = /^(?:data:\w+\/)(\w+)/;
    let ext = '';
    const match = doc.content.match(regex);
    if (match) {
      ext = match[1];
    }
    return ext;
  };

  const download = (name: string, content: string) => {
    const link = document.createElement('a');
    link.href = content;
    link.download = name || 'document';
    link.click();
  };

  return (
    <>
      {loading ? (
        <IonProgressBar type="indeterminate" color="primary" />
      ) : (
        <IonList className={className || ''}>
          {docs.map((document: SupplierAccountApplicationDocument) => (
            <IonItem
              key={document.account_application_document_id}
              className="no-padding"
            >
              <IonLabel>
                <div>
                  {document.name}
                  {getExt(document) ? `.${getExt(document)}` : ''}
                </div>
              </IonLabel>
              <div slot="end">
                {getType(document) === 'pdf' ? (
                  <IonButton
                    fill="clear"
                    className="ion-no-padding ion-margin-horizontal"
                    onClick={() => {
                      setSelectedDocument(document);
                      setDocViewerOpen(true);
                    }}
                    data-tooltip-id="global-tooltip"
                    data-tooltip-content="View"
                    data-tooltip-place="bottom"
                  >
                    <IonIcon slot="icon-only" icon={eyeOutline} />
                  </IonButton>
                ) : null}

                <IonButton
                  fill="clear"
                  className="ion-no-padding ion-margin-horizontal"
                  onClick={() => download(document.name, document.content)}
                  data-tooltip-id="global-tooltip"
                  data-tooltip-content="Download"
                  data-tooltip-place="bottom"
                >
                  <IonIcon slot="icon-only" icon={downloadOutline} />
                </IonButton>
              </div>
            </IonItem>
          ))}
        </IonList>
      )}
    </>
  );
};
