import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { OrganizationType } from 'src/interfaces/OrganizationType';

interface InitialState {
  organizationTypes: OrganizationType[] | null;
  organizationTypesLoading: boolean;
}

const initialState: InitialState = {
  organizationTypes: null,
  organizationTypesLoading: false,
};

/**
 * admin slice intended for data related to Trulla Admin stuff
 */
const adminSlice = createSlice({
  name: 'admin',
  initialState: initialState,
  reducers: {
    setOrganizationTypes: (
      state,
      action: PayloadAction<OrganizationType[]>
    ) => {
      state.organizationTypes = action.payload;
    },
    setOrganizationTypesLoading: (state, action: PayloadAction<boolean>) => {
      state.organizationTypesLoading = action.payload;
    },
  },
});

export const adminReducer = adminSlice.reducer;
export const { setOrganizationTypes, setOrganizationTypesLoading } =
  adminSlice.actions;
