import { IonSkeletonText } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SupplierConfig } from 'src/config/supplierConfigurations';
import { useUtils } from 'src/hooks/useUtils';
import { Account } from 'src/interfaces/Account';
import { Supplier } from 'src/interfaces/Supplier';
import './ChipAccount.css';
import { PopoverAccountMeta } from 'src/components/shared/PopoverAccountMeta';

export const ChipAccount: React.FC<{
  account: Account;
  style?: React.CSSProperties;
  small?: boolean;
  showMeta?: boolean;
  loading?: boolean;
}> = ({ account, style, small, showMeta = true, loading }) => {
  const utils = useUtils();
  const allSuppliers: Supplier[] | null = useSelector(
    (state: any) => state.app.allSuppliers
  );
  const supplier = allSuppliers?.find((supplier) => {
    return supplier.supplier_id === account.supplier_id;
  });
  const [supplierConfig, setSupplierConfig] = useState<SupplierConfig | null>(
    null
  );

  const hasDates = !!(
    account.custom_attributes?.start_date || account.custom_attributes?.end_date
  );

  useEffect(() => {
    const customConfig = utils.getSupplierConfig(supplier?.number);
    setSupplierConfig(customConfig);
  }, [supplier]);

  return (
    <div
      className={`accountChip ${
        small ? 'small' : ''
      } d-flex ion-justify-content-between ion-align-items-center`}
      style={style}
    >
      <div>
        {loading ? (
          <>
            <IonSkeletonText
              animated={true}
              style={{
                width: '60px',
                height: 'var(--font-size-xs)',
              }}
              className="ion-no-margin margin-bottom-xs"
            />
            <IonSkeletonText
              animated={true}
              style={{
                width: '100px',
                height: 'var(--font-size-xs)',
              }}
              className="ion-no-margin"
            />
          </>
        ) : (
          <>
            <p className="accountDescription">{account.description}</p>
            <p className="accountNumber">
              {supplierConfig && supplierConfig?.account?.transform
                ? supplierConfig.account.transform(account.number)
                : account.number}
            </p>
          </>
        )}
      </div>
      {!loading &&
        supplier &&
        account.custom_attributes &&
        showMeta &&
        hasDates && (
          <PopoverAccountMeta supplier={supplier} account={account} />
        )}
    </div>
  );
};
