import { IonCard, IonCol, IonRow } from '@ionic/react';
import { Breadcrumbs, Breadcrumb } from 'src/components/shared/Breadcrumbs';
import { Page } from 'src/layouts/Page';
import { TableOnboardRequests } from './TableOnboardRequests';

export const OnboardRequestsPage: React.FC = () => {
  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Trulla Admin' },
    { label: 'Registration Requests' },
  ];

  return (
    <Page title="Registration Requests">
      <Breadcrumbs
        breadcrumbs={breadcrumbs}
        title="Registration Requests"
      ></Breadcrumbs>

      {/* TODO: data table here with all the user requests */}

      <IonRow>
        <IonCol>
          <IonCard>
            <TableOnboardRequests />
          </IonCard>
        </IonCol>
      </IonRow>
    </Page>
  );
};
