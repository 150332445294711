import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import './ModalShipTo.css';
import { addOutline, closeOutline, saveOutline } from 'ionicons/icons';
import { useEffect, useId, useState } from 'react';
import { FormManageShipTo } from './FormManageShipTo';
import { ShipToFormData } from './ApplicationStep3';

export const ModalShipTo: React.FC<{
  isOpen: boolean;
  onDismiss: (shipTo?: ShipToFormData) => void;
  shipTo?: ShipToFormData | null;
}> = ({ isOpen, onDismiss, shipTo }) => {
  const formId = useId();

  const [formValid, setFormValid] = useState<boolean>(false);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

  useEffect(() => {
    if (!isOpen) {
      // resetModal();
    }
  }, [isOpen]);

  const onSubmitted = (formData: ShipToFormData) => {
    onDismiss(formData);
  };

  return (
    <IonModal
      isOpen={isOpen}
      onIonModalDidDismiss={() => onDismiss()}
      className="modalShipTo"
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>{shipTo ? 'Edit Ship To' : 'Add Ship To'}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => onDismiss()} fill="clear">
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <FormManageShipTo
          shipTo={shipTo}
          formId={formId}
          setIsValid={setFormValid}
          setIsSubmitted={setFormSubmitted}
          onSubmitted={onSubmitted}
        />
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <div slot="end">
            <IonButton
              size="small"
              fill="clear"
              color="medium"
              onClick={() => onDismiss()}
              className="ion-margin-end"
            >
              Cancel
            </IonButton>
            <IonButton size="small" fill="solid" type="submit" form={formId}>
              {shipTo ? 'Save' : 'Add'}
              <IonIcon slot="end" icon={shipTo ? saveOutline : addOutline} />
            </IonButton>
          </div>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};
