import {
  IonBadge,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRouterLink,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { ButtonAddToCart } from 'src/components/buyer/ButtonAddToCart';
import './ModalItemDetails.css';
import { useUtils } from 'src/hooks/useUtils';
import { Account } from 'src/interfaces/Account';
import { AppUserSelf } from 'src/interfaces/AppUserSelf';
import { ItemCatalog } from 'src/interfaces/ItemCatalog';
import { ItemPurchaseOrder } from 'src/interfaces/ItemPurchaseOrder';
import { ItemShoppingCart } from 'src/interfaces/ItemShoppingCart';
import { Supplier } from 'src/interfaces/Supplier';
import { closeOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  SupplierConfig,
  supplierConfigurations,
} from 'src/config/supplierConfigurations';

export const ModalItemDetails: React.FC<{
  account?: Account;
  item: ItemCatalog | ItemShoppingCart | ItemPurchaseOrder | null;
  onDismiss: () => void;
  isOpen: boolean;
}> = ({ account, item, onDismiss, isOpen }) => {
  const utils = useUtils();
  const authUser: AppUserSelf | null = useSelector(
    (state: any) => state.auth.user
  );
  const allSuppliers: Supplier[] | null = useSelector(
    (state: any) => state.app.allSuppliers
  );
  const [supplier, setSupplier] = useState<Supplier | null>(null);

  const [isPurchaseOrderItem, setIsPurchaseOrderItem] = useState(false);
  const [totalCost, setTotalCost] = useState<number>(0);

  const [supplierConfig, setSupplierConfig] = useState<SupplierConfig | null>(
    null
  );

  useEffect(() => {
    const customConfig = utils.getSupplierConfig(supplier?.number);
    setSupplierConfig(customConfig);
  }, [supplier]);

  useEffect(() => {
    if (item) {
      if ((item as ItemPurchaseOrder)?.purchase_order_id) {
        setIsPurchaseOrderItem(true);
      }
      if (item.item_unit_price) {
        setTotalCost(
          parseFloat(item.item_unit_price) *
            (item as ItemPurchaseOrder).quantity
        );
      }
      setSupplier(
        allSuppliers?.find(
          (supplier) => supplier.supplier_id === account?.supplier_id
        ) || null
      );
    } else {
      setIsPurchaseOrderItem(false);
    }
  }, [item, account]);

  return (
    <IonModal
      isOpen={isOpen}
      onIonModalWillDismiss={onDismiss}
      className="itemDetails"
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            {isPurchaseOrderItem ? 'Line Item Details' : 'Item Details'}
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onDismiss}>
              <IonIcon icon={closeOutline} slot="icon-only" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRow>
          <IonCol className="ion-padding-horizontal">
            <h2 className="font-size-large font-weight-500 text-color-black">
              {item?.item_description}
            </h2>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="12">
            <IonList>
              <IonItem>
                <IonLabel>Supplier</IonLabel>

                <IonRouterLink
                  routerLink={`/suppliers/1`}
                  routerDirection="forward"
                  onClick={onDismiss}
                >
                  Sandoz
                </IonRouterLink>
              </IonItem>
              <IonItem>
                <IonLabel>NDC</IonLabel>
                {item?.item_ndc && item?.item_ndc !== 'NONE' ? (
                  <p>{utils.formatNdc(item?.item_ndc)}</p>
                ) : (
                  <IonBadge color="light">N/A</IonBadge>
                )}
              </IonItem>
              <IonItem>
                <IonLabel>Supplier Item Number</IonLabel>
                <p>{item?.supplier_item_number}</p>
              </IonItem>
              {/* <IonItem>
                <IonLabel>Case Quantity</IonLabel>
                {item && item.item_case_quantity ? (
                  <p>{+item.item_case_quantity}</p>
                ) : (
                  <IonBadge color="light">N/A</IonBadge>
                )}
              </IonItem> */}
              <IonItem>
                <IonLabel>Price Per Pack</IonLabel>
                {item?.item_unit_price ? (
                  <p className="text-color-black font-weight-500">
                    {utils.toUsd(+item.item_unit_price)}
                  </p>
                ) : (
                  <IonBadge color="light">N/A</IonBadge>
                )}
              </IonItem>
              {/* {supplierConfig && supplierConfig.custom_attributes ? (
                <>
                  {supplierConfig.custom_attributes.map((attribute) => {
                    return (
                      <IonItem key={attribute.key}>
                        <IonLabel>{attribute.label}</IonLabel>
                        <p>
                          {attribute.transform(
                            item
                              ? item.custom_attributes[attribute.key]
                              : ''
                          )}
                        </p>
                      </IonItem>
                    );
                  })}
                </>
              ) : null} */}
            </IonList>
          </IonCol>
        </IonRow>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonRow>
            <IonCol
              size="12"
              sizeLg="4"
              offsetLg="8"
              className="ion-no-padding"
            >
              <IonList>
                {isPurchaseOrderItem ? (
                  <IonItem>
                    <IonLabel className="font-size-default">
                      Quantity Ordered
                    </IonLabel>
                    {(item as ItemPurchaseOrder)?.quantity ? (
                      <p className="font-size-default">
                        {(item as ItemPurchaseOrder)?.quantity}
                      </p>
                    ) : null}
                  </IonItem>
                ) : null}
                <IonItem lines="none">
                  <IonLabel className="font-size-default">Total Cost</IonLabel>
                  <p className="font-size-default text-color-black font-weight-500">
                    {item?.item_unit_price ? (
                      utils.toUsd(totalCost || 0)
                    ) : (
                      <IonBadge color="light">N/A</IonBadge>
                    )}
                  </p>
                </IonItem>
              </IonList>
            </IonCol>
          </IonRow>
        </IonToolbar>
        {authUser && !utils.isAnalyst(authUser) ? (
          <IonToolbar>
            <div slot="end">
              {item && account && (item as ItemCatalog) ? (
                <ButtonAddToCart
                  item={item as ItemCatalog}
                  account={account}
                  onQuantityChange={(quantity: number | null) => {
                    if (typeof quantity === 'number' && item?.item_unit_price) {
                      setTotalCost(parseFloat(item.item_unit_price) * quantity);
                    } else {
                      setTotalCost(0);
                    }
                  }}
                />
              ) : null}

              {isPurchaseOrderItem ? (
                <IonButtons>
                  <IonButton fill="clear" onClick={onDismiss}>
                    Ok
                  </IonButton>
                </IonButtons>
              ) : null}
            </div>
          </IonToolbar>
        ) : null}
      </IonFooter>
    </IonModal>
  );
};
