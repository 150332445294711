import { IonCard, IonCol, IonRow } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Divider } from 'src/components/shared/Divider';
import { useUtils } from 'src/hooks/useUtils';
import { Organization } from 'src/interfaces/Organization';
import { Page } from 'src/layouts/Page';
import { TableLocationsSuper } from './TableLocationsSuper';
import { Breadcrumb, Breadcrumbs } from 'src/components/shared/Breadcrumbs';
import { useApi } from 'src/hooks/useApi';

export const LocationsPage: React.FC = () => {
  const api = useApi();
  const { organization_id } = useParams<{
    organization_id: string;
  }>();
  const [loading, setLoading] = useState(false);
  const [organization, setOrganization] = useState<Organization | null>(null);

  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Trulla Admin' },
    { label: 'Organizations', path: '/admin/organizations', direction: 'back' },
    {
      label: organization ? organization.name : organization_id,
      path: `/admin/organizations/${organization_id}`,
      direction: 'back',
    },
    { label: 'Locations' },
  ];

  useEffect(() => {
    getOrganization();
  }, [organization_id]);

  const getOrganization = () => {
    setLoading(true);
    api
      .get(`organization/${organization_id}`)
      .then((response) => {
        if (response.status === 200) {
          setOrganization(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Page title="Locations">
      <Breadcrumbs breadcrumbs={breadcrumbs} title="Locations"></Breadcrumbs>
      <IonRow>
        <IonCol size="12">
          <IonCard>
            <TableLocationsSuper
              organization_name={organization?.name}
              organization_id={organization_id}
            />
          </IonCard>
        </IonCol>
      </IonRow>
    </Page>
  );
};
