import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppUserSelf } from 'src/interfaces/AppUserSelf';
import { MsalAccessToken } from 'src/interfaces/MsalAccessToken';

interface InitialState {
  user: AppUserSelf | null;
  userLoading: boolean;
  accessToken: MsalAccessToken | null;
  onboardApiAccessToken: MsalAccessToken | null;
  sessionExpired: boolean | null;
}

const initialState: InitialState = {
  user: null,
  userLoading: true,
  accessToken: null,
  onboardApiAccessToken: null,
  sessionExpired: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    // user
    setUserLoading: (state, action: PayloadAction<boolean>) => {
      state.userLoading = action.payload;
    },
    setUser: (state, action: PayloadAction<AppUserSelf | null>) => {
      state.user = action.payload;
    },
    setAccessToken: (state, action: PayloadAction<MsalAccessToken | null>) => {
      state.accessToken = action.payload;
    },
    setOnboardApiAccessToken: (
      state,
      action: PayloadAction<MsalAccessToken | null>
    ) => {
      state.onboardApiAccessToken = action.payload;
    },
    setSessionExpired: (state, action: PayloadAction<boolean | null>) => {
      state.sessionExpired = action.payload;
    },
    logout: (state) => {
      localStorage.removeItem('td-token');
      localStorage.removeItem('lastActive');
      state.user = null;
      state.sessionExpired = null;
      // state.accounts = null;
      // state.organization = null;
    },
  },
});

export const authReducer = authSlice.reducer;
export const {
  setUser,
  setUserLoading,
  setAccessToken,
  setOnboardApiAccessToken,
  setSessionExpired,
  logout,
} = authSlice.actions;
