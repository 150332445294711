import { zodResolver } from '@hookform/resolvers/zod';
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonToggle,
} from '@ionic/react';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormLicenseInput } from 'src/components/buyer/FormLicenseInput';
import { Divider } from 'src/components/shared/Divider';
import { FormError } from 'src/components/shared/FormError';
import { FormInput } from 'src/components/shared/FormInput';
import { SelectLocation340bType } from 'src/components/shared/SelectLocation340bType';
import { SelectLocationType } from 'src/components/shared/SelectLocationType';
import { SelectState } from 'src/components/shared/SelectState';
import { v } from 'src/utils/validators';
import { z } from 'zod';

const addLocationSchema = z
  .object({
    locationName: v.locationName(),
    locationTypeId: z
      .number({
        errorMap: () => ({ message: 'Facility Type is required' }),
      })
      .int(),
    location340bTypeId: z
      .number({
        errorMap: () => ({ message: '340B Type is required' }),
      })
      .int(),
    opaisId: z.string().max(50, { message: 'Field is too long' }),
    facilityId: z
      .string()
      .max(30, { message: 'Facility ID is too long' })
      .optional(),
    departmentId: z
      .string()
      .max(30, { message: 'Department ID is too long' })
      .optional(),
    gcp: v.gcp(),
    gln: v.gln('GLN', false),
    hin: v.hin(),
    deaLicenseNum: v.deaLicenseNum(),
    deaLicenseExpiration: v.date('DEA Expiration', true),
    deaLicenseFilesize: z
      .number()
      .max(5000000, { message: 'File too large. Must be less than 5MB' }),
    deaLicenseFilename: z.string().min(1, { message: 'Missing DEA filename' }),
    deaLicenseFileBase64: v.deaLicenseFileBase64(),
    stateLicenseNum: v.stateLicenseNum(),
    stateLicenseExpiration: v.date('State Expiration', true),
    stateLicenseFilesize: z
      .number()
      .max(5000000, { message: 'File too large. Must be less than 5MB' }),
    stateLicenseFilename: z
      .string()
      .min(1, { message: 'Missing DEA filename' }),
    stateLicenseFileBase64: v.stateLicenseFileBase64(),
    addressName: v.addressName(),
    address1: v.address1(),
    address2: v.address2(),
    city: v.city(),
    state: v.state(),
    postalCode: v.zip(),
    differentBilling: z.boolean(),
    billingAddressName: v.addressName('Billing Address Name', false),
    billingAddress1: v.address1('Street Address', false),
    billingAddress2: v.address2('Suite, Unit, etc', false),
    billingCity: v.city('City', false),
    billingState: v.state('State', false),
    billingPostalCode: v.zip('Zip', false),
  })
  // OPAIS 340B ID is required if the 340B type is not "Not 340B"
  .refine(
    (schema) =>
      schema.location340bTypeId !== 1 && !schema.opaisId ? false : true,
    {
      message: 'OPAIS 340B ID is required',
      path: ['opaisId'],
    }
  )
  // Billing address fields are required if "Different Billing Address" is checked
  .refine(
    (schema) =>
      schema.differentBilling && !schema.billingAddressName ? false : true,
    {
      message: 'Billing Address Name is required',
      path: ['billingAddressName'],
    }
  )
  .refine(
    (schema) =>
      schema.differentBilling && !schema.billingAddress1 ? false : true,
    {
      message: 'Street Address is required',
      path: ['billingAddress1'],
    }
  )
  .refine(
    (schema) => (schema.differentBilling && !schema.billingCity ? false : true),
    {
      message: 'City is required',
      path: ['billingCity'],
    }
  )
  .refine(
    (schema) =>
      schema.differentBilling && !schema.billingState ? false : true,
    {
      message: 'State is required',
      path: ['billingState'],
    }
  )
  .refine(
    (schema) =>
      schema.differentBilling && !schema.billingPostalCode ? false : true,
    {
      message: 'Zip is required',
      path: ['billingPostalCode'],
    }
  );

export type AddLocationSchema = z.infer<typeof addLocationSchema>;

export const FormAddLocation: React.FC<{
  formId: string;
  onSubmit: (location: AddLocationSchema) => void;
  loading: boolean;
}> = ({ formId, onSubmit, loading }) => {
  const {
    handleSubmit,
    control,
    setValue,
    register,
    watch,
    formState: { errors },
  } = useForm<AddLocationSchema>({
    resolver: zodResolver(addLocationSchema),
    defaultValues: {
      locationName: '',
      locationTypeId: undefined,
      location340bTypeId: undefined,
      facilityId: '',
      departmentId: '',
      opaisId: '',
      gcp: '',
      gln: '',
      hin: '',
      deaLicenseNum: '',
      deaLicenseExpiration: '',
      deaLicenseFileBase64: '',
      stateLicenseNum: '',
      stateLicenseExpiration: '',
      stateLicenseFileBase64: '',
      addressName: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      postalCode: '',
      differentBilling: false,
      billingAddressName: '',
      billingAddress1: '',
      billingAddress2: '',
      billingCity: '',
      billingState: '',
      billingPostalCode: '',
    },
  });
  const locationName = watch('locationName');
  const location340bTypeId = watch('location340bTypeId');
  const differentBilling = watch('differentBilling');

  /**
   * Update address name when location name changes
   */
  useEffect(() => {
    if (locationName) {
      setValue('addressName', locationName);
    } else {
      setValue('addressName', '');
    }
  }, [locationName]);

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <IonRow>
        <IonCol size="12" sizeLg="6">
          <IonCard className="fullHeight">
            <IonCardHeader>
              <IonCardTitle>Facility Info</IonCardTitle>
            </IonCardHeader>
            <IonCardContent className="ion-padding">
              <IonList lines="none">
                <IonRow>
                  <IonCol size="12">
                    <FormInput
                      label="Location Name"
                      onIonChange={(e) => {
                        setValue('locationName', e.detail.value);
                      }}
                      {...register('locationName')}
                      errorMessage={errors.locationName?.message}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12">
                    <Controller
                      name="locationTypeId"
                      control={control}
                      render={({ field }) => {
                        return (
                          <SelectLocationType
                            disabled={loading}
                            value={field.value}
                            onIonChange={(e) => {
                              setValue('locationTypeId', e.detail.value);
                            }}
                            errorMessage={errors.locationTypeId?.message}
                          />
                        );
                      }}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol
                    size="12"
                    sizeXl={
                      location340bTypeId && location340bTypeId !== 1
                        ? '6'
                        : undefined
                    }
                  >
                    <Controller
                      name="location340bTypeId"
                      control={control}
                      render={({ field }) => {
                        return (
                          <SelectLocation340bType
                            disabled={loading}
                            value={field.value}
                            onIonChange={(e) => {
                              setValue('location340bTypeId', e.detail.value);
                            }}
                            errorMessage={errors.location340bTypeId?.message}
                          />
                        );
                      }}
                    />
                  </IonCol>
                  <IonCol size="12" sizeXl="6">
                    {location340bTypeId && location340bTypeId !== 1 ? (
                      <FormInput
                        label="OPAIS 340B ID"
                        onIonChange={(e) => {
                          setValue('opaisId', e.detail.value as string);
                        }}
                        {...register('opaisId')}
                        disabled={loading}
                        errorMessage={errors.opaisId?.message}
                      />
                    ) : null}
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol size="12" sizeXl="6">
                    <FormInput
                      label="Facility ID"
                      onIonChange={(e) => {
                        setValue('facilityId', e.detail.value);
                      }}
                      {...register('facilityId')}
                      disabled={loading}
                      errorMessage={errors.facilityId?.message}
                    />
                  </IonCol>
                  <IonCol size="12" sizeXl="6">
                    <FormInput
                      label="Department ID"
                      onIonChange={(e) => {
                        setValue('departmentId', e.detail.value);
                      }}
                      {...register('departmentId')}
                      disabled={loading}
                      errorMessage={errors.departmentId?.message}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" sizeXl="6">
                    <FormInput
                      label="GLN (optional)"
                      helperText="Global Locator Number"
                      onIonChange={(e) => {
                        setValue('gln', e.detail.value);
                      }}
                      {...register('gln')}
                      disabled={loading}
                      errorMessage={errors.gln?.message}
                    />
                  </IonCol>
                  <IonCol size="12" sizeXl="6">
                    <FormInput
                      label="GCP (optional)"
                      helperText="Global Company Prefix"
                      onIonChange={(e) => {
                        setValue('gcp', e.detail.value);
                      }}
                      {...register('gcp')}
                      disabled={loading}
                      errorMessage={errors.gcp?.message}
                    />
                  </IonCol>
                  <IonCol size="12" sizeXl="6">
                    <FormInput
                      label="HIN (optional)"
                      helperText="Hospital Identifier Number"
                      onIonChange={(e) => {
                        setValue('hin', e.detail.value);
                      }}
                      {...register('hin')}
                      disabled={loading}
                      errorMessage={errors.hin?.message}
                    />
                  </IonCol>
                </IonRow>
              </IonList>
            </IonCardContent>
          </IonCard>
        </IonCol>

        <IonCol size="12" sizeLg="6">
          <IonCard className="fullHeight">
            <IonCardHeader>
              <IonCardTitle>Licensing</IonCardTitle>
            </IonCardHeader>
            <IonCardContent className="ion-padding">
              <IonList lines="none">
                <IonRow>
                  <IonCol>
                    <Divider className="ion-no-margin" text="DEA" />
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol size="7">
                    <FormInput
                      {...register('deaLicenseNum')}
                      label="DEA License #"
                      disabled={loading}
                      onIonChange={(e) => {
                        setValue('deaLicenseNum', e.detail.value);
                      }}
                      errorMessage={errors.deaLicenseNum?.message}
                    />
                  </IonCol>
                  <IonCol size="5">
                    <FormInput
                      {...register('deaLicenseExpiration')}
                      label="DEA Expiration"
                      disabled={loading}
                      onIonChange={(e) => {
                        setValue('deaLicenseExpiration', e.detail.value);
                      }}
                      placeholder="yyyy-mm-dd"
                      errorMessage={errors.deaLicenseExpiration?.message}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12">
                    <Controller
                      name="deaLicenseFileBase64"
                      control={control}
                      render={({ field }) => {
                        return (
                          <>
                            <FormLicenseInput
                              label="DEA License"
                              placeholder="Supported types: pdf, png, jpeg. File size: 5MB or smaller."
                              accept="application/pdf, image/png, image/jpeg"
                              value={field.value}
                              setValue={(filesize, filename, base64Content) => {
                                setValue('deaLicenseFilesize', filesize || 0);
                                setValue('deaLicenseFilename', filename || '');
                                setValue(
                                  'deaLicenseFileBase64',
                                  base64Content || ''
                                );
                              }}
                              showThumbnail={true}
                              errorMessage={
                                errors.deaLicenseFileBase64?.message
                              }
                              disabled={loading}
                            />
                            {errors?.deaLicenseFileBase64 && (
                              <FormError>
                                {errors?.deaLicenseFileBase64.message}
                              </FormError>
                            )}
                          </>
                        );
                      }}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol className="ion-margin-top">
                    <Divider className="ion-no-margin" text="State" />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="7">
                    <FormInput
                      {...register('stateLicenseNum')}
                      label="State License #"
                      disabled={loading}
                      onIonChange={(e) => {
                        setValue('stateLicenseNum', e.detail.value);
                      }}
                      errorMessage={errors.stateLicenseNum?.message}
                    />
                  </IonCol>
                  <IonCol size="5">
                    <FormInput
                      {...register('stateLicenseExpiration')}
                      label="State Expiration"
                      disabled={loading}
                      onIonChange={(e) => {
                        setValue('stateLicenseExpiration', e.detail.value);
                      }}
                      placeholder="yyyy-mm-dd"
                      errorMessage={errors.stateLicenseExpiration?.message}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12">
                    <Controller
                      name="stateLicenseFileBase64"
                      control={control}
                      render={({ field }) => {
                        return (
                          <>
                            <FormLicenseInput
                              label="State License"
                              placeholder="Supported types: pdf, png, jpeg. File size: 5MB or smaller."
                              accept="application/pdf, image/png, image/jpeg"
                              value={field.value}
                              setValue={(filesize, filename, base64Content) => {
                                setValue('stateLicenseFilesize', filesize || 0);
                                setValue(
                                  'stateLicenseFilename',
                                  filename || ''
                                );
                                setValue(
                                  'stateLicenseFileBase64',
                                  base64Content || ''
                                );
                              }}
                              showThumbnail={true}
                              errorMessage={
                                errors.stateLicenseFileBase64?.message
                              }
                              disabled={loading}
                            />
                            {errors?.stateLicenseFileBase64 && (
                              <FormError>
                                {errors?.stateLicenseFileBase64.message}
                              </FormError>
                            )}
                          </>
                        );
                      }}
                    />
                  </IonCol>
                </IonRow>
              </IonList>
            </IonCardContent>
          </IonCard>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="12" sizeLg="6">
          <IonCard className="fullHeight">
            <IonCardHeader>
              <IonCardTitle>Address</IonCardTitle>
            </IonCardHeader>
            <IonCardContent className="ion-padding">
              <IonList lines="none">
                <IonRow>
                  <IonCol size="12" sizeLg="8" sizeXl="6">
                    <FormInput
                      label="Address Name"
                      onIonChange={(e) => {
                        setValue('addressName', e.detail.value);
                      }}
                      {...register('addressName')}
                      disabled={loading}
                      errorMessage={errors.addressName?.message}
                    />
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol>
                    <FormInput
                      label="Street Address"
                      onIonChange={(e) => {
                        setValue('address1', e.detail.value);
                      }}
                      {...register('address1')}
                      disabled={loading}
                      errorMessage={errors.address1?.message}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <FormInput
                      label="Suite, Unit, etc. (optional)"
                      onIonChange={(e) => {
                        setValue('address2', e.detail.value);
                      }}
                      {...register('address2')}
                      disabled={loading}
                      errorMessage={errors.address2?.message}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <FormInput
                      label="City"
                      onIonChange={(e) => {
                        setValue('city', e.detail.value);
                      }}
                      {...register('city')}
                      disabled={loading}
                      errorMessage={errors.city?.message}
                    />
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol size="12" sizeMd="6">
                    <Controller
                      name="state"
                      control={control}
                      render={({ field }) => {
                        return (
                          <SelectState
                            disabled={loading}
                            errorMessage={errors.state?.message}
                            rounded={true}
                            value={field.value}
                            onIonChange={(e) => {
                              setValue('state', e.detail.value);
                            }}
                          />
                        );
                      }}
                    />
                  </IonCol>
                  <IonCol size="12" sizeMd="6">
                    <FormInput
                      {...register('postalCode')}
                      label="Zip"
                      disabled={loading}
                      onIonChange={(e) => {
                        setValue('postalCode', e.detail.value);
                      }}
                      errorMessage={errors.postalCode?.message}
                    />
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol>
                    <Controller
                      name="differentBilling"
                      control={control}
                      render={({ field }) => {
                        return (
                          <IonItem lines="none" className="no-padding">
                            <IonLabel position="stacked">
                              Different Billing Address
                            </IonLabel>
                            <IonToggle
                              checked={field.value}
                              onIonChange={(e: any) => {
                                setValue('differentBilling', e.detail.checked);
                              }}
                              disabled={loading}
                            />
                            {errors.differentBilling && (
                              <FormError>
                                {errors.differentBilling?.message}
                              </FormError>
                            )}
                          </IonItem>
                        );
                      }}
                    />
                  </IonCol>
                </IonRow>
              </IonList>
            </IonCardContent>
          </IonCard>
        </IonCol>

        <IonCol size="12" sizeLg="6">
          <IonCard className="fullHeight" disabled={!differentBilling}>
            <IonCardHeader>
              <IonCardTitle>Billing Address</IonCardTitle>
            </IonCardHeader>
            <IonCardContent className="ion-padding">
              <IonList lines="none">
                <IonRow>
                  <IonCol size="12" sizeLg="8" sizeXl="6">
                    <FormInput
                      label="Billing Address Name"
                      onIonChange={(e) => {
                        setValue('billingAddressName', e.detail.value);
                      }}
                      {...register('billingAddressName')}
                      disabled={loading}
                      errorMessage={errors.billingAddressName?.message}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <FormInput
                      label="Street Address"
                      onIonChange={(e) => {
                        setValue('billingAddress1', e.detail.value);
                      }}
                      {...register('billingAddress1')}
                      disabled={loading}
                      errorMessage={errors.billingAddress1?.message}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <FormInput
                      label="Suite, Unit, etc. (optional)"
                      onIonChange={(e) => {
                        setValue('billingAddress2', e.detail.value);
                      }}
                      {...register('billingAddress2')}
                      disabled={loading}
                      errorMessage={errors.billingAddress2?.message}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <FormInput
                      label="City"
                      onIonChange={(e) => {
                        setValue('billingCity', e.detail.value);
                      }}
                      {...register('billingCity')}
                      disabled={loading}
                      errorMessage={errors.billingCity?.message}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" sizeMd="6">
                    <Controller
                      name="billingState"
                      control={control}
                      render={({ field }) => {
                        return (
                          <SelectState
                            disabled={loading}
                            errorMessage={errors.billingState?.message}
                            rounded={true}
                            value={field.value || null}
                            onIonChange={(e) => {
                              setValue('billingState', e.detail.value);
                            }}
                          />
                        );
                      }}
                    />
                  </IonCol>
                  <IonCol size="12" sizeMd="6">
                    <FormInput
                      {...register('billingPostalCode')}
                      label="Zip"
                      disabled={loading}
                      onIonChange={(e) => {
                        setValue('billingPostalCode', e.detail.value);
                      }}
                      errorMessage={errors.billingPostalCode?.message}
                    />
                  </IonCol>
                </IonRow>
              </IonList>
            </IonCardContent>
          </IonCard>
        </IonCol>
      </IonRow>
    </form>
  );
};
