import { IonCard, IonCol, IonRow } from '@ionic/react';
import { useParams } from 'react-router';
import { Page } from 'src/layouts/Page';

export const UserPage: React.FC = () => {
  const { organization_id, app_user_id } = useParams<{
    organization_id: string;
    app_user_id: string;
  }>();
  return (
    <Page title="UserPage">
      <IonRow>
        <IonCol>
          <IonCard>UserPage</IonCard>
          <p>Organization ID: {organization_id}</p>
          <p>App User ID: {app_user_id}</p>
        </IonCol>
      </IonRow>
    </Page>
  );
};
