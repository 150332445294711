import { IonRouterLink } from '@ionic/react';
import { DataTable } from 'src/components/shared/DataTable';
import { DataTableHeader } from 'src/components/shared/DataTable/interfaces/DataTableHeader';
import { DataTableRow } from 'src/components/shared/DataTable/interfaces/DataTableRow';
import { useApi } from 'src/hooks/useApi';
import { useUtils } from 'src/hooks/useUtils';
import { useEffect, useState } from 'react';

interface Organization {
  id: number;
  name: string;
  salesTotal: number;
}

export const TableTopCustomers: React.FC<{ timeFrame: string }> = ({
  timeFrame,
}) => {
  const api = useApi();
  const utils = useUtils();
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState<Organization[]>([]);

  const headers: DataTableHeader[] = [
    { text: 'Organization Name', key: 'name' },
    { text: `Total Sales (${timeFrame})`, key: 'salesTotal', align: 'right' },
  ];

  useEffect(() => {
    getTopCustomers();
  }, []);

  useEffect(() => {
    getTopCustomers();
  }, [timeFrame]);

  const mockTopCustomers: Organization[] = [
    // {
    //   id: 1,
    //   name: 'Banner',
    //   salesTotal: 23455.11,
    // },
    // {
    //   id: 2,
    //   name: "Providence St. John's",
    //   salesTotal: 12345.99,
    // },
    // {
    //   id: 3,
    //   name: 'Prisma',
    //   salesTotal: 11200.77,
    // },
    // {
    //   id: 4,
    //   name: 'Medstar',
    //   salesTotal: 10800.47,
    // },
    // {
    //   id: 5,
    //   name: 'Yale New Haven',
    //   salesTotal: 776.2,
    // },
  ];

  const getTopCustomers = () => {
    setLoading(true);
    setTimeout(() => {
      setCustomers(mockTopCustomers);
      setLoading(false);
    }, 150);

    // axios
    //   .get('topCustomers', { baseURL: process.env.REACT_APP_MOCK_URL })
    //   .then(({ data }) => {
    //     setCustomers(data || []);
    //     setLoading(false);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     setLoading(false);
    //   });
  };

  return (
    <DataTable
      title="Top Customers"
      headers={headers}
      loading={loading}
      rows={customers.map((customer): DataTableRow => {
        const row: DataTableRow = {
          item: customer,
          key: customer.id,
          columns: [
            {
              header: 'name',
              content: (
                <IonRouterLink className="font-size-large">
                  {customer.name}
                </IonRouterLink>
              ),
            },
            {
              header: 'salesTotal',
              content: (
                <p className="text-color-black font-size-default font-weight-500">
                  {utils.toUsd(customer.salesTotal, 0)}
                </p>
              ),
            },
          ],
        };
        return row;
      })}
    />
  );
};
