import './SlideHeader.css';
import { CSSProperties } from 'react';

export interface SlideStep {
  label: string | JSX.Element;
  onClick?: () => void;
}

export const SlideHeader: React.FC<{
  steps: any[];
  activeIndex: number;
  className?: string;
  style?: CSSProperties;
}> = ({ steps, activeIndex, className, style }) => {
  const getClassName = (step: any, index: number) => {
    let className = 'step';
    if (activeIndex === index) {
      className += ' active';
    }
    if (activeIndex > index) {
      className += ' complete';
    }
    if (step.onClick) {
      className += ' clickable';
    }

    return className;
  };

  const calculateWidth = (): string => {
    const percent = `${(activeIndex / (steps.length - 1)) * 100}%`;
    return percent;
  };
  return (
    <div
      style={style}
      className={className ? `slideHeader ${className}` : 'slideHeader'}
    >
      <div className="progressBar">
        <div className="progress" style={{ width: calculateWidth() }} />
      </div>
      <div className="slideSteps">
        {steps.map((step, index) => (
          <div
            className={getClassName(step, index)}
            key={index}
            onClick={step.onClick}
          >
            <div className="number">{index + 1}</div>
            <p>{step.label}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
