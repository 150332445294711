import {
  IonButton,
  IonButtons,
  IonCard,
  IonCheckbox,
  IonCol,
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPopover,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { TableSupplierDeals } from './TableSupplierDeals';
import { Breadcrumb, Breadcrumbs } from 'src/components/shared/Breadcrumbs';
import {
  addOutline,
  calendarOutline,
  closeOutline,
  saveOutline,
} from 'ionicons/icons';
import { Page } from 'src/layouts/Page';
import { useState } from 'react';

import styles from '../supplierStyles.module.css';

export const DealsPage: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const [expirationDate, setExpirationDate] = useState<string | null>(null);

  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Deals' },
  ];

  const onClickAdd = () => {
    setIsOpen(true);
  };

  const onDismiss = () => {
    setIsOpen(false);
  };

  return (
    <Page title="Supplier Deals">
      <Breadcrumbs breadcrumbs={breadcrumbs}>
        <IonButton size="small" onClick={onClickAdd}>
          Add Featured Deal
          <IonIcon slot="end" icon={addOutline} />
        </IonButton>
      </Breadcrumbs>

      <IonRow>
        <IonCol>
          <IonCard>
            <TableSupplierDeals />
          </IonCard>
        </IonCol>
      </IonRow>

      {/* TODO: Move this into its own component */}
      <IonModal
        isOpen={isOpen}
        onDidDismiss={onDismiss}
        className={styles.addDealModal}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>Add Featured Deal</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={onDismiss}>
                <IonIcon slot="icon-only" icon={closeOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList>
            <IonItem>
              <IonLabel position="floating">Vendor Item Number</IonLabel>
              <IonInput type="text" />
            </IonItem>
            <IonItem>
              <IonLabel position="floating">NDC</IonLabel>
              <IonInput type="text" placeholder="xxxx-xxx-xxx" />
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Description</IonLabel>
              <IonInput type="text" placeholder="Lorem Ipsum" />
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Available To</IonLabel>
              <IonSelect interface="popover" placeholder="Select Account Type">
                <IonSelectOption value="All">All</IonSelectOption>
                <IonSelectOption value="340B">340B</IonSelectOption>
                <IonSelectOption value="GPO">GPO</IonSelectOption>
                <IonSelectOption value="WAC">WAC</IonSelectOption>
              </IonSelect>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Dating</IonLabel>
              <IonInput type="text" placeholder="Min 3 months, etc" />
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Min. Order Qty</IonLabel>
              <IonInput type="number" />
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Price ($)</IonLabel>
              <IonInput type="number" />
            </IonItem>

            <IonItem>
              <IonLabel>Expiration date</IonLabel>
              <IonDatetimeButton datetime="expirationDate">
                {!expirationDate && (
                  <IonIcon slot="date-target" icon={calendarOutline} />
                )}
              </IonDatetimeButton>
              <IonPopover keepContentsMounted={true}>
                <IonDatetime
                  id="expirationDate"
                  presentation="date"
                  value={expirationDate}
                  onIonChange={(e) =>
                    setExpirationDate(e.detail.value as string)
                  }
                  min="2022"
                  max="2027"
                ></IonDatetime>
              </IonPopover>
              {expirationDate && (
                <IonButton
                  size="small"
                  fill="clear"
                  color="medium"
                  onClick={() => setExpirationDate(null)}
                  className="ion-no-padding"
                >
                  <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
                </IonButton>
              )}
            </IonItem>

            <IonItem lines="none">
              <IonCheckbox
                checked={termsAccepted}
                onIonChange={(e) =>
                  setTermsAccepted(e.detail.checked as boolean)
                }
              />
              <IonLabel className="ion-text-wrap">
                By adding this featured deal, I agree to Trulla's{' '}
                <span className="font-style-italic">&lt;%&gt;</span> transaction
                fee for the sale of a featured deal.
              </IonLabel>
            </IonItem>
          </IonList>
        </IonContent>
        <IonFooter>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton color="medium" onClick={onDismiss}>
                Cancel
              </IonButton>
              <IonButton
                color="primary"
                onClick={onDismiss}
                disabled={!termsAccepted}
              >
                Save
                <IonIcon slot="end" icon={saveOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      </IonModal>
    </Page>
  );
};
