import { IonButton, IonCard, IonCol, IonIcon, IonRow } from '@ionic/react';
import { TableUsers } from 'src/components/shared/TableUsers';
import { Breadcrumb, Breadcrumbs } from 'src/components/shared/Breadcrumbs';
import { addOutline } from 'ionicons/icons';
import { Page } from 'src/layouts/Page';
import { useSelector } from 'react-redux';
import { Supplier } from 'src/interfaces/Supplier';

export const UsersPage: React.FC = () => {
  const supplier: Supplier | null = useSelector(
    (state: any) => state.supplier.supplier
  );

  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    {
      label: 'Settings',
      path: supplier ? '/supplier/settings' : '/settings',
      direction: 'back',
    },
    { label: 'Users' },
  ];

  return (
    <Page title="User Settings">
      <Breadcrumbs breadcrumbs={breadcrumbs} title="User Settings">
        <IonButton
          size="small"
          routerLink={`${supplier ? '/supplier' : ''}/settings/users/add`}
          routerDirection="forward"
        >
          Add User
          <IonIcon slot="end" icon={addOutline} />
        </IonButton>
      </Breadcrumbs>
      <IonRow>
        <IonCol>
          <IonCard>
            <TableUsers />
          </IonCard>
        </IonCol>
      </IonRow>
    </Page>
  );
};
