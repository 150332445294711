import { IonItem, IonItemDivider, IonLabel, IonList } from '@ionic/react';
import { useUtils } from 'src/hooks/useUtils';

export const ViewTechnicalSupport: React.FC = () => {
  const utils = useUtils();
  return (
    <>
      <IonList>
        <IonItemDivider>
          <IonLabel>Trulla Technical Support</IonLabel>
        </IonItemDivider>
        <IonItem>
          <IonLabel>Email</IonLabel>
          <p slot="end" className="ion-text-right">
            <a href={`mailto:trulla-support@spendmend.com`}>
              trulla-support@spendmend.com
            </a>
          </p>
        </IonItem>
        <IonItem lines="none">
          <IonLabel>Phone</IonLabel>
          <p slot="end" className="ion-text-right">
            <a href={`tel:3852583688`}>{utils.formatPhone('3852583688')}</a>
          </p>
        </IonItem>
        <IonItem lines="none">
          <IonLabel>Support Hours</IonLabel>
          <p slot="end" className="ion-text-right">
            7:00 am to 5:00 pm MT
          </p>
        </IonItem>
        <IonItem lines="none">
          <IonLabel>Support Days</IonLabel>
          <p slot="end" className="ion-text-right">
            Monday - Friday
          </p>
        </IonItem>
      </IonList>
      <IonList>
        <IonItem lines="none">
          <p className="font-style-italic font-size-large ion-text-center">
            For more information please see the "Help" section
          </p>
        </IonItem>
      </IonList>
    </>
  );
};
