import { useIsAuthenticated } from '@azure/msal-react';
import { useUtils } from 'src/hooks/useUtils';
import { AppUserSelf } from 'src/interfaces/AppUserSelf';
import { LoadingPage } from 'src/pages/shared/LoadingPage';
import { NotFoundPage } from 'src/pages/shared/NotFoundPage';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

export const RoleSuperRoute = ({ component: Component, ...rest }: any) => {
  const utils = useUtils();
  const isAuthenticated = useIsAuthenticated();
  const authUser: AppUserSelf | null = useSelector(
    (state: any) => state.auth.user
  );

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated && authUser && utils.isSuperAdmin(authUser)) {
          return <Component {...props} />;
        } else if (
          isAuthenticated &&
          authUser &&
          (utils.isAnalyst(authUser) ||
            utils.isBuyer(authUser) ||
            utils.isAdmin(authUser))
        ) {
          return <Redirect to="/" />;
        } else if (authUser && !utils.isSuperAdmin(authUser)) {
          return <NotFoundPage />;
        }
        return <LoadingPage />;
      }}
    />
  );
};
