import {
  IonBadge,
  IonButton,
  IonCol,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
  IonRouterLink,
  IonRow,
  IonSearchbar,
  IonText,
  IonToggle,
  useIonAlert,
  useIonViewWillEnter,
} from '@ionic/react';
import {
  closeOutline,
  pencilOutline,
  peopleOutline,
  refreshOutline,
} from 'ionicons/icons';
import { useEffect, useId, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ButtonFilter } from 'src/components/shared/ButtonFilter';
import { DataTable } from 'src/components/shared/DataTable';
import { DataTableHeader } from 'src/components/shared/DataTable/interfaces/DataTableHeader';
import { DataTableItemAction } from 'src/components/shared/DataTable/interfaces/DataTableItemAction';
import { DataTablePagination } from 'src/components/shared/DataTable/interfaces/DataTablePagination';
import { DataTableRow } from 'src/components/shared/DataTable/interfaces/DataTableRow';
import { Subtitle } from 'src/components/shared/Subtitle';
import { useAlert } from 'src/hooks/useAlert';
import { useApi } from 'src/hooks/useApi';
import { Location } from 'src/interfaces/Location';
import { setToast } from 'src/redux/appReducer';

interface LocationForTable extends Location {
  location_type_name: string;
  location_type_description: string | null;
  location_340b_type_name: string;
  location_340b_type_abbreviation: string;
  location_340b_type_description: string | null;
  address_name: string;
  address1: string;
  address2: string | null;
  city: string;
  state: string;
  postal_code: string;
  country: string;
  billing_address_name: string | null;
  billing_address1: string | null;
  billing_address2: string | null;
  billing_city: string | null;
  billing_state: string | null;
  billing_postal_code: string | null;
  billing_country: string | null;
}

export const TableLocationsSuper: React.FC<{
  organization_name?: string;
  organization_id: string;
}> = ({ organization_name, organization_id }) => {
  const api = useApi();
  const dispatch = useDispatch();
  const [presentAlert] = useIonAlert();
  const { showAlert } = useAlert();
  const filterButtonId = useId();
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState<LocationForTable[]>([]);

  const [filterDeactivated, setFilterDeactivated] = useState(false);
  const [search, setSearch] = useState<string | null>(null);
  const [pagination, setPagination] = useState<DataTablePagination | null>(
    null
  );
  const [totalCount, setTotalCount] = useState<number>(0);
  const [orderBy, setOrderBy] = useState<string | null>('name');
  const [orderByDesc, setOrderByDesc] = useState<boolean>(false);
  const headers: DataTableHeader[] = [
    { text: 'Location Name', key: 'name', width: '50%', sortable: true },
    { text: 'Facility ID', key: 'facility_id', sortable: true },
    { text: 'Address', key: 'address', sortable: true },
    { text: 'Billing Address', key: 'billing_address', sortable: true },
  ];

  useIonViewWillEnter(() => {
    getLocations();
  }, []);

  useEffect(() => {
    getLocations();
  }, [organization_id, filterDeactivated, pagination, search]);

  const getLocations = () => {
    if (!organization_id || !pagination) {
      return;
    }
    setLoading(true);
    api
      .get(`organization/${organization_id}/locations`, {
        limit: pagination.limit,
        offset: pagination.offset,
        order_by: orderBy ? orderBy : undefined,
        order_by_desc: orderByDesc,
        active: !filterDeactivated,
        search: search ? search : undefined,
      })
      .then((response) => {
        if (response.status === 200) {
          setLocations(response.data.result || []);
          setTotalCount(response.data.total);
        }
        setLoading(false);
      });
  };

  const actions = (location: LocationForTable): DataTableItemAction[] => {
    let actions: DataTableItemAction[] = [
      {
        label: 'Manage Location',
        icon: <IonIcon icon={pencilOutline} />,
        routerLink: (location: LocationForTable) => {
          return `/settings/locations/${location.location_id}`;
        },
        callback: (location: LocationForTable) => {
          return null;
        },
      },
      {
        label: 'User Access',
        icon: <IonIcon icon={peopleOutline} />,
        routerLink: (location: LocationForTable) => {
          return `/settings/locations/${location.location_id}/users`;
        },
        callback: (location: LocationForTable) => {
          return null;
        },
      },
    ];
    if (location.active) {
      actions.push({
        label: 'Deactivate Location',
        icon: <IonIcon icon={closeOutline} />,
        color: 'danger',
        callback: (location: LocationForTable) => {
          onClickDelete(location);
        },
      });
    } else {
      actions.push({
        label: 'Re-Activate Location',
        icon: <IonIcon icon={refreshOutline} />,
        color: 'success',
        callback: (location: LocationForTable) => {
          onClickReactivate(location);
        },
      });
    }
    return actions;
  };

  const onClickDelete = (location: LocationForTable) => {
    presentAlert({
      header: 'Delete the following Location?',
      message: `${location.name}`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Delete',
          role: 'confirm',
        },
      ],
      onWillDismiss: (e: any) => {
        if (e.detail.role === 'confirm') {
          deleteLocation(location);
        }
      },
    });
  };

  const deleteLocation = (location: LocationForTable) => {
    setLoading(true);
    api
      .delete(`location/${location.location_id}`)
      .then((response) => {
        if (response.status === 204) {
          dispatch(
            setToast({
              message: 'Successfully deleted',
            })
          );
          getLocations();
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const onClickReactivate = (location: Location) => {
    const options = {
      color: 'success',
      header: 'Reactivate the following location?',
      message: location.name,
    };
    showAlert(options, (confirm: boolean) => {
      if (confirm) {
        reactivateLocation(location);
      } else {
        console.log('Cancelled');
      }
    });
  };

  const reactivateLocation = (location: Location) => {
    setLoading(true);
    const locationData = { ...location };
    locationData.active = true;
    api
      .put(`location/${location.location_id}`, locationData)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          dispatch(
            setToast({
              message: 'Location has been reactivated successfully',
            })
          );
          getLocations();
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const clearFilters = () => {
    setFilterDeactivated(false);
  };

  return (
    <>
      <DataTable
        title="Locations"
        subtitle={organization_name}
        headers={headers}
        actions={actions}
        loading={loading}
        search={false}
        onPaginationChange={(pagination: DataTablePagination) =>
          setPagination(pagination)
        }
        itemsPerPage={5}
        itemsPerPageOptions={[5, 10, 15, 20]}
        orderBy={orderBy}
        orderByDesc={orderByDesc}
        setOrderBy={setOrderBy}
        setOrderByDesc={setOrderByDesc}
        totalCount={totalCount}
        rows={locations.map((location: LocationForTable): DataTableRow => {
          const row: DataTableRow = {
            item: location,
            key: location.location_id,
            columns: [
              {
                header: 'name',
                content: (
                  <>
                    <IonRouterLink
                      className="font-size-large"
                      routerLink={`/settings/locations/${location.location_id}`}
                    >
                      {location.name}
                    </IonRouterLink>
                    <Subtitle>{location.location_type_name}</Subtitle>
                    {!location.active ? (
                      <IonText color="danger">
                        <p className="ion-no-margin font-style-italic font-size-xs">
                          Deactivated
                        </p>
                      </IonText>
                    ) : null}
                  </>
                ),
              },
              {
                header: 'facility_id',
                content: <p>{location.facility_id}</p>,
              },
              {
                header: 'address',
                content: (
                  <>
                    <p>
                      {location.address1}
                      {location.address2 && (
                        <span>,&nbsp;{location.address2}</span>
                      )}
                    </p>
                    <p>
                      {location.city} {location.state}, {location.postal_code}
                    </p>
                  </>
                ),
              },
              {
                header: 'billing_address',
                content: (
                  <>
                    {!location.billing_address1 ? (
                      <p>&mdash;</p>
                    ) : (
                      <>
                        {(location.billing_address1 ||
                          location.billing_address2) && (
                          <p>
                            {location.billing_address1}
                            {location.billing_address2 && (
                              <span>,&nbsp;{location.billing_address2}</span>
                            )}
                          </p>
                        )}
                        {(location.billing_city ||
                          location.billing_state ||
                          location.billing_postal_code) && (
                          <p>
                            {location.billing_city} {location.billing_state},{' '}
                            {location.billing_postal_code}
                          </p>
                        )}
                      </>
                    )}
                  </>
                ),
              },
            ],
          };
          return row;
        })}
      >
        <IonRow>
          <IonCol size="12" sizeSm="4">
            {filterDeactivated && (
              <IonButton fill="clear" size="small" onClick={clearFilters}>
                Clear Filters
              </IonButton>
            )}
            <ButtonFilter id={filterButtonId} />
            <IonPopover
              trigger={filterButtonId}
              triggerAction="click"
              className="filterPopover"
            >
              <IonContent>
                <IonList lines="none" className="padding-sm">
                  <IonItem button detail={false} className="filterItem">
                    <IonLabel>Deactivated</IonLabel>
                    <IonToggle
                      slot="end"
                      checked={filterDeactivated}
                      onIonChange={(e) =>
                        setFilterDeactivated(e.detail.checked as boolean)
                      }
                    />
                  </IonItem>
                </IonList>
              </IonContent>
            </IonPopover>
          </IonCol>
          <IonCol size="12" sizeSm="8">
            <IonSearchbar
              placeholder="Search Locations..."
              value={search}
              onIonChange={(e: any) => setSearch(e.detail.value as string)}
              debounce={350}
              className="ion-text-left"
            />
          </IonCol>
        </IonRow>
      </DataTable>
    </>
  );
};
