import {
  IconDefinition,
  faGear,
  faPills,
  faScrewdriverWrench,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonCard, IonCardContent, IonCol, IonRow } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useUtils } from 'src/hooks/useUtils';
import { Organization } from 'src/interfaces/Organization';
import { Subtitle } from '../Subtitle';
import { SupportCategory } from './ModalContactUs';
import styles from './contactUs.module.css';

interface CategoryCard {
  category: SupportCategory;
  title: string;
  helperText?: string | null;
  icon: IconDefinition;
}

export const ViewNoneSelected: React.FC<{
  handleSelect: (category: SupportCategory) => void;
}> = ({ handleSelect }) => {
  const utils = useUtils();
  const organization: Organization | null = useSelector(
    (state: any) => state.app.organization
  );
  const [categoryCards, setCategoryCards] = useState<CategoryCard[]>([]);

  useEffect(() => {
    const categoryCards: CategoryCard[] = [
      {
        category: 'supplier',
        title: 'A specific Supplier',
        helperText:
          'I want to ask a question, check an order, report an order problem, etc.',
        icon: faPills,
      },
      {
        category: 'admin',
        title: "One of my organization's Trulla Admins",
        helperText:
          'I want to add a user, change which locations I have access to, etc.',
        icon: faGear,
      },
      {
        category: 'technical',
        title: 'Trulla technical support',
        helperText:
          "I'm experiencing a bug, or some other technical issue with the website.",
        icon: faScrewdriverWrench,
      },
    ];

    // if the logged in user is a Supplier, hide the option to contact other Suppliers.
    const isSupplier = utils.isSupplierOrganization(organization);
    if (isSupplier) {
      setCategoryCards(
        categoryCards.filter((catCard) => {
          return catCard.category !== 'supplier';
        })
      );
    } else {
      setCategoryCards(categoryCards);
    }
  }, [organization]);

  return (
    <IonRow className="ion-padding-horizontal ion-padding-bottom">
      <IonCol size="12">
        <h2 className="font-size-large text-color-black ion-text-center">
          I'd like to contact...
        </h2>
      </IonCol>
      {categoryCards.map((categoryCard, index) => (
        <IonCol
          size="12"
          key={categoryCard.category}
          className="ion-margin-bottom"
        >
          <IonCard button onClick={() => handleSelect(categoryCard.category)}>
            <IonCardContent>
              <div className={styles.selectCategoryContainer}>
                <FontAwesomeIcon
                  icon={categoryCard.icon}
                  className={styles.selectCategoryIcon}
                />
                <div>
                  <h3 className="text-color-black font-weight-600">
                    {categoryCard.title}
                  </h3>
                  <Subtitle>{categoryCard.helperText}</Subtitle>
                </div>
              </div>
            </IonCardContent>
          </IonCard>
        </IonCol>
      ))}
    </IonRow>
  );
};
