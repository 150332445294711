import { faHospital } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonCol,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonRow,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { useUtils } from 'src/hooks/useUtils';
import { AppUser } from 'src/interfaces/AppUser';
import { AssignedAppUser } from 'src/interfaces/AssignedAppUser';
import { Location } from 'src/interfaces/Location';
import { addOutline, closeOutline } from 'ionicons/icons';

import { useEffect, useState } from 'react';

export const ModalAssignUsers: React.FC<{
  location: Location;
  users: AppUser[];
  onDismiss: (
    data?: AssignedAppUser[] | null | undefined,
    role?: string
  ) => void;
}> = ({ location, users, onDismiss }) => {
  const utils = useUtils();
  const [search, setSearch] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [usersFiltered, setUsersFiltered] = useState<AppUser[]>(users);

  const [selectedUsers, setSelectedUsers] = useState<AssignedAppUser[]>([]);

  useEffect(() => {
    if (users) {
      filterUsers();
    } else {
      setUsersFiltered([]);
    }
  }, [users, search]);

  const filterUsers = () => {
    setUsersFiltered(
      users.filter((user: AppUser) =>
        // Filter by search query
        utils.getFullName(user).toLowerCase().includes(search.toLowerCase())
      )
    );
  };

  console.log(location);

  const onClickAdd = () => {
    onDismiss(selectedUsers);
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Assign Users</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => onDismiss(null, 'cancel')}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <div>
            <IonSearchbar
              value={search}
              onIonChange={(e: any) => setSearch(e.detail.value as string)}
              placeholder="Search for a User"
            />
            <div className="ion-padding-horizontal ion-margin-bottom d-flex ion-align-items-end ion-justify-content-between">
              <p className="ion-no-margin ion-margin-end">
                <FontAwesomeIcon
                  icon={faHospital}
                  className="margin-right-sm"
                />
                {location?.name}
              </p>
              <span className="font-style-italic">Current Location</span>
            </div>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {usersFiltered.length > 0 ? (
          <IonList>
            {usersFiltered.map((user) => (
              <IonItem key={user.app_user_id} disabled={loading}>
                <IonCheckbox
                  slot="start"
                  checked={
                    !!selectedUsers.find(
                      (selectedUser) =>
                        selectedUser.app_user_id === user.app_user_id
                    )
                  }
                  onIonChange={(e) => {
                    const checked = e.detail.checked;
                    if (checked) {
                      setSelectedUsers([
                        ...selectedUsers,
                        {
                          app_user_id: user.app_user_id,
                          first_name: user.first_name || '',
                          last_name: user.last_name || '',
                          email_address: user.email_address || '',
                        },
                      ]);
                    } else {
                      setSelectedUsers(
                        selectedUsers.filter(
                          (selectedUser) =>
                            selectedUser.app_user_id !== user.app_user_id
                        )
                      );
                    }
                  }}
                />
                <IonRow className="fullWidth">
                  <IonCol
                    size="12"
                    sizeMd="6"
                    className="ion-no-padding ion-text-left"
                  >
                    <p>{utils.getFullName(user)}</p>
                  </IonCol>
                  <IonCol
                    size="12"
                    sizeMd="6"
                    className="ion-no-padding ion-padding-start ion-text-right"
                  >
                    <p>{user.email_address}</p>
                  </IonCol>
                </IonRow>
              </IonItem>
            ))}
          </IonList>
        ) : (
          <div className="ion-padding ion-text-center">
            <p className="font-style-italic">No users available</p>
          </div>
        )}
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <div slot="end">
            <IonButton
              fill="clear"
              color="dark"
              size="small"
              onClick={() => onDismiss()}
            >
              Cancel
            </IonButton>
            <IonButton
              size="small"
              onClick={onClickAdd}
              disabled={selectedUsers.length === 0}
            >
              Add
              <IonIcon slot="end" icon={addOutline} />
            </IonButton>
          </div>
        </IonToolbar>
      </IonFooter>
    </>
  );
};
