import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import {
  IonApp,
  IonLoading,
  IonSplitPane,
  setupIonicReact,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';

/* Theme variables */
import 'src/styles/variables.css';

/* KendoReact Theme */
import '@progress/kendo-theme-default/dist/all.css';

/* Inline Manual stylesheet*/
import 'src/styles/inline-manual.css';

/* Custom stylesheet */
import 'src/styles/App.css';

import { InteractionStatus } from '@azure/msal-browser';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { ModalSupplierAdd } from 'src/components/buyer/ModalSupplierAdd';
import { EffectsManager } from 'src/components/shared/EffectsManager';
import { Header } from 'src/components/shared/Header';
import { Menu } from 'src/components/shared/Menu';
import { ToastManager } from 'src/components/shared/ToastManager';
import { useAlertAddSupplier } from 'src/hooks/useAlertAddSupplier';
import { useUtils } from 'src/hooks/useUtils';
import { Account } from 'src/interfaces/Account';
import { AppUserSelf } from 'src/interfaces/AppUserSelf';
import { Organization } from 'src/interfaces/Organization';
import { Routes } from 'src/routes/Routes';
import { ModalContactUs } from './components/shared/ModalContactUs';
import { setContactUsOpen } from './redux/appReducer';

setupIonicReact({
  mode: 'ios',
  swipeBackEnabled: false,
  innerHTMLTemplatesEnabled: false,
});

export const App: React.FC = () => {
  const utils = useUtils();
  const { instance, inProgress, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const alertAddSupplier = useAlertAddSupplier();
  const dispatch = useDispatch();

  // app state
  const authUser: AppUserSelf | null = useSelector(
    (state: any) => state.auth.user
  );
  const supplierAccounts: Account[] | null = useSelector(
    (state: any) => state.app.accounts
  );
  const organization: Organization | null = useSelector(
    (state: any) => state.app.organization
  );
  const contactUsOpen: boolean = useSelector(
    (state: any) => state.app.contactUsOpen
  );
  const [addSupplierModalOpen, setAddSupplierModalOpen] = useState(false);

  // supplierAccounts
  useEffect(() => {
    if (supplierAccounts && supplierAccounts.length === 0) {
      alertAddSupplier.showAlert((e: any) => {
        if (e.detail.role === 'confirm') {
          if (authUser && !utils.isAnalyst(authUser)) {
            setAddSupplierModalOpen(true);
          }
        }
      });
    }
  }, [supplierAccounts]);

  return (
    <IonApp
      className={utils.isSupplierOrganization(organization) ? 'supplier' : ''}
    >
      <IonReactRouter>
        {isAuthenticated && authUser ? (
          <IonSplitPane contentId="mainContent" when="xl" placeholder="">
            <Menu />
            <div id="mainContent">
              <Header />
              <Routes />
            </div>
          </IonSplitPane>
        ) : (
          <Routes />
        )}
      </IonReactRouter>

      {/* Global toast messages */}
      <ToastManager />

      <ModalContactUs
        isOpen={contactUsOpen}
        onDismiss={() => {
          dispatch(setContactUsOpen(false));
        }}
      />

      {/* Global manager for side effects */}
      <EffectsManager />

      {/*  Global tooltips */}
      <Tooltip id="global-tooltip" />

      {authUser && !utils.isAnalyst(authUser) ? (
        <ModalSupplierAdd
          isOpen={addSupplierModalOpen}
          onClose={(data?: any) => setAddSupplierModalOpen(false)}
          onDismiss={(data?: any) => {
            setAddSupplierModalOpen(false);
          }}
        />
      ) : null}
      {/* popover loading for logout state */}
      <IonLoading
        isOpen={inProgress === InteractionStatus.Logout}
        translucent={true}
        spinner="crescent"
      ></IonLoading>
    </IonApp>
  );
};
