import {
  IonButton,
  IonCard,
  IonCardContent,
  IonChip,
  IonCol,
  IonIcon,
  IonProgressBar,
  IonRow,
  IonSkeletonText,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Breadcrumb, Breadcrumbs } from 'src/components/shared/Breadcrumbs';
import { useApi } from 'src/hooks/useApi';
import { Supplier } from 'src/interfaces/Supplier';
import { Page } from 'src/layouts/Page';

import { chevronBackOutline, chevronForwardOutline } from 'ionicons/icons';
import { ListSupplierContacts } from 'src/components/buyer/ListSupplierContacts';
import { LoadingInline } from 'src/components/shared/LoadingInline';
import { LogoSupplier } from 'src/components/shared/LogoSupplier';
import { FormAccountApplication } from 'src/features/accountApplication/components/FormAccountApplication';
import { FormStep } from 'src/features/accountApplication/interfaces/FormStep';

import { SupplierTerm } from 'src/interfaces/SupplierTerm';
import styles from '../buyerStyles.module.css';
import { Organization } from 'src/interfaces/Organization';
import { useSelector } from 'react-redux';

export const AccountApplicationPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const api = useApi();
  const organization: Organization | null = useSelector(
    (state: any) => state.app.organization
  );

  const [loading, setLoading] = useState(false);
  const [supplierLoading, setSupplierLoading] = useState(false);
  const [supplier, setSupplier] = useState<Supplier | null>(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [supplierTerms, setSupplierDocs] = useState<SupplierTerm[]>([]);
  const [supplierTermsLoading, setSupplierDocsLoading] =
    useState<boolean>(true);

  /**
   * Each Supplier may have unique configuration for this form, so the steps must be dynamically set.
   */
  const [formSteps, setFormSteps] = useState<FormStep[]>([]);

  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Suppliers', path: '/suppliers', direction: 'back' },
    {
      label: supplier ? `${supplier.name}` : id,
      path: `/suppliers/${id}`,
      direction: 'back',
    },
    {
      label: 'Account Application',
    },
  ];

  useEffect(() => {
    getSupplier();
  }, [id]);

  useEffect(() => {
    if (supplier) {
      getSupplierTerms();
    } else {
      setSupplierDocs([]);
    }
  }, [supplier]);

  const getSupplier = () => {
    setSupplierLoading(true);
    api
      .get(`supplier/${id}`)
      .then(({ data }) => {
        setSupplier(data);
        setSupplierLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setSupplierLoading(false);
      });
  };

  const getSupplierTerms = () => {
    if (!supplier || !organization) {
      return;
    }
    setSupplierDocsLoading(true);
    api
      .get(`organization/${organization.organization_id}/supplier_terms`, {
        supplier_id: supplier.supplier_id,
      })
      .then((response) => {
        if (response.status === 200) {
          const docs: SupplierTerm[] = response.data;
          setSupplierDocs(docs);
        }
        setSupplierDocsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setSupplierDocsLoading(false);
      });
  };

  const onClickPrev = () => {
    setActiveIndex(activeIndex - 1);
  };

  const getFormId = (activeIndex: number) => {
    if (formSteps.length === 0) return;
    const formStep: FormStep = formSteps[activeIndex];
    return formStep.formId;
  };

  const Footer: React.FC = () => {
    return (
      <div className="d-flex ion-justify-content-between">
        <div>
          {activeIndex > 0 ? (
            <IonButton
              color="primary"
              onClick={onClickPrev}
              fill="clear"
              disabled={loading}
            >
              <IonIcon slot="start" icon={chevronBackOutline} />
              Previous
            </IonButton>
          ) : null}
        </div>
        <div>
          <IonButton
            color="primary"
            form={getFormId(activeIndex)}
            type="submit"
            disabled={loading}
          >
            {activeIndex === formSteps.length - 1
              ? 'Submit Application'
              : 'Next'}
            {loading ? (
              <span slot="end" className="ion-margin-start">
                <LoadingInline color="light" />
              </span>
            ) : (
              <IonIcon slot="end" icon={chevronForwardOutline} />
            )}
          </IonButton>
        </div>
      </div>
    );
  };

  return (
    <Page footer={<Footer />} title="Account Application">
      <Breadcrumbs
        breadcrumbs={breadcrumbs}
        title="Apply for an Account"
      ></Breadcrumbs>
      {supplierLoading ? (
        <IonProgressBar type="indeterminate" color="primary" />
      ) : (
        <>
          {supplier?.application_enabled ? (
            <>
              <IonRow>
                <IonCol size="12" className="ion-text-center">
                  {(supplier && <LogoSupplier src={supplier.logo} />) || (
                    <IonSkeletonText
                      animated={true}
                      className="supplierLogo"
                    ></IonSkeletonText>
                  )}
                </IonCol>
              </IonRow>
              <FormAccountApplication
                supplier={supplier}
                activeIndex={activeIndex}
                loading={loading}
                supplierTerms={supplierTerms}
                supplierTermsLoading={supplierTermsLoading}
                setLoading={setLoading}
                setActiveIndex={setActiveIndex}
                setFormSteps={setFormSteps}
              />
            </>
          ) : (
            <IonRow>
              {/* "NOT SUPPORTED" MESSAGING */}
              <IonCol>
                <IonCard className={styles.accountApplicationCard}>
                  <div className="ion-padding ion-text-center">
                    {(supplier && <LogoSupplier src={supplier.logo} />) || (
                      <IonSkeletonText
                        animated={true}
                        className="supplierLogo"
                      ></IonSkeletonText>
                    )}
                  </div>
                  <IonCardContent>
                    <IonRow>
                      <IonCol size="12" className="ion-text-center">
                        <IonChip color="danger">
                          Account Application Not Supported
                        </IonChip>
                      </IonCol>
                      <IonCol size="12" className="">
                        <p className="margin-bottom-large">
                          {supplier?.name} does not currently support
                          applications for direct accounts from within Trulla
                          Direct.
                        </p>
                      </IonCol>
                      <IonCol size="12">
                        <p>
                          Use the information below to contact {supplier?.name}{' '}
                          if you wish to apply for a direct account.
                        </p>
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                  <div>
                    {supplier ? (
                      <ListSupplierContacts supplier={supplier} />
                    ) : (
                      <IonProgressBar type="indeterminate" color="primary" />
                    )}
                  </div>
                </IonCard>
              </IonCol>
            </IonRow>
          )}
        </>
      )}
    </Page>
  );
};
