import { IonButton, IonIcon } from '@ionic/react';
import { arrowBackOutline, homeOutline } from 'ionicons/icons';
import { Page } from 'src/layouts/Page';

import { useHistory } from 'react-router';

export const NotFoundPage: React.FC = () => {
  const history = useHistory();

  const style = {
    container: {
      paddingTop: '100px',
    },
    header: {
      fontSize: '80px',
      lineHeight: 1,
      marginTop: 0,
    },
  };

  return (
    <Page title="404">
      <div className="ion-text-center" style={style.container}>
        <h1 className="ion-margin-bottom" style={style.header}>
          404
        </h1>
        <p className="ion-margin-bottom">
          Whoops, the page you're looking for couldn't be found...
        </p>
        <IonButton
          className="ion-margin-horizontal"
          fill="outline"
          onClick={() => history.goBack()}
        >
          <IonIcon slot="start" icon={arrowBackOutline}></IonIcon>
          Go Back
        </IonButton>
        <IonButton
          routerLink="/"
          routerDirection="root"
          className="ion-margin-horizontal"
          fill="outline"
        >
          <IonIcon slot="start" icon={homeOutline} />
          Home
        </IonButton>
      </div>
    </Page>
  );
};
