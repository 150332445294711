import { LoadingInline } from 'src/components/shared/LoadingInline';
import { DataTableActions } from './DataTableActions';
import { DataTableHeader } from './interfaces/DataTableHeader';
import { DataTableItemAction } from './interfaces/DataTableItemAction';
import { DataTableRow } from './interfaces/DataTableRow';

export const DataTableMobile: React.FC<{
  headers: DataTableHeader[];
  rows: DataTableRow[];
  onClickRow?: any;
  actions?: (item: any) => DataTableItemAction[];
  loading?: boolean;
}> = ({ headers, rows, onClickRow, actions, loading }) => {
  const getHeaderText = (value: string): string | null => {
    const header = headers.find((header) => {
      return header.key === value;
    });
    if (header) {
      return header.text;
    }
    return null;
  };

  return (
    <table className="mobile">
      <thead></thead>
      <tbody>
        {loading && (
          <tr>
            <td>
              <LoadingInline large />
            </td>
          </tr>
        )}
        {!loading && rows.length === 0 && (
          <tr>
            <td colSpan={100}>
              <h2 className="ion-text-center ion-margin-vertical font-style-italic font-size-large">
                No data to display...
              </h2>
            </td>
          </tr>
        )}
        {!loading &&
          rows.map((row: DataTableRow, rowIndex: number) => {
            return (
              <tr
                key={row.key || rowIndex}
                className={onClickRow ? 'clickable' : ''}
                onClick={() => (onClickRow ? onClickRow(row) : null)}
              >
                {headers.map((header) => {
                  const column = row.columns.find(
                    (column) => column.header === header.key
                  );
                  if (!column) {
                    return <></>;
                  }
                  return (
                    <td key={column.header} className="mobileRow">
                      <div className="mobileHeader">
                        {getHeaderText(column.header)}
                      </div>
                      <div className="mobileContent">{column.content}</div>
                    </td>
                  );
                })}

                {actions && (
                  <td className="mobileRow">
                    <div className="mobileHeader">Actions</div>
                    <div className="mobileContent ion-text-right">
                      <DataTableActions actions={actions} item={row.item} />
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};
