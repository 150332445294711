import { IonItem, IonLabel, IonSkeletonText } from '@ionic/react';
import { ApplicationField } from 'src/features/accountApplication/interfaces/ApplicationField';
import { useCurrentScreenWidth } from 'src/hooks/useCurrentScreenWidth';

export const AccountApplicationItem: React.FC<{
  field: ApplicationField | null;
  loading?: boolean;
}> = ({ field, loading }) => {
  const width = useCurrentScreenWidth();

  return (
    <>
      {field ? (
        <IonItem className="no-padding">
          <IonLabel position={width < 768 ? 'stacked' : undefined}>
            {loading ? (
              <IonSkeletonText
                animated
                style={{ width: '60%', height: '16px' }}
              />
            ) : (
              field.name
            )}
          </IonLabel>
          {loading ? (
            <IonSkeletonText
              animated
              style={{ width: '35%', height: '16px' }}
            />
          ) : (
            <p className="text-color-black font-weight-600">{field.value}</p>
          )}
        </IonItem>
      ) : null}
    </>
  );
};
