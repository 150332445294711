import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonSkeletonText,
} from '@ionic/react';
import { Breadcrumb, Breadcrumbs } from 'src/components/shared/Breadcrumbs';
import { Page } from 'src/layouts/Page';

import { ButtonEdit } from 'src/components/shared/ButtonEdit';
import { CardActions } from 'src/components/shared/CardActions';
import { UserAvatar } from 'src/components/shared/UserAvatar';
import { useApi } from 'src/hooks/useApi';
import { useUtils } from 'src/hooks/useUtils';
import { AppUserSelf } from 'src/interfaces/AppUserSelf';
import { Location } from 'src/interfaces/Location';
import { Organization } from 'src/interfaces/Organization';
import { pencilOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setToast } from 'src/redux/appReducer';
import { setUser } from 'src/redux/authReducer';
import styles from '../sharedStyles.module.css';

export const UserProfilePage: React.FC = () => {
  const api = useApi();
  const utils = useUtils();
  const authUser: AppUserSelf | null = useSelector(
    (state: any) => state.auth.user
  );
  const organization: Organization | null = useSelector(
    (state: any) => state.app.organization
  );
  const selectedLocation: Location | null = useSelector(
    (state: any) => state.app.selectedLocation
  );
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();

  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    if (authUser) {
      refreshAuthUser();
    }
  }, []);

  useEffect(() => {
    setFirstName(authUser?.first_name);
    setLastName(authUser?.last_name);
  }, [authUser]);

  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'User Profile' },
  ];

  const revertChanges = () => {
    setFirstName(authUser?.first_name);
    setLastName(authUser?.last_name);
  };

  const onClickCancel = () => {
    revertChanges();
    setEditing(false);
  };
  const onClickSave = () => {
    // TODO: form validation
    updateUser();
  };
  const updateUser = () => {
    setLoading(true);
    const data = {
      first_name: firstName,
      last_name: lastName,
    };
    api
      .put('app_user/self', data)
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            setToast({
              message: 'Successfully updated!',
            })
          );
          dispatch(setUser(response.data[0]));
        }
        setLoading(false);
        setEditing(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const refreshAuthUser = () => {
    setLoading(true);
    api
      .get('app_user/self')
      .then((response) => {
        if (response.status === 200) {
          dispatch(setUser(response.data));
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <Page className={styles.userProfile} title="User Profile">
      <Breadcrumbs breadcrumbs={breadcrumbs}></Breadcrumbs>
      <IonRow>
        <IonCol>
          <IonCard>
            <IonCardContent className="ion-text-center">
              <IonRow>
                <IonCol className="ion-text-center">
                  {loading ? (
                    <IonSkeletonText
                      animated={true}
                      className={styles.userProfileAvatar}
                    />
                  ) : (
                    <>{authUser ? <UserAvatar user={authUser} /> : null}</>
                  )}
                </IonCol>
              </IonRow>

              {editing ? (
                <IonRow>
                  <IonCol size="12">
                    <IonItem lines="full">
                      <IonLabel position="floating">First Name</IonLabel>
                      <IonInput
                        type="text"
                        value={firstName}
                        readonly={!editing}
                        disabled={loading}
                        onIonChange={(e) =>
                          setFirstName(e.detail.value as string)
                        }
                      />
                    </IonItem>
                  </IonCol>
                  <IonCol size="12">
                    <IonItem lines="full">
                      <IonLabel position="floating">Last Name</IonLabel>
                      <IonInput
                        type="text"
                        value={lastName}
                        readonly={!editing}
                        disabled={loading}
                        onIonChange={(e) =>
                          setLastName(e.detail.value as string)
                        }
                      />
                    </IonItem>
                  </IonCol>
                </IonRow>
              ) : (
                <IonRow>
                  <IonCol>
                    <h1>
                      {firstName} {lastName}
                      <IonButton
                        fill="clear"
                        className={`${styles.userProfileEditButton} ion-no-padding ion-no-margin`}
                        size="small"
                        onClick={() => setEditing(true)}
                        data-tooltip-id="global-tooltip"
                        data-tooltip-content="Edit Name"
                        data-tooltip-place="bottom"
                        disabled={loading}
                      >
                        <IonIcon icon={pencilOutline} slot="icon-only" />
                      </IonButton>
                    </h1>
                    <p>{authUser?.email_address}</p>
                    <IonList>
                      <IonItem>
                        <IonLabel>Organization</IonLabel>
                        <p className="font-weight-500 text-color-black">
                          {organization?.name}
                        </p>
                      </IonItem>
                      {utils.isBuyerOrganization(organization) ? (
                        <IonItem>
                          <IonLabel>Current Location</IonLabel>
                          <p className="font-weight-500 text-color-black">
                            {selectedLocation?.name}
                          </p>
                        </IonItem>
                      ) : null}
                      <IonItem>
                        <IonLabel>Role</IonLabel>
                        <p className="font-weight-500 text-color-black text-capitalize">
                          {authUser?.app_role_name}
                        </p>
                      </IonItem>
                    </IonList>
                  </IonCol>
                </IonRow>
              )}
            </IonCardContent>
            <CardActions>
              {editing ? (
                <ButtonEdit
                  editing={editing}
                  loading={loading}
                  onClickEdit={() => setEditing(true)}
                  onClickCancel={onClickCancel}
                  onClickSave={onClickSave}
                />
              ) : null}
            </CardActions>
          </IonCard>
        </IonCol>
      </IonRow>
    </Page>
  );
};
