import {
  IonButton,
  IonCard,
  IonCol,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
  IonRow,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  useIonModal,
  useIonRouter,
} from '@ionic/react';
import { Breadcrumb, Breadcrumbs } from 'src/components/shared/Breadcrumbs';
import { ButtonFilter } from 'src/components/shared/ButtonFilter';
import { DataTable } from 'src/components/shared/DataTable';
import { DataTableHeader } from 'src/components/shared/DataTable/interfaces/DataTableHeader';
import { DataTablePagination } from 'src/components/shared/DataTable/interfaces/DataTablePagination';
import { DataTableRow } from 'src/components/shared/DataTable/interfaces/DataTableRow';
import { useApi } from 'src/hooks/useApi';
import { useUtils } from 'src/hooks/useUtils';
import { ApplicationOrganization } from 'src/interfaces/ApplicationOrganization';
import { Supplier } from 'src/interfaces/Supplier';
import { SupplierAccountApplication } from 'src/interfaces/SupplierAccountApplication';
import { Page } from 'src/layouts/Page';
import { useEffect, useId, useState } from 'react';
import { useSelector } from 'react-redux';
import { ModalAccountApplications } from './ModalAccountApplications';

export const AccountApplicationsPage: React.FC = () => {
  const api = useApi();
  const utils = useUtils();
  const filterButtonId = useId();
  const supplier: Supplier | null = useSelector(
    (state: any) => state.supplier.supplier
  );
  const router = useIonRouter();

  const [loading, setLoading] = useState(false);

  const [pagination, setPagination] = useState<DataTablePagination | null>(
    null
  );
  const [totalCount, setTotalCount] = useState<number>(0);
  const [orderBy, setOrderBy] = useState<string | null>('name');
  const [orderByDesc, setOrderByDesc] = useState<boolean>(false);
  const [filtersPopover, setFiltersPopover] = useState(false);

  const [search, setSearch] = useState<string | null>(null);
  const [filterStatus, setFilterStatus] = useState<string | null>(null);

  const [applicationOrgs, setApplicationOrgs] = useState<
    ApplicationOrganization[]
  >([]);
  const [selectedOrg, setSelectedOrg] =
    useState<ApplicationOrganization | null>(null);

  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Accounts', path: '/supplier/accounts', direction: 'back' },
    { label: 'Account Applications' },
  ];

  const [presentModal, dismissModal] = useIonModal(ModalAccountApplications, {
    organization: selectedOrg,
    onDismiss: (
      application?: SupplierAccountApplication | null | undefined,
      role?: string
    ) => {
      dismissModal(application, role);
      setSelectedOrg(null);
      if (application) {
        router.push(
          `/supplier/accounts/applications/${application.account_application_id}`
        );
      }
    },
  });

  useEffect(() => {
    getOrganizations();
  }, []);

  useEffect(() => {
    getOrganizations();
  }, [supplier, search, pagination, filterStatus]);

  useEffect(() => {
    if (selectedOrg) {
      showModal();
    }
  }, [selectedOrg]);

  const showModal = () => {
    if (!selectedOrg) {
      return;
    }
    presentModal({
      onWillDismiss: (e: any) => {
        setSelectedOrg(null);
      },
    });
  };

  const headers: DataTableHeader[] = [
    { text: 'Organization Name', key: 'name', sortable: true },
  ];

  const getOrganizations = () => {
    if (!supplier || !pagination) {
      return;
    }
    setLoading(true);
    api
      .get(
        `supplier/${supplier.supplier_id}/account_application_organizations`,
        {
          limit: pagination.limit,
          offset: pagination.offset,
          order_by: orderBy ? orderBy : null,
          order_by_desc: orderByDesc,
          search: search ? search : undefined,
          application_status: filterStatus || undefined,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setApplicationOrgs(response.data.result || []);
          setTotalCount(response.data.total || 0);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const clearFilters = () => {
    setFilterStatus(null);
  };

  return (
    <Page title="Account Applications">
      <Breadcrumbs breadcrumbs={breadcrumbs}></Breadcrumbs>
      <IonRow>
        <IonCol>
          <IonCard>
            <DataTable
              title="Account Applications"
              onPaginationChange={(pagination: DataTablePagination) =>
                setPagination(pagination)
              }
              orderBy={orderBy}
              orderByDesc={orderByDesc}
              setOrderBy={setOrderBy}
              setOrderByDesc={setOrderByDesc}
              totalCount={totalCount}
              headers={headers}
              itemsPerPageOptions={[10, 15, 25, 50]}
              loading={loading}
              search={false}
              subtitle={
                <>
                  {filterStatus !== null ? (
                    <span>
                      Showing Organizations with{' '}
                      <span className="text-color-black font-weight-600">
                        {filterStatus}
                      </span>{' '}
                      applications
                    </span>
                  ) : (
                    <span>
                      Showing{' '}
                      <span className="text-color-black font-weight-600">
                        all
                      </span>{' '}
                      Organizations
                    </span>
                  )}
                </>
              }
              onClickRow={(row: DataTableRow) => {
                const organization: ApplicationOrganization = row.item;
                setSelectedOrg(organization);
              }}
              rows={applicationOrgs.map(
                (
                  organization: ApplicationOrganization,
                  index
                ): DataTableRow => {
                  const row: DataTableRow = {
                    item: organization,
                    key: organization.organization_id,
                    columns: [
                      {
                        header: 'name',
                        content: (
                          <p
                            className="font-size-large"
                            style={{
                              marginTop: 'var(--app-spacing-small)',
                              marginBottom: 'var(--app-spacing-small)',
                            }}
                          >
                            {organization.name}
                          </p>
                        ),
                      },
                    ],
                  };
                  return row;
                }
              )}
            >
              <IonRow>
                <IonCol size="12" sizeSm="4">
                  {filterStatus !== null ? (
                    <IonButton fill="clear" size="small" onClick={clearFilters}>
                      Clear Filters
                    </IonButton>
                  ) : null}
                  <ButtonFilter
                    id={filterButtonId}
                    disabled={loading}
                    onClick={() => setFiltersPopover(true)}
                  />
                  <IonPopover
                    trigger={filterButtonId}
                    isOpen={filtersPopover}
                    triggerAction="click"
                    onIonPopoverWillDismiss={() => setFiltersPopover(false)}
                    className="popoverOrderFilters"
                  >
                    <IonContent>
                      <IonList className="padding-sm">
                        <IonItem lines="none" className="no-padding">
                          <IonLabel position="stacked">Status</IonLabel>
                          <IonSelect
                            className="rounded"
                            placeholder="Filter by Status"
                            interface="popover"
                            interfaceOptions={{
                              alignment: 'end',
                              dismissOnSelect: true,
                              size: 'auto',
                            }}
                            onIonChange={(e) => setFilterStatus(e.detail.value)}
                            value={filterStatus}
                          >
                            <IonSelectOption value={'pending'}>
                              Pending
                            </IonSelectOption>
                            <IonSelectOption value={'approved'}>
                              Approved
                            </IonSelectOption>
                            <IonSelectOption value={'rejected'}>
                              Rejected
                            </IonSelectOption>
                          </IonSelect>
                        </IonItem>
                      </IonList>
                    </IonContent>
                  </IonPopover>
                </IonCol>

                <IonCol size="12" sizeSm="8">
                  <IonSearchbar
                    value={search}
                    onIonChange={(e: any) =>
                      setSearch(e.detail.value as string)
                    }
                    debounce={350}
                    className="ion-text-left"
                    placeholder="Search"
                  />
                </IonCol>
              </IonRow>
            </DataTable>
          </IonCard>
        </IonCol>
      </IonRow>
    </Page>
  );
};
