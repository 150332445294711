import { IonCol, IonRow, IonicSlides, useIonRouter } from '@ionic/react';
import { Buffer } from 'buffer';
import { useEffect, useId, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from 'src/components/shared/Divider';
import { StepCard } from 'src/components/shared/StepCard';
import { ApplicationField } from 'src/features/accountApplication/interfaces/ApplicationField';
import { FormStep } from 'src/features/accountApplication/interfaces/FormStep';
import { useApi } from 'src/hooks/useApi';
import { useUtils } from 'src/hooks/useUtils';
import { AppUserSelf } from 'src/interfaces/AppUserSelf';
import { Supplier } from 'src/interfaces/Supplier';
import { SupplierTerm } from 'src/interfaces/SupplierTerm';
import { setToast } from 'src/redux/appReducer';
import { Swiper as SwiperInterface } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ApplicationStep1, ApplicationStep1Schema } from './ApplicationStep1';
import { ApplicationStep2, ApplicationStep2Schema } from './ApplicationStep2';
import { ApplicationStep3, ShipToFormData } from './ApplicationStep3';
import { ApplicationStep4, ApplicationStep4Schema } from './ApplicationStep4';

interface DocumentSchema {
  name: string;
  filesize: number | null;
  original_filename: string | null;
  content: string;
}
interface AccountApplicationSchema {
  location_id: number;
  location_name: string;
  supplier_id: number;
  fields: ApplicationField[];
  supplier_term_ids: number[];
  dea_license: DocumentSchema;
  state_license: DocumentSchema;
  fields_csv: DocumentSchema | null;
}

export const FormAccountApplicationSandoz: React.FC<{
  supplier: Supplier;
  activeIndex: number;
  loading?: boolean;
  supplierTerms: SupplierTerm[];
  supplierTermsLoading: boolean;
  setLoading: (loading: boolean) => void;
  setActiveIndex: (index: number) => void;
  setFormSteps: (steps: FormStep[]) => void;
}> = ({
  supplier,
  activeIndex,
  loading,
  supplierTerms,
  supplierTermsLoading,
  setLoading,
  setActiveIndex,
  setFormSteps,
}) => {
  const api = useApi();
  const utils = useUtils();
  const dispatch = useDispatch();
  const router = useIonRouter();

  const step1FormId = useId();
  const step2FormId = useId();
  const step3FormId = useId();
  const step4FormId = useId();
  const formSteps: FormStep[] = [
    { formId: step1FormId, label: 'General' },
    { formId: step2FormId, label: 'Billing' },
    { formId: step3FormId, label: 'Ship To' },
    { formId: step4FormId, label: 'Terms' },
  ];
  const authUser: AppUserSelf | null = useSelector(
    (state: any) => state.auth.user
  );

  const [swiperInstance, setSwiperInstance] = useState<SwiperInterface>();
  const [step1FormValid, setStep1FormValid] = useState<boolean>(false);
  const [step2FormValid, setStep2FormValid] = useState<boolean>(false);
  const [step3FormValid, setStep3FormValid] = useState<boolean>(false);
  const [step4FormValid, setStep4FormValid] = useState<boolean>(false);

  const [step1FormSubmitted, setStep1FormSubmitted] = useState<boolean>(false);
  const [step2FormSubmitted, setStep2FormSubmitted] = useState<boolean>(false);
  const [step3FormSubmitted, setStep3FormSubmitted] = useState<boolean>(false);
  const [step4FormSubmitted, setStep4FormSubmitted] = useState<boolean>(false);

  const [step1FormData, setStep1FormData] = useState<ApplicationStep1Schema>();
  const [step2FormData, setStep2FormData] = useState<ApplicationStep2Schema>();
  const [step3FormData, setStep3FormData] = useState<ShipToFormData[]>([]);
  const [step4FormData, setStep4FormData] = useState<ApplicationStep4Schema>();

  const onSubmitStep1Form = (data: ApplicationStep1Schema) => {
    setStep1FormData(data);
    setActiveIndex(1);
  };
  const onSubmitStep2Form = (data: ApplicationStep2Schema) => {
    setStep2FormData(data);
    setActiveIndex(2);
  };
  const onSubmitStep3Form = (data: ShipToFormData[]) => {
    setStep3FormData(data);
    setActiveIndex(3);
  };
  const onSubmitStep4Form = (data: ApplicationStep4Schema) => {
    setStep4FormData(data);
    if (step1FormValid && step2FormValid && step3FormValid && step4FormValid) {
      onSubmitApplication();
    } else {
      setStep1FormSubmitted(true);
      setStep2FormSubmitted(true);
      setStep3FormSubmitted(true);
      setStep4FormSubmitted(true);
      dispatch(
        setToast({
          color: 'danger',
          message: 'Please fix errors before submitting.',
        })
      );
    }
  };

  /**
   * Run once, when component is mounted.
   */
  useEffect(() => {
    setFormSteps(formSteps);
  }, []);

  /**
   * Automatically hit slideTo when activeIndex is changed
   */
  useEffect(() => {
    slideTo(activeIndex);
  }, [activeIndex]);

  // use callbacks to set things like the "setSupplierDocsAcceptedCount" state
  const slideTo = (
    index: number,
    onBeforeSlide?: () => void,
    onAfterSlide?: () => void
  ) => {
    if (swiperInstance) {
      onBeforeSlide && onBeforeSlide();
      swiperInstance.slideTo(index);
      onAfterSlide && onAfterSlide();
    }
  };

  const onActiveIndexChange = (e?: any) => {
    const index = swiperInstance?.activeIndex;
    setActiveIndex(index || 0);
  };

  const fieldsToCsv = (fields: ApplicationField[]) => {
    const csvFields: Array<string[]> = [];
    // turn fields into multi-dimensional array
    fields.forEach((field) => {
      csvFields.push([field.name, field.value]);
    });
    // convert array to a csv string
    const csvString = utils.makeCsv(csvFields);
    // convert to base64 encoded string
    const encodedString = Buffer.from(csvString).toString('base64');
    // finally create the base64 string with the correct data type
    const base64String = `data:text/csv;base64,${encodedString}`;
    return base64String;
  };

  const onSubmitApplication = async () => {
    const formData: AccountApplicationSchema[] = [];
    step3FormData.forEach((shipTo) => {
      const fields: ApplicationField[] = [
        {
          key: 'orgName',
          name: 'Organization Name',
          value: step1FormData?.orgName,
        },
        {
          key: 'orgContactName',
          name: 'Organization Contact Name',
          value: step1FormData?.orgContactName,
        },
        {
          key: 'orgContactEmail',
          name: 'Organization Contact Email',
          value: step1FormData?.orgContactEmail,
        },
        {
          key: 'primaryGpo',
          name: 'Primary GPO Affiliation',
          value: step1FormData?.otherGpo
            ? step1FormData.otherGpo
            : step1FormData?.primaryGpo,
        },
        {
          key: 'dscsaContactName',
          name: 'DSCSA Contact Name',
          value: step1FormData?.dscsaContactName,
        },
        {
          key: 'dscsaContactEmail',
          name: 'DSCSA Contact Email',
          value: step1FormData?.dscsaContactEmail,
        },
        {
          key: 'dscsaContactPhone',
          name: 'DSCSA Contact Phone',
          value: step1FormData?.dscsaContactPhoneExt
            ? `${step1FormData?.dscsaContactPhone} Extension ${step1FormData?.dscsaContactPhoneExt}`
            : step1FormData?.dscsaContactPhone,
        },

        {
          key: 'billingEmail',
          name: 'Email Address for Billing',
          value: step2FormData?.billingEmail,
        },
        {
          key: 'billingAddress1',
          name: 'Billing Street Address',
          value: step2FormData?.billingAddress1,
        },
        {
          key: 'billingAddress2',
          name: 'Billing Suite, Unit, etc',
          value: step2FormData?.billingAddress2,
        },
        {
          key: 'billingCity',
          name: 'Billing City',
          value: step2FormData?.billingCity,
        },
        {
          key: 'billingState',
          name: 'Billing State',
          value: step2FormData?.billingState,
        },
        {
          key: 'billingZip',
          name: 'Billing Zip',
          value: step2FormData?.billingZip,
        },

        {
          key: 'classOfTrade',
          name: 'Class Of Trade',
          value: shipTo.classOfTrade,
        },
        {
          key: 'address1',
          name: 'Ship-to Street address',
          value: shipTo.address1,
        },
        {
          key: 'address2',
          name: 'Ship-to Suite, Unit, etc',
          value: shipTo.address2,
        },
        { key: 'city', name: 'Ship-to City', value: shipTo.city },
        { key: 'state', name: 'Ship-to State', value: shipTo.state },
        { key: 'zip', name: 'Ship-to Zip', value: shipTo.zip },
        {
          key: 'deaLicenseNum',
          name: 'Dea License #',
          value: shipTo.deaLicenseNum,
        },
        {
          key: 'deaLicenseExpiration',
          name: 'Dea License Expiration',
          value: shipTo.deaLicenseExpiration,
        },
        {
          key: 'stateLicenseNum',
          name: 'State License #',
          value: shipTo.stateLicenseNum,
        },
        {
          key: 'stateLicenseExpiration',
          name: 'State License Expiration',
          value: shipTo.stateLicenseExpiration,
        },
        {
          key: 'gcp',
          name: 'Global Company Prefix (GCP)',
          value: shipTo.gcp,
        },
        {
          key: 'gln',
          name: 'Global Locator Number (GLN)',
          value: shipTo.gln,
        },
        {
          key: 'hin',
          name: 'Hospital Identifier Number (HIN)',
          value: shipTo.hin,
        },
        {
          key: 'applicantName',
          name: 'Applicant Name',
          value: `${authUser!.first_name} ${authUser!.last_name}`,
        },
        {
          key: 'applicantEmail',
          name: 'Applicant Email',
          value: authUser!.email_address,
        },
      ];

      const term_ids: number[] = supplierTerms.map(
        (term) => term.supplier_term_id
      );

      formData.push({
        location_id: shipTo.location_id,
        location_name: shipTo.location_name,
        supplier_id: supplier?.supplier_id,
        fields: fields,
        supplier_term_ids: term_ids,
        dea_license: {
          name: 'DEA License',
          filesize: shipTo.deaLicenseFilesize || null,
          original_filename: shipTo.deaLicenseFilename || null,
          content: shipTo.deaLicenseFileBase64,
        },
        state_license: {
          name: 'State License',
          filesize: shipTo.stateLicenseFilesize || null,
          original_filename: shipTo.stateLicenseFilename || null,
          content: shipTo.stateLicenseFileBase64,
        },
        fields_csv: {
          name: 'Account Wizard Fields',
          filesize: null,
          original_filename: 'account_wizard_fields.csv',
          content: fieldsToCsv(fields),
        },
      });
    });
    submitApplication(formData);
  };

  const submitApplication = (formData: AccountApplicationSchema[]) => {
    setLoading(true);
    api
      .post(`account_application`, formData)
      .then((response) => {
        if (response.status === 201) {
          dispatch(
            setToast({
              header: `Successfully Submitted!`,
              message: `Please allow 30 days from receipt to verify and complete onboarding information for set up of the Sandoz Trulla Direct account and to provide the Agreement start date and end date to the Customer.`,
              duration: -1,
              buttons: [
                {
                  text: 'Close',
                  role: 'cancel',
                },
              ],
            })
          );
          router.push('/', 'back', 'pop');
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <IonRow>
        <IonCol size="6" sizeMd="3" className="ion-padding-horizontal">
          <StepCard
            label="General"
            index={0}
            clickable={true}
            setActiveIndex={setActiveIndex}
            isActive={activeIndex === 0}
            isComplete={step1FormSubmitted && step1FormValid}
            isInvalid={step1FormSubmitted && !step1FormValid}
            disabled={loading}
          />
        </IonCol>
        <IonCol size="6" sizeMd="3" className="ion-padding-end">
          <StepCard
            label="Billing"
            index={1}
            clickable={step1FormSubmitted}
            setActiveIndex={setActiveIndex}
            isActive={activeIndex === 1}
            isComplete={step2FormSubmitted && step2FormValid}
            isInvalid={step2FormSubmitted && !step2FormValid}
            disabled={loading}
          />
        </IonCol>
        <IonCol size="6" sizeMd="3" className="ion-padding-end">
          <StepCard
            label="Ship To"
            index={2}
            clickable={step2FormSubmitted}
            setActiveIndex={setActiveIndex}
            isActive={activeIndex === 2}
            isComplete={step3FormSubmitted && step3FormValid}
            isInvalid={step3FormSubmitted && !step3FormValid}
            disabled={loading}
          />
        </IonCol>
        <IonCol size="6" sizeMd="3" className="ion-padding-end">
          <StepCard
            label="Terms"
            index={3}
            clickable={step3FormSubmitted}
            setActiveIndex={setActiveIndex}
            isActive={activeIndex === 3}
            isComplete={step4FormSubmitted && step4FormValid}
            isInvalid={step4FormSubmitted && !step4FormValid}
            disabled={loading}
          />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <Divider text={formSteps[activeIndex].label} className="light" />
        </IonCol>
      </IonRow>
      <IonRow>
        {/* ACCOUNT WIZARD CONTAINER */}
        <IonCol>
          <Swiper
            onSwiper={(swiper) => setSwiperInstance(swiper)}
            modules={[IonicSlides]}
            initialSlide={0}
            speed={400}
            allowTouchMove={false}
            onActiveIndexChange={() => onActiveIndexChange()}
          >
            {/* STEP 1 | GENERAL */}
            <SwiperSlide>
              <ApplicationStep1
                formId={step1FormId}
                loading={loading}
                setIsValid={setStep1FormValid}
                setIsSubmitted={setStep1FormSubmitted}
                onSubmitted={onSubmitStep1Form}
              />
            </SwiperSlide>

            {/* STEP 2 | BILLING */}
            <SwiperSlide>
              <ApplicationStep2
                formId={step2FormId}
                loading={loading}
                setIsValid={setStep2FormValid}
                setIsSubmitted={setStep2FormSubmitted}
                onSubmitted={onSubmitStep2Form}
              />
            </SwiperSlide>

            {/* STEP 3 | SHIP TO */}
            <SwiperSlide>
              <ApplicationStep3
                formId={step3FormId}
                loading={loading}
                setIsValid={setStep3FormValid}
                setIsSubmitted={setStep3FormSubmitted}
                onSubmitted={onSubmitStep3Form}
              />
            </SwiperSlide>

            {/* STEP 4 | TERMS */}
            <SwiperSlide>
              <ApplicationStep4
                formId={step4FormId}
                loading={loading}
                supplierTerms={supplierTerms}
                supplierTermsLoading={supplierTermsLoading}
                setIsValid={setStep4FormValid}
                setIsSubmitted={setStep4FormSubmitted}
                onSubmitted={onSubmitStep4Form}
                supplier={supplier}
              />
            </SwiperSlide>
          </Swiper>
        </IonCol>
      </IonRow>
    </>
  );
};
