import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { FormManageAccountsAdd } from 'src/components/shared/FormManageAccountsAdd';
import { FormManageAccountsList } from 'src/components/shared/FormManageAccountsList';
import { LogoSupplier } from 'src/components/shared/LogoSupplier';
import { SupplierConfig } from 'src/config/supplierConfigurations';
import { useUtils } from 'src/hooks/useUtils';
import { Account } from 'src/interfaces/Account';
import { Location } from 'src/interfaces/Location';
import { Supplier } from 'src/interfaces/Supplier';
import { useEffect, useState } from 'react';

export const FormManageAccounts: React.FC<{
  loading: boolean;
  setLoading: (value: boolean) => void;
  supplier: Supplier;
  location: Location;
  existingAccounts?: Account[];
  newAccountIds: number[];
  setNewAccountIds: (accountIds: number[]) => void;
  searched?: boolean;
  setSearched?: (value: boolean) => void;
  editAccount?: (account: Account) => void;
  submitTrigger: number;
  onClickAddMore?: () => void;
}> = ({
  loading,
  setLoading,
  supplier,
  location,
  existingAccounts,
  newAccountIds,
  setNewAccountIds,
  searched,
  setSearched,
  editAccount,
  submitTrigger,
  onClickAddMore,
}) => {
  const utils = useUtils();

  const [supplierConfig, setSupplierConfig] = useState<SupplierConfig | null>(
    null
  );
  useEffect(() => {
    const customConfig = utils.getSupplierConfig(supplier?.number);
    setSupplierConfig(customConfig);
  }, [supplier]);

  return (
    <IonGrid className="ion-no-padding">
      <IonRow>
        <IonCol
          size="12"
          className="d-flex ion-align-items-center ion-justify-content-center padding-xs"
        >
          <LogoSupplier src={supplier.logo} />
        </IonCol>
      </IonRow>
      {existingAccounts && existingAccounts.length > 0 ? (
        <FormManageAccountsList
          existingAccounts={existingAccounts}
          onClickAccount={(account: Account) => {
            if (editAccount) {
              editAccount(account);
            }
          }}
          submitTrigger={submitTrigger}
          onClickAddMore={() => {
            if (onClickAddMore) {
              onClickAddMore();
            }
          }}
        />
      ) : (
        <FormManageAccountsAdd
          loading={loading}
          setLoading={setLoading}
          newAccountIds={newAccountIds}
          setNewAccountIds={setNewAccountIds}
          searched={searched}
          setSearched={setSearched}
          supplierConfig={supplierConfig}
          supplier={supplier}
          location={location}
          submitTrigger={submitTrigger}
        />
      )}
    </IonGrid>
  );
};
