import { useMsal } from '@azure/msal-react';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCol,
  IonRow,
} from '@ionic/react';
import { loginRequest } from 'src/config/authConfig';
import { CardActions } from 'src/components/shared/CardActions';
import { LogoTrulla } from 'src/components/shared/LogoTrulla';
import { Page } from 'src/layouts/Page';
import { useSelector } from 'react-redux';
import { getErrorMessage } from 'src/utils/ErrorHelper';
import { _401_403_MESSAGE, ERROR_MESSAGE } from 'src/constants';

export const NoUserPage: React.FC = () => {
  const sessionExpired: boolean | null = useSelector(
    (state: any) => state.auth.sessionExpired
  );
  const noUserPageErrorCode: string = useSelector(
    (state: any) => state.app.noUserPageErrorCode
  );
  const is401Or403Code = noUserPageErrorCode === '401' || noUserPageErrorCode === '403'
  const errorMessage = getErrorMessage.get(noUserPageErrorCode) || ERROR_MESSAGE
  const { instance } = useMsal();
  const msalAccount = instance.getActiveAccount();

  return (
    <Page title="User Not Found">
      <IonRow style={{ marginTop: '5px' }}>
        <IonCol className="ion-text-center">
          <LogoTrulla />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="12" sizeMd="6" sizeLg="4" offsetMd="3" offsetLg="4">
          {/* 
            There are 2 cases here:
            • The user is authenticated with AD, but their session has expired
              • Show the user a "Your session has expired" message
              • Add a "Log back in" button
            • The user is authenticated with AD, but that user's email is not in the app database.
              • Show the user a message about not being able to retrieve their details.
              • Show a "Log out" button
          */}
          {sessionExpired === true ? (
            <IonCard>
              <IonCardContent>
                <p className="ion-text-center" style={{ marginBottom: '10px' }}>
                  Your session has expired
                </p>
              </IonCardContent>
              <CardActions className="flex-wrap">
                <IonButton
                  fill="outline"
                  color="primary"
                  onClick={() => instance.loginRedirect(loginRequest)}
                  expand="block"
                  className="fullWidth"
                >
                  Log back in
                </IonButton>
                <IonButton
                  fill="outline"
                  color="danger"
                  onClick={() => instance.logoutRedirect()}
                  expand="block"
                  className="fullWidth"
                >
                  Logout
                </IonButton>
              </CardActions>
            </IonCard>
          ) : (
            <IonCard>
              <IonCardTitle>
                <h2 className="ion-text-center">
                  <>
                    Hi {msalAccount?.idTokenClaims?.given_name}{' '}
                    {msalAccount?.idTokenClaims?.family_name}
                  </>
                </h2>
              </IonCardTitle>
              <IonCardContent>
                {
                  errorMessage && !is401Or403Code && (
                    <p className="ion-text-center" style={{ marginBottom: '10px' }}>
                      {errorMessage}
                    </p>
                  ) 
                }
                {
                  is401Or403Code && (
                    <p className="ion-text-center" style={{ marginBottom: '10px' }}>
                      {errorMessage}
                      {_401_403_MESSAGE}
                    </p>
                  )
                }
              </IonCardContent>
              <CardActions>
                <IonButton
                  fill="outline"
                  color="danger"
                  onClick={() => instance.logoutRedirect()}
                  expand="block"
                  className="fullWidth"
                >
                  Logout
                </IonButton>
              </CardActions>
            </IonCard>
          )}
        </IonCol>
      </IonRow>
    </Page>
  );
};
