import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { chevronBackCircleOutline, closeOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setContactUsCategory } from 'src/redux/appReducer';
import { ViewAdminSupport } from './ViewAdminSupport';
import { ViewNoneSelected } from './ViewNoneSelected';
import { ViewSupplierSupport } from './ViewSupplierSupport';
import { ViewTechnicalSupport } from './ViewTechnicalSupport';

export type SupportCategory = 'supplier' | 'admin' | 'technical';

export const ModalContactUs: React.FC<{
  isOpen: boolean;
  onDismiss: () => void;
}> = ({ isOpen, onDismiss }) => {
  const category: SupportCategory | null = useSelector(
    (state: any) => state.app.contactUsCategory
  );
  const dispatch = useDispatch();
  const [search, setSearch] = useState<string | null>(null);

  // reset search when the user clicks "Start Over"
  useEffect(() => {
    if (!category) {
      setSearch(null);
    }
  }, [category]);

  const handleDismiss = () => {
    dispatch(setContactUsCategory(null));
    onDismiss();
  };

  return (
    <IonModal
      isOpen={isOpen}
      onIonModalWillDismiss={handleDismiss}
      className="modalContactUs"
    >
      <IonHeader>
        <IonToolbar>
          {category && (
            <IonButtons slot="start">
              <IonButton onClick={() => dispatch(setContactUsCategory(null))}>
                <IonIcon slot="start" icon={chevronBackCircleOutline} />
                Start Over
              </IonButton>
            </IonButtons>
          )}

          <IonTitle>
            {!category && 'Contact'}
            {category === 'admin' && 'Contact an Admin'}
            {category === 'supplier' && 'Contact a Supplier'}
            {category === 'technical' && 'Contact Trulla'}
          </IonTitle>

          <IonButtons slot="end">
            <IonButton onClick={handleDismiss} fill="clear">
              <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
        {(category === 'supplier' || category === 'admin') && (
          <IonToolbar>
            <IonSearchbar
              autocomplete="off"
              placeholder={`Search for ${
                category === 'supplier' ? 'a supplier' : ''
              }${category === 'admin' ? 'an admin' : ''}...`}
              value={search}
              onIonChange={(e) => setSearch(e.detail.value as string)}
              className="ion-no-padding"
            />
          </IonToolbar>
        )}
      </IonHeader>
      <IonContent>
        {!category && (
          <ViewNoneSelected
            handleSelect={(category: SupportCategory) => {
              dispatch(setContactUsCategory(category));
            }}
          />
        )}
        {category === 'admin' && <ViewAdminSupport search={search} />}
        {category === 'supplier' && <ViewSupplierSupport search={search} />}
        {category === 'technical' && <ViewTechnicalSupport />}
      </IonContent>
    </IonModal>
  );
};
