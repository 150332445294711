import { zodResolver } from '@hookform/resolvers/zod';
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonList,
  IonRow,
} from '@ionic/react';
import { FormInput } from 'src/components/shared/FormInput';
import { SelectState } from 'src/components/shared/SelectState';
import { Location } from 'src/interfaces/Location';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setToast } from 'src/redux/appReducer';
import { z } from 'zod';
import { v } from 'src/utils/validators';
import { useApi } from 'src/hooks/useApi';
import { Address } from 'src/interfaces/Address';

const applicationStep2Schema = z.object({
  billingEmail: v.email(),
  billingAddress1: v.address1(),
  billingAddress2: v.address2(),
  billingCity: v.city(),
  billingState: v.state(),
  billingZip: v.state(),
});
export type ApplicationStep2Schema = z.infer<typeof applicationStep2Schema>;

export const ApplicationStep2: React.FC<{
  formId: string;
  loading?: boolean;
  setIsValid: (isValid: boolean) => void;
  setIsSubmitted: (isSubmitted: boolean) => void;
  onSubmitted: (data: ApplicationStep2Schema) => void;
}> = ({ formId, loading, setIsValid, setIsSubmitted, onSubmitted }) => {
  const api = useApi();
  const dispatch = useDispatch();
  const selectedLocation: Location | null = useSelector(
    (state: any) => state.app.selectedLocation
  );

  const [billingAddress, setBillingAddress] = useState<Address | null>(null);
  const [addressLoading, setAddressLoading] = useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    register,
    getValues,
    watch,
    formState: { errors, isSubmitted, isValid },
  } = useForm<ApplicationStep2Schema>({
    resolver: zodResolver(applicationStep2Schema),
    defaultValues: {
      billingEmail: '',
      billingAddress1: billingAddress?.address1 || '',
      billingAddress2: billingAddress?.address2 || '',
      billingCity: billingAddress?.city || '',
      billingState: billingAddress?.state || '',
      billingZip: billingAddress?.postal_code || '',
    },
  });

  useEffect(() => {
    setIsValid(isValid);
  }, [isValid]);

  useEffect(() => {
    setIsSubmitted(isSubmitted);
  }, [isSubmitted]);

  /**
   * When selected location changes, we should get the billing address
   */
  useEffect(() => {
    if (selectedLocation) {
      getBillingAddress();
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (billingAddress) {
      populateInputs();
    } else {
      setValue('billingAddress1', '');
      setValue('billingAddress2', '');
      setValue('billingCity', '');
      setValue('billingState', '');
      setValue('billingZip', '');
    }
  }, [billingAddress]);

  const getBillingAddress = () => {
    if (!selectedLocation) return;
    setAddressLoading(true);
    // try to use billing address first. If location has no billing address, use the ship to address.
    const addressId =
      selectedLocation.billing_address_id || selectedLocation.address_id;
    api
      .get(`address/${addressId}`)
      .then((res) => {
        setBillingAddress(res.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setAddressLoading(false);
      });
  };

  const populateInputs = () => {
    if (billingAddress) {
      setValue('billingAddress1', billingAddress.address1);
      setValue('billingAddress2', billingAddress.address2 || '');
      setValue('billingCity', billingAddress.city);
      setValue('billingState', billingAddress.state);
      setValue('billingZip', billingAddress.postal_code);
      dispatch(
        setToast({
          color: 'primary',
          message:
            "We've pre-populated some inputs based on your selected location",
          duration: 7000,
          position: 'bottom',
          buttons: [
            {
              text: 'Ok',
              role: 'cancel',
            },
          ],
        })
      );
    }
  };

  const onSubmit: (data: ApplicationStep2Schema) => void = (data) => {
    setIsValid(true);
    onSubmitted(data);
  };

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <IonRow>
        <IonCol size="12" sizeLg="7">
          <IonCard className="fullWidth ion-text-left">
            <IonCardHeader>
              <IonCardTitle>Billing Address</IonCardTitle>
            </IonCardHeader>
            <IonCardContent className="ion-padding">
              <IonList lines="none" className="ion-margin-bottom">
                <IonRow>
                  <IonCol>
                    <FormInput
                      {...register('billingAddress1')}
                      label="Billing Street Address"
                      disabled={loading}
                      onIonChange={(e) => {
                        setValue('billingAddress1', e.detail.value as string);
                      }}
                      placeholder="1234 Example Blvd."
                      errorMessage={errors.billingAddress1?.message}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <FormInput
                      {...register('billingAddress2')}
                      label="Suite, Unit, etc. (optional)"
                      disabled={loading}
                      onIonChange={(e) => {
                        setValue('billingAddress2', e.detail.value as string);
                      }}
                      placeholder="1234 Example Blvd."
                      errorMessage={errors.billingAddress2?.message}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <FormInput
                      {...register('billingCity')}
                      label="City"
                      disabled={loading}
                      onIonChange={(e) => {
                        setValue('billingCity', e.detail.value as string);
                      }}
                      errorMessage={errors.billingCity?.message}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" sizeMd="6">
                    <Controller
                      name="billingState"
                      control={control}
                      render={({ field }) => {
                        return (
                          <SelectState
                            disabled={loading}
                            errorMessage={errors.billingState?.message}
                            rounded={true}
                            value={field.value}
                            onIonChange={(e) => {
                              setValue(
                                'billingState',
                                e.detail.value as string
                              );
                            }}
                          />
                        );
                      }}
                    />
                  </IonCol>
                  <IonCol size="12" sizeMd="6">
                    <FormInput
                      {...register('billingZip')}
                      label="Zip"
                      disabled={loading}
                      onIonChange={(e) => {
                        setValue('billingZip', e.detail.value as string);
                      }}
                      placeholder="xxxxx or xxxxx-xxxx"
                      errorMessage={errors.billingZip?.message}
                    />
                  </IonCol>
                </IonRow>
              </IonList>
            </IonCardContent>
          </IonCard>
        </IonCol>
        <IonCol size="12" sizeLg="5">
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>Billing Contact</IonCardTitle>
            </IonCardHeader>
            <IonCardContent className="ion-padding">
              <IonList lines="none">
                <IonRow>
                  <IonCol>
                    <FormInput
                      {...register('billingEmail')}
                      label="Email Address for Billing"
                      disabled={loading}
                      onIonChange={(e) => {
                        setValue('billingEmail', e.detail.value);
                      }}
                      placeholder="billing@organization.com"
                      helperText="Invoices can be sent via email"
                      errorMessage={errors.billingEmail?.message}
                    />
                  </IonCol>
                </IonRow>
              </IonList>
            </IonCardContent>
          </IonCard>
        </IonCol>
      </IonRow>
    </form>
  );
};
