import { zodResolver } from '@hookform/resolvers/zod';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonChip,
  IonCol,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonNote,
  IonRow,
  IonSearchbar,
  IonSkeletonText,
  IonSpinner,
  IonText,
  useIonAlert,
  useIonModal,
} from '@ionic/react';
import { addOutline, closeCircleOutline } from 'ionicons/icons';
import { Fragment, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { FormInput } from 'src/components/shared/FormInput';
import { LoadingInline } from 'src/components/shared/LoadingInline';
import { SelectRole } from 'src/components/shared/SelectRole';
import { UserAvatar } from 'src/components/shared/UserAvatar';
import { useApi } from 'src/hooks/useApi';
import { useAzureOnboardApi } from 'src/hooks/useAzureOnboardApi';
import { useUtils } from 'src/hooks/useUtils';
import { AppUser } from 'src/interfaces/AppUser';
import { AppUserMapLocation } from 'src/interfaces/AppUserMapLocation';
import { AssignedLocation } from 'src/interfaces/AssignedLocation';
import { Organization } from 'src/interfaces/Organization';
import { v } from 'src/utils/validators';
import { z } from 'zod';
import { ModalAssignLocations } from '../../buyer/ModalAssignLocations';
import './FormManageUser.css';
import { PopoverUserMeta } from './PopoverUserMeta';
import { Supplier } from 'src/interfaces/Supplier';
import { LocationWithAddress } from 'src/interfaces/LocationWithAddress';
import { AppUserSelf } from 'src/interfaces/AppUserSelf';

const formUserSchema = z.object({
  firstName: v.firstName(),
  lastName: v.lastName(),
  email: v.email(),
  roleId: z.number().int(),
  locationIds: z.array(z.number().int()),
});

export type FormUserSchema = z.infer<typeof formUserSchema>;

const flattenAssignedLocations = (assignedLocations: AssignedLocation[]) => {
  const flattened: number[] = [];
  assignedLocations.forEach((assignedLocation) => {
    flattened.push(assignedLocation.location_id);
  });
  return flattened;
};

export const FormManageUser: React.FC<{
  formId: string;
  user?: AppUser;
  onSubmit: (user: FormUserSchema) => void;
  loading: boolean;
  setChanged?: (changed: boolean) => void;
  revertToggle?: boolean;
}> = ({ formId, user, onSubmit, loading, setChanged, revertToggle }) => {
  const api = useApi();
  const azureOnboardApi = useAzureOnboardApi();
  const utils = useUtils();
  const organization: Organization | null = useSelector(
    (state: any) => state.app.organization
  );
  const supplier: Supplier | null = useSelector(
    (state: any) => state.supplier.supplier
  );
  const authUser: AppUserSelf | null = useSelector(
    (state: any) => state.auth.user
  );

  const [presentAlert] = useIonAlert();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [roleId, setRoleId] = useState<number | null>(4); // default to Analyst
  const [locationIds, setLocationIds] = useState<number[]>([]);

  const [mfaLoading, setMfaLoading] = useState(false);
  const [mfaEnabled, setMfaEnabled] = useState(false);

  const [userLocations, setUserLocations] = useState<AppUserMapLocation[]>([]);
  const [userLocationsLoading, setUserLocationsLoading] =
    useState<boolean>(false);

  const [assignedLocations, setAssignedLocations] = useState<
    AssignedLocation[]
  >([]);

  const [search, setSearch] = useState<string>('');
  const [allLocations, setAllLocations] = useState<LocationWithAddress[]>([]);
  const [allLocationsLoading, setAllLocationsLoading] =
    useState<boolean>(false);

  const [assignedLocationsFiltered, setAssignedLocationsFiltered] = useState<
    AssignedLocation[]
  >([]);

  const [presentLocations, dismissLocations] = useIonModal(
    ModalAssignLocations,
    {
      locations: allLocations.filter((location) => {
        const exists = !!assignedLocations.find(
          (loc) => loc.location_id === location.location_id
        );
        return !exists;
      }),
      onDismiss: (data?: AssignedLocation[], role?: string) => {
        dismissLocations(data, role);
        if (data) {
          setAssignedLocations([...assignedLocations, ...data]);
        }
      },
    }
  );

  const changed =
    firstName !== user?.first_name ||
    lastName !== user?.last_name ||
    email !== user?.email_address ||
    roleId !== user?.app_role_id ||
    flattenAssignedLocations(assignedLocations).toString() !==
      user?.location_ids.toString();

  const {
    handleSubmit,
    control,
    setValue,
    register,
    formState: { errors },
  } = useForm<FormUserSchema>({
    resolver: zodResolver(formUserSchema),
    defaultValues: {
      firstName: user?.first_name || '',
      lastName: user?.last_name || '',
      email: user?.email_address || '',
      roleId: user?.app_role_id || 4, // default to analyst
      locationIds: user?.location_ids || [],
    },
  });

  /**
   * When state changes, ensure all form values are updated.
   */
  useEffect(() => {
    setValue('firstName', firstName);
    setValue('lastName', lastName);
    setValue('email', email);
    if (roleId) {
      setValue('roleId', roleId);
    }
    setValue('locationIds', locationIds);
  }, [firstName, lastName, email, roleId, locationIds]);

  /**
   * When the user changes, populate the form with the user's data
   */
  useEffect(() => {
    if (user) {
      populateData();
    }
  }, [user]);

  /**
   * When "revertToggle" changes AND the component has already been populated with data,
   * revert the form to the original data
   */
  useEffect(() => {
    if (changed) {
      populateData();
    }
  }, [revertToggle]);

  /**
   * Tell the parent component that the data has changed
   */
  useEffect(() => {
    if (setChanged) {
      setChanged(changed);
    }
  }, [changed]);

  /**
   * When the userLocations change, update the assigned locations
   */
  useEffect(() => {
    if (!userLocations) {
      setAssignedLocations([]);
    } else {
      resetAssignedLocations();
    }
  }, [userLocations]);

  useEffect(() => {
    if (user) {
      getUserLocations();
      // only get MFA status if we know the user is in B2C, and they're active
      if (user.in_b2c && user.active) {
        getMfaStatus();
      }
    }
  }, [user]);

  useEffect(() => {
    getAllLocations();
  }, [organization]);

  useEffect(() => {
    filterAssignedLocations();
  }, [search, allLocations, assignedLocations]);

  useEffect(() => {
    setLocationIds(flattenAssignedLocations(assignedLocations));
  }, [assignedLocations]);

  const showLocations = () => {
    presentLocations({
      onWillDismiss: (e: any) => {},
    });
  };

  const populateData = () => {
    if (user) {
      setFirstName(user.first_name);
      setLastName(user.last_name || '');
      setEmail(user.email_address);
      setRoleId(user.app_role_id);
      setLocationIds(user.location_ids);
      resetAssignedLocations();
    }
  };

  const resetAssignedLocations = () => {
    if (!userLocations) {
      return;
    }
    let assigned: AssignedLocation[] = [];
    userLocations.forEach((userLocation: AppUserMapLocation) => {
      assigned.push({
        location_id: userLocation.location_id,
        name: userLocation.location_name,
      });
    });
    setAssignedLocations(assigned);
  };

  const getAllLocations = () => {
    if (!organization) {
      return;
    }
    setAllLocationsLoading(true);
    api
      .get(`organization/${organization.organization_id}/locations`, {
        order_by: 'name',
        order_by_desc: false,
      })
      .then((response) => {
        if (response.status === 200) {
          setAllLocations(response.data.result || []);
        }
        setAllLocationsLoading(false);
      })
      .catch((error) => {
        setAllLocationsLoading(false);
      });
  };

  const getUserLocations = () => {
    if (!user) {
      return;
    }
    setUserLocationsLoading(true);
    api
      .get(`app_user/${user.app_user_id}/app_user_map_locations`)
      .then((response) => {
        if (response.status === 200) {
          const data: AppUserMapLocation[] = response.data;
          setUserLocations(data);
        }
        setUserLocationsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setUserLocationsLoading(false);
      });
  };

  const getMfaStatus = () => {
    if (!user) return;
    setMfaLoading(true);
    azureOnboardApi
      .get(`mfastatus/${user.email_address}`)
      .then((response) => {
        if (response.status === 200) {
          setMfaEnabled(response.data);
        }
      })
      .finally(() => {
        setMfaLoading(false);
      });
  };

  const handleMfaToggle = (value: boolean) => {
    if (!user) return;
    presentAlert({
      header: `${value ? 'Enable' : 'Disable'} MFA for`,
      message: `${utils.getFullName(user)}`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: value ? 'Enable' : 'Disable',
          cssClass: value ? '' : 'text-color-danger',
          role: 'confirm',
        },
      ],
      onWillDismiss: (e: any) => {
        if (e.detail.role === 'confirm') {
          updateMfaStatus(value);
        }
      },
    });
  };

  const updateMfaStatus = (value: boolean) => {
    if (!user) return;
    setMfaLoading(true);
    azureOnboardApi
      .put('mfastatus', {
        email: user.email_address,
        mfaValue: value ? true : false,
      })
      .then((response) => {
        if (response.status === 200) {
          setMfaEnabled(value);
        }
      })
      .finally(() => {
        setMfaLoading(false);
      });
  };

  const onClickResetMfaChoice = () => {
    // TODO: confirm
    resetMfaChoice();
  };

  const resetMfaChoice = () => {
    if (!user) return;
    setMfaLoading(true);
    azureOnboardApi
      .post('resetmfachoices', {
        email: user.email_address,
      })
      .then((response) => {
        if (response.status === 200) {
          //
        }
      })
      .finally(() => {
        setMfaLoading(false);
      });
  };

  const filterAssignedLocations = () => {
    setAssignedLocationsFiltered(
      assignedLocations.filter(
        (location: AssignedLocation) =>
          location.name &&
          location.name.toLowerCase().includes(search.toLowerCase())
      )
    );
  };

  const removeLocation = (assignedLocation: AssignedLocation) => {
    const newAssignedLocations = assignedLocations.filter(
      (location) => location.location_id !== assignedLocation.location_id
    );
    setAssignedLocations(newAssignedLocations);
  };

  const onClickRemoveAllLocations = () => {
    presentAlert({
      header: 'Remove all assigned Locations?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Remove All',
          role: 'confirm',
        },
      ],
      onWillDismiss: (e: any) => {
        if (e.detail.role === 'confirm') {
          removeAllLocations();
        }
      },
    });
  };

  const removeAllLocations = () => {
    setAssignedLocations([]);
  };

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <IonRow>
        <IonCol size="12" sizeLg="6" offsetLg={supplier ? '3' : undefined}>
          <IonCard className="userCard">
            {!loading && !user ? (
              <IonCardHeader>
                <IonCardTitle>User Info</IonCardTitle>
              </IonCardHeader>
            ) : null}
            <IonCardContent className="ion-no-padding">
              <IonRow>
                {loading ? (
                  <IonCol size="12" className="ion-padding ion-text-center">
                    <IonSkeletonText animated={true} className="avatar " />
                    <IonSpinner
                      name="crescent"
                      color="primary"
                      className="ion-margin-vertical"
                    />
                  </IonCol>
                ) : (
                  <>
                    {user ? (
                      <IonCol size="12" className="ion-padding ion-text-center">
                        <UserAvatar user={user} />
                        <p className="font-size-xl">
                          {utils.getFullName(user)}
                        </p>
                        <p className="font-size-small">{user.email_address}</p>
                        {user && <PopoverUserMeta user={user} />}
                        {user?.active === false && (
                          <IonText color="danger">
                            <h2 className="ion-text-center font-weight-500 font-style-italic font-size-xl">
                              Deactivated
                            </h2>
                          </IonText>
                        )}
                      </IonCol>
                    ) : null}
                  </>
                )}
                <IonCol size="12">
                  <IonList lines="none">
                    <IonRow className="ion-padding-horizontal ion-padding-bottom">
                      <IonCol size="12">
                        <FormInput
                          label="First Name"
                          disabled={loading || (user && !user.active)}
                          onIonChange={(e) => {
                            setFirstName(e.detail.value as string);
                          }}
                          {...register('firstName')}
                          errorMessage={errors.firstName?.message}
                        />
                      </IonCol>
                      <IonCol size="12">
                        <FormInput
                          label="Last Name"
                          disabled={loading || (user && !user.active)}
                          onIonChange={(e) => {
                            setLastName(e.detail.value as string);
                          }}
                          {...register('lastName')}
                          errorMessage={errors.lastName?.message}
                        />
                      </IonCol>
                      <IonCol size="12">
                        <FormInput
                          label="Email"
                          readonly={!!user && !utils.isSuperAdmin(authUser)}
                          disabled={loading || (user && !user.active)}
                          onIonChange={(e) => {
                            setEmail(e.detail.value as string);
                          }}
                          {...register('email')}
                          errorMessage={errors.email?.message}
                          helperText={
                            !!user && utils.isSuperAdmin(authUser)
                              ? 'As a Trulla Admin, you may edit the email address field. It will display as readonly to other roles.'
                              : undefined
                          }
                        />
                      </IonCol>
                      <IonCol size="12">
                        {/* If the user is a super, don't display the dropdown */}
                        {(user && !utils.isSuperAdmin(user)) || !user ? (
                          <Controller
                            name="roleId"
                            control={control}
                            render={({ field }) => {
                              return (
                                <SelectRole
                                  disabled={loading || (user && !user.active)}
                                  errorMessage={errors.roleId?.message}
                                  value={roleId || undefined}
                                  onIonChange={(e) => {
                                    setRoleId(+e.detail.value);
                                  }}
                                />
                              );
                            }}
                          />
                        ) : null}
                      </IonCol>
                    </IonRow>
                  </IonList>
                </IonCol>
              </IonRow>
              {user?.in_b2c && user?.active && (
                <IonRow className="ion-no-padding ion-no-margin">
                  <IonCol size="12" className="ion-no-padding ion-no-margin">
                    <IonItemDivider>
                      <IonLabel>Multi-factor Authentication (MFA)</IonLabel>
                    </IonItemDivider>
                    <IonList lines="full">
                      <IonItem className="ion-margin-bottom">
                        <IonLabel>
                          {mfaLoading ? (
                            <IonChip color="medium">Loading...</IonChip>
                          ) : (
                            <>
                              {mfaEnabled ? (
                                <IonChip color="success">Enabled</IonChip>
                              ) : (
                                <IonChip color="warning">Disabled</IonChip>
                              )}
                            </>
                          )}
                        </IonLabel>
                        <div>
                          {mfaEnabled ? (
                            <IonButton
                              color="danger"
                              onClick={() => handleMfaToggle(false)}
                              disabled={loading || mfaLoading}
                              size="small"
                            >
                              Disable MFA
                            </IonButton>
                          ) : (
                            <IonButton
                              color="primary"
                              onClick={() => handleMfaToggle(true)}
                              disabled={loading || mfaLoading}
                              size="small"
                            >
                              Enable MFA
                            </IonButton>
                          )}
                        </div>
                        {mfaLoading ? (
                          <IonNote slot="helper" style={{ width: '100%' }}>
                            <IonSkeletonText
                              animated={true}
                              style={{ width: '100%' }}
                            />
                            <IonSkeletonText
                              animated={true}
                              style={{ width: '300px', maxWidth: '100%' }}
                            />
                          </IonNote>
                        ) : (
                          <>
                            {mfaEnabled ? (
                              <IonNote slot="helper">
                                Multi-Factor Authentication is enabled for this
                                user. They will be required to perform an
                                additional verification step using their
                                preferred method when logging in.
                              </IonNote>
                            ) : (
                              <IonNote slot="helper">
                                Multi-Factor Authentication is disabled for this
                                user
                              </IonNote>
                            )}
                          </>
                        )}
                      </IonItem>
                    </IonList>
                    {mfaEnabled && (
                      <div className="d-flex ion-align-items-center ion-justify-content-end padding-sm">
                        <IonButton
                          onClick={onClickResetMfaChoice}
                          disabled={loading || mfaLoading}
                          slot="end"
                          fill="outline"
                          size="small"
                          data-tooltip-id="global-tooltip"
                          data-tooltip-place="bottom"
                          data-tooltip-content="Reset the user's preferred MFA choices. This will prompt the user to re-configure their MFA settings."
                        >
                          Reset MFA Choices
                        </IonButton>
                      </div>
                    )}
                  </IonCol>
                </IonRow>
              )}
            </IonCardContent>
          </IonCard>
          {/* <IonCard>
            <IonCardHeader>
              <IonCardTitle>Authentication Settings</IonCardTitle>
            </IonCardHeader>
            <IonCardContent></IonCardContent>
          </IonCard> */}
        </IonCol>
        {/* ASSIGNED LOCATIONS - only display if buyer */}
        {!supplier ? (
          <IonCol size="12" sizeLg="6">
            <IonCard>
              <IonCardHeader style={{ paddingBottom: 0 }}>
                <div className="fullWidth d-flex ion-justify-content-between ion-align-items-end">
                  <IonCardTitle>
                    Assigned Locations{' '}
                    {assignedLocations ? `(${assignedLocations.length})` : null}
                  </IonCardTitle>
                  {!user || (user && user.active) ? (
                    <div>
                      <IonButton
                        className="ion-no-margin"
                        size="small"
                        color="danger"
                        fill="clear"
                        onClick={onClickRemoveAllLocations}
                        disabled={loading || assignedLocations.length === 0}
                      >
                        Remove All
                      </IonButton>
                      <IonButton
                        className="ion-no-margin"
                        size="small"
                        onClick={showLocations}
                        disabled={loading}
                      >
                        Add
                        <IonIcon icon={addOutline} slot="end"></IonIcon>
                      </IonButton>
                    </div>
                  ) : null}
                </div>
              </IonCardHeader>
              <IonCardContent className="ion-no-padding ion-padding-horizontal ion-padding-bottom">
                <IonSearchbar
                  className="ion-no-padding"
                  placeholder="Search Assigned Locations..."
                  onIonChange={(e) => setSearch(e.detail.value as string)}
                />
                {userLocationsLoading ? (
                  <LoadingInline />
                ) : (
                  <IonList>
                    {assignedLocationsFiltered &&
                      assignedLocationsFiltered.map(
                        (assignedLocation: AssignedLocation, index: any) => (
                          <Fragment key={assignedLocation.location_id}>
                            <IonChip style={{ height: 'unset' }}>
                              <IonLabel>{assignedLocation.name}</IonLabel>
                              {!user || (user && user.active) ? (
                                <IonIcon
                                  icon={closeCircleOutline}
                                  color="danger"
                                  onClick={() =>
                                    removeLocation(assignedLocation)
                                  }
                                ></IonIcon>
                              ) : null}
                            </IonChip>
                          </Fragment>
                        )
                      )}
                  </IonList>
                )}
              </IonCardContent>
            </IonCard>
          </IonCol>
        ) : null}
      </IonRow>
    </form>
  );
};
