import {
  IonButton,
  IonButtons,
  IonCard,
  IonChip,
  IonCol,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonTitle,
  IonToggle,
  IonToolbar,
} from '@ionic/react';
import { Breadcrumb, Breadcrumbs } from 'src/components/shared/Breadcrumbs';
import { FormInputLegacy } from 'src/components/shared/FormInputLegacy';
import { SelectAccountType } from 'src/components/shared/SelectAccountType';
import { Subtitle } from 'src/components/shared/Subtitle';
import { useApi } from 'src/hooks/useApi';
import { Supplier } from 'src/interfaces/Supplier';
import { SupplierSettings } from 'src/interfaces/SupplierSettings';
import {
  addOutline,
  closeOutline,
  cloudUploadOutline,
  saveOutline,
  settingsSharp,
} from 'ionicons/icons';
import { Page } from 'src/layouts/Page';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSettingApplicationEnabled } from 'src/redux/supplierReducer';
import styles from '../supplierStyles.module.css';
import { TableSupplierAccounts } from './TableSupplierAccounts';

export const AccountsPage: React.FC = () => {
  const api = useApi();
  const [csvFile, setCsvFile] = useState<File | null>(null);
  const supplier: Supplier | null = useSelector(
    (state: any) => state.supplier.supplier
  );
  const settings: SupplierSettings | null = useSelector(
    (state: any) => state.supplier.settings
  );
  const accountApplication: boolean = useSelector(
    (state: any) => state.supplier.settingApplicationEnabled
  );
  const accountSync: boolean = useSelector(
    (state: any) => state.supplier.settingAccountSyncEnabled
  );
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [addAccountOpen, setAddAccountOpen] = useState(false);
  const [uploadAccountsOpen, setUploadAccountsOpen] = useState(false);

  const changed =
    accountApplication !== settings?.account?.application?.enabled ||
    accountSync !== settings?.account?.sync?.enabled;

  console.log(changed);
  console.log(
    'settings.accountApplication',
    settings?.account?.application?.enabled
  );
  console.log('accountApplication', accountApplication);
  console.log('settings.accountSync', settings?.account?.sync?.enabled);
  console.log('accountSync', accountSync);

  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Accounts' },
  ];

  useEffect(() => {
    getSupplierAccounts();
  }, []);

  const onDismiss = () => {
    setIsOpen(false);
    setAddAccountOpen(false);
    setUploadAccountsOpen(false);
  };

  const onClickSave = () => {
    console.log('save');
  };

  const getSupplierAccounts = () => {
    if (!supplier) {
      return;
    }
    api.get(`/supplier/${supplier.supplier_id}/accounts`).then((response) => {
      console.log(response.data);
    });
  };

  return (
    <Page title="Supplier Accounts">
      <Breadcrumbs breadcrumbs={breadcrumbs}>
        {accountApplication && (
          <IonButton
            size="small"
            routerLink="/supplier/accounts/applications"
            routerDirection="forward"
          >
            View Applications
          </IonButton>
        )}

        <IonButton
          fill="clear"
          size="small"
          color="medium"
          onClick={() => setIsOpen(true)}
          title="Account Settings"
        >
          <IonIcon slot="icon-only" icon={settingsSharp} />
        </IonButton>
      </Breadcrumbs>
      <IonRow>
        <IonCol>
          <IonCard>
            <TableSupplierAccounts
              accountSync={accountSync}
              onClickAdd={() => setAddAccountOpen(true)}
              onClickUpload={() => setUploadAccountsOpen(true)}
            />
          </IonCard>
        </IonCol>
      </IonRow>

      {/* TODO: componentize and turn into form */}
      <IonModal
        isOpen={isOpen}
        onDidDismiss={onDismiss}
        className={styles.accountModal}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>Account Settings</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={onDismiss}>
                <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonItemDivider>
            <IonLabel>Account Sync</IonLabel>
          </IonItemDivider>
          <IonList lines="full">
            <IonItem className="ion-margin-bottom">
              <IonLabel>Status</IonLabel>
              {accountSync ? (
                <IonChip color="success">Enabled</IonChip>
              ) : (
                <IonChip color="warning">Disabled</IonChip>
              )}
              {/* <IonToggle
                checked={accountSync}
                onIonChange={(e: any) =>
                  dispatch(setSettingAccountSyncEnabled(e.detail.checked))
                }
                disabled={true}
              /> */}

              <div slot="helper">
                {accountSync ? (
                  <Subtitle>
                    Account numbers are being synced automatically through
                    integrations with the Supplier.
                  </Subtitle>
                ) : (
                  <Subtitle>
                    Account numbers are manually managed by the vendor in the
                    Trulla Direct Supplier Portal.
                  </Subtitle>
                )}
                <Subtitle className="font-style-italic font-weight-500">
                  Note: If you wish to make changes to the Account Sync feature,
                  please reach out to Trulla Support.
                </Subtitle>
              </div>
            </IonItem>
          </IonList>

          <IonItemDivider>
            <IonLabel>New Account Wizard</IonLabel>
          </IonItemDivider>
          <IonList lines="full">
            <IonItem>
              <IonLabel>Enable New Account Wizard</IonLabel>
              <IonToggle
                checked={accountApplication}
                onIonChange={(e: any) =>
                  dispatch(setSettingApplicationEnabled(e.detail.checked))
                }
              />
            </IonItem>

            <div
              className="ion-padding-horizontal"
              style={{ paddingTop: '5px', paddingBottom: '5px' }}
            >
              <Subtitle>
                Allow users to apply for a direct account within Trulla
              </Subtitle>
            </div>
          </IonList>
        </IonContent>
        <IonFooter>
          <IonToolbar>
            <div slot="end">
              <IonButton
                fill="clear"
                color="dark"
                size="small"
                onClick={() => onDismiss()}
              >
                Cancel
              </IonButton>
              <IonButton size="small" onClick={onClickSave} disabled={!changed}>
                Save
                <IonIcon icon={saveOutline} slot="end" />
              </IonButton>
            </div>
          </IonToolbar>
        </IonFooter>
      </IonModal>

      {/* TODO: componentize and turn into form */}
      <IonModal
        className={styles.accountModal}
        isOpen={addAccountOpen}
        onDidDismiss={onDismiss}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>Add Account</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={onDismiss} fill="clear">
                <IonIcon icon={closeOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent className="ion-padding-horizontal ion-padding-bottom">
          <IonList lines="none">
            <IonRow>
              <IonCol size="6">
                <IonItem>
                  <IonLabel position="stacked">Account Number</IonLabel>
                  <IonInput className="rounded" placeholder="" />
                </IonItem>
              </IonCol>
              <IonCol size="6">
                <SelectAccountType
                  value={null}
                  setValue={(number) => console.log(number)}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12">
                <IonItem>
                  <IonLabel position="stacked">Account Name</IonLabel>
                  <IonInput className="rounded" placeholder="" />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <FormInputLegacy
                  value={null}
                  setValue={(value) => console.log(value)}
                  name="address1"
                  label="Street Address"
                  placeholder="1234 Example Blvd."
                  labelPosition="stacked"
                  required={true}
                  rounded={true}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <FormInputLegacy
                  value={null}
                  setValue={(value) => console.log(value)}
                  name="address2"
                  label="Suite, Unit, etc. (optional)"
                  labelPosition="stacked"
                  required={false}
                  rounded={true}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <FormInputLegacy
                  value={null}
                  setValue={(value) => console.log(value)}
                  name="city"
                  label="City"
                  labelPosition="stacked"
                  required={true}
                  rounded={true}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12" sizeMd="6">
                <FormInputLegacy
                  value={null}
                  setValue={(value) => console.log(value)}
                  name="state"
                  inputType="state"
                  label=""
                  labelPosition="stacked"
                  required={true}
                  rounded={true}
                />
              </IonCol>
              <IonCol size="12" sizeMd="6">
                <FormInputLegacy
                  value={null}
                  setValue={(value) => console.log(value)}
                  name="zip"
                  label="Zip"
                  placeholder="xxxxx"
                  labelPosition="stacked"
                  required={true}
                  rounded={true}
                />
              </IonCol>
            </IonRow>
          </IonList>
        </IonContent>
        <IonFooter>
          <IonToolbar>
            <div slot="end">
              <IonButton
                color="dark"
                size="small"
                fill="clear"
                onClick={onDismiss}
              >
                Cancel
              </IonButton>
              <IonButton size="small" onClick={onDismiss}>
                Add <IonIcon icon={addOutline} slot="end" />
              </IonButton>
            </div>
          </IonToolbar>
        </IonFooter>
      </IonModal>

      {/* TODO: componentize and turn into form */}
      <IonModal
        className={styles.accountModal}
        isOpen={uploadAccountsOpen}
        onDidDismiss={onDismiss}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>Upload Accounts</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={onDismiss} fill="clear">
                <IonIcon icon={closeOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent className="ion-padding-horizontal ion-padding-bottom">
          <IonList lines="none">
            <FormInputLegacy
              label="Attach a CSV file"
              labelPosition="stacked"
              accept=".csv"
              placeholder="Supported file types: .csv"
              name="accountCsv"
              inputType="file"
              required={true}
              value={csvFile}
              setValue={setCsvFile}
              rounded={true}
            />
          </IonList>
        </IonContent>
        <IonFooter>
          <IonToolbar>
            <div slot="end">
              <IonButton
                color="dark"
                size="small"
                fill="clear"
                onClick={onDismiss}
              >
                Cancel
              </IonButton>
              <IonButton size="small" onClick={onDismiss}>
                Upload <IonIcon icon={cloudUploadOutline} slot="end" />
              </IonButton>
            </div>
          </IonToolbar>
        </IonFooter>
      </IonModal>
    </Page>
  );
};
