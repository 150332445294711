import {
  IonButton,
  IonIcon,
  IonText,
  useIonAlert,
  useIonRouter,
} from '@ionic/react';
import {
  checkmarkCircleOutline,
  refreshOutline,
  saveOutline,
} from 'ionicons/icons';
import { useEffect, useId, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Breadcrumb, Breadcrumbs } from 'src/components/shared/Breadcrumbs';
import {
  FormManageUser,
  FormUserSchema,
} from 'src/components/shared/FormManageUser';
import { ShowAlertOptions, useAlert } from 'src/hooks/useAlert';
import { useApi } from 'src/hooks/useApi';
import { useEndpoints } from 'src/hooks/useEndpoints';
import { AppUser } from 'src/interfaces/AppUser';
import { Supplier } from 'src/interfaces/Supplier';
import { Page } from 'src/layouts/Page';
import { setToast } from 'src/redux/appReducer';

export const UserPage: React.FC = () => {
  const api = useApi();
  const { id } = useParams<{ id: string }>();
  const { showAlert } = useAlert();
  const userFormId = useId();
  const router = useIonRouter();
  const { createB2cUser, deleteB2cUser } = useEndpoints();

  const dispatch = useDispatch();
  const [presentAlert] = useIonAlert();

  const [user, setUser] = useState<AppUser>();
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState(false);
  const [revertToggle, setRevertToggle] = useState(false);

  const supplier: Supplier | null = useSelector(
    (state: any) => state.supplier.supplier
  );

  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    {
      label: 'Settings',
      path: supplier ? '/supplier/settings' : '/settings',
      direction: 'back',
    },
    {
      label: 'Users',
      path: supplier ? '/supplier/settings/users' : '/settings/users',
      direction: 'back',
    },
    { label: user ? `${user.first_name} ${user.last_name}` : id },
  ];

  useEffect(() => {
    getUser();
  }, [id]);

  const getUser = () => {
    setLoading(true);
    api
      .get(`app_user/${id}`)
      .then((response) => {
        if (response.status === 200) {
          const data: AppUser = response.data;
          setUser(data);
        } else if (response?.response?.status === 403) {
          dispatch(
            setToast({
              color: 'danger',
              header: 'Error 403 Unauthorized',
              message:
                'You do not have the proper permissions to view this page.',
            })
          );
          router.push('/settings/users/', 'back');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const revertChanges = () => {
    setRevertToggle(!revertToggle);
  };

  const onClickDeactivate = () => {
    presentAlert({
      header: 'Deactivate the following user?',
      message: `${user?.first_name} ${user?.last_name}`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Deactivate',
          cssClass: 'text-color-danger',
          role: 'confirm',
        },
      ],
      onWillDismiss: (e: any) => {
        if (e.detail.role === 'confirm') {
          deactivateUser();
        }
      },
    });
  };

  const deactivateUser = async () => {
    if (!user) return;
    setLoading(true);
    api.delete(`app_user/${user?.app_user_id}`).then(async (response) => {
      if (response.status === 204) {
        await deleteB2cUser(
          user,
          (response: any) => {
            setLoading(false);
            getUser();
            dispatch(
              setToast({
                message: 'Successfully deactivated user',
              })
            );
          },
          (response: any) => {
            setLoading(false);
            getUser();
            const options: ShowAlertOptions = {
              header: 'Possible Issue',
              subHeader:
                'This user was removed from the Application, but could not be removed from the Authentication system',
              message:
                'This user has been removed from the Application, and will NOT be able to use Trulla Direct. However, this user could not be removed from the Authentication system. If this user is part of an organization using an Identity Provider, they will need to have their access revoked from that provider.',
              confirmText: 'Ok',
            };
            showAlert(options, (confirm: boolean) => {
              //
            });
          }
        );
      } else {
        setLoading(false);
        dispatch(
          setToast({ message: 'Error deactivating user', color: 'danger' })
        );
      }
    });
  };

  const onClickReactivate = () => {
    presentAlert({
      header: 'Reactivate the following user?',
      message: `${user?.first_name} ${user?.last_name}`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Reactivate',
          role: 'confirm',
        },
      ],
      onWillDismiss: (e: any) => {
        if (e.detail.role === 'confirm') {
          reactivateUser();
        }
      },
    });
  };

  const reactivateUser = () => {
    if (!user) return;
    setLoading(true);
    const userData = { ...user };
    userData.active = true;
    api
      .put(`app_user/${user?.app_user_id}`, userData)
      .then(async ({ data }) => {
        await createB2cUser(user, false, (response: any) => {
          setLoading(false);
          setUser(data[0]);
          dispatch(
            setToast({
              message: 'User has been reactivated successfully',
            })
          );
        });
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const updateUser = (data: FormUserSchema) => {
    if (!user) {
      return;
    }
    setLoading(true);
    const userData = {
      app_role_id: data.roleId,
      organization_id: user.organization_id,
      first_name: data.firstName,
      last_name: data.lastName,
      email_address: data.email,
      location_ids: data.locationIds,
    };
    api
      .put(`app_user/${user?.app_user_id}`, userData)
      .then(({ data }) => {
        const updatedUser = data[0];
        setUser(updatedUser);
        setLoading(false);
        dispatch(
          setToast({
            message: `User updated successfully`,
            icon: checkmarkCircleOutline,
          })
        );
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        dispatch(
          setToast({
            header: 'Error',
            message: 'There was an error updating this user',
            color: 'danger',
          })
        );
      });
  };

  const Footer = () => {
    return (
      <div className="d-flex ion-justify-content-end ion-align-items-center">
        {changed ? (
          <IonText color="danger">
            <p className="ion-no-margin ion-margin-end font-style-italic">
              Unsaved Changes
            </p>
          </IonText>
        ) : null}
        <IonButton
          disabled={loading || !changed}
          onClick={revertChanges}
          color="medium"
          fill="clear"
          className="ion-margin-end"
        >
          Cancel
        </IonButton>
        <IonButton
          form={userFormId}
          type="submit"
          disabled={loading || !changed}
        >
          Save
          <IonIcon slot="end" icon={saveOutline} />
        </IonButton>
      </div>
    );
  };

  return (
    <Page
      title="User Settings"
      footer={user && user?.active ? <Footer /> : undefined}
    >
      <Breadcrumbs breadcrumbs={breadcrumbs} title="User Detail">
        {user?.active ? (
          <IonButton
            color="danger"
            disabled={loading}
            onClick={onClickDeactivate}
            size="small"
          >
            Deactivate User
          </IonButton>
        ) : null}
        {user && !user.active ? (
          <IonButton
            color="primary"
            disabled={loading}
            onClick={onClickReactivate}
            size="small"
          >
            <IonIcon icon={refreshOutline} slot="start" />
            Reactivate User
          </IonButton>
        ) : null}
      </Breadcrumbs>
      <FormManageUser
        formId={userFormId}
        user={user}
        onSubmit={(data: FormUserSchema) => {
          updateUser(data);
        }}
        loading={loading}
        setChanged={(changed: boolean) => setChanged(changed)}
        revertToggle={revertToggle}
      />
    </Page>
  );
};
